import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor';
import { agencyPaths } from 'routes/paths';
import AgencyPitchRequirementService from 'api/agency/pitch-requirement/pitch-requirement.service';
import {
    PitchRequirementModel,
    PlayerPositionEnum,
    PitchRequirementStepEnum,
    PlayersDetailsResponse,
    CreatePitchRequirementModel,
} from 'api/agency/pitch-requirement/pitch-requirement.models';
import { IdName } from 'api/core/id-name';
import { PriceTypeEnum } from 'constants/enums';
import { getAuth, getAuthCurrency } from 'store/auth/authReducer';
import Range from 'api/core/range';
import { capitalizeEachFirstLetter, formatWithCommas } from 'services/utils';

export const MAX_ANNUAL_GROSS_SALARY = 500000000;
const MIN_ANNUAL_GROSS_SALARY = 0;

class PitchRequirementState {
    isLoading: boolean;
    currentStep: PitchRequirementStepEnum;
    positions: IdName[];
    requirement: PitchRequirementModel;
    playersDetails: PlayersDetailsResponse[];
    sourcesData: IdName[];
    playerAdPitchedNoteStatuses: IdName[];
    transferWindowsData: IdName[];
}

const defaultState: PitchRequirementState = {
    isLoading: false,
    currentStep: PitchRequirementStepEnum.PrimaryInformation,
    positions: [],
    requirement: {
        id: null,
        squad: null,
        source: null,
        contactName: '',
        transferPeriod: null,
        toBuy: true,
        toLoan: false,
        loan: null,
        buy: null,
        grossSalary: null,
        age: null,
        minHeight: null,
        playerPosition: null,
        isLeftFooted: false,
        moreInformation: '',
        playerId: null,
        pitchedPlayerStatusId: null,
        agencyId: null,
        currencyId: null,
    },
    playersDetails: [],
    sourcesData: [],
    playerAdPitchedNoteStatuses: [],
    transferWindowsData: [],
};

export type PitchRequirementValue = string | boolean | number | IdName;

const stateController = new StateController<PitchRequirementState>(
    'AGENCY/PITCH_REQUIREMENT',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                await Promise.allSettled([
                    dispatch(Actions.getPlayersDetails()),
                    dispatch(Actions.getSources()),
                    dispatch(Actions.getPlayerAdPitchedNoteStatuses()),
                    dispatch(Actions.getTransferWindows()),
                    dispatch(Actions.getPlayerPositions()),
                ]);

                const agencyId = getAuth(getState()).agencyId;
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        requirement: { ...prevState.requirement, agencyId },
                    }))
                );

                const { requirement } = Selectors.getRoot(getState());

                if (requirement.id) {
                    await dispatch(Actions.getAgentRequirement(requirement?.id));
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static getAgentRequirement(id: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await AgencyPitchRequirementService.getAgentRequirement(id);

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        requirement: {
                            ...prevState.requirement,
                            agencyId: data.agencyId,
                            source: data?.playerAdSource,
                            squad: data.squad,
                            contactName: data?.contactName,
                            transferPeriod: data.transferPeriod,
                            grossSalary: data?.grossSalaryAmount,
                            buy: data?.transferFeeAmount,
                            loan: data?.monthlyLoanBudgetAmount,
                            playerPosition: data?.generalPosition,
                            isLeftFooted: data.isLeftFooted,
                            currencyId: data.currencyId,
                            age: data.age,
                            minHeight: data.minHeight,
                            toBuy:
                                data?.transferFeeAmount && data?.grossSalaryAmount ? true : false,
                            toLoan: data?.monthlyLoanBudgetAmount ? true : false,
                            moreInformation: data?.moreInformation,
                            playerId: data?.player?.id,
                            pitchedPlayerStatusId: data.playerAdPitchedNoteStatus?.id,
                        },
                    }))
                );

                dispatch(Actions.goToStep(PitchRequirementStepEnum.PitchedPlayer));
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static createRequirement() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({ ...prevState, isLoading: true })));
            try {
                const { requirement } = Selectors.getRoot(getState());

                const request: CreatePitchRequirementModel = {
                    id: requirement.id ? requirement.id : null,
                    agencyId: requirement.agencyId,
                    playerAdSourceId: requirement.source.id,
                    contactName: requirement.contactName,
                    transferPeriod: requirement.transferPeriod,
                    transferFeeAmount: requirement.buy
                        ? Helpers.valueWithoutCommas(requirement.buy)
                        : null,
                    grossSalaryAmount: requirement.grossSalary
                        ? Helpers.valueWithoutCommas(requirement.grossSalary)
                        : null,
                    loanFeeAmount: requirement.loan
                        ? Helpers.valueWithoutCommas(requirement.loan)
                        : null,
                    generalPositionId: requirement.playerPosition?.id,
                    isLeftFooted: requirement.isLeftFooted,
                    currencyId: Selectors.getCurrency(getState()).id,
                    fromAge: requirement.age?.min,
                    toAge: requirement.age?.max,
                    minHeight: requirement.minHeight,
                    moreInformation: requirement.moreInformation,
                    squadId: requirement.squad?.id,
                    playerId: requirement.playerId,
                    playerAdPitchedNoteStatusId: requirement.pitchedPlayerStatusId,
                    type: requirement.toBuy ? PriceTypeEnum.Buy : PriceTypeEnum.Loan,
                };
                await AgencyPitchRequirementService.createAgentRequirement(request);
                historyAccessor.push(agencyPaths.pitchPage);
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(
                    stateController.setState((prevState) => ({ ...prevState, isLoading: false }))
                );
            }
        };
    }

    public static getPlayersDetails() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const playersDetails = await AgencyPitchRequirementService.getPlayersDetails();
                await dispatch(stateController.setState({ playersDetails }));
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static getPlayerPositions() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { positions } = await AgencyPitchRequirementService.getPlayerPositions();
                const positionsList = Helpers.positionsFormatted(positions);
                const position = positionsList.find((p) => p.id === PlayerPositionEnum.Goalkeeper);

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        positions: positions,
                        requirement: {
                            ...prevState.requirement,
                            playerPosition: position,
                        },
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static getSources() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await AgencyPitchRequirementService.getSources();

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        sourcesData: data,
                        requirement: {
                            ...prevState.requirement,
                            source: data[0],
                        },
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static getPlayerAdPitchedNoteStatuses() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const playerAdPitchedNoteStatuses =
                    await AgencyPitchRequirementService.getPlayerAdPitchedNoteStatuses();

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        playerAdPitchedNoteStatuses,
                        requirement: {
                            ...prevState.requirement,
                            pitchedPlayerStatusId: playerAdPitchedNoteStatuses[0].id,
                        },
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static getTransferWindows() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await AgencyPitchRequirementService.getTransferWindows();

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        transferWindowsData: data,
                        requirement: {
                            ...prevState.requirement,
                            transferPeriod: data[0].name,
                        },
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static goToPitchPage() {
        return (dispatch) => {
            dispatch(Actions.dispose());
            historyAccessor.push(agencyPaths.pitchPage);
        };
    }
    public static goToStep(step: PitchRequirementStepEnum) {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: step }));
        };
    }

    public static setPitchRequirementTransferType(type: PriceTypeEnum) {
        return (dispatch, getState: () => AppState) => {
            if (type === PriceTypeEnum.Buy) {
            } else {
            }
        };
    }

    public static setPitchRequirementValue(key: string, value?: PitchRequirementValue) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    requirement: {
                        ...prevState.requirement,
                        [key]: value,
                    },
                }))
            );
        };
    }

    public static clearPitchRequirementValue(key: string) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    requirement: {
                        ...prevState.requirement,
                        [key]: null,
                    },
                }))
            );
        };
    }

    public static setPitchRequirementPosition(position: IdName) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    requirement: {
                        ...prevState.requirement,
                        playerPosition: position,
                    },
                }))
            );
        };
    }

    public static setPlayerMinHeight(value: number) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    requirement: {
                        ...prevState.requirement,
                        minHeight: value,
                    },
                }))
            );
        };
    }

    public static onChangePlayerAge(value: Range<number>) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    requirement: {
                        ...prevState.requirement,
                        age: {
                            min: Number(value.min),
                            max: Number(value.max),
                        },
                    },
                }))
            );
        };
    }

    public static openPitchRequirementPage() {
        return (dispatch) => {
            historyAccessor.push(agencyPaths.pitchRequirement);
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.pitchRequirement;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).currentStep;
    public static getRequirement = (state: AppState) => Selectors.getRoot(state).requirement;
    public static getPlayerPositions = (state: AppState) => Selectors.getRoot(state).positions;
    public static getCurrency = (state: AppState) => getAuthCurrency(state);
    public static getTransferFeeValidation = (state: AppState) => {
        const { toBuy, buy } = Selectors.getRequirement(state);

        if (!toBuy) {
            return { hasError: false, text: null };
        }
        if (!buy) {
            return {
                hasError: true,
                text: 'You must enter a transfer fee amount',
            };
        }

        return { hasError: false, text: null };
    };

    public static getGrossSalaryValidation = (state: AppState) => {
        const { toBuy, grossSalary } = Selectors.getRequirement(state);

        if (!toBuy) {
            return { hasError: false, text: null };
        }

        if (!grossSalary) {
            return {
                hasError: true,
                text: 'You must enter a gross salary amount',
            };
        }
        const salaryAmount = Helpers.valueWithoutCommas(grossSalary);

        if (salaryAmount > MAX_ANNUAL_GROSS_SALARY) {
            return {
                hasError: true,
                text: `Maximum gross salary is ${formatWithCommas(MAX_ANNUAL_GROSS_SALARY)}`,
            };
        }

        return salaryAmount < MIN_ANNUAL_GROSS_SALARY
            ? {
                  hasError: true,
                  text: `Minimum required value is ${formatWithCommas(MIN_ANNUAL_GROSS_SALARY)}`,
              }
            : { hasError: false, text: null };
    };

    public static getMonthlyLoanBudgetValidation = (state: AppState) => {
        const { toLoan, loan } = Selectors.getRequirement(state);

        if (!toLoan) {
            return { hasError: false, text: null };
        }

        if (!loan) {
            return {
                hasError: true,
                text: 'You must enter a loan budget amount',
            };
        }

        const salaryAmount = Helpers.valueWithoutCommas(loan);

        return salaryAmount < 0
            ? {
                  hasError: true,
                  text: `Minimum required value is 0`,
              }
            : { hasError: false, text: null };
    };
}

class Helpers {
    public static valueWithoutCommas(value: string) {
        return parseInt(value.toString().replace(/,/g, ''));
    }

    public static positionsFormatted(positions: IdName[]) {
        const positionsToUpdate = [
            PlayerPositionEnum.CentreBack_All,
            PlayerPositionEnum.CentreBack_LeftFooted,
        ];
        const newNames = ['Centre Back (All)', 'Centre Back (Left Footed)'];

        positions.forEach((item) => {
            const index = positionsToUpdate.indexOf(item.id);
            if (index !== -1) {
                item.name = newNames[index];
            }
        });

        return [...positions];
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PitchRequirementState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
    Helpers as Helpers,
};
