import compose from 'lodash/fp/compose';
import {
    PLAYERSEARCH_PLAYER_SELECTED,
    PLAYERS_FETCH,
    PLAYERSEARCH_SCREEN_DISPOSE,
    PLAYERSEARCH_LOAD_SIMILAR_PLAYERS,
    // PLAYERS_INTEREST,
    PLAYER_ADS_ACTIVITIES_COMPARISON_FETCH,
    PLAYER_ADS_FETCH,
    PLAYERS_DECLARE_INTEREST_SAVE,
    PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE,
    PLAYERS_DECLARE_INTEREST,
    PLAYERS_INTEREST_SAVE
} from '../../actionTypes';
import { createSelector } from 'reselect';

const initialState = {
    ids: [],
    selectedId: null,
    similarPlayers: {},
    declareInterestProcessingIds: [],
    declareInterestToAgentProcessingIds: [],
    loanContributionProcessingIds: [],
    loanAvailabilityProcessingIds: [],
    transferAvailabilityProcessingIds: [],
    askingPriceProcessingIds: []
};

export default (players = initialState, action) => {
    switch (action.type) {

        case PLAYERS_DECLARE_INTEREST_SAVE.REQUEST: {
            const playerId = action.payload.playerId
            return { ...players, declareInterestProcessingIds: [...players.declareInterestProcessingIds, playerId] }
        }
        case PLAYERS_DECLARE_INTEREST_SAVE.SUCCESS: {
            const playerId = action.payload.request.playerId
            const isNotSimilarPlayersGrid = (players.similarPlayers || {}).output === undefined

            if (isNotSimilarPlayersGrid) {
                return { ...players, declareInterestProcessingIds: players.declareInterestProcessingIds.filter(item => item !== playerId) }
            } else {
                return { 
                    ...players,
                    declareInterestProcessingIds:  players.declareInterestProcessingIds.filter(item => item !== playerId) ,
                    similarPlayers: {
                        ...players.similarPlayers,
                        output: players.similarPlayers.output.map(item => {
                            if (item.playerId === playerId) {
                                return {
                                    ...item,
                                    player: {
                                        ...item.player,
                                        interestWasDeclared: true,
                                    },
                                };
                            } else {
                                return item;
                            }
                        }),
                    }
                }
            }
        }
        case PLAYERS_DECLARE_INTEREST_SAVE.ERROR: {
            const playerId = action.payload.playerId
            return { ...players, declareInterestProcessingIds: players.declareInterestProcessingIds.filter(item => item !== playerId) }
        }

        case PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE.REQUEST: {
            const playerId = action.payload.playerId
            return { ...players, declareInterestToAgentProcessingIds: [...players.declareInterestToAgentProcessingIds, playerId] }
        }
        case PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE.SUCCESS: {
            const playerId = action.payload.request.playerId
            const isNotSimilarPlayersGrid = (players.similarPlayers || {}).output === undefined

            if (isNotSimilarPlayersGrid) {
                return { ...players, declareInterestToAgentProcessingIds: players.declareInterestToAgentProcessingIds.filter(item => item !== playerId) }
            } else {
                return { 
                    ...players,
                    declareInterestToAgentProcessingIds:  players.declareInterestToAgentProcessingIds.filter(item => item !== playerId) ,
                    similarPlayers: {
                        ...players.similarPlayers,
                        output: players.similarPlayers.output.map(item => {
                            if (item.playerId === playerId) {
                                return {
                                    ...item,
                                    player: {
                                        ...item.player,
                                        interestWasDeclaredToAgent: true,
                                    },
                                };
                            } else {
                                return item;
                            }
                        }),
                    }
                }
            }
        }
        case PLAYERS_DECLARE_INTEREST_TO_AGENT_SAVE.ERROR: {
            const playerId = action.payload.playerId
            return { ...players, declareInterestToAgentProcessingIds: players.declareInterestToAgentProcessingIds.filter(item => item !== playerId) }
        }

        case PLAYERS_DECLARE_INTEREST: {
            const playerId = action.payload

            const isNotSimilarPlayersGrid = (players.similarPlayers || {}).output === undefined
            if (isNotSimilarPlayersGrid) {
                return players;
            } else {
                return {
                    ...players,
                    similarPlayers: {
                        ...players.similarPlayers,
                        output: players.similarPlayers.output.map(item => {
                            if (item.playerId === playerId) {
                                return {
                                    ...item,
                                    player: {
                                        ...item.player,
                                        interestWasDeclared: true,
                                    },
                                };
                            } else {
                                return item;
                            }
                        }),
                    },
                };
            }
        }

        case PLAYERSEARCH_SCREEN_DISPOSE: {
            return initialState;
        }

        case PLAYERSEARCH_LOAD_SIMILAR_PLAYERS.SUCCESS: {
            return { ...players, similarPlayers:  action.payload.data ? { ...action.payload.data, pageSize: action.payload.request.payload.pageSize } : {}};
        }

        case PLAYERS_INTEREST_SAVE.REQUEST: {
            const { playerId, requestType } = action.payload;
            
            const isAskingPriceAction = requestType === 4
            const isLoanContributionAction = requestType === 3
            const isLoanAvailabilityAction = requestType === 2
            const isTransferAvailabilityAction = requestType === 1

            return {
                ...players,
                loanContributionProcessingIds: isLoanContributionAction ? [...players.loanContributionProcessingIds, playerId] : players.loanContributionProcessingIds,
                loanAvailabilityProcessingIds: isLoanAvailabilityAction ? [...players.loanAvailabilityProcessingIds, playerId] : players.loanAvailabilityProcessingIds,
                askingPriceProcessingIds: isAskingPriceAction ? [...players.askingPriceProcessingIds, playerId] : players.askingPriceProcessingIds,
                transferAvailabilityProcessingIds: isTransferAvailabilityAction ? [...players.transferAvailabilityProcessingIds, playerId] : players.transferAvailabilityProcessingIds,
            };
        }
        case PLAYERS_INTEREST_SAVE.SUCCESS: {
            const { playerId, requestType } = action.payload.request;
            const map = {
                1: 'isTransferAvailabilityDeclared',
                2: 'isLoanAvailabilityDeclared',
                3: 'isLoanContributionDeclared',
                4: 'isAskingPriceDeclared',
            };

            const isNotSimilarPlayersGrid = (players.similarPlayers || {}).output === undefined
            const isAskingPriceAction = requestType === 4
            const isLoanContributionAction = requestType === 3
            const isLoanAvailabilityAction = requestType === 2
            const isTransferAvailabilityAction = requestType === 1

            if (isNotSimilarPlayersGrid) {
                return {
                    ...players,
                    loanContributionProcessingIds:
                        isLoanContributionAction
                            ? players.loanContributionProcessingIds.filter(item => item !== playerId)
                            : players.loanContributionProcessingIds,
                    loanAvailabilityProcessingIds:
                        isLoanAvailabilityAction
                            ? players.loanAvailabilityProcessingIds.filter(item => item !== playerId)
                            : players.loanAvailabilityProcessingIds,
                    askingPriceProcessingIds:
                        isAskingPriceAction
                            ? players.askingPriceProcessingIds.filter(item => item !== playerId)
                            : players.askingPriceProcessingIds,
                    transferAvailabilityProcessingIds:
                        isTransferAvailabilityAction
                            ? players.transferAvailabilityProcessingIds.filter(item => item !== playerId)
                            : players.transferAvailabilityProcessingIds,
                }
            } else {
                return {
                    ...players,
                    loanContributionProcessingIds:
                        isLoanContributionAction
                            ? players.loanContributionProcessingIds.filter(item => item !== playerId)
                            : players.loanContributionProcessingIds,
                    loanAvailabilityProcessingIds:
                        isLoanAvailabilityAction
                            ? players.loanAvailabilityProcessingIds.filter(item => item !== playerId)
                            : players.loanAvailabilityProcessingIds,
                    askingPriceProcessingIds:
                        isAskingPriceAction
                            ? players.askingPriceProcessingIds.filter(item => item !== playerId)
                            : players.askingPriceProcessingIds,
                    transferAvailabilityProcessingIds:
                        isTransferAvailabilityAction
                            ? players.transferAvailabilityProcessingIds.filter(item => item !== playerId)
                            : players.transferAvailabilityProcessingIds,
                    similarPlayers: {
                        ...players.similarPlayers,
                        output: players.similarPlayers.output.map(item => {
                            if (item.playerId === playerId) {
                                return {
                                    ...item,
                                    player: {
                                        ...item.player,
                                        [map[requestType]]: true,
                                    },
                                };
                            } else {
                                return item;
                            }
                        }),
                    },
                };
            }
        }
        case PLAYERS_INTEREST_SAVE.ERROR: {
            const { playerId, requestType } = action.payload;

            const isAskingPriceAction = requestType === 4
            const isLoanContributionAction = requestType === 3
            const isLoanAvailabilityAction = requestType === 2
            const isTransferAvailabilityAction = requestType === 1

            return {
                ...players,
                loanContributionProcessingIds:
                    isLoanContributionAction
                        ? players.loanContributionProcessingIds.filter(item => item !== playerId)
                        : players.loanContributionProcessingIds,
                loanAvailabilityProcessingIds:
                    isLoanAvailabilityAction
                        ? players.loanAvailabilityProcessingIds.filter(item => item !== playerId)
                        : players.loanAvailabilityProcessingIds,
                askingPriceProcessingIds:
                    isAskingPriceAction
                        ? players.askingPriceProcessingIds.filter(item => item !== playerId)
                        : players.askingPriceProcessingIds,
                transferAvailabilityProcessingIds:
                    isTransferAvailabilityAction
                        ? players.transferAvailabilityProcessingIds.filter(item => item !== playerId)
                        : players.transferAvailabilityProcessingIds,
            }
        }

        case PLAYERS_FETCH.SUCCESS: {

            const { request, data } = action.payload;
            // cancelled request
            if (!action.payload.data) {
                return {
                    ...players
                }
            }

            if (request.scope !== 'playerSearch') return players;

            return {
                ...players,
                ids: data.output.map(player => player.id),
            };
        }

        case PLAYERSEARCH_PLAYER_SELECTED: {
            const playerId = action.payload.id;
            return {
                ...players,
                selectedId: players.selectedId === playerId ? null : playerId,
            };
        }

        default:
            return players;
    }
};

const path = state => state.playerSearch.players;

const getSelectedPlayerId = compose(
    players => players.selectedId,
    path,
);

const getPlayerIds = compose(
    players => players.ids,
    path,
);

const getSimilarPlayers = compose(
    players => players.similarPlayers,
    path,
);

export const getSearchSimilarPlayers = createSelector(
    getSimilarPlayers,
    similarPlayers => {
        if ((similarPlayers || {}).output === undefined) return [];
        return similarPlayers.output.map(item => ({
            ...item.player,
            ...item.statistic,
            gsnSkill: item.gsnSkill,
            gsnPerformance: item.gsnPerformance,
            similarityPercentage: item.similarityPercentage,
        }));
    },
);

export const getSimilarPlayerPagination = state => {
    let similarPlayers = path(state).similarPlayers;
    if (similarPlayers === null) return {};
    return {
        currentPage: similarPlayers.currentPageNumber,
        pageCount: similarPlayers.pageCount,
        rowCount: similarPlayers.rowCount,
        pageSize: similarPlayers.pageSize,
    };
};

export const selectDeclareInterestProcessingIds = compose(
    players => players.declareInterestProcessingIds,
    path
)
export const selectDeclareInterestToAgentProcessingIds = compose(
    players => players.declareInterestToAgentProcessingIds,
    path
)
export const selectLoanContributionProcessingIds = compose(
    players => players.loanContributionProcessingIds,
    path
)
export const selectLoanAvailabilityProcessingIds = compose(
    players => players.loanAvailabilityProcessingIds,
    path
)
export const selectTransferAvailabilityProcessingIds = compose(
    players => players.transferAvailabilityProcessingIds,
    path
)
export const selectAskingPriceProcessingIds = compose(
    players => players.askingPriceProcessingIds,
    path
)

export const isRecommendationModalFetching = state => {
    let fetchingStatus = state.requests.some(r => r.type === PLAYER_ADS_ACTIVITIES_COMPARISON_FETCH || r.type === PLAYER_ADS_FETCH)
    return fetchingStatus
};