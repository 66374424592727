import axios from 'axios-config';
import { Dashboard, PlayerPitchOpportunity, LiteCountersResponse, AgentUser, ClubConnectionsResponse, PlayersVerificationResponse } from 'api/agency/agent/landing/agency-dashboard'
import { AgencyAgentInvitationCreateRequest, InviteAgencyResponse } from './invite-agents.model'
import { CompetitionWithActiveSquadsAndRecommendersModel, SetStrategyRequest } from './strategy-modal-models'
import { GetPlayerRepresentationItem } from './player-request'
import { PlayerRepresentationResponse } from "./representation-request.model";

export default class AgencyHomePageService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getDashboard(): Promise<Dashboard> {
        const { data } = await axios.get<Dashboard>(`api/AgencyHomePage/Dashboard`, this.axiosConfig)
        return data;
    }

    public static async getPitchOpportunitiesByPlayerId(playerId: number): Promise<PlayerPitchOpportunity> {
        const { data } = await axios.get<PlayerPitchOpportunity>(`api/AgencyHomePage/PitchOpportunitiesByPlayerId/${playerId}`, this.axiosConfig)
        return data;
    }

    public static async getLiteCounters(): Promise<LiteCountersResponse> {
        const { data } = await axios.get<LiteCountersResponse>(`api/AgencyHomePage/GetLiteCounters`, this.axiosConfig)
        return data;
    }

    // To add a test mode for credit swap, use dateTest, 'x-date': dateTest? dateTest : new Date().toISOString(),
    public static async getPlayers(): Promise<PlayersVerificationResponse> {
        const { data } = await axios.get<PlayersVerificationResponse>(`api/v2/AgencyPlayer/Players`, this.axiosConfig)
        return data;
    }
    // public static async getPlayers(dateTest?: string): Promise<PlayersVerificationResponse> {
    //     const { data } = await axios.get<PlayersVerificationResponse>(
    //         `api/v2/AgencyPlayer/Players`,
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'x-date': dateTest ? dateTest : new Date().toISOString(),
    //             }
    //         }
    //     )
    //     return data;
    // }

    // To add a test mode for credit swap, use dateTest, 'x-date': dateTest? dateTest : new Date().toISOString(),
    public static async removePlayer(id: number): Promise<void> {
        await axios.delete(`api/v2/AgencyPlayer/Players/${id}/Remove`, this.axiosConfig)
    }
    // public static async removePlayer(id: number, dateTest?: string): Promise<void> {
    //     await axios.delete(`api/v2/AgencyPlayer/Players/${id}/Remove`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'x-date': dateTest ? dateTest : new Date().toISOString(),
    //         }
    //     })
    // }

    public static async upgradePlayer(id: number, shared: boolean): Promise<void> {
        await axios.post(`api/v2/AgencyPlayer/Players/${id}/Upgrade`, shared, this.axiosConfig)
    }

    public static async saveVideos(request: { playerId: number, urls: Array<string> }): Promise<boolean> {
        const { data } = await axios.post<boolean>(`api/v2/AgencyPlayer/SaveVideos`, request, this.axiosConfig)
        return data;
    }

    public static async getAgencyUsers(): Promise<Array<AgentUser>> {
        const { data } = await axios.get<Array<AgentUser>>(`api/AgencyHomePage/UserAgents`, this.axiosConfig)
        return data;
    }

    public static async removeAgencyUser(userAgentId: number): Promise<boolean> {
        const { data } = await axios.post(`api/AgencyHomePage/UserAgents/Remove`, { userAgentId }, this.axiosConfig)
        return data;
    }

    public static async getClubConnections(): Promise<ClubConnectionsResponse> {
        const { data } = await axios.get<ClubConnectionsResponse>(`api/AgencyHomePage/ClubConnections`, this.axiosConfig)
        return data;
    }

    public static async addAgents(request: AgencyAgentInvitationCreateRequest): Promise<InviteAgencyResponse> {
        const { data } = await axios.post(`api/v2/AgencyAgentInvitation/CreateRange`, request, this.axiosConfig)
        return data;
    }

    public static async activeSquadsFetch(playerId: number): Promise<CompetitionWithActiveSquadsAndRecommendersModel> {
        const { data } = await axios.get(`api/v2/AgencyPlayer/Players/${playerId}/GetClubs`, this.axiosConfig)
        return data;
    }

    public static async setStrategy(request: SetStrategyRequest): Promise<any> {
        const { data } = await axios.post(`api/v2/AgencyPlayer/SetStrategy`, request, this.axiosConfig)
        return data;
    }

    public static async dismissInvitedColleagues(): Promise<any> {
        await axios.post(`api/AgencyHomePage/DismissAgentInvitedColleagues`, {}, this.axiosConfig)
    }

    public static async getPlayerPerRequest(): Promise<Array<GetPlayerRepresentationItem>> {
        const { data } = await axios.get(`api/AgencyHomePage/GetPlayerRepRequests`, this.axiosConfig)
        return data;
    }

    public static async acceptPlayerRequest(uid: string, isRegular: boolean, sharedInsignts: boolean): Promise<any> {
        await axios.post(`api/AgencyHomePage/AcceptPlayerRepresentation/${uid}`, { isRegular: isRegular, insightsShared: sharedInsignts }, this.axiosConfig)
    }

    public static async denyPlayerRequest(uid: string): Promise<any> {
        await axios.post(`api/AgencyHomePage/DenyPlayerRepresentation/${uid}`, {}, this.axiosConfig)
    }

    public static async showEvent(): Promise<boolean> {
        const { data } = await axios.get(`api/AgencyHomePage/ShowEvent`, this.axiosConfig);
        return data;
    }

    // To add a test mode for credit swap, use dateTest, 'x-date': dateTest? dateTest : new Date().toISOString(),
    public static async downgradePlayer(playerId: number): Promise<void> {
        await axios.post(`api/v2/AgencyPlayer/Downgrade/${playerId}`, null, this.axiosConfig)
    }
    // public static async downgradePlayer(playerId: number, dateTest: string): Promise<void> {
    //     await axios.post(`api/v2/AgencyPlayer/Downgrade/${playerId}`,
    //         null,
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'x-date': dateTest ? dateTest : new Date().toISOString(),
    //             }
    //         }
    //     )
    // }

    public static async getRepresentationRequests(): Promise<Array<PlayerRepresentationResponse>> {
        const { data } = await axios.get(`api/AgencyHomePage/GetPlayerRepresentationMessages`, this.axiosConfig);
        return data;
    }
}
