export const translationsEn = {
    menu: {
        squad: 'Squad',
        messaging: 'Messaging',
        search: 'Search',
        shortList: 'Shortlist',
        playerAds: 'Player Ads',
        clubAds: 'Club Ads',
        pitch: 'Pitch',
        friendlies: 'Friendlies',
        marketplace: 'Marketplace',
        events: 'Events',
        plusPitch: 'Plus Pitch',
        editProfile: 'Settings',
        financialInsights: 'Financial Insights',
        loanInsights: 'Virtual Loan Assistant',
        logOut: 'Logout',
        home: 'Home'
    },
    errorMessages: {
        pleaseEnterValue: 'Please enter value!',
        minimumRequiredValue: 'Minimum required value is ',
    },
    availabilityEditor: {
        forSale: 'To Buy',
        forLoan: 'To Loan',
        yes: 'Yes',
        no: 'No',
        priceUponRequest: 'Price upon request',
        askingPrice: 'Asking price',
        sellOn: 'Sell-on',
        monthlyLoanFee: 'Monthly loan fee incl. gross salary',
        saveChanges: 'Save changes',
        insertAmount: 'Insert Amount!',
        mustBeRange: 'Must be in range from 0 to 100!',
    },
    clubAds: {
        dropDown: {
            player: 'Player',
            selectPlayer: 'select player',
            playerDescription: 'Player Description',
            min: 'Min.',
            cm: 'cm',
            age: 'Age',
            monthlyLoanFee: 'Monthly loan fee incl. gross salary',
            transferFee: 'Transfer fee',
            pitchPlayerNow: 'Pitch Player Now',
        },
        filterComponent: {
            showResults: 'Show results',
            toBuy: 'To Buy',
            toLoan: 'To Loan',
        },
        filterSection: {
            transferType: 'Transfer Type',
            transferFee: 'Transfer fee',
            monthlyLoanFee: 'Loan budget or gross salary',
            position: 'Position',
            goalkeeper: 'Goalkeeper',
            leftBack: 'Left Back',
            rightBack: 'Right Back',
            centreBack: 'Centre Back',
            defensiveMidfielder: 'Defensive Midfielder',
            centralMidfielder: 'Central Midfielder',
            attackingMidfielder: 'Attacking Midfielder',
            winger: 'Winger',
            forward: 'Forward',
            league: 'League',
            status: 'Status',
            interest: 'Interest',
            dismissed: 'Dismissed',
            others: 'Others',
            saved: 'Saved',
        },
        gridHeader: {
            searchPosition: 'Search player name, position or club name...',
            searchPlayer: 'Search player position, club or league name...',
            newFeatureTrial: 'New feature! You are on trial.',
            newFeature: 'New feature!',
            youAreOnTrial: 'You are on trial.',
        },
        pitchInsights: {
            pitchInsightsTab: 'PITCH INSIGHTS',
            playersPitched: 'Players pitched',
            pitchesSent: 'Pitches sent',
            clubsReached: 'Clubs reached',
            last6Months: 'Last 6 months',
            lastNMonths: 'Last N months',
            lastMonth: 'Last month',
            pitchStatus: {
                awaitingReply: 'Awaiting Reply',
                declaredInterest: 'Declared interest',
                interest: 'Interest',
                saved: 'Saved',
                opened: 'Opened',
                dismissed: 'Dismissed',
                removed: 'Removed',
            },
            upgradePanel: {
                defaultText: 'UPGRADE TO GET ACCESS TO PITCH INSIGHTS WITH EXCLUSIVE PITCH TOOLS AND DATA INSIGHTS',
                recentActivityText: 'SHOWING ANONYMISED DATA - UPGRADE TO GET FULL VERSION WITH CLUB NAMES AND MORE',
                moreButton: 'More ...'
            },
            sampleData: {
                sampleDataOnly: 'SAMPLE DATA ONLY',
                upgradeToActivate: 'UPGRADE TO ACTIVATE',
            },
            successRate: {
                panelTitle: 'Success Rate',
                averageSuccessRate: 'AVERAGE SUCCESS RATE'
            },
            responsesChart: {
                panelTitle: 'Responses on most pitched players',
                chartLegend: {
                    declaredInterest: 'DECLARED INTEREST/SAVED PITCH',
                    dismissed: 'DISMISSED PITCH',
                    awaitingReply: 'AWAITING REPLY'
                },
                clickForDetail: 'Click for details'
            },
            recentActivity: {
                panelTitle: 'Recent Activity',
                panelTitleModal: 'Responses on most pitched players',
                player: 'Player:',
                showOnlyItems: 'Show only items where I can test interest',
                backToGraph: 'Back to graph',
                table: {
                    time: 'Time',
                    activity: 'Activity',
                    club: 'Club',
                    description: 'Description',
                    testInterestButton: 'Test interest',
                    pitched: 'Pitched',
                    hidden: '[HIDDEN]',
                    upgradeToTestInterest: 'Upgrade to test interest',
                    testInterestCanBeTested: 'Interest can be tested',
                    testInterestWasTested: 'Interest was tested',
                    more: 'MORE',
                    showFirst5: 'SHOW FIRST 5'
                },
                description: {
                    club: 'A club',
                    savedPitchOf: 'saved pitch of',
                    declaredInterestIn: 'declared interest in',
                    dismissedPitchOf: 'dismissed pitch of',
                    openedPitchOf: 'opened pitch of',
                    pitchOf: 'Pitch of',
                    deliveredTo: 'delivered to a',
                    to: 'to a',
                    pitchWasRemoved: 'removed pitch of',
                    undefinedStatus: 'Undefined status'
                }
            },
            pitchLog: {
                panelTitle: 'Pitch log',
                searchPlaceholder: 'Filter by player or club name...',
                time: 'Time',
                hidden: '[HIDDEN]',
                player: 'Player',
                pitchedTo: 'Pitched To',
                transferType: 'Transfer Type',
                price: 'Price',
                more: 'MORE',
                showFirst5: 'SHOW FIRST 5',
                maxGrossSalary: 'MAX GROSS SALARY',
                all: 'Show All...',
                showFirst6: 'Show First  6 ',
            },
        },
        tabNavigation: {
            clubAds: 'Club Ads',
            myPitches: 'My Pitches',
            pitchInsights: 'Pitch Insights'
        },
        myPitchesGrid: {
            hidden: 'HIDDEN',
            date: 'Date',
            playerName: 'Player Name',
            club: 'Club',
            league: 'League',
            position: 'Position',
            transferType: 'Transfer Type',
            price: 'Price',
            status: 'Status',
            noResult: 'No results. You have not sent any player pitches.',
        },
        grid: {
            hidden: 'HIDDEN',
            posted: 'Posted',
            position: 'Position',
            club: 'Club',
            league: 'League',
            country: 'Country',
            transferType: 'Transfer Type',
            budgetUpTo: 'LOAN BUDGET OR GROSS SALARY',
            yourPitches: 'Your Pitches',
        }

    },
    competitionList: {
        editLeaguesList: 'Edit leagues list',
        createLeaguesList: 'Create leagues list',
        clear: 'Clear',
        selected: 'Selected',
        selectLeagues: 'Select leagues from the right list',
        searchCountry: 'Search country or competition...',
        deleteList: 'Delete list',
        cancel: 'Cancel',
        saveChanges: 'Save changes',
        myCompetitions: 'My Competitions',
        enterListName: 'Please enter list name.',
        enterUniqueName: 'Please enter unique list name. League list named',
        alreadyExists: 'already exists',
    },
    declareInterestButton: {
        declaredInterest: 'Declared Interest',
        declareInterest: 'Declare interest',
    },
    editProfile: {
        menu: {
            profile: 'Profile',
            users: 'Users',
            documents: 'Documents',
        },
        profile: {
            name: 'First Name',
            lastName: 'Last Name',
            surname: 'Surname',
            email: 'Email',
            club: 'Club',
            agency: 'Agency',
            jobTitle: 'Job Title',
            phone: 'Phone',
            changePassword: 'Change Password',
            reset: 'Reset',
            saveChanges: 'Save Changes',
            insertUserName: 'Insert user name (first and last)!',
            interfaceLanguage: 'Interface language',
            restrictedAccess: 'Restricted access',
            friendlContentOnly: 'Friendly content only',
            insertUserFirstName: 'Insert user first name !',
            insertUserLastName: 'Insert user last name !',
        },
        users: {
            user: 'User',
            jobTitle: 'Job Title',
            access: 'Access',
            fullAccess: 'Full',
            friendlyAccess: 'Friendly',
            status: 'Status',
            addUser: 'Add user...',
        },
        documents: {
            termsOfService: 'Terms of service',
            privacyPolicy: 'Privacy Policy',
            dataProcessingTerms: 'Data Processing Terms',
            antiSlaveryPolicy: 'Anti-slavery and Human Trafficking Policy',
        },
        createUser: {
            insertUserName: 'Insert user name (first and last)!',
            insertUserEmail: 'Insert user email!',
            insertUserPhone: 'Insert user phone!',
            selectUserJobTitle: 'Select user job title!',
            editUser: 'Edit user',
            createUser: 'Create user',
            updateUser: 'Update user',
            cancel: 'Cancel',
            phone: 'Phone',
            email: 'Email',
            jobTitle: 'Job Title',
            name: 'First Name',
            lastName: 'Last Name',
            surname: 'Surname',
            restrictedAccess: 'Restricted access',
            friendlContentOnly: 'Friendly content only',
        },
        emailPreferences: {
            unreadPlayerPitches: 'Unread player pitches',
            declareInterest: 'Player interest',
            saveChanges: 'Save Changes',
            newSuperPitch: 'New plus pitch',
            adShouldBeUpdated: 'Update player ads',
            unreadMessages: 'Unread messages',
            newMessage: 'New message',
            declareInterestForFriendlyMatch: 'Friendly interest',
            invitationToFriendlyMatch: 'New friendly invitations',
            unreadInvitations: 'Unread friendly invitations',
            friendlyAds: 'Friendly ads',
            
            friendlyRelated: 'Friendly related',
            inboundPlayers: 'Players in',
            outboundPlayers: 'players out',
            messages: 'Messages',
            generalEmails: 'General emails',

            friendlyProposals: 'Friendly proposals',
            updateOldFriendlyAds: 'Update old friendly ads',
            playerProposals: 'Player proposals',
            shortlistPlayerStatusChange: 'Shortlisted player status change',
            newPlayerPitch: 'New player pitch',
            superPitch: 'New super pitch',
            clubAdProposal: 'Club ad proposal',
            featureUpdates: 'Feature updates',
            eventInformation: ' Event information',
            blogPromotion: 'Blog promotion',
            recommendedEmailsOnly: 'Recommended Emails Only',
        },
        password: {
            insertPassword: 'Insert password!',
            insertNewPassword: 'Insert new password!',
            confirmNewPassword: 'Confirm new password',
            passwordsNotEqual: 'Passwords are not equal!',
            changePassword: 'Change password',
            currentPassword: 'Current Password',
            newPassword: 'New password',
            cancel: 'Cancel',
            setNewPassword: 'Set New Password',
            passwordIsInvalid: 'Password is invalid',
        },
        onUpdatePassword: {
            success: 'Password changed successfully',
            failed: 'Failed to change password. Enter correct old password.',
        },
    },
    eventPopupBanner: {
        register: 'Register',
    },
    events: {
        registrationCompleted: 'Registration completed!',
        registerNow: 'Register Now',
        preRegisterNow: 'Register',
        confirmedAttendees: 'Confirmed Attendees',
        pastEvents: 'PAST EVENTS',
        downloadEca: 'Download ECA presentation',
        summit: 'Summit',
        summitName: 'Deal Day',
        summitDate: '16th of July',
        summitPlace: 'Stamford Bridge,  London',
        summitYear: 'Summit 2019',
        summitText:
            'The only conference in football exclusively for decision makers. By Invitation Only.',
        summitStartDate: '20th March',
        whyAttend: 'Why Attend?',
        whyAttendText:
            'TransferRoom Deal Day is a unique opportunity to build relationships with decision makers from clubs across the world and to discuss specific transfers without the involvement of intermediaries.',
        duringDay: 'During one full day in',
        youWillGet: 'you will get:',
        oneToOne: 'At least 15 one-to-one meetings with other clubs',
        accessToClubs:
            'Access to clubs and players in markets you previously had restricted access to',
        marketInformation:
            'Market information on which players are available for sale and loan in the coming transfer window.',
        speaker: 'Speaker',
        speakerPosition: 'Owner, FC Nordsj?lland',
        speakerDescription:
            'FC Nordsjaelland is one of the most impressive stories about talent development in the world of football.',
        speakerText:
            'At TransferRoomпїЅs Deal Day, owner and Chairman of FC Nordsjaelland, Tom Vernon, will give an insight into the clubпїЅs talent strategy and not least his vision to change lives and build leaders through football.',
        agenda: 'Agenda',
        registration: 'Registration',
        presentation: 'Presentation',
        clubToClub: 'Club-to-club meetings',
        lunchNetworking: 'Lunch & Networking',
        drinksNetworking: 'Drinks & Networking',
        closing: 'Closing',
        photos: 'Photos',
        whenAndWhere: {
            whenAndWhere: 'When and where',
            date: '16th of July',
            place: 'Stamford Bridge, London',
        },
        participants: 'Participants',
        countries: 'Countries',
        leagues: 'Leagues',
        day: 'Day',
        days: 'Days',
        hour: 'Hour',
        hours: 'Hours',
        minute: 'Minute',
        minutes: 'Minutes',
        second: 'Second',
        seconds: 'Seconds',
        soldOut: 'Sold Out',
    },
    expiredAds: {
        tokenIsExpired: 'Token is expired.',
        please: 'Please',
        login: 'login',
        toViewExpiredAds: 'to view expired ads.',
        gettingOld: 'One or more of your ads are getting old:',
        considerUpdating: 'Consider updating or removing old ads',
        upToDate: 'All your ads are up to date.',
        epic: {
            successfully: '1 of your ads was updated successfully!',
            removedSuccesfully: '1 of your ads was removed successfully!',
        },
    },
    financialInsights: {
        spendingPower: {
            chart: {
                highest: "HIGHEST",
                lowest: "LOWEST",
                typical: "TYPICAL",
                avarage: "AVERAGE",
            },
            title: "SPENDING POWER",
            tabTransfers: "TRANSFERS",
            tabLoans: "LOANS"
        },
        sampleData: {
            sampleDataOnly: "SAMPLE DATA ONLY",
            upgradeToActivate: "UPGRADE TO ACTIVATE",
        },
        upgradePanel: {
            moreButton: "MORE ..."
        },
        searchBar: {
            country: 'Country:',
            league: 'League:',
            club: 'Club:',
            currency: 'CURRENCY:'
        },
        totalClubPlayerValue: 'TOTAL CLUB PLAYER VALUE',
        clubValueRank: 'CLUB VALUE RANK',
        of: 'OF',
        inLeague: 'IN LEAGUE',
        currentSquadSize: 'CURRENT SQUAD SIZE',
        averagePlayerValuation: 'AVERAGE PLAYER VALUATION',
        averagePlayerAge: 'AVERAGE PLAYER AGE',
        season: 'Season',
        lastThreeSeasons: 'Last three seasons',
        transferOverview: {
            panelTitle: 'TRANSFERS OVERVIEW LAST 6 WINDOWS',
            transferIn: 'Transfer In',
            transfersIn: 'Transfers In',
            transferOut: 'Transfer Out',
            transfersOut: 'Transfers Out',
            balance: 'Balance',
            summer: 'SUMMER',
            winter: 'WINTER'
        },
        biggestTransfers: {
            panelTitle: 'BIGGEST TRANSFERS AND MOST VALUABLE PLAYERS',
            panelTitleShort: 'BIGGEST TRANSFERS',
            top: 'TOP',
            biggestTransfersIn: 'BIGGEST TRANSFERS IN',
            biggestTransfersOut: 'BIGGEST TRANSFERS OUT',
            theHighestTransferIn: 'THE HIGHEST TRANSFER IN',
            theHighestTransferOut: 'THE HIGHEST TRANSFER OUT',
            noTransfersOut: 'NO TRANSFERS OUT',
            noTransfersIn: 'NO TRANSFERS IN',
            noIncomingTransfers: 'No incoming transfers this season',
            noOutgoingTransfers: 'No outgoing transfers this season',
        },
        playerValuations: {
            panelTitle: '21st CLUB - PLAYER VALUATIONS',
            table: {
                player: 'Player',
                pos: 'pos',
                age: 'Age',
                contractExpiry: 'Contract Expiry',
                value: 'Value',
                percentOfClubValue: 'Percent of Club Value'
            },
            more: 'MORE',
            showFirst5: 'SHOW FIRST 5'
        },
        chartDonut: {
            freeTransfers: 'FREE TRANSFERS',
            loans: 'LOANS',
            youthPlayers: 'YOUTH PLAYERS',
            transfers: 'TRANSFERS',
        },
        incomingPlayers: {
            panelTitle: 'INCOMING PLAYERS',
            tables: {
                top: 'TOP',
                importCountries: 'IMPORT COUNTRIES',
                topImportCountry: 'TOP IMPORT COUNTRY',
                importLeagues: 'IMPORT LEAGUES',
                topImportLeague: 'TOP IMPORT LEAGUE',
                noTransfers: 'NO TRANSFERS',
                noData: 'No monetary transfers this season',
                country: 'COUNTRY',
                league: 'LEAGUE',
                total: 'TOTAL',
                deals: 'DEALS',
                average: 'AVERAGE'
            }
        },
        outgoingPlayers: {
            panelTitle: 'OUTGOING PLAYERS',
            tables: {
                top: 'TOP',
                exportCountries: 'EXPORT COUNTRIES',
                topExportCountry: 'TOP EXPORT COUNTRY',
                exportLeagues: 'EXPORT LEAGUES',
                topExportLeague: 'TOP EXPORT LEAGUE',
                noTransfers: 'NO TRANSFERS',
                noData: 'No monetary transfers this season',
                country: 'COUNTRY',
                league: 'LEAGUE',
                total: 'TOTAL',
                deals: 'DEALS',
                average: 'AVERAGE'
            }
        }
    },
    friendlyMatches: {
        filter: {
            squad: 'Squad',
            date: 'Date',
            venue: 'Venue',
            venueCountries: 'Country of venue',
            setDate: 'Set date...',
            to: 'To',
            from: 'From',
        },
        activity: {
            friendly: 'Friendly',
            friendlies: 'Friendlies',
            open: 'Open',
            ad: 'Ad',
            ads: 'Ads',
            my: 'My',
            reFriendlyInvitation: 'RE: Friendly invitation',
        },
        attention: {
            areStillRelevant: 'Are your ads still relevant?',
            getSureUpToDate:
                'Please review your ads and ensure that they are up to date.',
            reviewMyAds: 'Review my ads',
            myAdsAreOk: 'My ads are ok',
        },
        alert: {
            considerUpdating: 'Consider updating or removing old ads',
            gettingOld: 'One or more of your ads are getting old:',
        },
        main: {
            na: 'N/A',
            showMore: 'Show More...',
            youHaveReplied: 'you have replied:',
            declareInterest: '"declare interest"',
            declareInterestWithoutQuotas: 'declared interest',
            awaitingReply: 'Awaiting reply...',
            didNotReply: 'Did not reply',
            cancel: 'Cancel',
            notAvailable: 'NOT AVAILABLE',
            notAvailableWithQuotas: '"NOT AVAILABLE"',
            expiredAds: 'the invitation has expired',
            removed: 'Removed',
            updated: 'UPDATED',
            expired: 'EXPIRED',
            venue: 'Venue',
            homeAway: 'Home/Away',
            home: 'Home',
            countryOfVenue: 'Country of venue',
            interest: 'Interest',
            interests: 'Interests',
            declared: 'Declared',
            message: 'message',
            messageWithQuotas: '"new message"',
            messages: 'messages',
            sent: 'Sent a',
            sentBeforePlural: 'Sent',
            available: 'available',
            not: 'Not',
            newMessage: 'New Message',
            sentMessage: 'SENT A MESSAGE',
            updateAd: 'Update ad',
            remove: 'Remove',
            away: 'Away',
            keepUpToDate: 'Make sure to keep your ads up to date.',
            yesStillLooking: "Yes, I'm still looking",
            stillLooking: 'Are you still looking for an opponent for',
            invitedYou: 'invited you to a friendly match.',
            inviteAdditionalClubs: 'INVITE ADDITIONAL CLUBS',
        },
        editor: {
            firstTeam: 'First Team',
            home: 'Home',
            away: 'Away',
            createFriendlyAd: 'Create friendly ad',
            venue: 'Venue',
            countryOfVenue: 'Country of venue',
            cancel: 'Cancel',
            launchAd: 'Launch ad',
            chooseDates: 'Choose date(s)...',
            date: 'Date',
        },
        invitations: {
            alreadyInvited: 'Already invited',

            inviteAdditionalClubsDialogTitle:
                'INVITE ADDITIONAL CLUBS TO YOUR FRIENDLY',
            inviteAdditionalClubsButtonTitle: 'INVITE ADDITIONAL CLUBS NOW',
            friendlies: 'Friendlies',
            open: 'Open',
            ads: 'Ads',
            friendly: 'Friendly',
            ad: 'Ad',
            my: 'My',
            inviteClubs: 'Invite Clubs To Your Friendly',
            inviteClubsButtonTitle: 'Invite Clubs Now',
            recipients: 'Recipients',
            removeAll: 'Remove All',
            country: 'COUNTRY:',
            league: 'LEAGUE:',
            skip: 'Skip',
            createNewAd: 'Invite Clubs Now',
        },
        match: {
            venue: 'Venue',
            homeAway: 'Home/Away',
            home: 'Home',
            countryOfVenue: 'Country of venue',
            away: 'Away',
        },
        declareInterest: {
            declaredInterest: 'Declared Interest',
            declareInterest: 'Declare Interest',
        },
        myFriendlyMatches: {
            activeAds: 'Active Ads',
            expiredAds: 'Expired Ads',
            friendlyInvitationSubject: 'Friendly invitation',
        },
        newInvitations: {
            youHaveReceived: 'You have received',
            newInvitation: 'new invitation',
            newInvitations: 'new invitations',
        },
        menu: {
            activity: 'Activity',
            invitations: 'Invitations',
            myAds: 'My ads',
            createNewAd: 'Create new ad',
        },
        friendlySuggestion: {
            friendlySuggestion: 'Friendly suggestions',
        },
    },
    fullStat: {
        fullStats: 'Full Stats',
        average: 'Average',
        date: 'Date',
        match: 'Match',
        competition: 'Competition',
        minPlayed: 'Min. played',
        goals: 'Goals',
        assists: 'Assists',
        noRecords: 'No records found',
    },
    landingPage: {
        recommendations: {
            highPriorityRecommendation: 'High priority recommendation for you',
            highPriorityRecommendations: 'High priority recommendations for you',
            fixNow: 'Fix now',
            fixed: 'Fixed!',
            done: 'Done!',
            makePlayersAvailable: {
                title: 'Make Players Available',
                description: 'List your players for sale or loan to appeal to as many buyers as possible.'
            },
            pitchYourPlayers: {
                title: 'Pitch Your Players',
                description: 'Pitching your players to relevant clubs highly increases your chances of receiving direct offers.'
            },
            createPlayerAds: {
                title: 'Create Player Ads',
                description: 'Specify your needs to get pitches of relevant players. Receive offers sent directly from other clubs.'
            },
            launchFriendlyAds: {
                title: 'Launch Friendly Ads',
                description: 'Share when and where you are looking to play and get interest from other clubs.'
            },
            addMoreUsers: {
                title: 'Add more users + a friendly manager',
                description: 'Add relevant colleagues to TransferRoom to maximize your club’s activity and results with TransferRoom.'
            },
        },
        doingWell: {
            youAreDoingWell: 'You are doing well!',
            makePlayerAvailable: 'Make a player available',
            addPlayerToReleaseList: 'Add a player to your release list',
            pitchPlayer: 'Pitch a player',
            makeAdForAPlayer: 'Create an ad for a player',
            arrangeFriendlyMatch: 'Arrange a friendly match',
            findAndComparePlayers: 'Find and compare players'
        },
        performanceIndicators: {
            heading: 'Performance indicators',
            playersMadeAvailable: 'Player%{value} made available',
            playersPitchesLast: 'Player pitch%{value} last 30 days',
            playersAdsLast: 'Player ad%{value} last 30 days',
            friendlyAdsLast: 'Friendly ad%{value} last 30 days'
        },
        createPlayerAdModal: {
            //Step 1
            specifyYourTransferTargets: 'Specify your requirement to receive offers',
            whichPositionAreYouLooking: 'Which positions are you looking for?',
            //Step 3
            playerAdsCreated: 'player ads created!',
            playerAdCreated: 'player ad created!',
            weFound: 'We found',
            recommendationsForYou: 'recommendations for you',
            recommendationForYou: 'recommendation for you',
            player: 'Player',
            marketValue: 'Market value',
            //Common
            cancel: 'Cancel',
            continue: 'Continue',
            done: 'Done'
        }
    },
    matchMaker: {
        youHaveMatch: 'You have a match!',
        pitchToMultiple: 'Pitch to multiple clubs',
        name: 'Name',
        budget: 'Budget',
        pitch: 'Pitch',
        skip: 'Skip',
        pitchNow: 'Pitch Now',
        pos: 'Pos.',
    },
    messages: {
        deleteConversation: 'Delete conversation',
        newMessage: 'New Message',
        search: 'Search by club name...',
        enterMessage: 'Enter message...',
        noChannelsFound: 'No messages found!',
        today: 'Today',
        yesterday: 'Yesterday',
        noClubsMatch: 'No search results. Try again...',
    },
    notifications: {
        hasBeenPitched: 'has been pitched. Good luck!',
        yourMessageTo: 'Your message to',
        hasBeenSent: 'has been sent',
        somethingWentWrong: 'Something went wrong. Please try again',
    },
    performance: {
        competition: 'Competition',
        apps: 'Apps',
        startingApps: 'Starting Apps',
        goals: 'Goals',
        assists: 'Assists',
        minPerGoal: 'Min. per Goal',
        minPerAssist: 'Min. per Assist',
        totalMinPlayed: 'Total min. played',
        avgMinPlayed: 'Avg. min. played',
        performanceData: 'Performance data',
        total: 'Total',
    },
    playerAds: {
        alert: {
            gettingOld: 'One or more of your ads are getting old:',
            considerUpdating: 'Consider updating or removing old ads',
            yesStillLooking: "Yes, I'm still looking",
            remove: 'Remove',
            areYouStillLooking: 'Are you still looking for',
            keepUpToDate: 'Make sure to keep your ads up to date.',
            budget: 'Budget:',
            upTo: 'Up to',
            to: 'To',
        },
        getStarted: {
            findMember: 'Find your next squad member here',
            startWithAd: 'Around 50% of player transfers starts with an AD.',
            getStarted: 'Get started now',
            createAd: 'Create your ad',
        },
        myAds: {
            activeAds: 'Active Ads',
            removedAds: 'Removed Ads',
        },
        noPitches: {
            youHave0: 'You have 0 new pitches',
            butYouHave: 'But you have',
            yourAdsAreLive:
                'Your ads are live - and when new pitches appear, they appear here!',
            savedPitch: 'saved pitch',
            savedPitches: 'saved pitches',
        },
        pitch: {
            savePitch: 'Save pitch',
            dismiss: 'Dismiss and go to next',
            dismiss2: 'Dismiss',
        },
        pitchPlayer: {
            age: 'Age',
            yrs: 'yrs',
            askingPrice: 'Asking price',
            loanFee: 'Loan fee',
            notAvailable: 'Not available',
        },
        main: {
            newPlusPitchHeader: 'PLUS PITCH',
            removedOn: 'Removed on',
            upTo: 'Up to',
            pitches: 'Pitches',
            pitch: 'Pitch',
            received: 'Received',
            saved: 'Saved',
            interests: 'Interests',
            interest: 'Interest',
            declared: 'Declared',
            updated: 'UPDATED',
            updateAd: 'Update ad',
            remove: 'Remove',
            activity: 'Activity',
            savedPitches: 'Saved pitches',
            myAds: 'My ads',
            createNewAd: 'Create new ad',
            wantMoreData: 'Want even more data on players?',
            whenYouSave:
                'When you save a pitch, you get access to analysis tools and you can compare a player to your own or other pitched players.',
            monthlyLoanFee: 'Monthly loan budget incl. gross salary:',
            transferFee: 'Transfer fee:',
            to: 'To',
        },
        actions: {
            club: 'Club',
            clubs: 'Clubs',
            pitches: 'pitches',
            pitch: 'pitch',
            newSuperPitch: 'new Super ',
            newPlus: 'new plus',
            received: 'received',
            superPitchReceived: 'SUPER PITCH RECEIVED',
            buy: 'buy',
            loan: 'loan',
            newPitchesForYourAd: 'new pitches for your ad for',
            newPitchForYourAd: 'new pitch for your ad for',
            to: 'to',
            withBudgetUpTo: 'with budget up to',
            tip: 'TIP:',
            inDoubt:
                'If you are in doubt, you can always save a pitch for further analysis',
        },
        summary: {
            pitch: 'Pitch',
            pitches: 'Pitches',
            new: 'new',
            ad: 'Ad',
            ads: 'Ads',
            active: 'active',
            saved: 'saved',
        },
        savedPitch: {
            newMessage: 'New Message',
            remove: 'Remove',
            compareTo: 'Compare to...',
            type: 'Type...',
            profile: 'Profile',
            radar: 'Radar',
            table: 'Table',
            season: 'Season',
        },
        savedPitches: {
            newPitch: 'new pitch',
            newPitches: 'new pitches',
            superPitch: 'SUPER PITCH',
            buy: 'buy',
            loan: 'loan',
            forYourAd: 'for your ad for',
            to: 'to',
            withBudgetUpTo: 'with budget up to',
        },
        upgradeOffer: {
            wantMoreAds: 'Want to make more ads?',
            itTakes2Mins:
                'It only takes 2 minutes and you can always change it',
            getStarted: 'Get started now for',
            only20: 'only &#163;20 per month!',
            upgradeNow: 'Upgrade now',
            remindLater: 'Remind later',
            upgradeOffer: 'Upgrade offer',
        },
        adPitches: {
            delivered: 'DELIVERED',

            interest: 'INTEREST',
            saved: 'SAVED',
            opened: 'OPENED',
        },
        pitchStatus: {
            delivered: 'Delivered',
            declaredInterest: 'Declared interest',
            interest: 'Interest',
            saved: 'Saved',
            opened: 'Opened',
            dismissed: 'Dismissed',
            removed: 'Removed',
        },
        formatAd: {
            buy: 'buy',
            loan: 'loan',
            age: 'Age',
            min: 'Min.',
            cm: 'cm',
            updated: 'Updated',
            ago: 'ago',
            lastUpdatedFuture: '"lastUpdated" date should not be from future',
            updatedToday: 'Updated today',
            day: 'day',
            week: 'week',
            month: 'mo',
            year: 'year',
        },
        editor: {
            insertAmount: 'Insert amount!',
            minimumValueRequired: 'Minimum required value is %{value}',
            multipleAds: 'Player Ads [Multiple Ads]',
            position: 'Position:',
            toLoan: 'To Loan',
            toBuy: 'To Buy',
            currency: 'Currency',
            select: 'Select position...',
            transferFee: 'Transfer fee',
            upTo: 'up to:',
            monthlyLoanFee: 'Monthly loan fee',
            mo: '/mo.',
            createNewAds: 'Create new ads',
            createAdRecentSearch: 'Create ad based on you recent search',
            planSummerTransfers: 'Plan your summer transfers',
            increaseChances:
                'Creating ads increases your chances with more than 50%',
            cancel: 'Cancel',
            done: 'Done!',
            launchAd: 'Launch ad',
            createNewAd: 'Create new ad',
            age: 'Age',
            yrs: 'yrs',
            minimumHeight: 'Minimum height:',
            cm: 'cm',
            ft: 'ft',
            in: 'in',
        },
    },
    playerRanking: {
        title: 'Player ranking',
        trial: 'New feature! You are on a trial.',
        noData: 'No statistic data for ranking',
        show10More: 'Show 10 more...',
        show10First: 'Show first 10',
        all: 'ALL',
        myShortList: 'My shortlist',
        compareTo: 'Compare to',
        position: 'Position',
        statistics: {
            scoringContribution: 'Scoring contribution',
            scoringContributionDesc: '(Goals + Assists)',
            contribution: 'Scoring contribution',
            contributionDesc: '(Goals + Assists)',
            goalEfficiency: 'Goal efficiency',
            goalEfficiencyDesc: '(Goals / xG)',
            finishing: 'Finishing',
            finishingDesc: '(xG / Shots)',
            goals: 'Goals',
            assists: 'Assists',
            xG: 'xG',
            xA: 'xA',
            succesfuldribbles: 'Successful dribbles',
            shots: 'Shots',
            shotsOnTarget: 'Shots on target',
            passesAccuracy: 'Passes accuracy',
            forwardPassesAccuracy: 'Forward passes accuracy',
            passesToFinalThirdAccuracy: 'Passes to final third accuracy',
            longPassesAccuracy: 'Long passes accuracy',
            interceptions: 'Interceptions',
            aerialDuelsWon: 'Aerial duels won',
            defensiveDuelsWon: 'Defensive duels won',
            saves: 'Saves',
            goalkeeping: 'Goalkeeping',
            goalkeepingDesc: '(CG / xCG)',
            succesfulCrosses: 'Successful crosses',
            crossesAccuracy: 'Crosses accuracy',
            minutesPlayed: 'Minutes played',
            totalGoals: 'Total goals',
            totalSuccessfulDribbles: 'Minutes played',
            totalShots: 'Minutes played',
            totalPasses: 'Total passes',
            totalForwardPasses: 'Total forward passes',
            totalPassesToFinalThird: 'Total passes to final third',
            totalLongPasses: 'Total long passes',
            totalInterceptions: 'Minutes played',
            totalAerialDuels: 'Total aerial duels',
            totaDefensiveDuels: 'Total defensive duels',
            totalSaves: 'Minutes played',
            totalCrosses: 'Total crosses',
            totalSuccessfulCrosses: 'Total successful crosses',
            totaDefensiveDuelsWon: 'Tota defensive duels won',
            totalAerialDuelsWon: 'Total aerial duels won',
            totalSuccessfulLongPasses: 'Total successful long passes',
            totalSuccessfulPassesToFinalThird: 'Total successful passes to final third',
            totalSuccessfulForwardPasses: 'Total successful forward passes',
            totalSuccessfulPasses: 'Total successful passes',
            totalxGAssists: 'Total xG assists',
            totalxGShots: 'Total xG shots',
            totalAssists: 'Total assists',
        },
    },
    playerComparison: {
        main: {
            playersComparison: 'Players comparison',
            noChartData: 'No chart data available...',
            noData: 'No data available...',
        },
        filters: {
            season: 'Season',
            searchPlayer: 'Search player...',
            type: 'Type...',
            profile: 'Profile',
        },
        table: {
            dataIsComparing:
                '* Data is comparing performance from player’s domestic league',
            statsPer90: '** All stats are per 90 minutes',
        },
        constants: {
            minutesPlayed: 'Minutes played',
            defensiveStatsP90: 'Defensive stats P90',
            shotsAgainst: 'Shots against',
            saves: 'Saves',
            concededGoals: 'Conceded goals',
            xCG: 'xCG',
            offensiveStatsP90: 'Offensive stats P90',
            passesAccuracy: 'Passes accuracy',
            longPassesAccuracy: 'Long passes accuracy',
            forwardPassesAccuracy: 'Forward passes accuracy',
            height: 'Height',
            defensiveDuelsWon: 'Defensive duels won',
            aerialDuelsWon: 'Aerial duels won',
            interceptions: 'Interceptions',
            scoringContribution: 'Scoring contribution',
            shots: 'Shots',
            crossesAccuracy: 'Crosses accuracy',
            passesToFinalThirdAccuracy: 'Passes to final third accuracy',
            shotsOnTarget: 'Shots on target',
            successfulCrosses: 'Successful crosses',
            successfulDribbles: 'Successful dribbles',
            xG: 'xG',
            goals: 'Goals',
            assists: 'Assists',
            xA: 'xA',
            season: 'Season',
        },
        formatPlayer: {
            average: 'Average',
            avg: 'Avg.',
        },
    },
    playerInfo: {
        notAvailable: 'not available',
        declareInterest: 'Declare interest',
        notInterest: 'not interest',
    },
    positions: {
        goalkeeper: 'Goalkeeper',
        leftBack: 'Left Back',
        rightBack: 'Right Back',
        centerBack: 'Center Back',
        defensiveMidfielder: 'Defensive Midfielder',
        centralMidfielder: 'Central Midfielder',
        attackingMidfielder: 'Attacking Midfielder',
        winger: 'Winger',
        forward: 'Forward',
    },
    positionSelector: {
        primaryPosition: 'Primary position',
        secondaryPosition: 'Secondary position',
        none: 'none',
    },
    removePlayer: {
        areYouSure: 'Are you sure you want to remove',
        playerWillBeRemoved: 'Player will be removed from your squad',
        reason: 'Reason to remove',
        chooseReason: 'Choose the reason...',
        removePlayer: 'Remove Player',
        cancel: 'Cancel',
        reasons: {
            outOnLoan: 'Player is out on loan',
            retired: 'Player has retired',
            released: 'Player has been released',
            leftTheClub: 'Player has left the club',
            otherReason: 'Other reason',
        },
    },
    accessRestricted: {
        dontHavePermission:
            "Sorry, you don't have permission to use this feature. Upgrades are available.",
        weWillBeInTouch: 'Thanks. We will be in touch with more information',
        restrictedAccess: 'Restricted access',
        close: 'Close',
        moreInfo: 'More info',
        newMessagesLimitIsRichedOut:
            'You have reached your 5 new messages limit per 24 hours. Try again, later. For any questions, please contact <a href="mailto:support@transferroom.com">support@transferroom.com</a>',
    },
    messaging: {
        messageButton: 'MESSAGE',
        message: 'Message...',
        cancel: 'Cancel',
        send: 'Send',
        messaging: 'Messaging',
        sendMessage: 'Send Message',
    },
    shortlist: {
        shortlisted: 'Shortlisted',
        addToShortlist: 'Add to shortlist',
    },
    superPitch: {
        pitchCreated: {
            hasBeenSuperPitched: 'has been super pitched to',
            club: 'club',
            clubs: 'clubs',
            anotherPlayer: 'Super Pitch Another player',
        },
        createFlow: {
            superPitch: 'SUPER PITCH',
            newFeature: 'NEW FEATURE',
            pitchYourPlayer:
                'Pitch your player to a specific club or a whole league',
            leftThis: 'left this',
            month: 'month',
            pitchPlayerNow: 'Pitch player now!',
            clear: 'Clear',
            readyToGo: 'Ready to go now! Just click Pitch Button.',
        },
        playerAdd: {
            selectPlayer: 'SELECT PLAYER',
            selectPlayerName: 'Select player name...',
            cancel: 'Cancel',
            choosePlayer: 'Choose player...',
            player: 'Player',
            startByChoosing:
                'Start by choosing a player you want to Super Pitch!',
        },
        setPrice: {
            setPriceFor: 'SET PRICE FOR',
            yes: 'Yes',
            no: 'No',
            askingPrice: 'Asking price',
            sellOn: 'Sell-on',
            forLoan: 'For Loan',
            monthlyLoanFee: 'Monthly loan fee',
            price: 'Price',
            setPrice: 'Set price...',
            forSale: 'For Sale',
            nowSetPrice: 'Now set the price and transfer type!',
            cancel: 'Cancel',
            pm: 'pm',
        },
        setRecipients: {
            zeroSelected: '0 Selected',
            selectLeagues: 'Select leagues from the list',
            recipientsUpTo: 'Recipients (up to',
            selectRecipients: 'SELECT RECIPIENTS',
            selectClubName: 'Select club name or league...',
            clubsLimit: 'clubs limit',
            reached: 'reached. Review clubs selected in the left list.',
            clearSelection: 'Clear Selection',
            cancel: 'Cancel',
            saveChanges: 'Save Changes',
            readyToGo: 'Choose club or whole league, and you are ready to go!',
            chooseRecipients: 'Choose recipients...',
            recipients: 'Recipients',
        },
        utils: {
            club: 'club',
            clubs: 'clubs',
            league: 'league',
            leagues: 'leagues',
        },
    },
    pitchPlus: {
        getAccess: {
            getAccessNow: {
                getAccessTo: 'GET ACCESS TO',
                plusPitch: 'PLUS PITCH',
                now: 'NOW!',
            },
            description1:
                '1 in every 4 Plus Pitch results in players finding a new club.',
            description2:
                'More than x5 higher success rate than normal pitches.',
            requestInfoButton: 'REQUEST INFORMATION',
        },
        navigationPanel: {
            leftThisMonth: 'LEFT THIS MONTH',
            newPlusPitchButton: 'New Plus Pitch',
        },
        createFirst: {
            createFirstNow: {
                createYourFirst: 'CREATE YOUR FIRST',
                plusPitch: 'PLUS PITCH',
                now: 'NOW!',
            },
            description1:
                '1 in every 4 Plus Pitch results in players finding a new club.',
            description2:
                'More than x5 higher success rate than normal pitches.',
            createNewPlusPitchButton: 'CREATE NEW PLUS PITCH',
        },
        plusPitchItem: {
            plusPitched: 'PLUS PITCHED',
            forSale: 'To Buy:',
            forLoan: 'To Loan:',
            forHire: 'To hire:',
            mo: '/mo',
            circles: {
                low: 'LOW',
                medium: 'MEDIUM',
                good: 'GOOD',
                high: 'HIGH',
                veryhigh: 'VERY HIGH',
                max: 'MAX',
                readStatus: 'Read Status',
                interestRate: 'Interest Rate',
            },
            reactions: {
                undefined: 'Undefined',
                awaitingResponse: 'Awaiting response...',
                opened: 'Opened',
                savedPitch: 'Saved pitch ...',
                declaredInterest: 'Declared interest',
                dismissedPitch: 'Dismissed pitch ...',
                removed: 'Removed',
                awaitingResponseHeader: 'Awaiting response from',
                and: 'and',
                otherClubs: 'other clubs...',
                otherClub: 'other club...',
                close: 'Close...',
            },
            clubHasSavedPlusPitch:
                'club has saved the plus pitch. You can test their interest',
            clubsHaveSavedPlusPitch:
                'clubs have saved the plus pitch. You can test their interest',
            testInterestNowButton: 'TEST INTEREST NOW!',
            testInterestButton: 'TEST INTEREST',
            interestTested: 'Interest tested',
            interestCanBeTested: 'Interest can be tested',
            show: 'Show',
            more: 'more...',
            of: 'of',
            keepSavedPitch: 'Keep player in saved pitches',
        },
        generalPerformance: {
            heading: 'GENERAL PITCH PERFORMANCE',
            basedOn: 'Based on',
            quality: 'Quality',
            rates: {
                low: 'LOW',
                good: 'GOOD',
                veryGood: 'VERY GOOD',
                great: 'GREAT',
                max: 'MAX',
            },
            agentTip: 'Plus Pitch Agent Tip:',
            tipDescription:
                'This is your opportunity to communicate your expectations directly to the market. Make sure to make the pricing attractive and select a relevant audience of clubs to achieve the best possible results.',
            plusPitches: 'plus pitches',
        },
        pitchCreated: {
            hasBeenPlusPitched: 'has been Plus Pitched to',
            club: 'club',
            clubs: 'clubs',
            anotherPlayer: 'Plus Pitch Another player',
        },
        createFlow: {
            createNewPlusPitchButton: 'CREATE NEW PLUS PITCH',
            plusPitch: 'PLUS PITCH',
            newFeature: 'NEW FEATURE',
            startByChoosingPlayer:
                'Start by choosing a player you want to Plus Pitch!',
            startByChoosingHeadCoach:
                'Start by choosing a Head Coach you want to Plus Pitch!',
            pitchYourPlayer:
                'Pitch your player to a specific club or a whole league',
            leftThis: 'left this',
            month: 'month',
            pitchPlayerNow: 'Pitch player now!',
            close: 'Close',
            readyToGo: 'Ready to go now! Just click Pitch Button.',
        },
        playerAdd: {
            selectPlayer: 'SELECT PLAYER',
            selectHeadCoach: 'SELECT HEAD COACH',
            selectPlayerName: 'Select player name...',
            cancel: 'Cancel',
            choosePlayer: 'Choose player...',
            chooseHeadCoach: 'Choose Head Coach...',
            player: 'Player',
            startByChoosing:
                'Start by choosing a player you want to Plus Pitch!',
        },
        setPrice: {
            setPriceFor: 'SET PRICE FOR',
            yes: 'Yes',
            no: 'No',
            askingPrice: 'Asking price',
            sellOn: 'Sell-on',
            forLoan: 'To Loan',
            monthlyLoanFee: 'Monthly loan fee incl. gross salary',
            price: 'Price',
            setPrice: 'Set price...',
            forSale: 'To Buy',
            nowSetPrice: 'Now set the price and transfer type!',
            cancel: 'Cancel',
            pm: 'pm',
            mo: '/mo',
            setPriceButton: 'Set price',
            saveChanges: 'Save Changes',
            forHire: 'To hire',
            nowSetSalary: 'Now set the salary!',
        },
        setRecipients: {
            zeroSelected: '0 Selected',
            selectLeagues: 'Select leagues from the list',
            recipientsUpTo: 'Recipients (up to',
            selectRecipients: 'ADD RECIPIENTS',
            selectClubName: 'Select club name or league...',
            clubsLimit: 'clubs limit',
            reached: 'reached. Review clubs selected in the left list.',
            clearSelection: 'Clear Selection',
            cancel: 'Cancel',
            saveChanges: 'Add Recipients',
            readyToGo: 'Choose club or whole league, and you are ready to go!',
            chooseRecipients: 'Choose recipients...',
            recipients: 'Recipients',
        },
        utils: {
            club: 'club',
            clubs: 'clubs',
            league: 'league',
            leagues: 'leagues',
        },
        testInterest: {
            forSale: 'To Buy',
            forLoan: 'To Loan',
            sendMessage: 'SEND MESSAGE',
            deleteSavedPitch: 'DELETE SAVED PITCH',
            thisClub: 'this club',
            theseClubs: 'these clubs',
            whenCanYou: 'When can you test interest?',
            canTestDaysAfter:
                'You can test interest in players %{number} days after your pitch has been saved.',
            testInterestWith: 'Test interest with',
            testInterest: 'TEST INTEREST',
            plusPitched: 'PLUS PITCHED',
            alreadyTestedInterest: 'You already tested interest with them',
            savedThePitch: 'Saved the pitch',
            cannotTestBefore: 'You cannot test their interest before',
            wouldLikeYouToRespond:
                'would like you to respond on a Plus Pitch you have saved',
            timesOut: 'SAVED PLUS PITCH - TIMES OUT IN',
            ifYouDoNotReact:
                'IMPORTANT: If you do not react to these, they will time out and the saved pitch will be deleted automatically.',
            rePlusPitchOf: 'Re: Plus pitch of',
            cancel: 'CANCEL',
        },
    },
    superPitch2: {
        getAccess: {
            getAccessNow: {
                getAccessTo: 'GET ACCESS TO',
                plusPitch: 'SUPER PITCH',
                now: 'NOW!',
            },
            description1:
                '1 in every 4 Super Pitch results in players finding a new club.',
            description2:
                'More than x5 higher success rate than normal pitches.',
            requestInfoButton: 'REQUEST INFORMATION',
        },
        navigationPanel: {
            leftThisMonth: 'LEFT THIS MONTH',
            newPlusPitchButton: 'New Super Pitch',
        },
        createFirst: {
            createFirstNow: {
                createYourFirst: 'CREATE YOUR FIRST',
                plusPitch: 'SUPER PITCH',
                now: 'NOW!',
            },
            description1:
                '1 in every 4 Super Pitch results in players finding a new club.',
            description2:
                'More than x5 higher success rate than normal pitches.',
            createNewPlusPitchButton: 'CREATE NEW SUPER PITCH',
        },
        plusPitchItem: {
            plusPitched: 'SUPER PITCHED',
            forSale: 'For Sale:',
            forLoan: 'For Loan:',
            circles: {
                low: 'LOW',
                medium: 'MEDIUM',
                good: 'GOOD',
                high: 'HIGH',
                veryhigh: 'VERY HIGH',
                max: 'MAX',
                readStatus: 'Read Status',
                interestRate: 'Interest Rate',
            },
            reactions: {
                undefined: 'Undefined',
                awaitingResponse: 'Awaiting response...',
                opened: 'Opened',
                savedPitch: 'Saved pitch ...',
                declaredInterest: 'Declared interest',
                dismissedPitch: 'Dismissed pitch ...',
                removed: 'Removed',
                awaitingResponseHeader: 'Awaiting response from',
                and: 'and',
                otherClubs: 'other clubs...',
                otherClub: 'other club...',
                close: 'Close...',
            },
            clubHasSavedPlusPitch:
                'club has saved the super pitch. You can test their interest',
            clubsHaveSavedPlusPitch:
                'clubs have saved the super pitch. You can test their interest',
            testInterestNowButton: 'TEST INTEREST NOW!',
            testInterestButton: 'TEST INTEREST',
            interestTested: 'Interest tested',
            interestCanBeTested: 'Interest can be tested',
            show: 'Show',
            more: 'more...',
            of: 'of',
            keepSavedPitch: 'Keep player in saved pitches',
        },
        generalPerformance: {
            heading: 'GENERAL PITCH PERFORMANCE',
            basedOn: 'Based on',
            quality: 'Quality',
            rates: {
                low: 'LOW',
                good: 'GOOD',
                veryGood: 'VERY GOOD',
                great: 'GREAT',
                max: 'MAX',
            },
            agentTip: 'Super Pitch Agent Tip:',
            tipDescription:
                'This is your opportunity to communicate your expectations directly to the market. Make sure to make the pricing attractive and select a relevant audience of clubs to achieve the best possible results.',
            plusPitches: 'super pitches',
        },
        pitchCreated: {
            hasBeenPlusPitched: 'has been Super Pitched to',
            club: 'club',
            clubs: 'clubs',
            anotherPlayer: 'Super Pitch Another player',
        },
        createFlow: {
            createNewPlusPitchButton: 'CREATE NEW SUPER PITCH',
            plusPitch: 'SUPER PITCH',
            newFeature: 'NEW FEATURE',
            startByChoosing:
                'Start by choosing a player you want to Super Pitch!',
            pitchYourPlayer:
                'Pitch your player to a specific club or a whole league',
            leftThis: 'left this',
            month: 'month',
            pitchPlayerNow: 'Pitch player now!',
            clear: 'Close',
            readyToGo: 'Ready to go now! Just click Pitch Button.',
        },
        playerAdd: {
            selectPlayer: 'SELECT PLAYER',
            selectPlayerName: 'Select player name...',
            cancel: 'Cancel',
            choosePlayer: 'Choose player...',
            player: 'Player',
            startByChoosing:
                'Start by choosing a player you want to Super Pitch!',
        },
        setPrice: {
            setPriceFor: 'SET PRICE FOR',
            yes: 'Yes',
            no: 'No',
            askingPrice: 'Asking price',
            sellOn: 'Sell-on',
            forLoan: 'For Loan',
            monthlyLoanFee: 'Monthly loan fee',
            price: 'Price',
            setPrice: 'Set price...',
            forSale: 'For Sale',
            nowSetPrice: 'Now set the price and transfer type!',
            cancel: 'Cancel',
            pm: 'pm',
            setPriceButton: 'Set price',
        },
        setRecipients: {
            zeroSelected: '0 Selected',
            selectLeagues: 'Select leagues from the list',
            recipientsUpTo: 'Recipients (up to',
            selectRecipients: 'ADD RECIPIENTS',
            selectClubName: 'Select club name or league...',
            clubsLimit: 'clubs limit',
            reached: 'reached. Review clubs selected in the left list.',
            clearSelection: 'Clear Selection',
            cancel: 'Close',
            saveChanges: 'Add Recipients',
            readyToGo: 'Choose club or whole league, and you are ready to go!',
            chooseRecipients: 'Choose recipients...',
            recipients: 'Recipients',
        },
        utils: {
            club: 'club',
            clubs: 'clubs',
            league: 'league',
            leagues: 'leagues',
        },
        testInterest: {
            forSale: 'To Buy',
            forLoan: 'To Loan',
            forHire: 'To Hire',
            sendMessage: 'SEND MESSAGE',
            deleteSavedPitch: 'DELETE SAVED PITCH',
            thisClub: 'this club',
            theseClubs: 'these clubs',
            whenCanYou: 'When can you test interest?',
            canTestDaysAfter:
                'You can test interest in players %{number} days after your pitch has been saved.',
            headCoachCanTestDaysAfter:
                'You can test interest in Head Coaches %{number} days after your pitch has been saved.',
            testInterestWith: 'Test interest with',
            testInterest: 'TEST INTEREST',
            plusPitched: 'SUPER PITCHED',
            alreadyTestedInterest: 'You already tested interest with them',
            savedThePitch: 'Saved the pitch',
            cannotTestBefore: 'You cannot test their interest before',
            wouldLikeYouToRespond:
                'would like you to respond on a Super Pitch you have saved',
            timesOut: 'SAVED SUPER PITCH - TIMES OUT IN',
            ifYouDoNotReact:
                'IMPORTANT: If you do not react to these, they will time out and the saved pitch will be deleted automatically.',
            rePlusPitchOf: 'Re: Super pitch of',
            cancel: 'CANCEL',
        },
    },
    playerSimilarity: {
        similarPlayers: 'Similar Players',
    },
    video: {
        whatClubsSay: 'What do clubs say about TransferRoom?',
        watchInsights:
            'Watch insights on how top clubs are using TransferRoom to save time and money.',
        whatOtherClubsSay: 'WHAT OTHER CLUBS SAY',
        allVideos: 'ALL VIDEOS',
    },
    pager: {
        pageSize: 'Page size:',
        page: 'Page',
        of: 'of',
        to: 'to',
    },
    agGrid: {
        yes: 'Yes',
        no: 'No',
        name: 'Name',
        currentImage: 'Current Image',
        deleteImage: 'Delete Image',
        dropFilesHere: 'Drop files here...',
        tryDropping:
            'Try dropping some files here, or click to select files to upload.',
    },
    askingPrice: {
        askingPrice: 'Asking price',
        priceUponRequest: 'Price upon request',
        sellOn: '% sell-on',
        availableForSale: 'Available to buy',
    },
    availabilityBadge: {
        available: 'Available',
        toBeReleased: 'TO BE RELEASED'
    },
    planSummerTransferBanner: {
        planSummerTransfers: 'Plan your summer transfers',
        creatingAds:
            'Creating ads increases your chances of success with 50% or more',
        getStarted: 'Get Started Now',
    },
    superPitchAnnouncement: {
        newFeature: 'NEW FEATURE',
        pitchYourPlayer:
            'Plus pitch - pitch your player to a specific club or a whole league',
        leftThisMonth: 'left this month',
        newSuperPitch: 'New plus pitch',
        leftThis: 'left this',
        month: 'month',
    },
    transferStatusButton: {
        makeAvailable: 'Make available',
    },
    calendar: {
        weekDaysAbbreviated: {
            sunday: 'S',
            monday: 'M',
            tuesday: 'T',
            wednesday: 'W',
            thursday: 'T',
            friday: 'F',
            saturday: 'S',
        },
        months: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
        monthsAbbreviated: {
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'May',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Oct',
            nov: 'Nov',
            dec: 'Dec',
        },
        weekDays: {
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
        },
    },
    clubEntry: {
        club: 'Club',
        exp: 'exp.',
        onLoanto: 'On Loan to',
    },
    contractExpiry: {
        month: 'month',
        months: 'months',
    },
    dateRangePicker: {
        selectFirst: 'Please select the first day.',
        selectLast: 'Please select the last day.',
        to: 'to',
    },
    filter: {
        clear: 'Clear',
        squad: 'Squad',
        status: 'Status',
        age: 'Age',
        contractExpiry: 'Contract Expiry',
        position: 'Position',
        league: 'League',
        marketValue: 'Market Value',
        gsnPotential: 'GSN Potential',
        minutesPlayed: 'Minutes Played',
        xTV: 'xTV',
        height: 'Height'
    },
    contractExpirySelector: {
        yr: 'yr',
        mo: 'mo',
        yrs: 'yrs',
    },
    feeSelector: {
        pm: 'pm',
    },
    height: {
        cm: 'cm',
        feet: 'feet',
    },
    helpIcons: {
        monthlyLoanFeeIncludes:
            'Monthly loan fee includes club fee and player salary.',
        sellOnIsPercentage:
            'Sell-on % is the percentage of any transfer profit from a subsequent sale of the player to a third party.',
        exportPdf: 'Export profile to a PDF file'
    },
    logout: {
        logout: 'Logout...',
    },
    monthlyLoanFeeEntry: {
        availableForLoan: 'Available to loan',
        monthlyLoanFee: 'Monthly loan fee:',
        priceUponRequest: 'Price upon request',
        pm: '/mo',
    },
    nationalityEntry: {
        nationality: 'Nationality',
    },
    noResult: {
        loading: 'Loading',
        noResults: 'No results. The list is empty.',
    },
    paging: {
        showingResults: 'Showing results',
    },
    pitchPlayerButton: {
        pitchPlayer: 'Pitch Player',
    },
    positionEntry: {
        position: 'Position',
        secondaryPos: 'Secondary Pos.',
    },
    weight: {
        kg: 'kg',
        lbs: 'lbs',
    },
    main: {
        loading: 'Loading...',
    },
    buttons: {
        ourButtons: 'Our buttons',
        effectLarge: 'effect large:',
    },
    mySquad: {
        advancedFilter: {
            showResults: 'Show results',
            allPlayers: 'All Players',
            forLoan: 'For Loan',
            forSale: 'For Sale',
            forFree: 'For Free',
            outOnLoad: 'Out on loan',
        },
        grid: {
            removePlayer: 'Remove player',
            choosePlayer: 'Search for player…',
            edit: 'Edit',
            filter: 'FILTER',
            cancel: 'Cancel',
            save: 'Save',
        },
        transferStatusButton: {
            sale: 'Buy',
            loan: 'Loan',
            makeAvailable: 'Make Available',
            seeProfile: 'See Profile',
            for: 'To',
        },
        uploadVideo: {
            video: 'Video',
            insertLink: 'Insert (YouTube or Vimeo) link...',
            cancel: 'Cancel',
            saveChanges: 'Save changes',
            max2Videos: 'Max 2 videos are allowed per player',
        },
        recommendedMatch: {
            heading: 'RECOMMENDATION MATCH',
            isLookingTo: 'is looking to',
            buy: 'Buy',
            loan: 'Loan',
            a: 'a',
            transferFee: 'Transfer fee:',
            loanFee: 'Loan budget:',
            upTo: 'up to',
            doYouWantToPitch: 'Do you want to pitch',
            pitch: 'PITCH',
            decline: 'DECLINE',
            monthlyloanfee:"Monthly loan fee:",
            inclGrossSalary: "incl.gross salary",
            askingPrice:"Asking price:"
        },
    },
    delayDayBanner: {
        days: 'DAYS',
        dealDay: 'Deal day',
        hours: 'HOURS',
        minutes: 'MINUTES',
        seconds: 'SECONDS',
        learnMore: 'LEARN MORE',
    },
    playerSearch: {
        titleAll: 'ALL PLAYERS',
        titleShortList: 'MY SHORTLIST',
        titleSimilarTo: 'SIMILAR TO',
        new: 'NEW',
        selectSorting: 'SELECT SORTING',
        sortBy: 'Sort by',
        sortByIncreasing: 'Increasing',
        sortByDecreasing: 'Decreasing',
        applySorting: 'APPLY SORTING',
        sortbyAvailability: 'Availability',
        bannerWebinar: {
            webinar: 'Webinar',
            marketValue: 'Market Value',
            date: '8th April, 2pm BST',
            dayLeft: 'DAY LEFT',
            daysLeft: 'DAYS LEFT',
        },
        dropDown: {
            requestedLoanContribution: 'Requested loan contribution',
            requestLoanContribution: 'Request loan contribution',
            requestedLoanAvailability: 'Requested loan availability',
            requestLoanAvailability: 'Request loan availability',
            profile: 'Profile',
            yrs: 'yrs',
            newMessage: 'New Message',
            requestedAskingPrice: 'Requested asking price',
            requestAskingPrice: 'Request asking price',
            requestedTransferAvailability: 'Requested transfer availability',
            requestTransferAvailability: 'Request transfer availability',
            sendByEmail: 'Send by Email',
        },
        filter: {
            showResults: 'Show results',
            createLeaguesList: 'Create leagues list',
            forLoan: 'To Loan',
            forSale: 'To Buy',
            forFree: 'Free',
            toBeReleased: 'To be Released',
            age: 'Age',
            contractExpiry: 'Contract Expiry',
            position: 'Position',
            league: 'League',
            gsnPerformance: 'GSN Performance'
        },
        grid: {
            searchPlayerName: 'Search player name or club...',
            filter: 'FILTER',
        },
        menu: {
            allPlayers: 'All players',
            myShortlist: 'My shortlist',
        },
        customColumns: {
            of: 'of',
            columnsSelected: 'columns selected',
            performanceData: 'PERFORMANCE DATA',
        },
        main: {
            playerSearch: 'Player Search',
            watchVideo: 'Watch video of the player',
        },
        similarTo: {
            suggestionTip:
                'Use the search engine to instantly run our algorithm on the database of 50K players to find the one most similar in terms of his level and performance',
        },
        recommendationBanner: {
            heading:
                'Get intelligent player recommendations from our data analyst engine',
            description:
                'Simply complete the ad we have already made ready for you and get 5 player recommendations right away',
            getYourRecommendationButton: 'Get your recommendations now',
        },
    },
    playerProfile: {
        webinar: {
            panelHeading: 'Webinar: Market Value'
        },
        marketValue: {
            panelHeading: 'MARKET VALUE',
            showMeButton: 'SHOW ME!',
            landing: {
                valuationAvailable: 'PLAYER VALUATION AVAILABLE!',
                inCooperation: 'In cooperation with 21st Club',
                interested: 'INTERESTED?',
                contributeWithOpinion: 'Contribute with your own opinion to see the valuation',
                placeholder: 'Enter your valuation ...'
            },
            chart: {
                marketValue: 'Market Value',
                club: 'Club',
                selection: {
                    latest12Months: 'Latest 12 months',
                    latest2Years: 'Latest 2 years',
                    latest3Years: 'Latest 3 years',
                    latest4Years: 'Latest 4 years',
                    latest5Years: 'Latest 5 years',
                },
                clubValuation: '21st Club valuation',
                tipText: `21st Club uses data to account for a player’s
                performance levels and age to provide an objective assessment
                of his transfer value, based on recent market conditions and transactions.`,
                lastUpdated: 'Last updated',
                requestedAskingPrice: 'Requested asking price',
                requestAskingPrice: 'Request asking price',
                requestedAvailability: 'Requested availability',
                requestAvailability: 'Request availability',
            }
        },
        gsnPerformance: {
            panelHeading: 'GSN INDEX - PERFORMANCE AND POTENTIAL',
            chartDescription: 'Global Soccer Network is the first rating agency for professional footballers and the creators of the unique rating system, the GSN-Index.',
            performance: 'Performance',
            potential: 'Potential',
            compareTo: 'Compare to ...',
            dropdownTitle: 'Performance & Potential Ranking Tables',
            dropdown: {
                position: 'Position',
                my1stTeam: 'My 1st Team',
                myShortlist: 'My Shortlist',
                manageShortlist: 'MANAGE SHORTLIST',
                addPlayers: 'ADD PLAYERS',
                rank: 'RANK',
                player: 'PLAYER',
                perf: 'PERF.',
                potential: 'POTENTIAL',
                all: 'ALL',
                youDontHavePlayersInShortlist: 'You do not have players on your shortlist',
                addPlayersToMyShortlist: 'ADD PLAYERS TO MY SHORTLIST'
            },
            modal: {
                heading: "MANAGE YOUR SHORTLIST",
                playersOnShortlist: 'Players on Shortlist',
                searchPlaceholder: 'Type a player name',
                tip: 'Select players on the right to add to your shortlist',
                saveBtn: 'Save'
            }
        },
        comparison: {
            playerComparsion: 'Player Comparsion',
        },
        passport: {
            playerPassport: 'Player Passport',
            height: 'Height',
            foot: 'Foot',
            newMessage: 'New Message',
        },
        summary: {
            yrs: 'yrs',
            marketValue: 'Market Value',
        },
        transferHistory: {
            transferHistory: 'Transfer History',
            date: 'Date',
            from: 'From',
            to: 'To',
            type: 'Type',
            price: 'Price',
            noTransferHistory: 'There is no transfer history available',
            show: 'Show',
            more: 'more...',
        },
        transferBenchmarks: {
            player: 'Player',
            percentMatch: '% Match',
            age: 'Age',
            year: 'Year',
            from: 'From',
            to: 'To',
            fee: 'Fee',
            showMore: 'Show %{number} more',
        },
        video: {
            playerVideo: 'Player Video',
        },
    },
    shortList: {
        shortList: 'Shortlist',
    },
    playerGrid: {
        player: 'Player',
        squad: 'Squad',
        position: 'Position',
        age: 'Age',
        gsnPotential: 'GSN Potential',
        gsnPerformance: 'GSN Performance',
        contractExpiry: 'Contract Expiry',
        transferStatus: 'Transfer Status',
        matchMaker: 'Match Maker',
        height: 'Height',
        foot: 'Foot',
        league: 'League',
        club: 'Club',
        marketValue: 'Market Value',
        recommendation: 'Recommendation',
        faPlayerPoint: 'GBE status',
    },
    utils: {
        cm: 'cm',
    },
    upgradeModal: {
        thankYou: 'Thank you',
        forYourInterest: 'for your interest!',
        weWillBeInTouch: 'We will be in touch shortly',
        close: 'Close'
    },
    positionAbbreviations: {
        GK: 'GK',
        LB: 'LB',
        RB: 'RB',
        CB: 'CB',
        DM: 'DM',
        CM: 'CM',
        AM: 'AM',
        W: 'W',
        F: 'F',
    },
    positionFullNames: {
        GK: 'Goalkeeper',
        LB: 'Left Back',
        RB: 'Right Back',
        CB: 'Centre Back',
        DM: 'Defensive Midfielder',
        CM: 'Central Midfielder',
        AM: 'Attacking Midfielder',
        W: 'Winger',
        F: 'Forward',
    },
    apiTexts: {
        withoutClub: 'Without Club',
        transferTypesHistory: {
            1: 'Transfer',
            2: 'Free Transfer',
            3: 'Loan',
            4: 'Back from Loan',
            5: 'Free Agent',
            6: 'Player Swap',
            7: 'Trial',
        },
        transferTypes: {
            1: 'To Buy',
            2: 'To Loan',
        },
        dayAgo: '%{number} day ago',
        daysAgo: '%{number} days ago',
        monthAgo: '%{number} month ago',
        monthsAgo: '%{number} months ago',
        yearAgo: '%{number} year ago',
        yearsAgo: '%{number} years ago',
        weekAgo: '%{number} week ago',
        weeksAgo: '%{number} weeks ago',
        hourAgo: '%{number} hour ago',
        hoursAgo: '%{number} hours ago',
        minuteAgo: '%{number} minute ago',
        minutesAgo: '%{number} minutes ago',
        secondAgo: '%{number} second ago',
        secondsAgo: '%{number} seconds ago',
        foot: {
            left: 'Left',
            right: 'Right',
        },
        userStatus: {
            active: 'Active',
            pendinginvitation: 'Pending Invitation',
        },
        areas: {
            1: 'Africa',
            2: 'Asia',
            3: 'Europe',
            4: 'N/C America',
            5: 'North America',
            6: 'South America',
            7: 'Oceania',
            8: 'World',
            9: 'Afghanistan',
            10: 'Albania',
            11: 'Algeria',
            12: 'American Samoa',
            13: 'Andorra',
            14: 'Angola',
            15: 'Anguilla',
            16: 'Antarctica',
            17: 'Antigua and Barbuda',
            18: 'Arab Emirates',
            19: 'Argentina',
            20: 'Armenia',
            21: 'Aruba',
            22: 'Australia',
            23: 'Austria',
            24: 'Azerbaijan',
            25: 'Bahamas',
            26: 'Bahrain',
            27: 'Bangladesh',
            28: 'Barbados',
            29: 'Belarus',
            30: 'Belgium',
            31: 'Belize',
            32: 'Benin',
            33: 'Bermuda',
            34: 'Bhutan',
            36: 'Bolivia',
            37: 'Bonaire, Sint Eustatius and Saba',
            38: 'Bosnia-Herzegovina',
            39: 'Botswana',
            40: 'Bouvet island',
            41: 'Brazil',
            42: 'British Indian Ocean Territory',
            43: 'British Virgin Islands',
            44: 'Brunei Darussalam',
            45: 'Bulgaria',
            46: 'Burkina Faso',
            47: 'Burundi',
            48: 'Cambodia',
            49: 'Cameroon',
            50: 'Canada',
            51: 'Cape Verde Islands',
            52: 'Cayman Islands',
            53: 'Central African Republic',
            54: 'Chad',
            55: 'Chile',
            56: 'China PR',
            57: 'Chinese Taipei',
            58: 'Christmas island',
            59: 'Cocos (keeling) islands',
            60: 'Colombia',
            61: 'Comoros',
            62: 'Congo',
            63: 'Congo DR',
            64: 'Cook Islands',
            65: 'Costa Rica',
            66: "Côte d'Ivoire",
            67: 'Croatia',
            68: 'Cuba',
            69: 'Curaçao',
            70: 'Cyprus',
            71: 'Czech Republic',
            72: 'Denmark',
            73: 'Djibouti',
            74: 'Dominica',
            75: 'Dominican Republic',
            76: 'East Timor',
            77: 'Ecuador',
            78: 'Egypt',
            79: 'El Salvador',
            80: 'England',
            81: 'Equatorial Guinea',
            82: 'Eritrea',
            83: 'Estonia',
            84: 'Ethiopia',
            85: 'Falkland islands (malvinas)',
            86: 'Faroe Islands',
            87: 'Fiji',
            88: 'Finland',
            89: 'France',
            90: 'France, metropolitan',
            91: 'French Guiana',
            92: 'French Polynesia',
            93: 'French Southern Territories',
            94: 'Gabon',
            95: 'Gambia',
            96: 'Georgia',
            97: 'Germany',
            98: 'Ghana',
            99: 'Gibraltar',
            100: 'Great Britain',
            101: 'Greece',
            102: 'Greenland',
            103: 'Grenada',
            104: 'Guadeloupe',
            105: 'Guam',
            106: 'Guatemala',
            107: 'Guernsey',
            108: 'Guinea',
            109: 'Guinea-Bissau',
            110: 'Guyana',
            111: 'Haiti',
            112: 'Holy See (Vatican City state)',
            113: 'Honduras',
            114: 'Hong Kong',
            115: 'Hungary',
            116: 'Iceland',
            117: 'India',
            118: 'Indonesia',
            119: 'Iran',
            120: 'Iraq',
            121: 'Ireland Republic',
            122: 'Isle of Man',
            123: 'Israel',
            124: 'Italy',
            125: 'Jamaica',
            126: 'Japan',
            127: 'Jersey',
            128: 'Jordan',
            129: 'Kazakhstan',
            130: 'Kenya',
            131: 'Kiribati',
            132: 'Korea DPR',
            133: 'Korea Republic',
            134: 'Kosovo',
            135: 'Kuwait',
            136: 'Kyrgyzstan',
            137: 'Laos',
            138: 'Latvia',
            139: 'Lebanon',
            140: 'Lesotho',
            141: 'Liberia',
            142: 'Libya',
            143: 'Liechtenstein',
            144: 'Lithuania',
            145: 'Luxembourg',
            146: 'Macao',
            147: 'Macedonia FYR',
            148: 'Madagascar',
            149: 'Malawi',
            150: 'Malaysia',
            151: 'Maldives',
            152: 'Mali',
            153: 'Malta',
            154: 'Marshall islands',
            155: 'Martinique',
            156: 'Mauritania',
            157: 'Mauritius',
            159: 'Mayotte',
            160: 'Mexico',
            161: 'Micronesia, federated states of',
            162: 'Moldova',
            163: 'Monaco',
            164: 'Mongolia',
            165: 'Montenegro',
            166: 'Montserrat',
            167: 'Morocco',
            168: 'Mozambique',
            169: 'Myanmar, Burma',
            170: 'Namibia',
            171: 'Nauru',
            172: 'Nepal',
            173: 'Netherlands',
            174: 'Netherlands antilles',
            175: 'New Caledonia',
            176: 'New Zealand',
            177: 'Nicaragua',
            178: 'Niger',
            179: 'Nigeria',
            180: 'Niue',
            181: 'Norfolk island',
            182: 'Northern Ireland',
            183: 'Northern Mariana Islands',
            184: 'Norway',
            185: 'Oman',
            186: 'Pakistan',
            187: 'Palau',
            188: 'Palestine',
            189: 'Panama',
            190: 'Papua New Guinea',
            191: 'Paraguay',
            192: 'Peru',
            193: 'Philippines',
            194: 'Pitcairn',
            195: 'Poland',
            196: 'Portugal',
            197: 'Puerto Rico',
            198: 'Qatar',
            199: 'Reunion',
            200: 'Romania',
            201: 'Russia',
            202: 'Rwanda',
            203: 'Saint Martin',
            204: 'Samoa',
            205: 'San Marino',
            206: 'São Tomé e Príncipe',
            207: 'Saudi Arabia',
            208: 'Scotland',
            209: 'Senegal',
            210: 'Serbia',
            211: 'Serbia and Montenegro',
            212: 'Seychelles',
            213: 'Sierra Leone',
            214: 'Singapore',
            215: 'Sint Maarten',
            216: 'Slovakia',
            217: 'Slovenia',
            218: 'Solomon Islands',
            219: 'Somalia',
            220: 'South Africa',
            221: 'South Georgia and the South Sandwich islands',
            222: 'South Sudan',
            223: 'Spain',
            224: 'Sri Lanka',
            225: 'St. helena',
            226: 'St. Kitts and Nevis',
            227: 'St. Lucia',
            228: 'St. pierre and miquelon',
            229: 'St. Vincent and the Grenadines',
            230: 'Sudan',
            231: 'Suriname',
            232: 'Svalbard and Jan Mayen islands',
            233: 'Swaziland',
            234: 'Sweden',
            235: 'Switzerland',
            236: 'Syria',
            237: 'Tahiti',
            238: 'Taiwan, province of china',
            239: 'Tajikistan',
            240: 'Tanzania',
            241: 'Thailand',
            242: 'Togo',
            243: 'Tokelau',
            244: 'Tonga',
            245: 'Trinidad and Tobago',
            246: 'Tunisia',
            247: 'Turkey',
            248: 'Turkmenistan',
            249: 'Turks and Caicos Islands',
            250: 'Tuvalu',
            251: 'Uganda',
            252: 'Ukraine',
            253: 'United Arab Emirates',
            254: 'United States',
            255: 'United states minor outlying islands',
            256: 'Uruguay',
            257: 'US Virgin Islands',
            258: 'Uzbekistan',
            259: 'Vanuatu',
            260: 'Venezuela',
            261: 'Vietnam',
            262: 'Virgin islands (british)',
            263: 'Virgin islands (u.s.)',
            264: 'Wales',
            265: 'Wallis and Futuna islands',
            266: 'Western Sahara',
            267: 'Yemen',
            268: 'Zambia',
            269: 'Zanzibar',
            270: 'Zimbabwe',
        },
        timeUnits: {
            now: 'now',
            day: 'day',
            days: 'days',
            week: 'week',
            weeks: 'weeks',
            month: 'month',
            months: 'months',
            hour: 'hour',
            hours: 'hours',
            minute: 'minute',
            minutes: 'minutes',
        },
        timeAgo: {
            today: 'Today',
            yesterday: 'Yesterday',
            thisWeek: 'This week',
            daysAgo: 'days ago',
            weekAgo: 'A week ago',
            weeksAgo: 'weeks ago',
            monthAgo: 'A month ago',
            monthsAgo: 'months ago',
            yearAgo: 'A year ago',
            yearsAgo: 'years ago'
        },
        monthUnitsShort: {
            0: 'Jan',
            1: 'Feb',
            2: 'Mar',
            3: 'Apr',
            4: 'May',
            5: 'Jun',
            6: 'Jul',
            7: 'Aug',
            8: 'Sep',
            9: 'Oct',
            10: 'Nov',
            11: 'Dec',
        }
    },
    adForceUpdate: {
        updateYourAds: 'Update your Ads',
        moreThan2MonthsAge:
            'You posted your ads more than 2 months ago. Please update your ads so other clubs knows if your are still looking.',
        updateAllAds: 'UPDATE ALL (%{number}) ADS',
        reviewYourAds: 'REVIEW YOUR ADS',
        updated: 'UPDATED',
        stillLooking: 'YES I AM STILL LOOKING',
        remove: 'REMOVE',
    },
};
