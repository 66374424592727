import * as redux from 'redux';
import * as SearchAutosuggestController from './autosuggest.controller';
import * as GridController from './grid.controller';
import * as FilterController from './filter.controller';
import * as CompetitionListController from './competition-list.controller';

export class State {
    search: SearchAutosuggestController.State;
    grid: GridController.State;
    filter: FilterController.State;
    competitionList: CompetitionListController.State;
}

export function combineReducers() {
    const reducers = {
        search: SearchAutosuggestController.Reducer,
        grid: GridController.Reducer,
        filter: FilterController.Reducer,
        competitionList: CompetitionListController.Reducer,
    };

    return redux.combineReducers(reducers);
}
