import Range from 'api/core/range';
import { IdName } from 'api/core/id-name';
import { PriceTypeEnum } from 'constants/enums';

export interface PlayerPositionsResponse {
    positions: IdName[];
}

export interface PlayersDetailsResponse {
    id: number;
    name: string;
    isPriority: boolean;
    clubName?: string;
    leagueName?: string;
}

export type PitchRequirementModel = {
    id: number;
    agencyId: number;
    source: IdName;
    contactName?: string;
    squad?: IdName;
    transferPeriod: string;
    toLoan: boolean;
    toBuy: boolean;
    loan?: string | null;
    buy?: string | null;
    grossSalary?: string | null;
    age: Range<number>;
    minHeight: number | null;
    playerPosition: IdName;
    isLeftFooted: boolean;
    moreInformation: string;
    playerId: number;
    pitchedPlayerStatusId: number;
    currencyId: number;
};

export enum PlayerPositionEnum {
    Goalkeeper = 1,
    LeftBack = 2,
    RightBack = 3,
    CentreBack_All = 4,
    CentreBack_LeftFooted = 5,
    DefensiveMidfielder = 6,
    CentralMidfielder = 7,
    AttackingMidfielder = 8,
    Winger = 9,
    Forward = 10,
}

export enum PlayerPositionNameEnum {
    Goalkeeper = 'Goalkeeper',
    LeftBack = 'Left Back',
    RightBack = 'Right Back',
    CentreBack_All = 'Centre Back (All)',
    CentreBack_LeftFooted = 'Centre Back (Left Footed)',
    DefensiveMidfielder = 'Defensive Midfielder',
    CentralMidfielder = 'Central Midfielder',
    AttackingMidfielder = 'Attacking Midfielder',
    Winger = 'Winger',
    Forward = 'Forward',
}

export enum PitchRequirementStepEnum {
    PrimaryInformation = 1,
    SpecificDetails = 2,
    PitchedPlayer = 3,
}

export type CreatePitchRequirementModel = {
    id: number;
    agencyId: number;
    squadId: number;
    playerAdSourceId: number | null;
    contactName: string;
    transferPeriod: string;
    transferFeeAmount: number | null;
    grossSalaryAmount: number | null;
    loanFeeAmount: number | null;
    generalPositionId: number;
    fromAge: number | null;
    toAge: number | null;
    minHeight: number | null;
    currencyId: number;
    moreInformation: string;
    isLeftFooted: boolean;
    playerId?: number;
    playerAdPitchedNoteStatusId?: number;
    type: PriceTypeEnum;
};

export interface PitchRequirementInfoModel {
    agencyId: number;
    playerAdSource: IdName;
    contactName: string;
    transferPeriod: string;
    transferFeeAmount: number | null;
    grossSalaryAmount: number | null;
    generalPosition: IdName;
    isLeftFooted: boolean;
    currencyId: number;
    age: Range<number>;
    minHeight: number | null;
    moreInformation: string;
    squad: IdName;
    player: PlayerDetailsModel;
    playerAdPitchedNoteStatus: IdName;
    monthlyLoanBudgetAmount: number;
}
export interface PlayerDetailsModel {
    id: number;
    clubName: string;
    leagueName: string;
}
export interface SquadShortInfo {
    id: number;
    name: string;
    shortName: string;
    league: string;
    areaName: string;
}
