import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import TestimonySlider from 'components/testimony-slider/testimony-slider.component';
import TrWithUs from 'components/tr-with-us/tr-with-us.component';
import AnonymousLayout from 'components/anonymous-layout/anonymous-layout.component';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
// import SmallRegularBanner from 'app/events/components/authorized-event-page/common/small-banner/regular-banner';
// import DealDayBannerBg from 'media/images/events/summit-2019.png'
import config from 'config'
import './landing.scss';
import { getMeta } from 'store/auth/auth.actions';
import { AMOUNT_OF_CLUBS as amountOfClubs } from 'constants/variable-amounts';

const HeroContainer = () => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="title" content="TransferRoom" />
            <meta
                name="description"
                content={`FOOTBALL’S TRANSFER MARKETPLACE. Trusted by decision makers from ${amountOfClubs} clubs.`}
            />
            <meta
                name="keywords"
                content="Football,Transfer,Transferroom, Football market, Fotball deals, Football players, Transfer market, Football marketplace "
            />
        </Helmet>
        <div className="hero-content">
            <div>
                <h1>
                    Football’s <br></br>Transfer Marketplace
                </h1>
                <p>Trusted by decision makers from {amountOfClubs} clubs</p>
                <div className="hero-actions">
                    <Link
                        className="btn tr-btn tr-btn--primary tr-btn--large get-access"
                        to="/registration"
                    >
                        Get Access Now
                    </Link>
                    <div className={"divider"} />
                    <Link
                        className="btn tr-btn tr-btn--primary tr-btn--large request-a-demo"
                        to="/registration"
                    >
                        Request a demo
                    </Link>
                </div>
            </div>
        </div>
        <div className="row">
            <TestimonySlider />
        </div>
    </>
);

const Container = () => (
    <>
        <div className="trActionBox-wrapper">
            <div className="tr-panel trActionBox">
                <h2>Looking to Buy?</h2>
                <hr />
                <p>
                    Search among <b>2047 available players</b>.
                </p>
                <p>
                    Share your requirements for players and{' '}
                    <b>receive player pitches</b>.
                </p>
                <a
                    className="btn tr-btn tr-btn--primary tr-btn--large"
                    href="/registration"
                >
                    Search among <b>2047 players</b>
                </a>
            </div>
            <div className="tr-panel trActionBox">
                <h2>Looking to Sell?</h2>
                <hr />
                <p>
                    Promote your players to <b>{amountOfClubs}+ clubs</b>.
                </p>
                <p>
                    915 player profiles are wanted by other clubs.{' '}
                    <b>Pitch your players</b> directly and propose a match.
                </p>
                <a
                    className="btn tr-btn tr-btn--primary tr-btn--large"
                    href="/registration"
                >
                    Search among <b>{amountOfClubs} clubs</b>
                </a>
            </div>
        </div>

        {/* New banner */}
        {/* <div className="landing-event-banner">
            <SmallRegularBanner
                isRegistered={false}
                eventName="Virtual Summit"
                location="Online"
                dayDate="8th"
                monthDate="December 2020"
                ctaText={'Register Now!'}
                ctaTextCompleted={'Registered'}
                isRegistrationClosed={false}
                backgroundImageSrc={DealDayBannerBg}
                onCTAClick={() => {
                    window.open('https://transferroom.us16.list-manage.com/subscribe?u=df5f11140500d51e02d674e79&id=bf4b81a23b', '_blank')
                }}
                whereFrom={"landing"}
                // additionalLocation="Online"
                // showMapLink="https://www.google.com/maps/place/Stamford+Bridge/@51.4816663,-0.1931452,17z/data=!3m1!4b1!4m5!3m4!1s0x48760f864b976f3d:0x48aa38781ea565f8!8m2!3d51.481663!4d-0.1909565"
            />
        </div> */}

        {/* Old banner */}
        {/* <div className="landing-event-banner">
            <section className="events-banner-landing_general_general">
                <div className="events-banner-landing_general__inner">
                    <div className="events-banner-landing_general__title">TransferRoom <span>Deal Day 2020</span></div>
                    <div className="events-banner-landing_general__subtitle">The only conference in football exclusively for decision makers. By Invitation Only.</div>
                    <div className="events-banner-landing_general__info">
                        <div className="events-banner-landing_general__info-item">
                            <span className="events-banner-landing_general__info-item--icon">
                                <i className="far fa-calendar-alt"></i>
                            </span>
                            <div className="events-banner-landing_general__info-item--text">
                                <div style={{ marginTop: 7 }}> 8th, July</div>
                            </div>
                        </div>
                        <div className="events-banner-landing_general__info-item" >
                            <span className="events-banner-landing_general__info-item--icon">
                                <i className="fa fa-map-marker-alt"></i>
                            </span>
                            <div style={{ lineHeight: '1em', flex: '0 0 auto', textAlign: 'left' }}>
                                <div className={'events-banner__localtion'} >London,</div>
                                <span>
                                    <span style={{ fontSize: 16, opacity: 0.7, color: '#ffffff', marginRight: 5 }}>Stamford Bridge</span>
                                    <a style={{ fontSize: 16, textDecoration: 'underline', color: '#ffba0f', }}
                                        href="https://www.google.com.ua/maps/place/Stamford+Bridge/@51.481663,-0.1931452,17z/data=!3m1!4b1!4m5!3m4!1s0x48760f864b976f3d:0x48aa38781ea565f8!8m2!3d51.481663!4d-0.1909565"
                                        rel="noopener noreferrer" 
                                        target="_blank"
                                    >
                                        Show map
                                    </a>
                                </span>
                            </div>
                        </div>

                    </div>
                    <div className="events-banner-landing_general__cta">
                        
                        <a className="btn tr-btn tr-btn--primary tr-btn--large registerButton event-countdown__registerButton" 
                            href="https://transferroom.us16.list-manage.com/subscribe?u=df5f11140500d51e02d674e79&id=bf4b81a23b" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            Register Now
                        </a>
                       
                        <div className="btn tr-btn tr-btn--grey tr-btn--large disabled events-banner__register-button completed">
                            Registration completed! 
                        </div>


                    </div>
                </div>
            </section>
        </div> */}
        {/* Old Video Section */}
        {
            /*<div className="trVideoSection">
            
            <div className="trVideoSection__video">
                <iframe
                    src="https://player.vimeo.com/video/331170506?title=0&byline=0&portrait=0"
                    title="Summit in Barcelona"
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                />
            </div>
            {
                <div className="trVideoSection__actions">
                    <a
                        className="btn tr-btn tr-btn--secondarytext tr-btn--large"
                        href="/tv"
                        onClick={() =>
                            trackRouteLink('Main - Watch more videos...')
                        }
                    >
                        Watch more videos...
                    </a>
                </div>
            }
            </div>*/
        }

        <TrWithUs />
    </>
);

class Decorator extends React.Component {

    componentDidMount() {
        if (!this.props.isLoggedIn && config.landingUrl !== null) {
            window.location = config.landingUrl;
        }
        this.props.refreshAuth();
    }

    render() {
        if (this.props.isLoggedIn)
            return <Redirect to={'/login'} />;

        if (config.landingUrl !== null) {
            return null;
        }

        return <AnonymousLayout
            heroContainer={<HeroContainer />}
            container={<Container />}
        />
    }
}

export default connect(
    state => ({
        isLoggedIn: !!state.auth.userId,
    }),
    (dispatch, ownProps) => {
        return {
            refreshAuth: () => {
                dispatch(getMeta()) 
            }
        }
    }
)(Decorator);
