import { USER_ACTIVITY_INSERT } from 'store/actionTypes';

const INITIAL_STATE = [];

export default (userActivities = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_ACTIVITY_INSERT.SUCCESS:
            return {};
        case USER_ACTIVITY_INSERT.ERROR:
            return {};
        default:
            return userActivities;
    }
};
