import { combineEpics } from 'redux-observable';
// import playerSearchEpic from 'store/playerSearch/epics/refreshUsersOnFilterChangeEpic';
import shortListEpic from './app/shortlist/epics/refresh-shortlist.epic';
import refreshUsersOnProfileChangeEpic from './app/edit-profile/epics/refreshUsersOnProfileChangeEpic';
// import messageOnUpdatePasswordEpic from './app/edit-profile/epics/messageOnUpdatePasswordEpic';
import logUpdatePasswordEpic from './app/edit-profile/epics/logUpdatePasswordEpic';
import {
    expiredAdWasUpdatedEpic,
    expiredAdWasRemovedEpic,
} from './app/expired-ads/epics/expired-ads.epics';
import refreshAuthEpic, {
    refreshAuthEpicUser,
    loginFromCookieEpic,
} from 'store/auth/epics/refresh-auth.epic';
import fetchAuthEpic from 'store/auth/epics/fetch-auth.epic';
import storeAuthEpic from 'store/auth/epics/store-auth.epic';
import playerProposalSentEpic from './admin/player-proposals/epics/messageSent.epic';
import { 
    refreshClubAdsOnPitchPlayer, 
    refreshClubAdsOnSetFilter 
} from './pages/Pitch/store/epics/refreshClubAdsOnPitchPlayerOrSetFilter';
import clubAdNewsletterSentEpic from './admin/newsletter/epics/newsletterSent.epic';
import friendlyProposalsSentEpic from './admin/friendly-proposals/epics/friendly-proposals.sent.epic';
import testInterestBatchEpic from 'pages/Pitch/tab-plus-pitch/store/epics';
import { emitErrorNotificationEpic } from 'app/notifications/notifications.epic'

const rootEpic = combineEpics(
    // playerSearchEpic,
    shortListEpic,
    refreshAuthEpic,
    refreshAuthEpicUser,
    loginFromCookieEpic,
    storeAuthEpic,
    refreshUsersOnProfileChangeEpic,
    // messageOnUpdatePasswordEpic,
    logUpdatePasswordEpic,
    expiredAdWasUpdatedEpic,
    expiredAdWasRemovedEpic,
    fetchAuthEpic,
    playerProposalSentEpic,
    refreshClubAdsOnPitchPlayer,
    refreshClubAdsOnSetFilter,
    clubAdNewsletterSentEpic,
    friendlyProposalsSentEpic,
    testInterestBatchEpic,
    emitErrorNotificationEpic
);

export default rootEpic;
