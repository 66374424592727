import {
    MATCHED_ADS_SUGGESTION_FETCH
} from 'store/actionTypes';

const initialState = {
    friendlyAdsMatchList: null,
    suggestedAdsList: null
};

export default (matched = initialState, action) => {
    switch (action.type) {
        case MATCHED_ADS_SUGGESTION_FETCH.SUCCESS: {
            return { ...matched, ...action.payload.data }
        }
        default:
            return matched;
    }
};