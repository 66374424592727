export const translationsIt = {
    menu: {
        squad: 'Rosa',
        messaging: 'Messaggi',
        search: 'Cercare',
        shortList: 'Preferiti',
        playerAds: 'Annunci Giocatori',
        clubAds: 'Richieste Giocatori',
        pitch: 'Pitch',
        friendlies: 'Amichevoli',
        marketplace: 'Marketplace',
        events: 'Eventi',
        plusPitch: 'Plus Pitch',
        editProfile: 'Impostazioni',
        financialInsights: 'Informazioni finanziarie',
        loanInsights: 'Loan Tracker',
        logOut: 'Esci',
        home: 'Home'
    },
    errorMessages: {
        pleaseEnterValue: 'Per favore inserisci il valore!',
        minimumRequiredValue: 'Minimum required value is ',
    },
    availabilityEditor: {
        forSale: 'In Vendita',
        forLoan: 'In Prestito',
        yes: 'Si',
        no: 'No',
        priceUponRequest: 'Prezzo su richiesta',
        askingPrice: 'Prezzo',
        sellOn: 'Rivendita',
        monthlyLoanFee: 'Spesa prestito mensile',
        saveChanges: 'Salva Modifiche',
        insertAmount: 'Inserisci Valore!',
        mustBeRange: 'Deve essere tra 0 e 100!',
    },
    clubAds: {
        dropDown: {
            player: 'Giocatore',
            selectPlayer: 'seleziona giocatore',
            playerDescription: 'Descrizione Giocatore',
            min: 'Min.',
            cm: 'cm',
            age: 'Eta’',
            monthlyLoanFee: 'Monthly loan fee incl. gross salary',
            transferFee: 'Spesa trasferimento',
            pitchPlayerNow: 'Offri Giocatore Ora',
        },
        filterComponent: {
            showResults: 'Mostra risultati',
            toBuy: 'Acquisto',
            toLoan: 'Prestito',
        },
        filterSection: {
            transferType: 'Tipo Trasferimento',
            transferFee: 'Transfer fee',
            monthlyLoanFee: 'Loan budget or gross salary',
            position: 'Posizione',
            goalkeeper: 'Portiere',
            leftBack: 'Terzino Sinistro',
            rightBack: 'Terzino Destro',
            centreBack: 'Difensore Centrale',
            defensiveMidfielder: 'Centrocampista Difensivo',
            centralMidfielder: 'Centrocampista Centrale',
            attackingMidfielder: 'Trequartista',
            winger: 'Ala',
            forward: 'Attaccante',
            league: 'Campionato',
            status: 'Stato',
            interest: 'Interesse',
            saved: 'Salvata',
            dismissed: 'Archiviata',
            others: 'Altri',
        },
        gridHeader: {
            searchPosition: 'Cerca giocatore, posizione o club',
            searchPlayer: 'Cerca giocatore o club',
            newFeatureTrial:
                'Nuova caratteristica! Sei nella versione di prova.',
            newFeature: 'Nuova caratteristica!',
            youAreOnTrial: 'Sei nella versione di prova.',
        },
        pitchInsights: {
            pitchInsightsTab: 'PITCH INSIGHTS',
            playersPitched: 'Players pitched',
            pitchesSent: 'Pitches sent',
            clubsReached: 'Clubs reached',
            last6Months: 'Last 6 months',
            lastNMonths: 'Last N months',
            lastMonth: 'Last month',
            pitchStatus: {
                awaitingReply: 'Awaiting Reply',
                declaredInterest: 'Declared interest',
                interest: 'Interest',
                saved: 'Saved',
                opened: 'Opened',
                dismissed: 'Dismissed',
                removed: 'Removed',
            },
            upgradePanel: {
                defaultText: 'UPGRADE TO GET ACCESS TO PITCH INSIGHTS WITH EXCLUSIVE PITCH TOOLS AND DATA INSIGHTS',
                recentActivityText: 'SHOWING ANONYMISED DATA - UPGRADE TO GET FULL VERSION WITH CLUB NAMES AND MORE',
                moreButton: 'More ...'
            },
            sampleData: {
                sampleDataOnly: 'SAMPLE DATA ONLY',
                upgradeToActivate: 'UPGRADE TO ACTIVATE',
            },
            successRate: {
                panelTitle: 'Success Rate',
                averageSuccessRate: 'AVERAGE SUCCESS RATE'
            },
            responsesChart: {
                panelTitle: 'Responses on most pitched players',
                chartLegend: {
                    declaredInterest: 'DECLARED INTEREST/SAVED PITCH',
                    dismissed: 'DISMISSED',
                    awaitingReply: 'AWAITING REPLY'
                },
                clickForDetail: 'Click for details'
            },
            recentActivity: {
                panelTitle: 'Recent Activity',
                player: 'Player:',
                showOnlyItems: 'Show only items where I can test interest',
                backToGraph: 'Back to graph',
                table: {
                    time: 'Time',
                    activity: 'Activity',
                    club: 'A club',
                    description: 'Description',
                    testInterestButton: 'Test interest',
                    pitched: 'Pitched',
                    hidden: '[HIDDEN]',
                    upgradeToTestInterest: 'Upgrade to test interest',
                    testInterestCanBeTested: 'Interest can be tested',
                    testInterestWasTested: 'Tnterest was tested',
                    more: 'MORE',
                    showFirst5: 'SHOW FIRST 5' 
                },
                description: {
                    club: 'Club',
                    savedPitchOf: 'saved pitch of',
                    declaredInterestIn: 'declared interest in',
                    dismissedPitchOf: 'dismissed pitch of',
                    openedPitchOf: 'opened pitch of',
                    deliveredTo: 'delivered to a',
                    pitchOf: 'Pitch of',
                    to: 'to a',
                    pitchWasRemoved: 'removed pitch of',
                    undefinedStatus: 'Undefined status'
                }
            },
            pitchLog: {
                panelTitle: 'Pitch log',
                searchPlaceholder: 'Filter by player or club name...',
                time: 'Time',
                hidden: '[HIDDEN]',
                player: 'Player',
                pitchedTo: 'Pitched To',
                transferType: 'Transfer Type',
                price: 'Price',
                more: 'MORE',
                showFirst5: 'SHOW FIRST 5',
                maxGrossSalary: 'MAX GROSS SALARY',
            },
        },
        tabNavigation: {
            clubAds: 'Richieste Giocatori',
            myPitches: 'Le mie Offerte',
            pitchInsights: 'Pitch Insights'
        },
        myPitchesGrid: {
            hidden: 'NASCOSTO',
            date: 'Data',
            playerName: 'Nome Giocatore',
            club: 'Squadra',
            league: 'Campionato',
            position: 'Posizione',
            transferType: 'Tipo Trasferimento',
            price: 'Prezzo',
            status: 'Stato',
            noResult: 'Nessun risultato. Non hai offerto nessun giocatore.',
        },
        grid: {
            hidden: 'NASCOSTO',
            posted: 'Pubblicato',
            position: 'Posizione',
            club: 'Squadra',
            league: 'Campionato',
            country: 'Paese',
            transferType: 'Tipo Trasferimento',
            budgetUpTo: ' LOAN BUDGET OR GROSS SALARY',
            yourPitches: 'Le Tue Offerte',
        },
    },
    competitionList: {
        editLeaguesList: 'Modifica lista campionati',
        createLeaguesList: 'Crea lista campionati',
        clear: 'Rimuovi',
        selected: 'Selezionati',
        selectLeagues: 'Scegli campionati dalla lista',
        searchCountry: 'Scegli nazione o competizione’',
        deleteList: 'Cancella lista',
        cancel: 'Cancella',
        saveChanges: 'Salva modifiche',
        myCompetitions: 'Le mie Competizioni',
        enterListName: 'Inserisci nome lista.',
        enterUniqueName: 'Inserisci nome lista unica. ',
        alreadyExists: 'esiste gia’',
    },
    declareInterestButton: {
        declaredInterest: 'Interesse Dichiarato',
        declareInterest: 'Dichiara Interesse',
    },
    editProfile: {
        menu: {
            profile: 'Profilo',
            users: 'Utenti',
            access: 'Accesso',
            fullAccess: 'Completo',
            friendlyAccess: 'Amichevole',
            documents: 'Documenti',
        },
        profile: {
            name: 'Nome',
            lastName: 'Cognome',
            email: 'Email',
            club: 'Squadra',
            agency: 'Agency',
            jobTitle: 'Titolo di Lavoro',
            phone: 'Telefono',
            changePassword: 'Cambia Password',
            reset: 'Reset',
            saveChanges: 'Salva Modifiche',
            insertUserName: 'Inserisci nome / cognome!',
            interfaceLanguage: 'Lingua interfaccia',
            restrictedAccess: 'Accesso limitato',
            friendlContentOnly: 'Solo contenuti amichevoli',
            insertUserFirstName: 'Inserisci nome !',
            insertUserLastName: 'Inserisci cognome !',
        },
        users: {
            user: 'Utente',
            jobTitle: 'Titolo di Lavoro',
            access: 'Access',
            fullAccess: 'Full',
            friendlyAccess: 'Friendly',
            status: 'Stato',
            addUser: 'Aggiungi utente’',
        },
        documents: {
            termsOfService: 'Termini di servizio',
            privacyPolicy: 'Politica sulla privacy',
            dataProcessingTerms: 'Termini di elaborazione dei data',
            antiSlaveryPolicy:
                'Politica contro la schiavitù e la tratta di esseri umani',
        },
        createUser: {
            insertUserName: 'Inserisci username (nome e cognome)!',
            insertUserEmail: 'Inserisci email utente!',
            insertUserPhone: 'Inserisci telefono utente!',
            selectUserJobTitle: 'Seleziona titolo di lavoro!',
            editUser: 'Modifica utente',
            createUser: 'Crea utente',
            updateUser: 'Aggiorna utente',
            cancel: 'Cancella',
            phone: 'Telefono',
            email: 'Email',
            jobTitle: 'Titolo di Lavoro',
            name: 'Nome',
            lastName: 'Cognome',
            surname: 'Surname',
            restrictedAccess: 'Accesso limitato',
            friendlContentOnly: 'Solo contenuti amichevoli',
        },
        emailPreferences: {
            unreadPlayerPitches: 'Notifica per Offerte Giocatori non lette',
            declareInterest: 'Notifica per Dichiarare Interesse',
            saveChanges: 'Salva Modifiche',
            newSuperPitch: 'Notifica per Nuova Super Pitch',
            adShouldBeUpdated: 'Notifica per Richiesta Giocatore da aggiornare',
            unreadMessages: 'Notifica per Messaggi non letti',
            newMessage: 'New message',
            declareInterestForFriendlyMatch:
                'Dichiara Interesse per Amichevole',
            invitationToFriendlyMatch: 'Invito a partita amichevole',
            unreadInvitations: 'Inviti da leggere',
            today: 'Oggi',
            yesterday: 'Ieri',

            friendlyRelated: 'Friendly related',
            inboundPlayers: 'Inbound players',
            outboundPlayers: 'Outbound players',
            messages: 'Messages',
            generalEmails: 'General emails',

            friendlyProposals: 'Friendly proposals',
            updateOldFriendlyAds: 'Update old friendly ads',
            playerProposals: 'Player proposals',
            shortlistPlayerStatusChange: 'Shortlist player status change',
            newPlayerPitch: 'New player pitch',
            superPitch: 'New super pitch',
            clubAdProposal: 'Club Ad proposal',
            featureUpdates: 'Feature Updates',
            eventInformation: 'Event Information',
            blogPromotion: 'Blog Promotion',
            recommendedEmailsOnly: 'Recommended Emails Only',
        },
        password: {
            insertPassword: 'Inserisci password!',
            insertNewPassword: 'Inserisci nuova password!',
            confirmNewPassword: 'Conferma nuova password',
            passwordsNotEqual: 'Le password non sono le stesse!',
            changePassword: 'Cambia password',
            currentPassword: 'Password attuale',
            newPassword: 'Nuova password',
            cancel: 'Cancella',
            setNewPassword: 'Imposta Nuova Password',
            passwordIsInvalid: 'La password non è valida',
        },
        onUpdatePassword: {
            success: 'Password cambiata correttamente',
            failed:
                'Impossibile cambiare password. Inserisci la vecchia password corretta.',
        },
    },
    eventPopupBanner: {
        register: 'Registrati',
    },
    events: {
        registrationCompleted: 'Registrazione completata!',
        registerNow: 'Registrati Ora',
        preRegisterNow: 'Pre-registrati ora!',
        confirmedAttendees: 'Partecipanti Confermati',
        pastEvents: 'EVENTI PASSATI',
        summit: 'Vertice',
        downloadEca: 'Scaricare ECA presentazione',
        summitName: 'Deal Day',
        summitDate: '16 Luglio',
        summitPlace: 'Stamford Bridge, London',
        summitYear: 'Vertice 2019',
        summitText:
            "L'unica conferenza nel calcio esclusivamente per i decision-makers. Solo su invito.",
        summitStartDate: '20 marzo',
        whyAttend: 'Perche’ Partecipare?',
        whyAttendText:
            "TransferRoom Deal Day è un'occasione unica per costruire relazioni con i decision-maker dei club di tutto il mondo e per discutere di trasferimenti specifici senza il coinvolgimento di intermediari.",
        duringDay: 'Durante una giornata a ',
        youWillGet: 'otterrai:',
        oneToOne: 'Almeno 15 meetings con altre squadre',
        accessToClubs: 'Accesso a squadre e giocatori in nuovi mercati',
        marketInformation:
            'Informazioni di calciomercato su quali giocatori sono disponibili in vendita e in prestito',
        speaker: 'Oratore',
        speakerPosition: 'Proprietario, FC Nordsjaelland',
        speakerDescription: '',
        speakerText: '',
        agenda: 'Programma',
        registration: 'Registrazione',
        presentation: 'Presentazioni',
        clubToClub: 'Meetings',
        lunchNetworking: 'Pranzo',
        drinksNetworking: 'Networking',
        closing: 'Chiusura',
        photos: 'Foto',
        whenAndWhere: {
            whenAndWhere: 'Quando e dove',
            date: '16 Luglio',
            place: 'Stamford Bridge, Londra',
        },
        participants: 'Partecipanti',
        countries: 'Paesi',
        leagues: 'Campionati',
        day: 'Giorno',
        days: 'Giorni',
        hour: 'Ora',
        hours: 'Ore',
        minute: 'Minuto',
        minutes: 'Minuti',
        second: 'Secondo',
        seconds: 'Secondi',
        soldOut: 'Esaurito'
    },
    expiredAds: {
        tokenIsExpired: 'Token scaduto.',
        please: 'Per favore',
        login: 'login',
        toViewExpiredAds: 'per vedere annunci scaduti. ',
        gettingOld: 'Una o piu’ delle tue richieste stanno diventando vecchie:',
        considerUpdating: 'Aggiorna o rimuovi vecchie richieste',
        upToDate: 'Tutte le tue richieste sono aggiornate.',
        epic: {
            successfully: '1 o piu’ delle tue richieste e’ stata aggiornata!',
            removedSuccesfully:
                '1 dei tuoi annunci e’ stato rimosso con successo!',
        },
    },
    financialInsights: {
        spendingPower: {
            chart: {
                highest: "HIGHEST",
                lowest: "LOWEST",
                typical: "TYPICAL",
                avarage: "AVERAGE",
            },
            title: "SPENDING POWER",
            tabTransfers: "TRANSFERS",
            tabLoans: "LOANS"
        },
        sampleData:{
            sampleDataOnly: "SAMPLE DATA ONLY",
            upgradeToActivate: "UPGRADE TO ACTIVATE"
        },
        upgradePanel:{
            moreButton: "MORE ..."
        },
        searchBar: {
            country: 'Paese:',
            league: 'Campionato:',
            club: 'Squadra:',
            currency: 'VALUTA:'
        },
        totalClubPlayerValue: 'VALORE TOTALE ROSA',
        clubValueRank: 'RANKING PER VALORE',
        of: 'SU',
        inLeague: 'IN CAMPIONATO',
        currentSquadSize: 'DIMENSIONI ROSA ATTUALE',
        averagePlayerValuation: 'VALUTAZIONE MEDIA GIOCATORI',
        averagePlayerAge: `ETA' MEDIA GIOCATORI`,
        season: 'Stagione',
        lastThreeSeasons: 'Last three seasons',
        transferOverview: {
            panelTitle: 'TRASFERIMENTI ULTIME 6 FINESTRE',
            transferIn: 'Trasferimento in Entrata',
            transfersIn: 'Transferimenti in Entrata',
            transferOut: 'Trasferimento in Uscita',
            transfersOut: 'Transferimenti in Uscita',
            balance: 'Bilancio',
            summer: 'ESTATE',
            winter: 'INVERNO'
        },
        biggestTransfers: {
            panelTitle: 'TRANSFERIMENTI E GIOCATORI PIÙ PREZIOSI',
            panelTitleShort: 'TRASFERIMENTI PIÙ ONEROSI',
            top: 'TOP',
            biggestTransfersIn: 'TRASFERIMENTI IN ENTRATA',
            biggestTransfersOut: 'TRASFERIMENTI IN USCITA',
            theHighestTransferIn: 'RECORD TRASFERIMENTO IN ENTRATA',
            theHighestTransferOut: 'RECORD TRANSFERIMENTO IN USCITA',
            noTransfersOut: 'NESSUN TRASFERIMENTO IN USCITA',
            noTransfersIn: 'NESSUN TRASFERIMENTO IN ETRATA',
            noIncomingTransfers: 'Nessun trasferimento in entrata',
            noOutgoingTransfers: 'Nessun trasferimento in uscita', 
        },
        playerValuations: {
            panelTitle: '21st CLUB - VALORI GIOCATORI',
            table: {
                player: 'Giocatore',
                pos: 'pos', 
                age: 'Età',
                contractExpiry: 'Scadenza contratto',
                value: 'Valore',
                percentOfClubValue: 'Percentuale del valore club'
            },
            more: 'ALTRO',
            showFirst5: 'MOSTRA I PRIMI 5'
        },
        chartDonut: {
            freeTransfers: 'A PARAMETRO ZERO',
            loans: 'PRESTITI',
            youthPlayers: 'GIOCATORI GIOVANI',
            transfers: 'TRASFERIMENTI',
        },
        incomingPlayers: {
            panelTitle: 'GIOCATORI IN ENTRATA',
            tables: {
                top: 'TOP',
                importCountries: 'PAESI PER IMPORTO',
                topImportCountry: 'TOP IL PAESE PER IMPORTO',
                importLeagues: 'CAMPIONATI PER IMPORTO',
                topImportLeague: 'TOP CAMPIONATO PER IMPORTO',
                noTransfers: 'NESSUN TRASFERIMENTO',
                noData: 'Nessun trasferimento monetario in questa stagione',
                country: 'PAESE',
                league: 'CAMPIONATO',
                total: 'TOTALE',
                deals: 'AFFARI',
                average: 'MEDIA'
            }
        },
        outgoingPlayers: {
            panelTitle: 'GIOCATORI IN USCITA',
            tables: {
                top: 'TOP',
                exportCountries: 'PAESI PER ESPORTO',
                topExportCountry: 'PAESI TOP (IN USCITA)', 
                exportLeagues: 'CAMPIONATI PER ESPORTO',
                topExportLeague: 'CAMPIONATI TOP (IN USCITA)',
                noTransfers: 'NESSUN TRASFERIMENTO',
                noData: 'Nessun trasferimento monetario in questa stagione',
                country: 'PAESE',
                league: 'CAMPIONATO',
                total: 'TOTALE',
                deals: 'AFFARI',
                average: 'MEDIA'
            }
        }
    },
    friendlyMatches: {
        filter: {
            squad: 'Squadra',
            date: 'Data',
            venue: 'Luogo',
            venueCountries: 'Luogo Paesi',
            setDate: 'Data...',
            to: 'A',
            from: 'Da',
            gsnPotential: 'GSN Potenziale' ,
            minutesPlayed: 'Minutes Played'
        },
        activity: {
            friendly: 'Amichevoli Attivo',
            friendlies: 'Amichevoli Attive',
            open: 'Apri',
            ad: 'Richiesta',
            ads: 'Richieste',
            my: 'Mie',
            reFriendlyInvitation: 'RE: Friendly invitation',
        },
        alert: {
            considerUpdating: 'Aggiorna o rimuovi vecchie richieste',
            gettingOld:
                'Una o piu’ delle tue richieste stanno diventando vecchie:',
        },
        attention: {
            areStillRelevant: 'I tuoi annunci sono ancora rilevanti?',
            getSureUpToDate:
                'Per favore, controlla che i tuoi annunci siano aggiornati.',
            reviewMyAds: 'Aggiorna i miei annunci',
            myAdsAreOk: 'I miei annunci sono OK',
        },
        main: {
            na: 'N/A',
            youHaveReplied: 'hai risposto:',
            showMore: 'Mostra di più...',
            declareInterest: '’dichiara interesse’',
            cancel: 'Cancelar',
            declareInterestWithoutQuotas: 'interesse dichiarato',
            awaitingReply: 'Aspettando una risposta...',
            didNotReply: 'Non ha risposto',
            notAvailable: 'NON DISPONIBILE',
            notAvailableWithQuotas: '’NON DISPONIBILE’',
            expiredAds: 'L’invito e’ scaduto',
            removed: 'Rimosso',
            updated: 'AGGIORNATO',
            expired: 'SCADUTO',
            venue: 'Luogo',
            homeAway: 'In Casa/In Trasferta',
            home: 'In Casa',
            countryOfVenue: 'Paese',
            interest: 'Interesse',
            interests: 'Interessi',
            declared: 'Dichiarato',
            message: 'messaggio',
            messageWithQuotas: '’nuovo messaggio’',
            messages: 'messaggi',
            sent: 'Ha inviato',
            sentBeforePlural: 'Ha inviato',
            available: 'disponibile',
            not: 'Non',
            newMessage: 'Nuovo Messaggio',
            sentMessage: 'INVIATO UN MESSAGGIO',
            updateAd: 'Aggiorna annuncio',
            remove: 'Rimuovi',
            away: 'In Trasferta',
            keepUpToDate: 'Tieni le tue richieste aggiornate.',
            yesStillLooking: 'Si, sto ancora cercando',
            stillLooking: 'Stai ancora cercando un avversario per',
            invitedYou: 'ti ha invitato ad un amichevole.',
            inviteAdditionalClubs: 'INVITA ALTRI CLUB',
        },
        editor: {
            firstTeam: 'Prima Squadra',
            home: 'In Casa',
            away: 'In Trasferta',
            createFriendlyAd: 'Crea annuncio amichevole',
            venue: 'Luogo',
            countryOfVenue: 'Paese',
            cancel: 'Cancella',
            launchAd: 'Pubblica Annuncio',
            chooseDates: 'Scegli data/e',
            date: 'Data',
        },
        invitations: {
            alreadyInvited: 'Già invitato',
            inviteAdditionalClubsDialogTitle:
                'INVITA ALTRI CLUB ALLA TUA AMICHEVOLE',
            inviteAdditionalClubsButtonTitle: 'INVITA ALTRI CLUB ADESSO',
            friendlies: 'Amichevoli',
            open: 'Apri',
            ads: 'Annunci',
            friendly: 'Amichevole',
            ad: 'Annuncio',
            my: 'Miei',
            inviteClubs: 'Invita i Club Alle Tue Amichevoli',
            inviteClubsButtonTitle: 'Invita i Club Ora',
            recipients: 'Destinatari',
            removeAll: 'Rimuovi Tutti',
            country: 'PAESE:',
            league: 'CAMPIONATO:',
            skip: 'Salta',
            createNewAd: 'Crea nuovo annuncio',
        },
        match: {
            venue: 'Luogo',
            homeAway: 'In casa/In trasferta',
            home: 'In casa',
            countryOfVenue: 'Paese',
            away: 'In trasferta',
        },
        declareInterest: {
            declaredInterest: 'Interesse Dichiarato',
            declareInterest: 'Dichiara Interesse',
        },
        myFriendlyMatches: {
            activeAds: 'Annunci Attivi',
            expiredAds: 'Annunci Scaduti',
            friendlyInvitationSubject: 'Friendly invitation',
        },
        newInvitations: {
            youHaveReceived: 'Hai ricevuto',
            newInvitation: 'nuovo invito',
            newInvitations: 'nuovi inviti',
        },
        menu: {
            activity: 'Attivita’',
            invitations: 'Inviti',
            myAds: 'I miei annunci',
            createNewAd: 'Crea nuovo annuncio',
        },
        friendlySuggestion: {
            friendlySuggestion: 'Suggerimenti amichevoli',
        },
    },
    fullStat: {
        fullStats: 'Statistiche Complete',
        average: 'Media',
        date: 'Data',
        match: 'Partita',
        competition: 'Competizione',
        minPlayed: 'Min. giocati',
        goals: 'Gol',
        assists: 'Assist',
        noRecords: 'Nessun record trovato',
    },
    landingPage: {
        recommendations: {
            highPriorityRecommendation: 'High priority recommendation for you',
            highPriorityRecommendations: 'High priority recommendations for you',
            fixNow: 'Fix now',
            fixed: 'Fixed!',
            done: 'Done!',
            makePlayersAvailable: {
                title: 'Make Players Available',
                description: 'List your players for sale or loan to appeal to as many buyers as possible.'
            },
            pitchYourPlayers: {
                title: 'Pitch Your Players',
                description: 'Pitching your players to relevant clubs highly increases your chances of receiving direct offers.'
            },
            createPlayerAds: {
                title: 'Create Player Ads',
                description: 'Specify your needs to get pitches of relevant players. Receive offers sent directly from other clubs.'
            },
            launchFriendlyAds: {
                title: 'Launch Friendly Ads',
                description: 'Share when and where you are looking to play and get interest from other clubs.'
            },
            addMoreUsers: {
                title: 'Add more users + a friendly manager',
                description: 'Add relevant colleagues to TransferRoom to maximize your club’s activity and results with TransferRoom.'
            },
        },
        doingWell: {
            youAreDoingWell: 'You are doing well!',
            makePlayerAvailable: 'Make a player available',
            addPlayerToReleaseList: 'Add a player to your release list',
            pitchPlayer: 'Pitch a player',
            makeAdForAPlayer: 'Make an ad for a player',
            arrangeFriendlyMatch: 'Arrange a friendly match',
            findAndComparePlayers: 'Find and compare players'
        },
        performanceIndicators: {
            heading: 'Performance indicators',
            playersMadeAvailable: 'Player%{value} made available',
            playersPitchesLast: 'Player pitch%{value} last 30 days',
            playersAdsLast: 'Player ad%{value} last 30 days',
            friendlyAdsLast: 'Friendly ad%{value} last 30 days'
        },
        createPlayerAdModal: {
            //Step 1
            specifyYourTransferTargets: 'Specify your requirement to receive offers',
            whichPositionAreYouLooking: 'Which positions are you looking for?',
            //Step 3
            playerAdsCreated: 'player ads created!',
            playerAdCreated: 'player ad created!',
            weFound: 'We found',
            recommendationsForYou: 'recommendations for you',
            recommendationForYou: 'recommendation for you',
            player: 'Player',
            marketValue: 'Market value',
            //Common
            cancel: 'Cancella',
            continue: 'Continue'
        }
    },
    matchMaker: {
        youHaveMatch: 'Hai un abbinamento!',
        pitchToMultiple: 'Offri ai club',
        name: 'Nome',
        budget: 'Budget',
        pitch: 'Offri',
        skip: 'Salta',
        pitchNow: 'Offri Ora',
        pos: 'Pos.',
    },
    messages: {
        deleteConversation: 'Elimina conversazione',
        newMessage: 'Nuovo Messaggio',
        search: 'Ricerca da nome squadra’',
        enterMessage: 'Scrivi messaggio’',
        noChannelsFound: 'Nessun messaggi trovato!',
        today: 'Oggi',
        yesterday: 'Ieri',
        noClubsMatch: 'Nessun club corrisponde alla tua ricerca. Riprova...',
    },
    notifications: {
        hasBeenPitched: 'e’ stato presentato. Buona fortuna!',
        yourMessageTo: 'Il tuo messaggio a',
        hasBeenSent: 'e’ stato inviato',
        somethingWentWrong: 'Qualcosa e’ andato storto. Riprova',
    },
    performance: {
        competition: 'Competizione',
        apps: 'Presenze',
        startingApps: 'Da Titolare',
        goals: 'Gol',
        assists: 'Assist',
        minPerGoal: 'Min. per Gol',
        minPerAssist: 'Min. per Assist',
        totalMinPlayed: 'Totale min. giocati',
        avgMinPlayed: 'Media min. Giocati',
        performanceData: 'Statistiche',
        total: 'Totale',
    },
    playerAds: {
        alert: {
            gettingOld:
                'Una o piu’ delle tue richieste stanno diventando vecchie:',
            considerUpdating: 'Aggiorna o rimuovi vecchie richieste',
            yesStillLooking: 'Si, sto ancora cercando',
            remove: 'Rimuovi',
            areYouStillLooking: 'Stai ancora cercando un',
            keepUpToDate: 'Tieni le tue richieste aggiornate.',
            budget: 'Budget:',
            upTo: 'Fino a',
            to: 'A',
        },
        getStarted: {
            findMember: 'Trova il tuo nuovo giocatore qui',
            startWithAd:
                'Piu’ del 50% dei trasferimenti avvengono con un ANNUNCIO',
            getStarted: 'Inizia ora',
            createAd: 'Crea il tuo annuncio',
        },
        myAds: {
            activeAds: 'Annunci Attivi',
            removedAds: 'Annunci Rimossi',
        },
        noPitches: {
            youHave0: 'Hai 0 nuove offerte',
            butYouHave: 'Ma hai',
            yourAdsAreLive:
                'I tuoi annunci sono stati pubblicati - e quando arrivano nuove offerte, appariranno qui!',
            savedPitch: 'offerta salvata',
            savedPitches: 'offerte salvate',
        },
        pitch: {
            savePitch: 'Salva offerta',
            dismiss: 'Rifiuta e vai avanti',
            dismiss2: 'Respingere',
        },
        pitchPlayer: {
            age: 'Eta’',
            yrs: 'anni',
            askingPrice: 'Prezzo',
            loanFee: 'Spesa prestito',
            notAvailable: 'Non disponibile',
        },
        main: {
            newPlusPitchHeader: 'PLUS OFFERT',
            removedOn: 'Rimosso su',
            upTo: 'Fino a',
            pitches: 'Offerte',
            pitch: 'Offerta',
            received: 'Ricevute',
            saved: 'Salvate',
            interests: 'Interessi',
            interest: 'Interesse',
            declared: 'Dichiarato',
            updated: 'AGGIORNATO',
            updateAd: 'Annunci aggiornati',
            remove: 'Rimuovi',
            activity: 'Attivita’',
            savedPitches: 'Offerte salvate',
            myAds: 'I miei annunci',
            createNewAd: 'Crea nuovo annuncio',
            wantMoreData: 'Vuoi ancora piu’ informazioni sui giocatori?',
            whenYouSave:
                'Quando salvi un’offerta, hai accesso a strumenti di analisi per comparare i tuoi giocatori e quelli delle altre squadre',
            monthlyLoanFee: 'Spesa prestito mensile:',
            transferFee: 'Spesa trasferimento:',
            to: 'A',
        },
        actions: {
            club: 'Club',
            clubs: 'Club',
            pitches: 'Offerte',
            pitch: 'Offer',
            newSuperPitch: 'nuove Super ',
            newPlus: 'nuove Plus',
            received: 'ricevuta',
            superPitchReceived: 'SUPER PITCH RICEVUTA',
            buy: 'comprare',
            loan: 'prestito',
            newPitchesForYourAd: 'nuove offerte per il tuo annuncio per',
            newPitchForYourAd: 'nuove offer per il tuo annuncio per',
            to: 'a',
            withBudgetUpTo: 'con budget fino a',
            tip: 'CONSIGLIO:',
            inDoubt:
                'Se hai dubbi, puoi salvare l’offerta e analizzarla in seguito',
        },
        summary: {
            pitch: 'Offerta',
            pitches: 'Offerte',
            new: 'nuovo',
            ad: 'Annuncio',
            ads: 'Annunci',
            active: 'attivi',
            saved: 'salvati',
        },
        savedPitch: {
            newMessage: 'Nuovo Messaggio',
            remove: 'Rimuovi',
            compareTo: 'Confronta con’',
            type: 'Tipo’',
            profile: 'Profilo',
            radar: 'Radar',
            table: 'Tabella',
            season: 'Stagione',
        },
        savedPitches: {
            newPitch: 'nuova offerta',
            newPitches: 'nuove offerte',
            superPitch: 'SUPER PITCH',
            buy: 'comprare',
            loan: 'prestito',
            forYourAd: 'per il tuo annuncio per',
            to: 'a',
            withBudgetUpTo: 'con budget fino a',
        },
        upgradeOffer: {
            wantMoreAds: 'Vuoi creare piu’ annunci?',
            itTakes2Mins:
                'Ci vogliono solo 2 minuti e puoi cambiarlo quando vuoi',
            getStarted: 'Inizia ora per',
            only20: 'solo XXX al mese!',
            upgradeNow: 'Esegui l’upgrade ora',
            remindLater: 'Ricordami in seguito',
            upgradeOffer: 'Offerta per upgrade',
        },
        adPitches: {
            delivered: 'INVIATO',
            interest: 'INTERESSE',
            saved: 'SALVATO',
            opened: 'APERTO',
        },
        pitchStatus: {
            delivered: 'Inviato',
            interest: 'Interesse',
            saved: 'Salvato',
            opened: 'Aperto',
            dismissed: 'Archiviato',
            removed: 'Rimosso',
        },
        formatAd: {
            buy: 'comprare',
            loan: 'prestito',
            age: 'Eta’',
            min: 'Min.',
            cm: 'cm',
            updated: 'Aggiornato',
            ago: 'fa',
            lastUpdatedFuture:
                '’data ultimo Aggiornamento’ non dovrebbe essere nel futuro',
            updatedToday: 'Aggiornato oggi',
            day: 'giorno',
            week: 'settimana',
            month: 'mese',
            year: 'anno',
        },
        editor: {
            insertAmount: 'Inserisci valore!',
            minimumValueRequired: 'Minimum required value is %{value}',
            multipleAds: 'Annunci Giocatori (Annunci Multipli)',
            position: 'Posizione:',
            toLoan: 'Prestito',
            toBuy: 'Comprare',
            currency: 'Valuta',
            select: 'Seleziona posizione’',
            transferFee: 'Valore trasferimento',
            upTo: 'fino a:',
            monthlyLoanFee: 'Spesa prestito mensile',
            mo: '/me.',
            createNewAds: 'Crea nuovo annuncio',
            createAdRecentSearch: 'Crea annuncio in base a ultima ricerca',
            planSummerTransfers: 'Pianifica i tuoi trasferimenti estivi',
            increaseChances:
                'Creare annunci aumenta le tue possibilita’ a piu’ del 50%',
            cancel: 'Cancella',
            done: 'Fatto!',
            launchAd: 'Pubblica annuncio',
            createNewAd: 'Crea nuovo annuncio',
            age: 'Eta’',
            yrs: 'anni',
            minimumHeight: 'Altezza minima:',
            cm: 'cm',
            ft: 'ft',
            in: 'in',
        },
    },
    playerRanking: {
        title: 'Classifica dei giocatori',
        trial: 'Nuova caratteristica! Sei in prova.',
        noData: 'Nessun dato statistico per la classifica',
        show10More: 'Mostra altri 10...',
        show10First: 'Mostra i primi 10',
        all: 'TUTTI',
        myShortList: 'La mia breve lista',
        compareTo: 'Paragonare a',
        position: 'Posizione',
        statistics: {
            scoringContribution: 'Punteggio assegnato',
            scoringContributionDesc: '(Obiettivi + Assist)',

            contribution: 'Punteggio assegnato',
            contributionDesc: '(Obiettivi + Assist)',

            goalEfficiency: 'Efficienza obiettivo',
            goalEfficiencyDesc: '(Obiettivi / xG)',

            finishing: 'Finitura',
            finishingDesc: '(xG / Scatti)',

            goals: 'Obiettivi',
            assists: 'Assist',
            xG: 'xG',
            xA: 'xA',
            succesfuldribbles: 'Dribbling riusciti',
            shots: 'Shots',
            shotsOnTarget: 'Tiri in porta',
            passesAccuracy: 'Passa la precisione',
            forwardPassesAccuracy: 'La precisione del passaggio in avanti',
            passesToFinalThirdAccuracy: 'Passa alla terza precisione finale',
            longPassesAccuracy: 'Accuratezza dei passaggi lunghi',
            interceptions: 'Intercettazioni',
            aerialDuelsWon: 'Duelli aerei vinti',
            defensiveDuelsWon: 'Duelli difensivi vinti',
            saves: 'Salva',
            goalkeeping: 'Portieri',
            goalkeepingDesc: '(CG / xCG)',

            succesfulCrosses: 'Riusciti successi',
            crossesAccuracy: 'Precisión de cruces',
            minutesPlayed: 'Attraversa la precisione',
            totalGoals: 'Obiettivi totali',
            totalSuccessfulDribbles: 'Attraversa la precisione',
            totalShots: 'Attraversa la precisione',
            totalPasses: 'Passaggi totali',
            totalForwardPasses: 'Passaggi forward totali',
            totalPassesToFinalThird: 'Il totale passa al terzo finale',
            totalLongPasses: 'Passaggi lunghi totali',
            totalInterceptions: 'Attraversa la precisione',
            totalAerialDuels: 'Duelli aerei totali',
            totaDefensiveDuels: 'Duelli difensivi totali',
            totalSaves: 'Attraversa la precisione',
            totalCrosses: 'Croci totali',
        },
    },
    playerComparison: {
        main: {
            playersComparison: 'Confronto giocatori',
            noChartData: 'Nessuna informazione disponibile’',
            noData: 'Nessuna informazione disponibile’',
        },
        filters: {
            season: 'Stagione',
            searchPlayer: 'Cerca giocatore’',
            type: 'Tipo’',
            profile: 'Profilo',
        },
        table: {
            dataIsComparing:
                '* performance comparate con i giocatori del campionato domestico',
            statsPer90: '** Tutte le statistiche in 90 minuti ',
        },
        constants: {
            minutesPlayed: 'Minuti giocati',
            defensiveStatsP90: 'Statistiche Difensive per 90 min.',
            shotsAgainst: 'Tiri Subiti',
            saves: 'Parate',
            concededGoals: 'Gol concessi',
            xCG: 'xCG',
            offensiveStatsP90: 'Statistiche offensive per 90 min.',
            passesAccuracy: 'Precisione passaggi',
            longPassesAccuracy: 'Precisione lanci lunghi',
            forwardPassesAccuracy: 'Precisione passaggi in avanti',
            height: 'Altezza',
            defensiveDuelsWon: 'Duelli difensivi vinti',
            aerialDuelsWon: 'Duelli aerei vinti',
            interceptions: 'Intercettazioni',
            scoringContribution: 'Contributo gol/assist',
            shots: 'Tiri',
            crossesAccuracy: 'Precisione cross',
            passesToFinalThirdAccuracy:
                'Precisione passaggi ultimo terzo di campo',
            shotsOnTarget: 'Tiri in porta',
            successfulCrosses: 'Cross riusciti',
            successfulDribbles: 'Dribbling riusciti',
            xG: 'xG',
            goals: 'Gol',
            assists: 'Assist',
            xA: 'xA',
            season: 'Stagione',
        },
        formatPlayer: {
            average: 'Media',
        },
    },
    playerInfo: {
        notAvailable: 'non disponibile',
        declareInterest: 'Dichiara Interesse',
        notInterest: 'non interesse',
    },
    positions: {
        goalkeeper: 'Portiere',
        leftBack: 'Terzino Sinistro',
        rightBack: 'Terzino Destro',
        centerBack: 'Difensore Centrale',
        defensiveMidfielder: 'Centrocampista Difensivo',
        centralMidfielder: 'Centrocampista Centrale',
        attackingMidfielder: 'Trequartista',
        winger: 'Ala',
        forward: 'Attaccante',
    },
    positionSelector: {
        primaryPosition: 'Posizione primaria',
        secondaryPosition: 'Posizione secondaria',
        none: 'nessuna',
    },
    removePlayer: {
        areYouSure: 'Sei sicuro di voler rimuovere',
        playerWillBeRemoved: 'Il giocatore verrra’ rimosso dalla tua squadra',
        reason: 'Ragione per rimuovere',
        chooseReason: 'Scegli la ragione’',
        removePlayer: 'Rimuovi Giocatore',
        cancel: 'Cancella',
        reasons: {
            outOnLoan: 'Giocatore in prestito',
            retired: 'Giocatore ritirato',
            released: 'Giocatore svincolato',
            leftTheClub: 'Giocatore ha lasciato il club',
            otherReason: 'Altro',
        },
    },
    accessRestricted: {
        dontHavePermission:
            'Non hai accesso a questo servizio. Upgrade disponibile.',
        weWillBeInTouch: 'Grazie. Ti contatteremo con ulteriori informazioni',
        restrictedAccess: 'Accesso riservato',
        close: 'Chiudi',
        moreInfo: 'Maggiori informazioni',
        newMessagesLimitIsRichedOut:
            'You have reached your 5 new messages limit per 24 hours. Try again, later. For any questions, please contact <a href="mailto:support@transferroom.com">support@transferroom.com</a>',
    },
    messaging: {
        messageButton: 'MESSAGIO’',
        message: 'Messaggio’',
        cancel: 'Cancella',
        send: 'Invia',
        messaging: 'Messaggi',
        sendMessage: 'Invia Messaggio',
    },
    shortlist: {
        shortlisted: 'Aggiunto ai preferiti',
        addToShortlist: 'Aggiungi ai preferiti',
    },
    superPitch: {
        pitchCreated: {
            hasBeenSuperPitched: 'e’ stato pitched a',
            club: 'campionato',
            clubs: 'campionati',
            anotherPlayer: 'Pitch Plus un altro giocatore',
        },
        createFlow: {
            superPitch: 'PITCH PLUS',
            newFeature: 'NUOVO STRUMENTO',
            pitchYourPlayer:
                'Offri il tuo giocatore a squadre specifiche o ad interi campionati',
            leftThis: 'mancanti questo',
            month: 'mese',
            pitchPlayerNow: 'Offri giocatore ora!',
            clear: 'Rimuovi',
            readyToGo: 'Tutto pronto! Ora clicca sul pulsante Offri.',
        },
        playerAdd: {
            selectPlayer: 'SELEZIONA GIOCATORE',
            selectPlayerName: 'Scegli nome giocatore’',
            cancel: 'Cancella',
            choosePlayer: 'Scegli giocatore’',
            player: 'Giocatore',
            startByChoosing:
                'Inizia selezionando un giocatore per la Pitch Plus',
        },
        setPrice: {
            setPriceFor: 'IMPOSTA PREZZO PER',
            yes: 'Si',
            no: 'No',
            askingPrice: 'Prezzo',
            sellOn: 'Rivendita',
            forLoan: 'In Prestito',
            monthlyLoanFee: 'Spesa prestito mensile',
            price: 'Prezzo',
            setPrice: 'Imposta prezzo’',
            forSale: 'In Vendita',
            nowSetPrice: 'Ora scegli il prezzo e il tipo di trasferimento!',
            cancel: 'Cancella',
            pm: 'pm',
        },
        setRecipients: {
            zeroSelected: '0 selezionati',
            selectLeagues: 'Seleziona leghe dalla lista',
            recipientsUpTo: 'Destinatari (fino a',
            selectRecipients: 'SCEGLI DESTINATARI',
            selectClubName: 'Seleziona nome della squadra o del campionato’',
            clubsLimit: 'limite squadre',
            reached:
                'raggiunti. Controlla le squadre selezionate nella lista di sinistra',
            clearSelection: 'Svuota Selezione',
            cancel: 'Cancella',
            saveChanges: 'Salva Modifiche',
            readyToGo: 'Scegli squadra o intero campionato, e sei pronto!',
            chooseRecipients: 'Scegli destinatari’',
            recipients: 'Destinatari',
        },
        utils: {
            club: 'squadra',
            clubs: 'squadre',
            league: 'campionato',
            leagues: 'campionati',
        },
    },
    pitchPlus: {
        getAccess: {
            getAccessNow: {
                getAccessTo: `OTTIENI L'ACCESSO AL`,
                plusPitch: 'PLUS PITCH',
                now: 'ORA!',
            },
            description1:
                '1 in ogni 4 Plus Pitch fa sì che i giocatori trovino una nuova squadra.',
            description2:
                'Percentuale di successo superiore di oltre 5 volte rispetto alle offerte normali.',
            requestInfoButton: 'RICHIESTA DI INFORMAZIONI',
        },
        navigationPanel: {
            leftThisMonth: 'LASCIATO QUESTO MESE',
            newPlusPitchButton: 'Nuovo Plus Pitch',
        },
        createFirst: {
            createFirstNow: {
                createYourFirst: 'CREA SUBITO IL TUO PRIMO',
                plusPitch: 'PLUS PITCH',
                now: '!',
            },
            description1:
                '1 in ogni 4 Plus Pitch fa sì che i giocatori trovino una nuova squadra.',
            description2:
                'Percentuale di successo superiore di oltre 5 volte rispetto alle offerte normali.',
            createNewPlusPitchButton: 'CREA NUOVO PLUS PITCH',
        },
        plusPitchItem: {
            plusPitched: 'CREATO',
            forSale: 'In vendita:',
            forLoan: 'In prestito:',
            circles: {
                low: 'BASSO',
                medium: 'MEDIO',
                good: 'BENE',
                high: 'ALTA',
                veryhigh: 'MOLTO ALTA',
                max: 'MAX',
                readStatus: 'Leggi lo stato',
                interestRate: `Tasso d'interesse`,
            },
            reactions: {
                undefined: 'Non definito',
                awaitingResponse: 'In attesa di risposta ...',
                opened: 'Ha aperto',
                savedPitch: 'Passo salvato ...',
                declaredInterest: 'Interesse dichiarato',
                dismissedPitch: 'Non più interessato ...',
                removed: 'Rimosso',
                awaitingResponseHeader: 'Awaiting response from',
                and: 'and',
                otherClubs: 'other clubs...',
                otherClub: 'other club...',
                close: 'Close...',
            },
            clubHasSavedPlusPitch:
                'squadra ha salvato il tiro in più. Puoi testare il loro interesse',
            clubsHaveSavedPlusPitch:
                'squadre hanno salvato il tiro in più. Puoi testare il loro interesse',
            testInterestNowButton: 'TEST DI INTERESSE ORA!',
            testInterestButton: 'TEST DI INTERESSE',
            interestTested: 'Interesse testato il',
            interestCanBeTested: `L'interesse può essere verificato il`,
            show: 'Mostra',
            more: 'altro ...',
            of: 'di',
            keepSavedPitch: 'Tieni giocatore in offerte salvate',
        },
        generalPerformance: {
            heading: 'GENERALI PITCH PRESTAZIONI',
            basedOn: 'Basato su',
            quality: 'Qualità',
            rates: {
                low: 'BASSO',
                good: 'BENE',
                veryGood: 'MOLTO BENE',
                great: 'GRANDE',
                max: 'MAX',
            },
            agentTip: 'Plus Pitch Agente la punta:',
            tipDescription:
                'Questa è la tua occasione per comunicare le tue aspettative direttamente al mercato. Assicurati di rendere i prezzi interessanti e seleziona un pubblico pertinente di club per ottenere i migliori risultati possibili.',
            plusPitches: 'offerte PLUS',
        },
        pitchCreated: {
            hasBeenPlusPitched: 'e’ stato Plus Pitched a',
            club: 'campionato',
            clubs: 'campionati',
            anotherPlayer: 'Plus Pitch un altro giocatore',
        },
        createFlow: {
            createNewPlusPitchButton: 'CREA NUOVO PLUS PITCH',
            plusPitch: 'PLUS PITCH',
            newFeature: 'NUOVO STRUMENTO',
            startByChoosing:
                'Inizia selezionando un giocatore per la Plus Pitch',
            pitchYourPlayer:
                'Offri il tuo giocatore a squadre specifiche o ad interi campionati',
            leftThis: 'mancanti questo',
            month: 'mese',
            pitchPlayerNow: 'Offri giocatore ora!',
            close: 'Chiudi',
            readyToGo: 'Tutto pronto! Ora clicca sul pulsante Offri.',
        },
        playerAdd: {
            selectPlayer: 'SELEZIONA GIOCATORE',
            selectPlayerName: 'Scegli nome giocatore’',
            cancel: 'Cancella',
            choosePlayer: 'Scegli giocatore’...',
            player: 'Giocatore',
            startByChoosing:
                'Inizia selezionando un giocatore per la Super Pitch',
        },
        setPrice: {
            setPriceFor: 'IMPOSTA PREZZO PER',
            yes: 'Si',
            no: 'No',
            askingPrice: 'Prezzo',
            sellOn: 'Rivendita',
            forLoan: 'In Prestito',
            monthlyLoanFee: 'Spesa prestito mensile',
            price: 'Prezzo',
            setPrice: 'Imposta prezzo’...',
            forSale: 'In Vendita',
            nowSetPrice: 'Ora scegli il prezzo e il tipo di trasferimento!',
            cancel: 'Cancella',
            pm: 'pm',
            setPriceButton: 'Imposta prezzo’',
        },
        setRecipients: {
            zeroSelected: '0 selezionati',
            selectLeagues: 'Seleziona leghe dalla lista',
            recipientsUpTo: 'Destinatari (fino a',
            selectRecipients: 'SCEGLI DESTINATARI',
            selectClubName: 'Seleziona nome della squadra o del campionato’',
            clubsLimit: 'limite squadre',
            reached:
                'raggiunti. Controlla le squadre selezionate nella lista di sinistra',
            clearSelection: 'Svuota Selezione',
            cancel: 'Chiudi',
            saveChanges: 'Aggiungi destinatari',
            readyToGo: 'Scegli squadra o intero campionato, e sei pronto!',
            chooseRecipients: 'Scegli destinatari’',
            recipients: 'Destinatari',
        },
        utils: {
            club: 'squadra',
            clubs: 'squadre',
            league: 'campionato',
            leagues: 'campionati',
        },
        testInterest: {
            forSale: 'In Vendita',
            forLoan: 'In Prestito',
            sendMessage: 'INVIA MESSAGGIO',
            deleteSavedPitch: 'ELIMINA OFFERTA SALVATA',
            thisClub: 'questo club',
            theseClubs: 'questi club',
            whenCanYou: "Quando puoi verificare l'interesse?",
            canTestDaysAfter:
                "Puoi testare l'interesse per i giocatori %{number} giorni dopo che il tuo tiro è stato salvato.",
            testInterestWith: "Metti alla prova l'interesse con",
            testInterest: 'INTERESSE DI PROVA',
            plusPitched: 'CREATO',
            alreadyTestedInterest: 'Hai gia testato interesse con loro',
            savedThePitch: 'Salvato la offerta',
            cannotTestBefore: 'Non puoi testare interesse prima di',
            wouldLikeYouToRespond:
                'vorrebbero una risposta su una OFFERTA PLUS che hai salvato',
            timesOut: 'OFFERTA PLUS SALVATA - SCADENZA',
            ifYouDoNotReact:
                'IMPORTANTE: se non si reagisce a questi, si verificheranno il timeout e la offerta salvata verrà eliminata automaticamente.',
            rePlusPitchOf: 'Re: Offerta Plus di',
            cancel: 'CANCELLA',
        },
    },
    superPitch2: {
        getAccess: {
            getAccessNow: {
                getAccessTo: 'GET ACCESS TO',
                plusPitch: 'SUPER PITCH',
                now: 'NOW!',
            },
            description1:
                '1 in every 4 Super Pitch results in players finding a new club.',
            description2:
                'More than x5 higher success rate than normal pitches.',
            requestInfoButton: 'REQUEST INFORMATION',
        },
        navigationPanel: {
            leftThisMonth: 'LEFT THIS MONTH',
            newPlusPitchButton: 'New Super Pitch',
        },
        createFirst: {
            createFirstNow: {
                createYourFirst: 'CREATE YOUR FIRST',
                plusPitch: 'SUPER PITCH',
                now: 'NOW!',
            },
            description1:
                '1 in every 4 Super Pitch results in players finding a new club.',
            description2:
                'More than x5 higher success rate than normal pitches.',
            createNewPlusPitchButton: 'CREATE NEW SUPER PITCH',
        },
        plusPitchItem: {
            plusPitched: 'SUPER PITCHED',
            forSale: 'For Sale:',
            forLoan: 'For Loan:',
            circles: {
                low: 'LOW',
                medium: 'MEDIUM',
                good: 'GOOD',
                high: 'HIGH',
                veryhigh: 'VERY HIGH',
                max: 'MAX',
                readStatus: 'Read Status',
                interestRate: 'Interest Rate',
            },
            reactions: {
                undefined: 'Undefined',
                awaitingResponse: 'Awaiting response...',
                opened: 'Opened',
                savedPitch: 'Saved pitch ...',
                declaredInterest: 'Declared interest',
                dismissedPitch: 'Dismissed pitch ...',
                removed: 'Removed',
                awaitingResponseHeader: 'Awaiting response from',
                and: 'and',
                otherClubs: 'other clubs...',
                otherClub: 'other club...',
                close: 'Close...',
            },
            clubHasSavedPlusPitch:
                'club has saved the super pitch. You can test their interest',
            clubsHaveSavedPlusPitch:
                'clubs have saved the super pitch. You can test their interest',
            testInterestNowButton: 'TEST INTEREST NOW!',
            testInterestButton: 'TEST INTEREST',
            interestTested: 'Interest tested',
            interestCanBeTested: 'Interest can be tested',
            show: 'Show',
            more: 'more...',
            of: 'of',
            keepSavedPitch: 'Keep player in saved pitches',
        },
        generalPerformance: {
            heading: 'GENERAL PITCH PERFORMANCE',
            basedOn: 'Based on',
            quality: 'Quality',
            rates: {
                low: 'LOW',
                good: 'GOOD',
                veryGood: 'VERY GOOD',
                great: 'GREAT',
                max: 'MAX',
            },
            agentTip: 'Super Pitch Agent Tip:',
            tipDescription:
                'This is your opportunity to communicate your expectations directly to the market. Make sure to make the pricing attractive and select a relevant audience of clubs to achieve the best possible results.',
            plusPitches: 'super pitches',
        },
        pitchCreated: {
            hasBeenPlusPitched: 'has been Super Pitched to',
            club: 'club',
            clubs: 'clubs',
            anotherPlayer: 'Super Pitch Another player',
        },
        createFlow: {
            createNewPlusPitchButton: 'CREATE NEW SUPER PITCH',
            plusPitch: 'SUPER PITCH',
            newFeature: 'NEW FEATURE',
            startByChoosing:
                'Start by choosing a player you want to Super Pitch!',
            pitchYourPlayer:
                'Pitch your player to a specific club or a whole league',
            leftThis: 'left this',
            month: 'month',
            pitchPlayerNow: 'Pitch player now!',
            clear: 'Close',
            readyToGo: 'Ready to go now! Just click Pitch Button.',
        },
        playerAdd: {
            selectPlayer: 'SELECT PLAYER',
            selectPlayerName: 'Select player name...',
            cancel: 'Cancel',
            choosePlayer: 'Choose player...',
            player: 'Player',
            startByChoosing:
                'Start by choosing a player you want to Super Pitch!',
        },
        setPrice: {
            setPriceFor: 'SET PRICE FOR',
            yes: 'Yes',
            no: 'No',
            askingPrice: 'Asking price',
            sellOn: 'Sell-on',
            forLoan: 'For Loan',
            monthlyLoanFee: 'Monthly loan fee',
            price: 'Price',
            setPrice: 'Set price...',
            forSale: 'For Sale',
            nowSetPrice: 'Now set the price and transfer type!',
            cancel: 'Cancel',
            pm: 'pm',
            setPriceButton: 'Set price',
        },
        setRecipients: {
            zeroSelected: '0 Selected',
            selectLeagues: 'Select leagues from the list',
            recipientsUpTo: 'Recipients (up to',
            selectRecipients: 'ADD RECIPIENTS',
            selectClubName: 'Select club name or league...',
            clubsLimit: 'clubs limit',
            reached: 'reached. Review clubs selected in the left list.',
            clearSelection: 'Clear Selection',
            cancel: 'Close',
            saveChanges: 'Add Recipients',
            readyToGo: 'Choose club or whole league, and you are ready to go!',
            chooseRecipients: 'Choose recipients...',
            recipients: 'Recipients',
        },
        utils: {
            club: 'club',
            clubs: 'clubs',
            league: 'league',
            leagues: 'leagues',
        },
        testInterest: {
            forSale: 'To Buy',
            forLoan: 'To Loan',
            sendMessage: 'SEND MESSAGE',
            deleteSavedPitch: 'DELETE SAVED PITCH',
            thisClub: 'this club',
            theseClubs: 'these clubs',
            whenCanYou: 'When can you test interest?',
            canTestDaysAfter:
                'You can test interest in players %{number} days after your pitch has been saved.',
            testInterestWith: 'Test interest with',
            testInterest: 'TEST INTEREST',
            plusPitched: 'SUPER PITCHED',
            alreadyTestedInterest: 'You already tested interest with them',
            savedThePitch: 'Saved the pitch',
            cannotTestBefore: 'You cannot test their interest before',
            wouldLikeYouToRespond:
                'would like you to respond on a Super Pitch you have saved',
            timesOut: 'SAVED SUPER PITCH - TIMES OUT IN',
            ifYouDoNotReact:
                'IMPORTANT: If you do not react to these, they will time out and the saved pitch will be deleted automatically.',
            rePlusPitchOf: 'Re: Super pitch of',
            cancel: 'CANCEL',
        },
    },
    playerSimilarity: {
        similarPlayers: 'Giocatori Simili',
    },
    video: {
        whatClubsSay: 'Cosa dicono i club di TransferRoom?',
        watchInsights:
            'Guarda le opinioni su come i top club utilizzano TransferRoom per risparmiare tempo e denaro.',
        whatOtherClubsSay: 'DICONO DI NOI',
        allVideos: 'TUTTI I VIDEO',
    },
    pager: {
        pageSize: 'Dimensione pagina:',
        page: 'Pagina',
        of: 'di',
        to: 'a',
    },
    agGrid: {
        yes: 'Si',
        no: 'No',
        name: 'Nome',
        currentImage: 'Immagine Attuale',
        deleteImage: 'Elimina Immagine',
        dropFilesHere: 'Trascina file qui’',
        tryDropping:
            'Prova a trascinare file qui, oppure clicca per selezionare i file da caricare.',
    },
    askingPrice: {
        askingPrice: 'Prezzo',
        priceUponRequest: 'Prezzo su richiesta',
        sellOn: '% rivendita',
        availableForSale: 'Disponibile in vendita',
    },
    availabilityBadge: {
        available: 'Disponibile',
        toBeReleased: 'DA RILASCIARE',
    },
    planSummerTransferBanner: {
        planSummerTransfers: 'Pianifica i tuoi trasferimenti estivi',
        creatingAds:
            'Creare annunci aumenta le tue possibilita’ a piu’ del 50%',
        getStarted: 'Inizia Ora',
    },
    superPitchAnnouncement: {
        newFeature: 'NUOVO STRUMENTO',
        pitchYourPlayer:
            'Plus pitch - offri il tuo giocatore a un club specifico o a interi campionati',
        leftThisMonth: 'rimaste questo mese',
        newSuperPitch: 'Nuova plus pitch',
        leftThis: 'rimaste',
        month: 'questo mese',
    },
    transferStatusButton: {
        makeAvailable: 'Rendi disponibile',
    },
    calendar: {
        weekDaysAbbreviated: {
            sunday: 'D',
            monday: 'L',
            tuesday: 'M',
            wednesday: 'M',
            thursday: 'G',
            friday: 'V',
            saturday: 'S',
        },
        months: {
            january: 'Gennaio',
            february: 'Febbraio',
            march: 'Marzo',
            april: 'Aprile',
            may: 'Maggio',
            june: 'Giugno',
            july: 'Luglio',
            august: 'Agosto',
            september: 'Settembre',
            october: 'Ottobre',
            november: 'Novembre',
            december: 'Dicembre',
        },
        monthsAbbreviated: { //TODO: fill with translations
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'May',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Oct',
            nov: 'Nov',
            dec: 'Dec',
        },
        weekDays: {
            sunday: 'Domenica',
            monday: 'Lunedi’',
            tuesday: 'Martedi’',
            wednesday: 'Mercoledi’',
            thursday: 'Giovedi’',
            friday: 'Venerdi’',
            saturday: 'Sabato',
        },
    },
    clubEntry: {
        club: 'Squadra',
        exp: 'scad.',
        onLoanto: 'In Prestito a',
    },
    contractExpiry: {
        month: 'mese',
        months: 'mesi',
    },
    dateRangePicker: {
        selectFirst: 'Selezionare primo giorno.',
        selectLast: 'Selezionare ultimo giorno.',
        to: 'a',
    },
    filter: {
        clear: 'Svuota',
        squad: 'Squadra',
        status: 'Stato',
        age: 'Eta’',
        contractExpiry: 'Scadenza Contratto',
        position: 'Posizione',
        league: 'Lega',
        marketValue: 'Valore di mercato',
        gsnPotential: 'GSN Potenziale'
    },
    contractExpirySelector: {
        yr: 'an',
        mo: 'me',
    },
    feeSelector: {
        pm: 'pm',
    },
    height: {
        cm: 'cm',
        feet: 'piedi',
    },
    helpIcons: {
        monthlyLoanFeeIncludes:
            'Spesa prestito mensile include lo stipendio lordo mensile del giocatore',
        sellOnIsPercentage:
            '% Rivendita e’ la percentuale di qualsiasi profitto da una eventuale futura rivendita a terzi',
        exportPdf: 'Export profile to a PDF file'
    },
    logout: {
        logout: 'Esci’',
    },
    monthlyLoanFeeEntry: {
        availableForLoan: 'Disponibile in prestito',
        monthlyLoanFee: 'Spesa prestito mensile:',
        priceUponRequest: 'Prezzo su richiesta',
        pm: 'pm',
    },
    nationalityEntry: {
        nationality: 'Nazionalita’',
    },
    noResult: {
        loading: 'Caricamento',
        noResults: 'Nessun risultato. Riprova’',
    },
    paging: {
        showingResults: 'Mostrando risultati',
    },
    pitchPlayerButton: {
        pitchPlayer: 'Offri Giocatore',
    },
    positionEntry: {
        position: 'Posizione',
        secondaryPos: 'Posizione sec.',
    },
    weight: {
        kg: 'kg',
        lbs: 'lbs',
    },
    main: {
        loading: 'Caricamento’',
    },
    buttons: {
        ourButtons: 'Nostri pulsanti',
        effectLarge: 'effetto largo:',
    },
    mySquad: {
        advancedFilter: {
            showResults: 'Mostra risultati',
            allPlayers: 'Tutti i Giocatori',
            forLoan: 'In Prestito',
            forSale: 'In Vendita',
            forFree: 'Libero',
            outOnLoad: 'In prestito',
        },
        grid: {
            removePlayer: 'Rimuovi giocatore',
            choosePlayer: 'Scegli giocatore’',
            edit: 'Modifica',
            filter: 'FILTRA',
            cancel: 'Cancella',
            save: 'Salva',
        },
        transferStatusButton: {
            sale: 'Vendita',
            loan: 'Prestito',
            makeAvailable: 'Rendi Disponibile',
            seeProfile: 'Vedi Profilo',
            for: 'In',
        },
        uploadVideo: {
            video: 'Video',
            insertLink: 'Inserisci (Youtube o Vimeo) link’',
            cancel: 'Cancella',
            saveChanges: 'Salva modifiche',
            max2Videos: 'Massimo 2 video per giocatore',
        },
        recommendedMatch: {
            heading: 'RECOMMENDATION MATCH',
            isLookingTo: 'is looking to',
            buy: 'buy',
            loan: 'loan',
            a: 'a',
            transferFee: 'Transfer Fee',
            loanFee: 'Loan Fee',
            upTo: 'up to',
            doYouWantToPitch: 'Do you want to pitch',
            pitch: 'PITCH',
            decline: 'DECLINE',
        },
    },
    delayDayBanner: {
        days: 'GIORNI',
        dealDay: 'Deal day',
        hours: 'ORE',
        minutes: 'MINUTI',
        seconds: 'SECONDI',
        learnMore: 'SCOPRI DI PIU’',
    },
    playerSearch: {
        titleAll: 'TUTTI I GIOCATORI',
        titleShortList: 'LA MIA LISTA',
        titleSimilarTo: 'SIMILE A',
        new: 'NUOVO',
        selectSorting: 'IMPOSTA ORDINE',
        sortBy: 'Ordina per',
        sortByIncreasing: 'Crescente',
        sortByDecreasing: 'Decrescente',
        applySorting: 'APPLICA ORDINE',
        sortbyAvailability: 'Disponibilità',
        bannerWebinar: {
            webinar: 'Webinar',
            marketValue: 'Market Value',
            date: '8th April, 2pm BST',
            dayLeft: 'DAY LEFT',
            daysLeft: 'DAYS LEFT',
        },
        dropDown: {
            requestedLoanContribution: 'Contributo prestito richiesto',
            requestLoanContribution: 'Richiedi contributo prestito',
            requestedLoanAvailability: 'Disponibilita’ in prestito richiesta',
            requestLoanAvailability: 'Richiedi disponibilita’ in prestito',
            profile: 'Profilo',
            yrs: 'anni',
            newMessage: 'Nuovo Messaggio',

            requestedAskingPrice: 'Prezzo richiesto',
            requestAskingPrice: 'Richiedi prezzo',
            requestedTransferAvailability:
                'Disponibilita’ trasferimento richiesta',
            requestTransferAvailability:
                'Richiedi disponibilita’ trasferimento',
            sendByEmail: 'Invia Email',
        },
        filter: {
            showResults: 'Mostra risultati',
            createLeaguesList: 'Crea lista campionati',
            forLoan: 'In Prestito',
            forSale: 'In Vendita',
            forFree: 'Libero',
            toBeReleased: 'Da rilasciare',
            age: 'Eta’',
            contractExpiry: 'Scadenza Contratto',
            position: 'Posizione',
            league: 'Campionato',
        },
        grid: {
            searchPlayerName: 'Cerca giocatore o club’',
            filter: 'FILTRA',
        },
        menu: {
            allPlayers: 'Tutti i giocatori',
            myShortlist: 'Preferiti',
        },
        customColumns: {
            of: 'di',
            columnsSelected: 'colonne selezionate',
            performanceData: 'STATISTICHE',
        },
        main: {
            playerSearch: 'Ricerca Giocatore',
            watchVideo: 'Guarda video del giocatore',
        },
        similarTo: {
            suggestionTip:
                'Utilizza il motore di ricerca per eseguire immediatamente il nostro algoritmo sul database di 50.000 giocatori per trovare quello più simile in termini di livello e prestazioni',
        },
        recommendationBanner: {
            heading:
                'Get intelligent player recommendations from our data analyst engine',
            description:
                'Simply complete the ad we have already made ready for you and get 5 player recommendations right away',
            getYourRecommendationButton: 'Get your recommendations now',
        },
    },
    playerProfile: {
        webinar: {
            panelHeading: 'Webinar: Market Value'
        },
        marketValue: {
            panelHeading: 'VALORE DI MERCATO', // ?
            showMeButton: 'MOSTRA',
            landing: {
                valuationAvailable: 'VALUTAZIONE GIOCATORI DISPONIBILE!',
                inCooperation: 'In cooperazione con 21st Club',
                interested: 'INTERESSATO?',
                contributeWithOpinion: 'Contribuisci con la tua opinione per vedere la valutazione',
                placeholder: 'Inserisci valutazione...'
            },
            chart: {
                marketValue: 'Valore di mercato',
                club: 'Club',
                selection: {
                    latest12Months: 'Ultimo 12 mesi',
                    latest2Years: 'Ultimo 2 anni',
                    latest3Years: 'Ultimo 3 anni',
                    latest4Years: 'Ultimo 4 anni',
                    latest5Years: 'Ultimo 5 anni',
                },
                clubValuation: 'Valutazione 21st Club',
                tipText: `21st Club utilizza i dati per tenere conto dei livelli di prestazione e dell'età di un giocatore, per fornire una valutazione obiettiva del suo valore di trasferimento, in base alle recenti condizioni e transazioni di mercato.`,
                lastUpdated: 'Aggiornato',
                requestedAskingPrice: 'Prezzo richiesto',
                requestAskingPrice: 'Richiedi prezzo',
                requestedAvailability: 'Disponibilita’ richiesta',
                requestAvailability: 'Richiedi disponibilita’',
            }
        },
        gsnPerformance: {
            panelHeading: 'GSN - PERFORMANCE E POTENZIALE',
            chartDescription: `Global Soccer Network è la prima agenzia di rating per calciatori professionisti e creatori del proprio sistema di classificazione: l'Indice GSN.`,
            performance: 'Performance',
            potential: 'Potenziale',
            compareTo: 'Confronta con’',
            dropdownTitle: 'Ranking per Performance e Potenziale',
            dropdown: {
                position: 'Posizione',
                my1stTeam: 'La mia rosa',
                myShortlist: 'Preferiti',
                manageShortlist: 'GESTISCI PREFERITI',
                addPlayers: 'AGGIUNGI GIOCATORI',
                rank: 'ORDINE',
                player: 'GIOCATORE',
                perf: 'PERF.',
                potential: 'POTENZIALE',
                all: 'TUTTI',
                youDontHavePlayersInShortlist: 'Non hai giocatori tra i tuoi preferiti',
                addPlayersToMyShortlist: 'AGGIUNGI GIOCATORI AI MIEI PREFERITI'
            },
            modal: {
                heading: "GESTISCI LA TUA LISTA PREFERITI",
                playersOnShortlist: 'Giocatori Preferiti',
                searchPlaceholder: 'Scrivi nome giocatore',
                tip: 'Seleziona i giocatori sulla destra per aggiungerli alla tua lista',
                saveBtn: 'Salva'
            }
        },
        comparison: {
            playerComparsion: 'Confronto Giocatori',
        },
        passport: {
            playerPassport: 'Passaporto Giocatore',
            height: 'Altezza',
            foot: 'Piede preferito',
            newMessage: 'Nuovo Messaggio',
        },
        summary: {
            yrs: 'anni',
            marketValue: 'Valore di mercato',
        },
        transferHistory: {
            transferHistory: 'Storia Trasferimenti',
            date: 'Data',
            from: 'Da',
            to: 'A',
            type: 'Tipo',
            price: 'Prezzo',
            noTransferHistory: 'Nessuna storia trasferimenti disponibile',
            show: 'Mostra',
            more: 'piu’’',
        },
        transferBenchmarks: {
            player: 'Player',
            percentMatch: '% Match',
            age: 'Age',
            year: 'Year',
            from: 'From',
            to: 'To',
            fee: 'Fee',
            showMore: 'Show %{number} more',
        },
        video: {
            playerVideo: 'Video Giocatore',
        },
    },
    shortList: {
        shortList: 'Preferiti',
    },
    playerGrid: {
        player: 'Giocatore',
        squad: 'Squadra',
        position: 'Posizione',
        age: 'Eta’',
        gsnPotential: 'GSN Potenziale',
        gsnPerformance: 'GSN Prestazione',
        contractExpiry: 'Scadenza Contratto',
        transferStatus: 'Stato Trasferimento',
        matchMaker: 'Match Maker',
        height: 'Altezza',
        foot: 'Piede preferito',
        league: 'Campionato',
        club: 'Squadra',
        marketValue: 'Valore di mercato',
        recommendation: 'Raccomandazione',
        faPlayerPoint: 'GBE Status',
    },
    utils: {
        cm: 'cm',
    },
    upgradeModal: {
        thankYou: 'Thank you',
        forYourInterest: 'for your interest!',
        weWillBeInTouch: 'We will be in touch shortly',
        close: 'Chiudi'
    },
    positionAbbreviations: {
        GK: 'PO',
        LB: 'TS',
        RB: 'TD',
        CB: 'DC',
        DM: 'CD',
        CM: 'CC',
        AM: 'TQ',
        W: 'AL',
        F: 'AT',
    },
    positionFullNames: {
        GK: 'Portiere',
        LB: 'Terzino Sinistro',
        RB: 'Terzino Destro',
        CB: 'Difensore Centrale',
        DM: 'Centrocampista Difensivo',
        CM: 'Centrocampista Centrale',
        AM: 'Trequartista',
        W: 'Ala',
        F: 'Attaccante',
    },
    apiTexts: {
        withoutClub:'Senza Club',
        transferTypesHistory: {
            1: 'Cessione',
            2: 'Libero',
            3: 'Prestito',
            4: 'Rientro dal Prestito',
            5: 'Svincolato',
            6: 'Scambio Giocatore',
            7: 'Provino',
        },
        transferTypes: {
            1: 'Vendita',
            2: 'Prestito',
        },
        dayAgo: '%{number} giorno fa',
        daysAgo: '%{number} giorni fa',
        monthAgo: '%{number} mese fa',
        monthsAgo: '%{number} mesi fa',
        yearAgo: '%{number} anno fa',
        yearsAgo: '%{number} anni fa',
        weekAgo: '%{number} settimana ago',
        weeksAgo: '%{number} settimane ago',
        hourAgo: '%{number} ora fa',
        hoursAgo: '%{number} ore fa',
        minuteAgo: '%{number} minuto fa',
        minutesAgo: '%{number} minuti fa',
        secondAgo: '%{number} secondo ago',
        secondsAgo: '%{number} second ago',
        foot: {
            left: 'Sinistro',
            right: 'Destro',
        },
        userStatus: {
            active: 'Attivo',
            pendinginvitation: 'Invito in attesa',
        },
        areas: {
            1: 'Africa',
            2: 'Asia',
            3: 'Europa',
            4: 'America Centrale',
            5: 'Nordamerica',
            6: 'Sudamerica',
            7: 'Oceania',
            8: 'Mondo',
            9: 'Afghanistan',
            10: 'Albania',
            11: 'Algeria',
            12: 'Samoa Americana',
            13: 'Andorra',
            14: 'Angola',
            15: 'Anguilla',
            16: 'Antartica',
            17: 'Antigua e Barbuda',
            18: 'Emirati Arabi',
            19: 'Argentina',
            20: 'Armenia',
            21: 'Aruba',
            22: 'Australia',
            23: 'Austria',
            24: 'Azerbaijan',
            25: 'Bahamas',
            26: 'Bahrain',
            27: 'Bangladesh',
            28: 'Barbados',
            29: 'Bielorussia',
            30: 'Belgio',
            31: 'Belize',
            32: 'Benin',
            33: 'Bermuda',
            34: 'Bhutan',
            36: 'Bolivia',
            37: 'Bonaire, Sint Eustatius and Saba',
            38: 'Bosnia-Herzegovina',
            39: 'Botswana',
            40: 'Bouvet island',
            41: 'Brasile',
            42: 'British Indian Ocean Territory',
            43: 'Isole Vergini Britanniche',
            44: 'Brunei Darussalam',
            45: 'Bulgaria',
            46: 'Burkina Faso',
            47: 'Burundi',
            48: 'Cambogia',
            49: 'Camerun',
            50: 'Canada',
            51: 'Capo Verde',
            52: 'Isole Cayman',
            53: 'Repubblica Centafricana',
            54: 'Ciad',
            55: 'Cile',
            56: 'Cina',
            57: 'Taipei Cinese',
            58: 'Christmas island',
            59: 'Cocos (keeling) islands',
            60: 'Colombia',
            61: 'Comoros',
            62: 'Congo',
            63: 'Congo DR',
            64: 'Cook Islands',
            65: 'Costa Rica',
            66: "Costa D'Avorio",
            67: 'Croazia',
            68: 'Cuba',
            69: 'Curaçao',
            70: 'Cipro',
            71: 'Repubblica Ceca',
            72: 'Danimarca',
            73: 'Djibouti',
            74: 'Dominica',
            75: 'Repubblica Dominicana',
            76: "Timor dell'Est",
            77: 'Ecuador',
            78: 'Egitto',
            79: 'El Salvador',
            80: 'Inghilterra',
            81: 'Guinea Equatoriale',
            82: 'Eritrea',
            83: 'Estonia',
            84: 'Ethiopia',
            85: 'Falkland islands (malvinas)',
            86: 'Isole Far Oer',
            87: 'Fiji',
            88: 'Finlandia',
            89: 'Francia',
            90: 'Francia, metropolitana',
            91: 'Guiana Francese',
            92: 'Polinesia Francese',
            93: 'Terre australi e antartiche francesi',
            94: 'Gabon',
            95: 'Gambia',
            96: 'Georgia',
            97: 'Germania',
            98: 'Ghana',
            99: 'Gibilterra',
            100: 'Regno Unito',
            101: 'Grecia',
            102: 'Groenlandia',
            103: 'Grenada',
            104: 'Guadalupe',
            105: 'Guam',
            106: 'Guatemala',
            107: 'Guernsey',
            108: 'Guinea',
            109: 'Guinea-Bissau',
            110: 'Guyana',
            111: 'Haiti',
            112: 'Santa Sede, Vaticano',
            113: 'Honduras',
            114: 'Hong Kong',
            115: 'Ungheria',
            116: 'Islanda',
            117: 'India',
            118: 'Indonesia',
            119: 'Iran',
            120: 'Iraq',
            121: 'Irlanda',
            122: 'Isola di Man',
            123: 'Israele',
            124: 'Italia',
            125: 'Giamaica',
            126: 'Giappone',
            127: 'Jersey',
            128: 'Giordania',
            129: 'Kazakhstan',
            130: 'Kenya',
            131: 'Kiribati',
            132: 'Korea DPR',
            133: 'Korea Republic',
            134: 'Kosovo',
            135: 'Kuwait',
            136: 'Kyrgyzstan',
            137: 'Laos',
            138: 'Lettonia',
            139: 'Libano',
            140: 'Lesotho',
            141: 'Liberia',
            142: 'Libia',
            143: 'Liechtenstein',
            144: 'Lituania',
            145: 'Lussemburgo',
            146: 'Macao',
            147: 'Macedonia',
            148: 'Madagascar',
            149: 'Malawi',
            150: 'Malesia',
            151: 'Maldive',
            152: 'Mali',
            153: 'Malta',
            154: 'Isole Marshall',
            155: 'Martinica',
            156: 'Mauritania',
            157: 'Mauritius',
            159: 'Mayotte',
            160: 'Messico',
            161: 'Micronesia',
            162: 'Moldavia',
            163: 'Monaco',
            164: 'Mongolia',
            165: 'Montenegro',
            166: 'Montserrat',
            167: 'Marocco',
            168: 'Mozambico',
            169: 'Myanmar, Birmania',
            170: 'Namibia',
            171: 'Nauru',
            172: 'Nepal',
            173: 'Olanda',
            174: 'Antille Olandesi',
            175: 'Nuova Caledonia',
            176: 'Nuova Zelanda',
            177: 'Nicaragua',
            178: 'Niger',
            179: 'Nigeria',
            180: 'Niue',
            181: 'Isole Norfolk',
            182: 'Irlanda del Nord',
            183: 'Isole Marianne',
            184: 'Norvegia',
            185: 'Oman',
            186: 'Pakistan',
            187: 'Palau',
            188: 'Palestina',
            189: 'Panama',
            190: 'Papua Nuova Guinea',
            191: 'Paraguay',
            192: 'Peru',
            193: 'Filippine',
            194: 'Isola Pitcairn',
            195: 'Polonia',
            196: 'Portogallo',
            197: 'Puerto Rico',
            198: 'Qatar',
            199: 'Riunione',
            200: 'Romania',
            201: 'Russia',
            202: 'Rwanda',
            203: 'Saint Martin',
            204: 'Samoa',
            205: 'San Marino',
            206: 'São Tomé e Príncipe',
            207: 'Arabia Saudita',
            208: 'Scozia',
            209: 'Senegal',
            210: 'Serbia',
            211: 'Serbia e Montenegro',
            212: 'Seychelles',
            213: 'Sierra Leone',
            214: 'Singapore',
            215: 'Sint Maarten',
            216: 'Slovacchia',
            217: 'Slovenia',
            218: 'Isole Solomon',
            219: 'Somalia',
            220: 'Sudafrica',
            221: 'South Georgia and the South Sandwich islands',
            222: 'Sudan del Sud',
            223: 'Spagna',
            224: 'Sri Lanka',
            225: 'St. helena',
            226: 'St. Kitts and Nevis',
            227: 'St. Lucia',
            228: 'St. pierre and miquelon',
            229: 'St. Vincent and the Grenadines',
            230: 'Sudan',
            231: 'Suriname',
            232: 'Svalbard and Jan Mayen islands',
            233: 'Swaziland',
            234: 'Svezia',
            235: 'Svizzera',
            236: 'Siria',
            237: 'Tahiti',
            238: 'Taiwan',
            239: 'Tajikistan',
            240: 'Tanzania',
            241: 'Thailandia',
            242: 'Togo',
            243: 'Tokelau',
            244: 'Tonga',
            245: 'Trinidad e Tobago',
            246: 'Tunisia',
            247: 'Turchia',
            248: 'Turkmenistan',
            249: 'Isole Turks and Caicos',
            250: 'Tuvalu',
            251: 'Uganda',
            252: 'Ucraina',
            253: 'Emirati Arabi Uniti',
            254: 'Stati Uniti',
            255: 'United states minor outlying islands',
            256: 'Uruguay',
            257: 'Isole Vergini US',
            258: 'Uzbekistan',
            259: 'Vanuatu',
            260: 'Venezuela',
            261: 'Vietnam',
            262: 'Isole Vergini Britanniche',
            263: 'Isole Vergini Americane',
            264: 'Galles',
            265: 'Wallis e Futuna',
            266: 'Sahara Occidentale',
            267: 'Yemen',
            268: 'Zambia',
            269: 'Zanzibar',
            270: 'Zimbabwe',
        },
        timeUnits: {
            now: 'ora',
            day: 'giorno',
            days: 'giorni',
            month: 'mese',
            months: 'mesi',
            hour: 'ora',
            hours: 'ore',
            minute: 'minuto',
            minutes: 'minuti',
            week: 'settimana',
            weeks: 'settimane',
        },
        timeAgo: {
            today: 'Oggi',
            yesterday: 'Ieri',
            thisWeek: 'This week',
            daysAgo: 'days ago',
            weekAgo: 'A week ago',
            weeksAgo: 'weeks ago',
            monthAgo: 'A month ago',
            monthsAgo: 'months ago',
            yearAgo: 'A year ago',
            yearsAgo: 'years ago'
        },
        monthUnitsShort: {
            0: 'Jan',
            1: 'Feb',
            2: 'Mar',
            3: 'Apr',
            4: 'May',
            5: 'Jun',
            6: 'Jul',
            7: 'Aug',
            8: 'Sep',
            9: 'Oct',
            10: 'Nov',
            11: 'Dec',
        }
    },
    adForceUpdate: {
        updateYourAds: 'Update your Ads',
        moreThan2MonthsAge:
            'You posted your ads more than 2 months ago. Please update your ads so other clubs knows if your are still looking.',
        updateAllAds: 'UPDATE ALL (%{number}) ADS',
        reviewYourAds: 'REVIEW YOUR ADS',
        updated: 'UPDATED',
        stillLooking: 'YES I AM STILL LOOKING',
        remove: 'REMOVE',
    },
};
