import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer';
import { PlayerStatisticService } from 'api/player/player-statistic-service'
import { CreatePlayerAdRequestV2 } from 'api/player-ad/models/create-player-ad'
import { Player } from 'api/player/model/player'
import { getAuth, hasAgentFeature } from 'store/auth/authReducer'
import { setupAgencyFeature } from 'store/auth/auth.actions'
import { PlayerService } from 'api/player/player-service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import CustomSignInService from 'api/custom-sign-in/custom-sign-in.service'
import { PlayerAd, PlayerAdInitialInfoResponse } from 'api/player-ad/models/player-ad'
import { PlayerAdService } from 'api/player-ad/player-ad-service'
import moment from 'moment'
import historyAccessor from 'history-accessor'
import * as PlayerAdsController from 'pages/player-ads/redux/common.controller'
import * as PlayerAdsMyAdsController from 'pages/player-ads/redux/my-ads.controller'
import { PlayerAdType } from 'api/player-ad-v2/models/player-ad-with-counters';
import AgencyOnboardingService from "../../../../api/agency/club/onboarding/onboarding.servise";
import { getPositionCodeById, getPositionIdByCode } from "../../../../utils/position-helper";
import { PlayerAdTypeEnumModel } from "../../../../api/agency/club/onboarding/onboarding-models";
import { ActionType, PageType, UserActivityType } from 'constants/enums';
import { Actions as PerformanceActions } from 'pages/landing-page/redux/performance-data.controller'
import {userPaths} from "../../../../routes/paths";
import { getPreferredPlayerAreas} from 'pages/player-ads/create-new-ad-modal/helpers/preferred-player-area';
import { getPreferredPlayingStyles} from 'pages/player-ads/create-new-ad-modal/helpers/preferred-player-playing-style';
import { IdName } from "api/core/id-name";
import { PlayerAdWithCountersModel } from 'api/player-ad-v2/models/player-ad-with-counters';
import { getCancelTokenSource } from 'axios-config'
import PlayerAdV2Service from 'api/player-ad-v2/player-ad-v2.service';

export const ageRule = { min: 16, max: 40 };
export const heightRule = { min: 150, max: 210 };
export enum PreviousPageName {
    HomePage = 'home' ,
    Performance = 'performance' ,
    PlayerAd = 'playerAds',
}

export class CreateAdModel {
    toLoan: boolean;
    toBuy: boolean;

    loan?: number;
    buy?: number;
    grossSalary?: number;

    age: { min: number, max: number }
    height: number;

    isLeftFooted: boolean;
    isAgencyRecommended: boolean;

    preferredPlayerArea: IdName;
    preferredPlayingStyle: IdName;
    isAnonymiseRequirement: boolean;
}

class CreatePlayerAdState {
    positions: { [key: string]: CreateAdModel }
    originalPositions: Array<string>;
    currentPositionIndex: number;
    playerSuggestions: Array<Player>;
    shortPlayerSuggestions: Array<Player>;
    currentStep: number;

    adsProcessing: boolean;
    processingSuggestions: Array<number>;
    outdatedAds: Array<PlayerAd>;
    outdatedAdsInitialState: PlayerAd[]
    outdatedAdsLoading: boolean;
    processingOutdatedAds: Array<number>;
    outdatedAdsRemoved: Array<PositionIdPair>;
    outdatedAdsApprovedV2: Array<PlayerAd>;
    isInviteRecommendedAgents: boolean;
    source: PreviousPageName;
    titleOfFinalStep: string;
    updatedAdsCount: number;

    playerAdInitialInfo: PlayerAdInitialInfoResponse;

    playerAds: Array<PlayerAdWithCountersModel>;
    isLoading: boolean

}


const defaultState: CreatePlayerAdState = {
    positions: {},
    originalPositions: [],
    playerSuggestions: [],
    shortPlayerSuggestions: [],
    currentPositionIndex: 0,
    currentStep: 1,
    adsProcessing: false,
    processingSuggestions: [],
    outdatedAds: [],
    outdatedAdsInitialState: [],
    outdatedAdsLoading: false,
    processingOutdatedAds: [],
    outdatedAdsRemoved: [],
    outdatedAdsApprovedV2: [],
    isInviteRecommendedAgents: false,
    source: PreviousPageName.HomePage,
    titleOfFinalStep: 'created',
    updatedAdsCount: null,
    
    playerAdInitialInfo: null,

    playerAds: [],
    isLoading: false,
}

export class PositionIdPair {
    position: string;
    id: number;
    type: number;
}

const stateController = new StateController<CreatePlayerAdState>(
    "PLAYER_ADS/CREATE_PLAYER_ADS_MODAL",
    defaultState
);

class Actions {

    public static token = null;

    public static openPage(source: PreviousPageName) {
        return async (dispatch, getState: () => AppState) => {
            let message = ''
            let pageName = ''

            switch (source) {
                case PreviousPageName.HomePage:
                    message = 'Create Ad'
                    pageName = 'Home [Create Player Ads]'
                    dispatch(stateController.setState({ source: PreviousPageName.HomePage }))
                    break
                case PreviousPageName.Performance:
                    message = 'Create Ad'
                    pageName = 'Home [Performance Indicators]'
                    dispatch(stateController.setState({ source: PreviousPageName.HomePage }))
                    break
                case PreviousPageName.PlayerAd:
                    message = 'Create Ad'
                    pageName = 'Player Ads'
                    dispatch(stateController.setState({ source: PreviousPageName.PlayerAd }))
                    break
                default:
                    break
            }

            historyAccessor.push('/create-player-ads')

            dispatch(userActivityInsert({
                Message: message,
                PageName: pageName,
                PageType: PageType.Home,
            }))
        }
    }

    public static backToPreviousPage() {
        return (dispatch, getState: () => AppState) => {
            const path = getState().playerAdsV2.createPlayerAds.source;
            historyAccessor.push(userPaths[path]);
        }
    }

    public static closeModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.backToPreviousPage())
            dispatch(userActivityInsert({
                Message: `Cancelled`,
                PageName: 'Create Player Ad',
                PageType: PageType.PlayerAds
            }))
        }
    }

    public static done() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.backToPreviousPage())
            dispatch(PerformanceActions.completedCreatePlayerAdsRecommendation());
            // dispatch(userActivityInsert({
            //     Message: `Done`,
            //     PageName: 'Player Ads [Player recommender]',
            //     PageType: PageType.PlayerAds
            // }));
        }
    }

    public static profileLinkActivity(playerId: number, squadId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                Message: `Opened Player Profile`,
                PageName: 'Player Ads [Player Recommender]',
                PlayerId: playerId,
                ClubId: squadId,
                PageType: PageType.PlayerAds
            }));
        }
    }

    public static removeAd(id: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((state) => ({ ...state, processingOutdatedAds: [...state.processingOutdatedAds, id] })))

            await PlayerAdService.removeOutdatedAd(getState().auth.aspNetUserId, id);
            dispatch(PlayerAdsMyAdsController.Actions.softlyRemoveAd(id))

            let item = getState().playerAdsV2.createPlayerAds.outdatedAds.find(x => x.id == id);
            dispatch(stateController.setState((state) => {
                return ({
                    ...state,
                    outdatedAds: state.outdatedAds.filter(x => x.id != id),
                    outdatedAdsRemoved: [
                        ...state.outdatedAdsRemoved,
                        { id: item.id, position: item.positionCode, type: item.type },
                    ],
                    processingOutdatedAds: state.processingOutdatedAds.filter(x => x != id)
                })
            }))

            let positionsText = `${item.positionCode}, ${item.type === PlayerAdType.Buy ? 'Buy' : 'Loan'}`;
            dispatch(userActivityInsert({
                Message: `Removed Ad: ${positionsText}`,
                PageName: 'Player Ads',
                PageType: PageType.PlayerAds
            }))
            dispatch(PlayerAdsController.Actions.loadCounters());
        }
    }

    public static stillLookingAd(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState().playerAdsV2.createPlayerAds;
            let item = appState.outdatedAds.find(x => x.id == id);

            dispatch(stateController.setState((state) => ({ ...state, processingOutdatedAds: [...state.processingOutdatedAds, id] })))
            let duration = moment.duration(moment.utc().diff(item.lastUpdated))
            if (duration.asHours() > 24) {
                
                let positionsText = `${item.positionCode}, ${item.type === PlayerAdType.Buy ? 'Buy' : 'Loan'}`;
                dispatch(userActivityInsert({
                    Message: `Updated Ad: ${positionsText}`,
                    PageName: 'Player Ads',
                    PageType: PageType.Home
                }))
                
                await PlayerAdService.updateOutdatedAd(getState().auth.aspNetUserId, id);
                
                if (window.location.pathname.includes('playerads/my-ads')) {
                    dispatch(PlayerAdsMyAdsController.Actions.loadAllAds(() => { }));
                }
            }

            dispatch(stateController.setState((state) => ({
                ...state,
                outdatedAds: [...state.outdatedAds.filter(x => x.id !== id)],
                outdatedAdsApprovedV2: [
                    ...state.outdatedAdsApprovedV2, 
                    item
                ],
                processingOutdatedAds: state.processingOutdatedAds.filter(x => x != id),
            })))
            dispatch(PlayerAdsController.Actions.loadCounters());
        }
    }

    public static deleteIndexFromMap(positionCode: string) {
        return (dispatch, getState: () => AppState) => {
            let newState = getState().playerAdsV2.createPlayerAds;
            let positions = { ...newState.positions };
            delete positions[positionCode];

            dispatch(stateController.setState({ currentPositionIndex: newState.currentPositionIndex - 1, positions: positions }))
        }
    }

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));

            await dispatch(Actions.loadOutdatedAds());
            await dispatch(Actions.loadAllAds());

            dispatch(stateController.setState({ isLoading: false}));
        }
    }

    public static loadOutdatedAds() {
        return async (dispatch, getState: () => AppState) => {

            let appState = getState();
            const aspId = appState.auth.aspNetUserId;

            dispatch(stateController.setState({ outdatedAdsLoading: true }));

            let result = await PlayerAdService.getMyOutdatedPlayerAds(aspId);
            
            let ads = result.filter(x => x.active);
            dispatch(stateController.setState({ 
                outdatedAdsLoading: false, 
                outdatedAds: ads,
                outdatedAdsInitialState: ads,
            }))
            dispatch(Actions.loadPlayerAdInitialInfo())
        }
    }

    public static loadAllAds() {
        return async (dispatch, getState: () => AppState) => {

            Actions.token = getCancelTokenSource();

            let myAdsItems = await PlayerAdV2Service.getAllAdsWithCounters(Actions.token.token);

            if (myAdsItems) {
                dispatch(stateController.setState({ playerAds: myAdsItems }));
            }
        }
    }

    public static nextStep() {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            let playerAd = getState().playerAdsV2.createPlayerAds;
            let step = getState().playerAdsV2.createPlayerAds.currentStep;

            if (step === 1) {
                const positions = getState().playerAdsV2.createPlayerAds.positions
                
                let positionsText = "";
                if (Object.keys(positions).length > 0) {
                    positionsText = Object.keys(positions).reduce((result, item) => {
                        return `${result}, ${item}`;
                    });
                }
                if (positionsText == ',') {
                    positionsText = ''
                }

                for (let key in positions) {
                    if (positions.hasOwnProperty(key)) {
                        let ad = positions[key];
                        ad.isAgencyRecommended = state.auth.isAgentsVisible;
                    }
                }

                dispatch(userActivityInsert({
                    Message: `Selected Positions: ${positionsText}`,
                    PageName: 'Create Player Ad',
                    PageType: PageType.PlayerAds
                }));
                setTimeout(() => {
                    dispatch(userActivityInsert({
                        Message: `Continue`,
                        PageName: 'Create Player Ad',
                        PageType: PageType.PlayerAds
                    }));
                }, 1000)
            }

            dispatch(stateController.setState({ currentStep: getState().playerAdsV2.createPlayerAds.currentStep + 1 }))
        }
    }

    public static goToFinalStep(title?: string, adsCount?: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(state => ({
                ...state,
                currentStep: 4,
                titleOfFinalStep: title ?? 'created',
            })))
            if (adsCount) {
                dispatch(stateController.setState({ updatedAdsCount: adsCount }))
            }
        }
    }

    public static prevStep() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: getState().playerAdsV2.createPlayerAds.currentStep - 1 }))
        }
    }

    public static changePositionsSelection(positionCode: string) {

        let defaultAdState: CreateAdModel = { 
            toLoan: false, 
            toBuy: false, 
            age: null, 
            height: null, 
            isAgencyRecommended: true, 
            isLeftFooted: false, 
            preferredPlayerArea: null, 
            preferredPlayingStyle: null, 
            isAnonymiseRequirement: false 
        }

        return (dispatch, getState: () => AppState) => {
            let state = getState();

            defaultAdState.isAgencyRecommended = state.auth.isAgentsVisible && (hasAgentFeature(state)
                || state.auth.isFreemiumSubscription);

            const positions = state.playerAdsV2.createPlayerAds.positions

            if (Object.keys(positions).includes(positionCode)) {
                delete positions[positionCode]
            } else {
                positions[positionCode] = defaultAdState
            }

            dispatch(stateController.setState({
                positions: { ...positions },
                originalPositions: [...Object.keys(positions)]
            }))
        }
    }

    public static onBackButton() {
        return (dispatch, getState: () => AppState) => {
            const currentIndex = getState().playerAdsV2.createPlayerAds.currentPositionIndex
            if (currentIndex === 0) {
                dispatch(Actions.prevStep())
            } else {
                dispatch(Actions.goToPrevPosition())
            }
        }
    }

    public static goToPrevPosition() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().playerAdsV2.createPlayerAds;
            let currentPositionIndex = substate.currentPositionIndex;
            let position = Object.keys(substate.positions)[substate.currentPositionIndex - 1];

            let outdatedAds = substate.outdatedAds;
            let stillLooking = substate.outdatedAdsApprovedV2;

            let adsToRevert = stillLooking.filter(x => x.positionCode == position);
            let adsToKeep = stillLooking.filter(x => x.positionCode != position);
            dispatch(stateController.setState({
                currentPositionIndex: currentPositionIndex - 1,
                outdatedAds: [...outdatedAds, ...adsToRevert],
                outdatedAdsApprovedV2: adsToKeep
            }))
        }
    }

    public static goToNextPosition(removePrev?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let currencyId = state.auth.currency.id;
            let subState = state.playerAdsV2.createPlayerAds;
            let currentPositionIndex = subState.currentPositionIndex;
            var currentPositionCode = Object.keys(subState.positions)[subState.currentPositionIndex];

            if (state.auth.isAgentsVisible && !hasAgentFeature(state) && subState.positions[currentPositionCode].isAgencyRecommended) {
                dispatch(stateController.setState({ adsProcessing: true }))
                for (let key in subState.positions) {
                    if (subState.positions.hasOwnProperty(key)) {
                        let ad = subState.positions[key];
                        ad.isAgencyRecommended = true;
                    }
                }
                dispatch(stateController.setState({ positions: { ...subState.positions } }))
                await AgencyOnboardingService.setUpAgencyFeatures();
                dispatch(setupAgencyFeature());
                dispatch(stateController.setState({ adsProcessing: false }))
            }

            if (currentPositionIndex === Object.keys(subState.positions).length - 1) {
                let request: Array<CreatePlayerAdRequestV2> = new Array<CreatePlayerAdRequestV2>();
                for (var key of Object.keys(subState.positions)) {
                    let current = subState.positions[key];
                    if (current.toBuy) {
                        request.push({
                            toAge: current.age ? current.age.max : null, 
                            fromAge: current.age ? current.age.min : null, 
                            minHeight: current.height,
                            type: PlayerAdTypeEnumModel.Buy, 
                            amount: current.buy, positionId: getPositionIdByCode(key), 
                            currencyId: currencyId, grossSalary: current.grossSalary,
                            isAgencyRecommendation: current.isAgencyRecommended,
                            isLeftFooted: current.isLeftFooted,
                            preferredNationalityAreaId: current.preferredPlayerArea?.id,
                            playingStyleId: current.preferredPlayingStyle?.id,
                            isAnonymised: current.isAnonymiseRequirement,
                        })
                    }

                    if (current.toLoan) {
                        request.push({
                            toAge: current.age ? current.age.max : null, 
                            fromAge: current.age ? current.age.min : null, 
                            minHeight: current.height,
                            type: PlayerAdTypeEnumModel.Loan, 
                            amount: current.loan, positionId: getPositionIdByCode(key), 
                            currencyId: currencyId,
                            isAgencyRecommendation: current.isAgencyRecommended,
                            isLeftFooted: current.isLeftFooted,
                            preferredNationalityAreaId: current.preferredPlayerArea?.id,
                            playingStyleId: current.preferredPlayingStyle?.id,
                            isAnonymised: false, 
                        })
                    }

                }

                dispatch(stateController.setState({ adsProcessing: true }))

                try {
                    let suggestions = [];
                    if (request.length > 0) {
                        const { playerAds } = await PlayerAdService.createPlayerAds(request);
                        const playerAdIds = playerAds.map(x => x.id);
                        suggestions = await PlayerStatisticService.getRecomendedPlayersByPlayerAdIds(playerAdIds, state.auth.clubId, state.auth.squadId, state.auth.userId);
                    }

                    const positions = request;
                    let positionsText = '';

                    positions.map((item, index) => {

                        let positionValueText = ''
                        let costValue = ''
                        let age = ''
                        let height = ''
                        let leftFooted = ''
                        let nationality = ''
                        let playingStyle = ''
            
                        if (item.type === 1) {
                            positionValueText += 'To Buy';
                            costValue = `, ${item.amount};${item.grossSalary}/yr`
                        }
                        if (item.type === 2) {
                            if (positionValueText.length > 0) {
                                positionValueText += ', '
                            }
                            costValue = `, ${item.amount}/mo`
                            positionValueText += 'To Loan';
                        }
                        if ((item.fromAge && item.fromAge !== 16) || (item.toAge && item.toAge !== 40)) {
                            age = `, Age ${item.fromAge}-${item.toAge}`
                        }
                        if (item.minHeight && item.minHeight !== 150) {
                            height = `, Min. ${item.minHeight}`
                        }
                        if (item.isLeftFooted) {
                            leftFooted = ' - LF'
                        }

                        if (item.preferredNationalityAreaId) {
                            const position = getPositionCodeById(item.positionId)
                            const area = subState.positions[position].preferredPlayerArea.name
                            
                            nationality = `, ${area}`
                        }
                      
                        if (item.playingStyleId) {
                            const position = getPositionCodeById(item.positionId)
                            const style = subState.positions[position].preferredPlayingStyle.name

                            playingStyle = `, ${style}`
                        }
                        
                        let finalText = `${getPositionCodeById(item.positionId)}${leftFooted} (${positionValueText}${costValue}${age}${height}${nationality}${playingStyle}${item.isAgencyRecommendation ? ', Invited Agents' : ''}${item.isAnonymised ? ',  Anonymised Requirement' : ''})`;
                        positionsText += `${finalText}`;

                        const isLastPosition = Object.keys(positions).length - 1 === index
                        positionsText += isLastPosition ? '.' : ', '
                    });

                    if (request.length > 0) {
                        dispatch(userActivityInsert({
                            Message: `Launched Player Ad: ${positionsText}`,
                            PageName: 'Create Player Ad',
                            PageType: PageType.PlayerAds,
                            ActionType: ActionType.LaunchedPlayerAd,
                        }));

                        // const auth = state.auth
                        // const isAgentsFreeTrialActive = hasAgentFeature(state)
                        // const i = request[request.length - 1]

                        // if (auth.isAgentsVisible) {
                        //     let message = '';
                        //     if (isAgentsFreeTrialActive && !i.isAgencyRecommendation) {
                        //         message = 'Unchecked Agents Invitation'
                        //     } else if (!isAgentsFreeTrialActive && i.isAgencyRecommendation) {
                        //         message = 'Activated Agency Trial'
                        //     } else if (!isAgentsFreeTrialActive && !i.isAgencyRecommendation) {
                        //         message = 'Unchecked Agency Trial activation'
                        //     }

                            // if (message) {
                            //     dispatch(userActivityInsert({
                            //         Message: message,
                            //         PageName: 'Player Ads [Create Player Ad]',
                            //         PageType: PageType.PlayerAds
                            //     }));
                            // }
                        // }
                    }

                    if (window.location.pathname.includes('playerads/my-ads')) {
                        dispatch(PlayerAdsMyAdsController.Actions.loadAllAds(() => { }));
                    }
                    dispatch(PlayerAdsController.Actions.loadCounters());


                    dispatch(
                        stateController.setState({
                            playerSuggestions: suggestions,
                            shortPlayerSuggestions: suggestions.slice(0, 3),
                            adsProcessing: false
                        })
                    )

                    if (request.length == 0) {
                        dispatch(Actions.closeModal())
                    } else {
                        dispatch(Actions.nextStep());
                    }

                } catch (e) {
                    dispatch(stateController.setState({ adsProcessing: false }))
                    console.error(e)
                }

            }
            else {
                dispatch(stateController.setState({ currentPositionIndex: currentPositionIndex + 1 }))

                if (removePrev) {
                    dispatch(this.deleteIndexFromMap(currentPositionCode))
                }
            }
        }
    }

    public static onAdPropertiesChange(position: string, toBuy: boolean, toLoan: boolean, loan?: number, buy?: number, grossSalary?: number, isAgencyRecommended?: boolean) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().playerAdsV2.createPlayerAds.positions;
            let changedPositions = { ...positions };

            let currentEntity = changedPositions[position];

            changedPositions[position] = { ...currentEntity, toBuy: toBuy, toLoan: toLoan, loan: loan, buy: buy, grossSalary: grossSalary, isAgencyRecommended };
            dispatch(stateController.setState({ positions: changedPositions }))
            dispatch(Actions.validateAnonymiseRequirement(position, toBuy, buy, grossSalary))
        }
    }

    public static validateAnonymiseRequirement(position: string, toBuy: boolean, buy?: number, grossSalary?: number) {
        return (dispatch, getState: () => AppState) => {
            if (!toBuy) {
                dispatch(Actions.onAnonymiseRequirementChange(position, false))
            }
                        
            if (!!buy || !!grossSalary){                
                const criteria = Selectors.getPlayerAdAnonymisationCriteria(getState())          
                                
                if ((buy < criteria.transferFeeThreshold.price && grossSalary < criteria.grossSalaryThreshold.price) || 
                    (!buy && grossSalary < criteria.grossSalaryThreshold.price) ||
                    (!grossSalary && buy < criteria.transferFeeThreshold.price)
                ) {
                    dispatch(Actions.onAnonymiseRequirementChange(position, false))
                }
            } 
        }
    }

    public static onLeftFootedChange(position: string, val: boolean) {
        return (dispatch, getState: () => AppState) => {
            const positions = getState().playerAdsV2.createPlayerAds.positions
            const changedPositions = { ...positions }

            const currentEntity = changedPositions[position]

            changedPositions[position] = { ...currentEntity, isLeftFooted: val }
            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }

    public static onAgePropertyChanged(position: string, min: number, max: number) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().playerAdsV2.createPlayerAds.positions;
            let changedPositions = { ...positions };

            let currentEntity = changedPositions[position];

            let upd = (min == ageRule.min && max == ageRule.max) ? null : { min: min, max: max }
            changedPositions[position] = { ...currentEntity, age: upd };

            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }

    public static onHeightPropertyChanged(position: string, val: number) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().playerAdsV2.createPlayerAds.positions;
            let changedPositions = { ...positions };

            let currentEntity = changedPositions[position];

            changedPositions[position] = { ...currentEntity, height: val == heightRule.min ? null : val };

            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }

    public static disposeModalState() {
        return dispatch => {
            dispatch(stateController.setState({ ...defaultState, positions: {} }))
            dispatch(PerformanceActions.refreshCounters());            
        }
    }

    public static declareInterest(playerId: number) {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();
            try {

                const { userId, squadId } = getAuth(state);
                dispatch(stateController.setState({ processingSuggestions: [...state.playerAdsV2.createPlayerAds.processingSuggestions, playerId] }))
                
                await PlayerService.declareInterestSave(playerId, userId, squadId)
                
                const shortPlayerSuggestions = getState().playerAdsV2.createPlayerAds.shortPlayerSuggestions
                
                shortPlayerSuggestions.find(i => i.id === playerId).player.interestWasDeclared = true
                dispatch(stateController.setState({
                    shortPlayerSuggestions: [...shortPlayerSuggestions]
                }))
                
                let playerClubId = shortPlayerSuggestions.find(i => i.id === playerId).player.parentSquadId
                dispatch(userActivityInsert({
                    Message: 'Declared Interest',
                    PageName: 'Player Ads [Player Recommender]',
                    PlayerId: playerId,
                    ClubId: playerClubId,
                    ActionType: ActionType.DeclaredInterest,
                    PageType: PageType.PlayerAds,
                    UserActivityType: UserActivityType.Action
                }));
                
                if (getState().playerAdsV2.createPlayerAds.shortPlayerSuggestions.length == 0) {
                    dispatch(this.closeModal());
                }
            } catch(e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({
                    processingSuggestions: [...getState().playerAdsV2.createPlayerAds.processingSuggestions.filter(x => x != playerId)]
                }))
            }
        }
    }

    public static notInterestedSuggestion(playerId: number) {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();

            try {
                await CustomSignInService.declinePlayer(playerId);
                
                const shortPlayerSuggestions = state.playerAdsV2.createPlayerAds.shortPlayerSuggestions;
                let suggestionsWithout = [...shortPlayerSuggestions.filter(x => x.id != playerId)];
                dispatch(stateController.setState({ shortPlayerSuggestions: [...suggestionsWithout] }))
                
                if (state.playerAdsV2.createPlayerAds.shortPlayerSuggestions.length === 0) {
                    dispatch(this.closeModal());
                }
                
                let playerClubId = shortPlayerSuggestions.find(i => i.id === playerId).player.parentSquadId
                dispatch(userActivityInsert({
                    Message: 'Not Interested',
                    PageName: 'Player Ads [Player Recommender]',
                    PlayerId: playerId,
                    ClubId: playerClubId,
                    PageType: PageType.PlayerAds
                }));
            } catch(e) {
                console.error(e)
            }
        }
    }

    public static suggestionsPlayerShortlistToggle(playerId: number) {
        return async (dispatch, getState: () => AppState) => {

            const suggestions = getState().playerAdsV2.createPlayerAds.playerSuggestions
            let objective = suggestions.filter(i => i.id === playerId)[0]

            let playerClubId = suggestions.find(i => i.id === playerId).player.parentSquadId
            if (objective.player.isInShortList) {
                await PlayerService.deleteFromShortList(playerId);
                dispatch(userActivityInsert({
                    Message: 'Removed from Shortlist',
                    PageName: 'Player Ads [Player Recommender]',
                    PlayerId: playerId,
                    ClubId: playerClubId,
                    PageType: PageType.PlayerAds
                }));
            } else {
                await PlayerService.addToShortList(playerId);
                dispatch(userActivityInsert({
                    Message: 'Added to Shortlist',
                    PageName: 'Player Ads [Player Recommender]',
                    PlayerId: playerId,
                    ClubId: playerClubId,
                    ActionType: ActionType.AddToShortlist,
                    PageType: PageType.PlayerAds
                }));
            }

            suggestions.find(item => item.id === objective.id).player.isInShortList = !objective.player.isInShortList

            dispatch(stateController.setState({ playerSuggestions: [...suggestions] }))
        }
    }

    public static onContinue(item: any) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            dispatch(userActivityInsert({
                Message: `Continue`,
                PageName: 'Create Player Ad',
                PageType: PageType.PlayerAds
            }))

            if (!item) {
                return
            }

            const auth = state.auth
            const isAgentsFreeTrialActive = hasAgentFeature(state)
            const i = item

            if (auth.isAgentsVisible) {
                let message = '';
                if (isAgentsFreeTrialActive && !i.isAgencyRecommended) {
                    message = 'Unchecked Agents Invitation'
                } else if (!isAgentsFreeTrialActive && i.isAgencyRecommended) {
                    message = 'Activated Agency Trial'
                }
                else if (!isAgentsFreeTrialActive && !i.isAgencyRecommended) {
                    message = 'Unchecked Agency Trial activation'
                }

                // if (message) {
                //     dispatch(userActivityInsert({
                //         Message: message,
                //         PageName: 'Player Ads [Create Player Ad]',
                //         PageType: PageType.PlayerAds
                //     }));
                // }
            }
        }
    }

    public static onPreferredPlayerAreaChanged(position: string, area: IdName) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().playerAdsV2.createPlayerAds.positions;
            let changedPositions = { ...positions };

            let currentEntity = changedPositions[position];

            changedPositions[position] = { ...currentEntity, preferredPlayerArea: area };

            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }

    public static onPreferredPlayingStyleChanged(position: string, playingStyle: IdName) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().playerAdsV2.createPlayerAds.positions;
            let changedPositions = { ...positions };

            let currentEntity = changedPositions[position];

            changedPositions[position] = { ...currentEntity, preferredPlayingStyle: playingStyle };

            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }

    public static onAnonymiseRequirementChange(position: string, value: boolean) {
        return (dispatch, getState: () => AppState) => {

            let positions = getState().playerAdsV2.createPlayerAds.positions;
            let changedPositions = { ...positions };

            let currentEntity = changedPositions[position];

            changedPositions[position] = { ...currentEntity, isAnonymiseRequirement: value };

            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }
    
    public static loadPlayerAdInitialInfo() {
        return async (dispatch) => {
            const initialInfo = await PlayerAdService.getPlayerAdInitialInfo();
            
            if (!initialInfo) {
                return null
            }

            dispatch(stateController.setState({ playerAdInitialInfo: initialInfo}))
        }
    }

    public static onFindOutMoreClick() {
        return (dispatch) => {
            dispatch(userActivityInsert({
                Message: `Clicked Playing Style`,
                PageName: 'Create Player Ad',
                PageType: PageType.PlayerAds
            }))
            window.open('https://blog.transferroom.com/transferrooms-playing-styles-explained', '_blank');
        }
    }

    public static onPlayingStyleTooltipHover() {
        return async (dispatch) => {
            dispatch(userActivityInsert({
                Message: `Viewed Playing Style Tooltip`,
                PageName: 'Create Player Ad',
                PageType: PageType.PlayerAds
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.playerAdsV2.createPlayerAds;
    public static getPlayerAdInitialInfo = (state: AppState) => Selectors.getRoot(state).playerAdInitialInfo;
    public static getPlayerAdAnonymisationCriteria = (state: AppState) => Selectors.getPlayerAdInitialInfo(state).anonymisationCriteria;
    public static getCurrentPositionIndex = (state: AppState) => Selectors.getRoot(state).currentPositionIndex;

    public static getCurrentPositionId = (state: AppState) => {
        const positionIndex = Selectors.getCurrentPositionIndex(state)
        const positionCode = Selectors.getRoot(state).originalPositions[positionIndex]
        
        return getPositionIdByCode(positionCode)

    }
    public static getPreferredPlayerAreasList = (state: AppState) => {
        const info = Selectors.getPlayerAdInitialInfo(state)
        if (!info) {
            return null
        }
        const areas = getPreferredPlayerAreas(info)
    
        return areas
    };
    
    public static getPreferredPlayingStylesList = (state: AppState) => {
        const info = Selectors.getPlayerAdInitialInfo(state)
        const positionId = Selectors.getCurrentPositionId(state)

        if (!info) {
            return null
        }

        const styles = getPreferredPlayingStyles(info, positionId)

        return styles
    };

    public static getPlayerAds = (state: AppState) => Selectors.getRoot(state).playerAds
    public static getPLayerAdsPositions = (state: AppState) => {
        const subState = Selectors.getPlayerAds(state)
        let playerAdsPositions = subState.map(x => x.ad.positionCode);
        let uniquePlayerAdsPositions = playerAdsPositions.filter((element, index) => {
            return playerAdsPositions.indexOf(element) === index;
        });

        return uniquePlayerAdsPositions
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CreatePlayerAdState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};


