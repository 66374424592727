import {
    GENERAL_POSITIONS_WITHOUT_PLAYER_ADS_FETCH,
    PLAYER_AD_UPDATE_EVENT,
} from 'store/actionTypes';
import createApiThunk from 'services/createApiThunk';
import { getAuthAspId } from 'store/auth/authReducer';

export const getGeneralPositionsWithoutAds = () => (dispatch, getState) => {
    const aspId = getAuthAspId(getState());
    const request = {
        type: GENERAL_POSITIONS_WITHOUT_PLAYER_ADS_FETCH,
        method: 'get',
        url: `/PlayerAds/GetGeneralPositionsWithoutAds/${aspId}`,
        success: GENERAL_POSITIONS_WITHOUT_PLAYER_ADS_FETCH.SUCCESS,
    };
    return dispatch(createApiThunk(request));
};

export const playerAdUpdateEvent = (payload) => async (dispatch, getState) => {
    const aspId = getAuthAspId(getState());

    let request = playerRequestAdUpdateAuthEvent({ payload, aspId });

    return await dispatch(createApiThunk(request));
};

const playerRequestAdUpdateAuthEvent = ({ payload: ad, aspId }) => {
    const request = {
        type: PLAYER_AD_UPDATE_EVENT,
        method: 'post',
        url: `PlayerAds/UpdatePlayerAd/${aspId}`,
        payload: ad.id,
        ad,
    };
    return request;
};

