import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CotactUsModal from 'components/anonymous-layout/contact-us.component'
import LogoWhite from '../../media/images/transferroom-logo-white@2x.png';
import Logo from '../../media/images/transferroom-logo@2x.png';
import './anonymous-layout.scss';
import { hubspotPaths } from 'routes/paths'


class HeaderComponent extends Component {


    constructor(props) {
        super(props);
        this.stickyHeader = null;
        this.setStickyHeaderRef = element => {
            this.stickyHeader = element;
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (!this.stickyHeader) {
            return;
        }

        this.stickyHeader.setAttribute(
            'class',
            `headerFixed`,
        );
    };

    render() {
        return (
            <>
                <header>
                    <Link className="tr-logo" to="/">
                        <img src={LogoWhite} alt="logo white" />
                    </Link>
                    <ul>
                        <li>
                            <div
                                className="btn tr-btn tr-btn--subtle"
                                onClick={() => {
                                    window.open('https://blog.transferroom.com/', '_self')
                                }}
                            >
                                Blog
                            </div>
                        </li>
                        <li>
                            <Link
                                className="btn tr-btn tr-btn--subtle"
                                to="/login"
                                data-test-id="landing-page-login-link"
                            >
                                Login
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="btn tr-btn tr-btn--subtle"
                                to="/registration"
                            >
                                Register
                            </Link>
                        </li>
                    </ul>
                </header>
                <header className="headerFixed" ref={this.setStickyHeaderRef}>
                    <Link className="tr-logo" to="/">
                        <img src={Logo} alt="logo" />
                    </Link>
                    {/* <ul className="headerFixed--nav">
                        <li>
                            <Link to="/">About</Link>
                        </li>
                        <li>
                            <Link to="/">What clubs say</Link>
                        </li>
                        <li>
                            <Link to="/">Events</Link>
                        </li>
                    </ul> */}
                    <ul>

                        <li>
                            <div
                                className="btn tr-btn tr-btn--secondary"
                                onClick={() => {
                                    window.open('https://blog.transferroom.com/', '_self')
                                }}
                            >
                                Blog
                            </div>
                        </li>

                        <li>
                            <Link
                                className="btn tr-btn tr-btn--secondary"
                                to="/login"
                            >
                                Login
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="btn tr-btn tr-btn--primary"
                                to="/registration"
                            >
                                Register
                            </Link>
                        </li>
                    </ul>
                </header>
            </>
        );
    }
}

const Header = HeaderComponent

const HeroContainer = ({ children, ...otherProps }) => {
    return (
        <div className="hero-container" {...otherProps}>
            {children}
        </div>
    );
};

const Container = ({ children, ...otherProps }) => {
    return (
        <div className="container" {...otherProps}>
            {children}
        </div>
    );
};

class Footer extends React.Component {

    state = { contact: false };

    render() {
        const links = [
            {
                title: 'Terms of Use',
                href: hubspotPaths.termsOfUse,
            },
            {
                title: 'Privacy Policy',
                href: hubspotPaths.privacyPolicy,
            },
            {
                title: 'Cookies',
                href: hubspotPaths.cookiePolicy,
            },
        ];

        return (
            <footer>
                {
                    this.state.contact
                    &&
                    <CotactUsModal onClose={() => { this.setState({ contact: false }) }} />
                }
                <div className="container text-center">
                    {links.map((link, index) => (
                        <Fragment key={index}>
                            <a
                                className="common-links"
                                href={link.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {link.title}
                            </a>
                            {index < links.length - 1 && '|'}
                        </Fragment>
                    ))}
                    <>
                        {'|'}
                        <button className="common-links btn btn-link contact-us-link"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ contact: true })
                            }}
                        >
                            Contact Us
                        </button>
                    </>
                </div>
                <div className="container text-center">
                    <Fragment>
                        <a
                            className="common-links"
                            href={hubspotPaths.antiSlaveryPolicy}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {'Anti-slavery and Human Trafficking Policy'}
                        </a>
                        {/* {index < links.length - 1 && '|'} */}
                    </Fragment>
                </div>
                <div className="container text-center">
                    <p style={{ paddingTop: '5px' }}>© Copyright Transferroom Limited {new Date().getFullYear()}</p>
                </div>
            </footer>

        );
    }
};

const AnonymousLayout = ({ className, heroContainer, container }) => {
    return (
        <div className={`container-fluid home-page ${className || ''}`.trim()}>
            <Header />
            <HeroContainer>{heroContainer}</HeroContainer>
            <Container>{container}</Container>
            <Footer />
        </div>
    );
};

AnonymousLayout.propTypes = {
    heroContainer: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    container: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default AnonymousLayout;
