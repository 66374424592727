import React from 'react';
import Slick from 'react-slick';

import TestimonyMonchi from '../../media/images/landing/testimony-monchi@2x.jpg';
import TestimonyVictororta from '../../media/images/landing/testimony-victororta@2x.jpg';
import TestimonyKevinThelwell from '../../media/images/landing/testimony-Kevin-Thelwell.jpg';
import TestimonyFergalHarkin from '../../media/images/landing/testimony-Fergal_Harkin@2x.jpg';
// import TestimonyTomaszZahorski from '../../media/images/landing/testimony-Tomasz_Zahorski@2x.jpg';
import TestimonyClausSteinlein from '../../media/images/landing/testimony-Claus_Steinlein@2x.jpg';
import TestimonyAlenPetrovic from '../../media/images/landing/testimony-AlenPetrovic@2x.jpg';
// import TestimonyTommyWiddrington from '../../media/images/landing/testimony-TommyWiddrington@2x.jpg';
import TestimonyGraemeMathie from '../../media/images/landing/testimony-GraemeMathie@2x.jpg';
// import TestimonyMorganBoullier from '../../media/images/landing/testimony-MorganBoullier@2x.jpg';
import TestimonySimonMesfin from '../../media/images/landing/testimony-SimonMesfin@2x.jpg';
import TestimonyDavidWeir from '../../media/images/landing/testimony-DavidWeir@2x.jpg';
import TestimonyJesperJansson from '../../media/images/landing/testimony-JesperJansson@2x.jpg';
import TestimonyIngeAndreOlsen from '../../media/images/landing/testimony-IngeAndreOlsen@2x.jpg';
import TestimonyGretarSteinsson from '../../media/images/landing/testimony-GretarSteinsson@2x.jpg';
// import TestimonyEbbeSand from '../../media/images/landing/testimony-EbbeSand@2x.jpg';
// import TestimonyPawelZurakowski from '../../media/images/landing/testimony-PawelZurakowski.jpg';
// import TestimonyAlonHarazi from '../../media/images/landing/testimony-AlonHarazi.jpg';
// import TestimonyTomVandenAbbeele from '../../media/images/landing/testimony-TomVandenAbbeele.jpg';
import TestimonyLesFerdinand from '../../media/images/landing/testimony-LesFerdinand.jpg';
import TestimonyDanAshworth from '../../media/images/landing/testimony-DanAshworth.png';
import TestimonySaracho from '../../media/images/landing/testimony-Saracho.jpg';

import './testimony-slider.scss';

const TestimonySlider = () => {

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            type="button"
            data-role="none"
            className="slick-arrow slick-prev"
        >
            <i className="fa fa-chevron-left" />
        </button>
    )
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button 
            {...props}
            type="button"
            data-role="none"
            className="slick-arrow slick-next"
        >
            <i className="fa fa-chevron-right" />
        </button>
    )

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 15000,
    };

    return (
        <Slick {...settings} className="testimonySlider slider">

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyVictororta} alt="testimony-victororta" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        The financial controller might say, "Oh another
                        software tool on our expense list". But I have saved a lot
                        of money this summer making 5 transfers. I have already
                        earned a lot of money using TransferRoom.
                    </blockquote>
                    <h4>
                        Victor Orta, Director of Football, Leeds United F.C.
                    </h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyDanAshworth} alt="Dan Ashworth" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        It’s a great way of improving your contact base and being really time efficient.
                    </blockquote>
                    <h4>
                        Dan Ashworth, Technical Director, Brighton & Hove Albion
                    </h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyKevinThelwell} alt="Kevin Thelwell" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        TransferRoom has changed my way of working and made the
                        transfer market look a lot different today.
                    </blockquote>
                    <h4>
                        Kevin Thelwell, Head of Sport, New York Red Bulls
                    </h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyLesFerdinand} alt="Les Ferdinand" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        There is nobody in between claiming one person is saying this and another person is saying that.
                    </blockquote>
                    <h4>
                        Les Ferdinand, Director of Football, Queens Park Rangers
                    </h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyMonchi} alt="testimony-monchi" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        TransferRoom helps reduce costs and connect directly
                        with other clubs. TransferRoom simplifies processes as
                        it creates transparency and thereby saves us time.
                    </blockquote>
                    <h4>
                        Ramon “Monchi” Rodriguez, Sporting Director, Sevilla FC
                    </h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyFergalHarkin} alt="testimony-Fergal_Harkin" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        TransferRoom helps reduce the time needed to make
                        connection with clubs. By being precise about what clubs
                        need, this enables us to speak about actual players.
                    </blockquote>
                    <h4>
                        Fergal Harkin, Football Partnerships Manager, Manchester City
                    </h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyClausSteinlein} alt="Claus Steinlein" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        TransferRoom has become a crucial part of how we connect
                        with the transfer market when buying and selling players.
                    </blockquote>
                    <h4>Claus Steinlein, CEO, FC Midtjylland</h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonySaracho} alt="Eduardo Saracho" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        TransferRoom is the perfect environment to develop a network of decision makers.
                    </blockquote>
                    <h4>
                        Eduardo Saracho, Director of Football, Club Puebla
                    </h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyDavidWeir} alt="David Weir" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        I use TransferRoom most days to look at what clubs around
                        Europe and beyond are looking for positionally, then trying
                        to match up our players and get in contact with those clubs
                        to see if it might be something interesting for both parties.
                    </blockquote>
                    <h4>David Weir, Football Partnerships Manager, Brighton & Hove Albion</h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyGretarSteinsson} alt="Gretar Steinsson" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        TransferRoom has become a big part of what I do. By letting
                        us communicate directly with other clubs it removes all the
                        ‘maybes’ and keeps it simple to a ‘YES’ or a ‘NO’.
                    </blockquote>
                    <h4>Gretar Steinsson, Chief Scout, Everton F.C.</h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyAlenPetrovic} alt="Alen Petrovic" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        TransferRoom is one of the greatest inventions in football.
                        Before you had to call so many numbers to check the availability
                        of a player, his salary and so forth. Now, everything you need
                        is on one platform.
                    </blockquote>
                    <h4>Alen Petrovic, Former Sporting Director, NK Osijek</h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyGraemeMathie} alt="Graeme Mathie" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        The transfer market can be challenging, because there is so much
                        noise. TransferRoom provides clarity by letting us know what players
                        are actually available and facilitate a direct contact to the decision
                        makers of other clubs.
                    </blockquote>
                    <h4>Graeme Mathie, Sporting Director, Hibernian FC</h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonySimonMesfin} alt="Simon Mesfin" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        TransferRoom offers the opportunity to connect with decision
                        makers across the world in a split second. You can log into
                        the system with the app or with your PC, and immediately you
                        are directly in touch with the whole football world.
                    </blockquote>
                    <h4>Simon Mesfin, Sporting Director, Lillestrom</h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyJesperJansson} alt="Jesper Jansson" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        I have been longing for a tool like TransferRoom that allows
                        to be connected directly with other clubs online and offline.
                    </blockquote>
                    <h4>Jesper Jansson, Sporting Director, Hammarby Fotboll</h4>
                </div>
            </div>

            <div className="trTestimony">
                <div className="trTestimony-thumb">
                    <img src={TestimonyIngeAndreOlsen} alt="Inge Andre Olsen" />
                </div>
                <div className="trTestimony-content">
                    <blockquote>
                        Before TransferRoom you had to use agents to get in touch
                        with the decision makers of other clubs and that would
                        increase the costs. TransferRoom has made it possible
                        to get directly in touch with the right people at other
                        clubs without relying on agents.
                    </blockquote>
                    <h4>
                        Inge Andre Olsen, Sporting Director, Aalborg BK
                    </h4>
                </div>
            </div>

        </Slick>
    );
};

export default TestimonySlider;
