export const translationsEs = {
    menu: {
        squad: 'Plantilla',
        messaging: 'Mensajes',
        search: 'Buscar',
        shortList: 'Preselección',
        playerAds: 'Anuncios de jugadores',
        clubAds: 'Anuncios de clubes',
        pitch: 'Pitch',
        friendlies: 'Amistosos',
        marketplace: 'Mercado',
        events: 'Eventos',
        plusPitch: 'Plus Pitch',
        editProfile: 'Ajustes',
        financialInsights: 'Financial Insights',
        loanInsights: 'Loan Tracker',
        logOut: 'Cerrar sesión',
        home: 'Home'
    },
    errorMessages: {
        pleaseEnterValue: 'Por favor ingrese el valor!',
        minimumRequiredValue: 'Minimum required value is ',
    },
    availabilityEditor: {
        forSale: 'En venta',
        forLoan: 'Para cesión',
        yes: 'Sí',
        no: 'No',
        priceUponRequest: 'Precio previa solicitud',
        askingPrice: 'Precio de venta',
        sellOn: 'Reventa',
        monthlyLoanFee: 'Cuota mensual de cesión',
        saveChanges: 'Guardar cambios',
        insertAmount: 'Indicar cantidad',
        mustBeRange: 'Debe estar entre 0 y 100',
    },
    clubAds: {
        dropDown: {
            player: 'Jugador',
            selectPlayer: 'seleccionar jugador',
            playerDescription: 'Descripción del jugador',
            min: 'Min.',
            cm: 'cm',
            age: 'Edad',
            monthlyLoanFee: 'Monthly loan fee incl. gross salary',
            transferFee: 'Cuota de traspaso',
            pitchPlayerNow: 'Ofertar jugador ahora',
        },
        filterComponent: {
            showResults: 'Mostrar resultados',
            toBuy: 'Para comprar',
            toLoan: 'Para ceder',
        },
        filterSection: {
            transferType: 'Tipo de traspaso',
            transferFee: 'Transfer fee',
            monthlyLoanFee: 'Loan budget or gross salary',
            position: 'Posición',
            goalkeeper: 'Portero',
            leftBack: 'Lateral izquierdo',
            rightBack: 'Lateral derecho',
            centreBack: 'Central',
            defensiveMidfielder: 'Centrocampista defensivo',
            centralMidfielder: 'Centrocampista',
            attackingMidfielder: 'Centrocampista de ataque',
            winger: 'Extremo',
            forward: 'Delantero',
            league: 'Liga',
            status: 'Estado',
            interest: 'Interés',
            dismissed: 'Descartado',
            others: 'Otros',
            saved: 'Guardados',
        },
        gridHeader: {
            searchPosition:
                'Buscar nombre de jugador, posición o nombre del club…',
            searchPlayer:
                'Realiza una búsqueda por posición del jugador, club o nombre de la liga.',
            newFeatureTrial:
                'Nueva caracteristica! Estás en la versión de prueba.',
            newFeature: 'Nueva caracteristica!',
            youAreOnTrial: 'Estás en la versión de prueba.',
        },
        pitchInsights: {
            pitchInsightsTab: 'PITCH INSIGHTS',
            playersPitched: 'Players pitched',
            pitchesSent: 'Pitches sent',
            clubsReached: 'Clubs reached',
            last6Months: 'Last 6 months',
            lastNMonths: 'Last N months',
            lastMonth: 'Last month',
            pitchStatus: {
                awaitingReply: 'Awaiting Reply',
                declaredInterest: 'Declared interest',
                interest: 'Interest',
                saved: 'Saved',
                opened: 'Opened',
                dismissed: 'Dismissed',
                removed: 'Removed',
            },
            upgradePanel: {
                defaultText: 'UPGRADE TO GET ACCESS TO PITCH INSIGHTS WITH EXCLUSIVE PITCH TOOLS AND DATA INSIGHTS',
                recentActivityText: 'SHOWING ANONYMISED DATA - UPGRADE TO GET FULL VERSION WITH CLUB NAMES AND MORE',
                moreButton: 'More ...'
            },
            sampleData: {
                sampleDataOnly: 'SAMPLE DATA ONLY',
                upgradeToActivate: 'UPGRADE TO ACTIVATE',
            },
            successRate: {
                panelTitle: 'Success Rate',
                averageSuccessRate: 'AVERAGE SUCCESS RATE'
            },
            responsesChart: {
                panelTitle: 'Responses on most pitched players',
                chartLegend: {
                    declaredInterest: 'DECLARED INTEREST/SAVED PITCH',
                    dismissed: 'DISMISSED',
                    awaitingReply: 'AWAITING REPLY'
                },
                clickForDetail: 'Click for details'
            },
            recentActivity: {
                panelTitle: 'Recent Activity',
                player: 'Player:',
                showOnlyItems: 'Show only items where I can test interest',
                backToGraph: 'Back to graph',
                table: {
                    time: 'Time',
                    activity: 'Activity',
                    club: 'Club',
                    description: 'Description',
                    testInterestButton: 'Test interest',
                    pitched: 'Pitched',
                    hidden: '[HIDDEN]',
                    upgradeToTestInterest: 'Upgrade to test interest',
                    testInterestCanBeTested: 'Interest can be tested',
                    testInterestWasTested: 'Interest was tested',
                    more: 'MORE',
                    showFirst5: 'SHOW FIRST 5' 
                },
                description: {
                    club: 'A club',
                    savedPitchOf: 'saved pitch of',
                    declaredInterestIn: 'declared interest in',
                    dismissedPitchOf: 'dismissed pitch of',
                    openedPitchOf: 'opened pitch of',
                    pitchOf: 'Pitch of',
                    deliveredTo: 'delivered to a',
                    to: 'to a',
                    pitchWasRemoved: 'removed pitch of',
                    undefinedStatus: 'Undefined status'
                }
            },
            pitchLog: {
                panelTitle: 'Pitch log',
                searchPlaceholder: 'Filter by player or club name...',
                time: 'Time',
                hidden: '[HIDDEN]',
                player: 'Player',
                pitchedTo: 'Pitched To',
                transferType: 'Transfer Type',
                price: 'Price',
                more: 'MORE',
                showFirst5: 'SHOW FIRST 5',
                maxGrossSalary: 'MAX GROSS SALARY',
            },
        },
        tabNavigation: {
            clubAds: 'Anuncios de clubes',
            myPitches: 'Mis ofertas',
            pitchInsights: 'Pitch Insights'
        },
        myPitchesGrid: {
            hidden: 'OCULTOS',
            date: 'Fecha',
            playerName: 'Nombre del jugador',
            club: 'Club',
            league: 'Liga',
            position: 'Posición',
            transferType: 'Tipo de traspaso',
            price: 'Precio',
            status: 'Estado',
            noResult:
                'No hay resultados. No ha enviado lanzamientos de ningún jugador.',
        },
        grid: {
            hidden: 'OCULTOS',
            posted: 'Publicado',
            position: 'Posición',
            club: 'Club',
            league: 'Liga',
            country: 'País',
            transferType: 'Tipo de traspaso',
            budgetUpTo: 'LOAN BUDGET OR GROSS SALARY',
            yourPitches: 'Tus ofertas',
        },
        
    },
    competitionList: {
        editLeaguesList: 'Editar lista de ligas',
        createLeaguesList: 'Crear lista de ligas',
        clear: 'Borrar',
        selected: 'Seleccionado',
        selectLeagues: 'Seleccionar ligas de la lista de la derecha',
        searchCountry: 'Buscar país o competición...',
        deleteList: 'Borrar lista',
        cancel: 'Cancelar',
        saveChanges: 'Guardar cambios',
        myCompetitions: 'Mis competiciones',
        enterListName: 'Indica el nombre de la lista.',
        enterUniqueName:
            'Indica un nombre de lista único. La lista de ligas llamada',
        alreadyExists: 'ya existe',
    },
    declareInterestButton: {
        declaredInterest: 'Interés mostrado',
        declareInterest: 'Declarar interés',
    },
    editProfile: {
        menu: {
            profile: 'Perfil',
            users: 'Usuarios',
            documents: 'Documentos',
        },
        profile: {
            name: 'Nombre',
            lastName: 'Apellido',
            email: 'Correo electrónico',
            club: 'Club',
            agency: 'Agency',
            jobTitle: 'Cargo',
            phone: 'Teléfono',
            changePassword: 'Cambiar contraseña',
            reset: 'Restaurar',
            saveChanges: 'Guardar cambios',
            insertUserName: 'Indicar nombre y apellido de usuario',
            interfaceLanguage: 'Idioma del interfaz',
            restrictedAccess: 'Acceso restringido',
            friendlContentOnly: 'Solo contenido amigable',
            insertUserFirstName: 'Indicar nombre de usuario',
            insertUserLastName: 'Indicar apellido de usuario',
        },
        users: {
            user: 'Usuario',
            jobTitle: 'Cargo',
            access: 'Access',
            fullAccess: 'Full',
            friendlyAccess: 'Friendly',
            status: 'Estado',
            addUser: 'Añadir usuario...',
        },
        documents: {
            termsOfService: 'Condiciones del servicio',
            privacyPolicy: 'Política de privacidad',
            dataProcessingTerms: 'Condiciones de tratamiento de datos',
            antiSlaveryPolicy:
                'Política contra la esclavitud y la trata de personas',
        },
        createUser: {
            insertUserName: 'Indicar nombre y apellido de usuario',
            insertUserEmail: 'Indicar correo electrónico de usuario',
            insertUserPhone: 'Indicar teléfono de usuario',
            selectUserJobTitle: 'Seleccionar cargo de usuario',
            editUser: 'Editar usuario',
            createUser: 'Crear usuario',
            updateUser: 'Actualizar usuario',
            cancel: 'Cancelar',
            phone: 'Teléfono',
            email: 'Correo electrónico',
            jobTitle: 'Cargo',
            name: 'Nombre',
            lastName: 'Apellido',
            surname: 'Surname',
            restrictedAccess: 'Acceso restringido',
            friendlContentOnly: 'Solo contenido amigable',
        },
        emailPreferences: {
            unreadPlayerPitches:
                'Notificación de ofertas de jugadores no leídas',
            declareInterest: 'Notificación de muestra de interés',
            saveChanges: 'Guardar cambios',
            newSuperPitch: 'Notificación de un nueva superoferta',
            adShouldBeUpdated:
                'Notificación de que un anuncio debe ser actualizado',
            unreadMessages: 'Notificación de mensajes no leídos',
            newMessage: 'New message',
            declareInterestForFriendlyMatch:
                'Declara interés en partido amistoso',
            invitationToFriendlyMatch: 'Invitación de partido amistoso',
            unreadInvitations: 'Invitaciones no leídos',

            friendlyRelated: 'Friendly related',
            inboundPlayers: 'Inbound players',
            outboundPlayers: 'Outbound players',
            messages: 'Messages',
            generalEmails: 'General emails',

            friendlyProposals: 'Friendly proposals',
            updateOldFriendlyAds: 'Update old friendly ads',
            playerProposals: 'Player proposals',
            shortlistPlayerStatusChange: 'Shortlist player status change',
            newPlayerPitch: 'New player pitch',
            superPitch: 'New super pitch',
            clubAdProposal: 'Club Ad proposal',
            featureUpdates: 'Feature Updates',
            eventInformation: 'Event Information',
            blogPromotion: 'Blog Promotion',
            recommendedEmailsOnly: 'Recommended Emails Only',
        },
        password: {
            insertPassword: 'Indica una contraseña',
            insertNewPassword: 'Indica una nueva contraseña',
            confirmNewPassword: 'Confirma la nueva contraseña',
            passwordsNotEqual: 'Las contraseñas no coinciden.',
            changePassword: 'Cambiar contraseña',
            currentPassword: 'Contraseña actual',
            newPassword: 'Nueva contraseña',
            cancel: 'Cancelar',
            setNewPassword: 'Establecer nueva contraseña',
            passwordIsInvalid: 'La contraseña no es válida',
        },
        onUpdatePassword: {
            success: 'La contraseña se ha cambiado correctamente',
            failed:
                'No se ha podido cambiar la contraseña. Introduce la contraseña antigua correcta.',
        },
    },
    eventPopupBanner: {
        register: 'Registrar',
    },
    events: {
        registrationCompleted: 'Inscripción completada',
        registerNow: 'Inscríbete ya',
        preRegisterNow: 'Pre-registrarse ahora!',
        confirmedAttendees: 'Participantes confirmados',
        pastEvents: 'EVENTOS ANTERIORES',
        downloadEca: 'Descargar ECA presentación',
        summit: 'Cumbre',
        summitName: 'Día del trato',
        summitDate: '16 de julio',
        summitPlace: 'Stamford Bridge, Londres',
        summitYear: 'Cumbre de 2019',
        summitText:
            'La única conferencia sobre fútbol exclusiva para los responsables de la toma de decisiones. Solo por invitación.',
        summitStartDate: '20 de marzo',
        whyAttend: '¿Por qué asistir?',
        whyAttendText:
            'El TransferRoom Deal Day es una oportunidad única para entablar relación con los responsables de la toma de decisiones de los clubes de todo el mundo y para discutir traspasos específicos sin la participación de intermediarios.',
        duringDay: 'Durante un día completo en',
        youWillGet: 'obtendrás:',
        oneToOne: 'Al menos 15 reuniones individuales con otros clubes',
        accessToClubs:
            'Acceso a clubes y jugadores en mercados a los que previamente tenías un acceso restringido',
        marketInformation:
            'Información de mercado sobre qué jugadores están disponibles para venta y cesión en la próxima temporada de fichajes.',
        speaker: 'Interlocutor',
        speakerPosition: 'Propietario, FC Nordsjælland',
        speakerDescription:
            'El FC Nordsjælland ha vivido una de las historias más impresionantes sobre el desarrollo de talentos en el mundo del fútbol.',
        speakerText:
            'Durante el TransferRoom Deal Day, el propietario y presidente del FC Nordsjælland, Tom Vernon, reflexionará sobre la estrategia de talentos del club y sobre su visión para cambiar vidas y formar líderes a través del fútbol.',
        agenda: 'Agenda',
        registration: 'Registro',
        presentation: 'Presentación',
        clubToClub: 'Reuniones entre clubes',
        lunchNetworking: 'Almuerzo y networking',
        drinksNetworking: 'Bebidas y networking',
        closing: 'Clausura',
        photos: 'Fotos',
        whenAndWhere: {
            whenAndWhere: 'Cuándo y dónde',
            date: '16 de julio',
            place: 'Stamford Bridge, Londres',
        },
        participants: 'Participantes',
        countries: 'Países',
        leagues: 'Ligas',
        day: 'Día',
        days: 'Días',
        hour: 'Hora',
        hours: 'Horas',
        minute: 'Minuto',
        minutes: 'Minutos',
        second: 'Segundo',
        seconds: 'Segundos',
        soldOut: 'Agotado'
    },
    expiredAds: {
        tokenIsExpired: 'El token ha caducado.',
        please: 'Por favor',
        login: 'inicia sesión',
        toViewExpiredAds: 'para ver los anuncios caducados.',
        gettingOld: 'Uno o varios anuncios llevan demasiado tiempo activos:',
        considerUpdating: 'Actualiza o elimina tus anuncios antiguos',
        upToDate: 'Todos tus anuncios están actualizados.',
        epic: {
            successfully: 'Un anuncio ha sido actualizado correctamente',
            removedSuccesfully:
                '¡1 de tus anuncios ha sido eliminado correctamente!',
        },
    },
    financialInsights: {
        spendingPower: {
            chart: {
                highest: "HIGHEST",
                lowest: "LOWEST",
                typical: "TYPICAL",
                avarage: "AVERAGE",
            },
            title: "SPENDING POWER",
            tabTransfers: "TRANSFERS",
            tabLoans: "LOANS"
        },
        sampleData: {
            sampleDataOnly: "SAMPLE DATA ONLY",
            upgradeToActivate: "UPGRADE TO ACTIVATE"
        },
        upgradePanel: {
            moreButton: "MORE ..."
        },
        searchBar: {
            country: 'Country:',
            league: 'League:',
            club: 'Club:',
            currency: 'CURRENCY'
        },
        totalClubPlayerValue: 'TOTAL CLUB PLAYER VALUE',
        clubValueRank: 'CLUB VALUE RANK',
        of: 'OF',
        inLeague: 'IN LEAGUE',
        currentSquadSize: 'CURRENT SQUAD SIZE',
        averagePlayerValuation: 'AVERAGE PLAYER VALUATION',
        averagePlayerAge: 'AVERAGE PLAYER AGE',
        season: 'Season',
        lastThreeSeasons: 'Last three seasons',
        transferOverview: {
            panelTitle: 'TRANSFERS OVERVIEW LAST 6 WINDOWS',
            transferIn: 'Transfer In',
            transfersIn: 'Transfers In',
            transferOut: 'Transfer Out',
            transfersOut: 'Transfers Out',
            balance: 'Balance',
            summer: 'SUMMER',
            winter: 'WINTER'
        },
        biggestTransfers: {
            panelTitle: 'BIGGEST TRANSFERS AND MOST VALUABLE PLAYERS',
            panelTitleShort: 'BIGGEST TRANSFERS',
            top: 'TOP',
            biggestTransfersIn: 'BIGGEST TRANSFERS IN',
            biggestTransfersOut: 'BIGGEST TRANSFERS OUT',
            theHighestTransferIn: 'THE HIGHEST TRANSFER IN',
            theHighestTransferOut: 'THE HIGHEST TRANSFER OUT',
            noTransfersOut: 'NO TRANSFERS OUT',
            noTransfersIn: 'NO TRANSFERS IN',
            noIncomingTransfers: 'No incoming transfers this season',
            noOutgoingTransfers: 'No outgoing transfers this season',
        },
        playerValuations: {
            panelTitle: '21st CLUB - PLAYER VALUATIONS',
            table: {
                player: 'Player',
                pos: 'pos',
                age: 'Age',
                contractExpiry: 'Contract Expiry',
                value: 'Value',
                percentOfClubValue: 'Percent of Club Value'
            },
            more: 'MORE',
            showFirst5: 'SHOW FIRST 5'
        },
        chartDonut: {
            freeTransfers: 'FREE TRANSFERS',
            loans: 'LOANS',
            youthPlayers: 'YOUTH PLAYERS',
            transfers: 'TRANSFERS',
        },
        incomingPlayers: {
            panelTitle: 'INCOMING PLAYERS',
            tables: {
                top: 'TOP',
                importCountries: 'IMPORT COUNTRIES',
                topImportCountry: 'TOP IMPORT COUNTRY',
                importLeagues: 'IMPORT LEAGUES',
                topImportLeague: 'TOP IMPORT LEAGUE',
                noTransfers: 'NO TRANSFERS',
                noData: 'No monetary transfers this season',
                country: 'COUNTRY',
                league: 'LEAGUE',
                total: 'TOTAL',
                deals: 'DEALS',
                average: 'AVERAGE'
            }
        },
        outgoingPlayers: {
            panelTitle: 'OUTGOING PLAYERS',
            tables: {
                top: 'TOP',
                exportCountries: 'EXPORT COUNTRIES',
                topExportCountry: 'TOP EXPORT COUNTRY',
                exportLeagues: 'EXPORT LEAGUES',
                topExportLeague: 'TOP EXPORT LEAGUE',
                noTransfers: 'NO TRANSFERS',
                noData: 'No monetary transfers this season',
                country: 'COUNTRY',
                league: 'LEAGUE',
                total: 'TOTAL',
                deals: 'DEALS',
                average: 'AVERAGE'
            }
        }
    },
    friendlyMatches: {
        filter: {
            squad: 'Plantilla',
            date: 'Fecha',
            venue: 'Sede',
            venueCountries: 'Sede Países',
            setDate: 'Fecha...',
            to: 'A',
            from: 'Desde',
            gsnPotential: 'GSN Potencial',
            
            minutesPlayed: 'Minutes Played'
        },
        activity: {
            friendly: 'Encuentros amistoso',
            friendlies: 'Encuentros amistosos',
            open: 'Abrir',
            ad: 'Anuncio',
            ads: 'Anuncios',
            my: 'Mi',
            reFriendlyInvitation: 'RE: Friendly invitation',
        },
        attention: {
            areStillRelevant: '¿Tus anuncios siguen siendo relevantes?',
            getSureUpToDate:
                'Revisa tus anuncios y asegúrate de que están actualizados.',
            reviewMyAds: 'Revisa mis anuncios',
            myAdsAreOk: 'Mis anuncios son correctos',
        },
        alert: {
            considerUpdating: 'Actualiza o elimina tus anuncios antiguos',
            gettingOld:
                'Uno o varios anuncios llevan demasiado tiempo activos:',
        },
        main: {
            na: 'N/A',
            showMore: 'Mostrar más...',
            youHaveReplied: 'has contestado:',
            declareInterest: '«mostrar interés»',
            notAvailable: 'NO DISPONIBLE',
            notAvailableWithQuotas: '«NO DISPONIBLE»',
            declareInterestWithoutQuotas: 'interés mostrado',
            awaitingReply: 'Esperando respuesta...',
            didNotReply: 'No contestó',
            cancel: 'Cancelar',
            expiredAds: 'la invitación ha caducado',
            removed: 'Eliminado',
            updated: 'ACTUALIZADO',
            expired: 'CADUCADO',
            venue: 'Sede',
            homeAway: 'En casa/fuera de casa',
            home: 'En casa',
            countryOfVenue: 'País de la sede',
            interest: 'Interés',
            interests: 'Intereses',
            declared: 'Mostrar',
            message: 'mensaje',
            messageWithQuotas: '«nuevo mensaje»',
            messages: 'mensajes',
            sent: 'Enviado un',
            sentBeforePlural: 'Enviado un',
            available: 'disponible',
            not: 'No',
            newMessage: 'Nuevo mensaje',
            sentMessage: 'ENVIADO UN MENSAJE',
            updateAd: 'Actualizar anuncio',
            remove: 'Eliminar',
            away: 'Fuera de casa',
            keepUpToDate: 'Asegúrate de mantener tus anuncios actualizados.',
            yesStillLooking: 'Sí, todavía estoy buscando',
            stillLooking: '¿Todavía estás buscando un oponente para',
            invitedYou: 'te ha invitado a un partido amistoso.',
            inviteAdditionalClubs: 'INVITA CLUBES ADICIONALES',
        },
        editor: {
            firstTeam: 'Primer equipo',
            home: 'En casa',
            away: 'Fuera de casa',
            createFriendlyAd: 'Crear un anuncio de encuentro amistoso',
            venue: 'Sede',
            countryOfVenue: 'País de la sede',
            cancel: 'Cancelar',
            launchAd: 'Publicar anuncio',
            chooseDates: 'Elige las fechas...',
            date: 'Fecha',
        },
        invitations: {
            alreadyInvited: 'Ya invitado',
            inviteAdditionalClubsDialogTitle:
                'INVITA CLUBES ADICIONALES A TU PARTIDO AMISTOSO',
            inviteAdditionalClubsButtonTitle: 'Invita clubes adicionales ya',
            friendlies: 'Encuentros amistosos',
            open: 'Abrir',
            ads: 'Anuncios',
            friendly: 'Encuentro amistoso',
            ad: 'Anuncio',
            my: 'Mi',
            inviteClubs: 'Invita a los clubes a tu encuentro amistoso',
            recipients: 'Destinatarios',
            removeAll: 'Eliminar todo',
            country: 'PAÍS:',
            league: 'LIGA:',
            skip: 'Saltar',
            createNewAd: 'Crear un nuevo anuncio',
        },
        match: {
            venue: 'Sede',
            homeAway: 'En casa/Fuera de casa',
            home: 'En casa',
            countryOfVenue: 'País de la sede',
            away: 'Fuera de casa',
        },
        declareInterest: {
            declaredInterest: 'Interés mostrado',
            declareInterest: 'Mostrar interés',
        },
        myFriendlyMatches: {
            activeAds: 'Anuncios activos',
            expiredAds: 'Anuncios caducados',
            friendlyInvitationSubject: 'Friendly invitation',
        },
        newInvitations: {
            youHaveReceived: 'Has recibido',
            newInvitation: 'nueva invitación',
            newInvitations: 'nuevas invitaciones',
        },
        menu: {
            activity: 'Actividad',
            invitations: 'Invitaciones',
            myAds: 'Mis anuncios',
            createNewAd: 'Crear un nuevo anuncio',
        },
        friendlySuggestion: {
            friendlySuggestion: 'Encuentro sugerencias',
        },
    },
    fullStat: {
        fullStats: 'Estadísticas completas',
        average: 'Promedio',
        date: 'Fecha',
        match: 'Encuentro',
        competition: 'Competición',
        minPlayed: 'Min. jugados',
        goals: 'Goles',
        assists: 'Asistencias',
        noRecords: 'No se encontraron registros',
    },
    landingPage: {
        recommendations: {
            highPriorityRecommendation: 'High priority recommendation for you',
            highPriorityRecommendations: 'High priority recommendations for you',
            fixNow: 'Fix now',
            fixed: 'Fixed!',
            done: 'Done!',
            makePlayersAvailable: {
                title: 'Make Players Available',
                description: 'List your players for sale or loan to appeal to as many buyers as possible.'
            },
            pitchYourPlayers: {
                title: 'Pitch Your Players',
                description: 'Pitching your players to relevant clubs highly increases your chances of receiving direct offers.'
            },
            createPlayerAds: {
                title: 'Create Player Ads',
                description: 'Specify your needs to get pitches of relevant players. Receive offers sent directly from other clubs.'
            },
            launchFriendlyAds: {
                title: 'Launch Friendly Ads',
                description: 'Share when and where you are looking to play and get interest from other clubs.'
            },
            addMoreUsers: {
                title: 'Add more users + a friendly manager',
                description: 'Add relevant colleagues to TransferRoom to maximize your club’s activity and results with TransferRoom.'
            },
        },
        doingWell: {
            youAreDoingWell: 'You are doing well!',
            makePlayerAvailable: 'Make a player available',
            addPlayerToReleaseList: 'Add a player to your release list',
            pitchPlayer: 'Pitch a player',
            makeAdForAPlayer: 'Make an ad for a player',
            arrangeFriendlyMatch: 'Arrange a friendly match',
            findAndComparePlayers: 'Find and compare players'
        },
        performanceIndicators: {
            heading: 'Performance indicators',
            playersMadeAvailable: 'Player%{value} made available',
            playersPitchesLast: 'Player pitch%{value} last 30 days',
            playersAdsLast: 'Player ad%{value} last 30 days',
            friendlyAdsLast: 'Friendly ad%{value} last 30 days'
        },
        createPlayerAdModal: {
            //Step 1
            specifyYourTransferTargets: 'Specify your requirement to receive offers',
            whichPositionAreYouLooking: 'Which positions are you looking for?',
            //Step 3
            playerAdsCreated: 'player ads created!',
            playerAdCreated: 'player ad created!',
            weFound: 'We found',
            recommendationsForYou: 'recommendations for you',
            recommendationForYou: 'recommendation for you',
            player: 'Player',
            marketValue: 'Market value',
            //Common
            cancel: 'Cancelar',
            continue: 'Continue'
        }
    },
    matchMaker: {
        youHaveMatch: 'Tienes un match',
        pitchToMultiple: 'Oferta a varios clubes',
        name: 'Nombre',
        budget: 'Presupuesto',
        pitch: 'Lanzar oferta',
        skip: 'Saltar',
        pitchNow: 'Lanzar oferta ahora',
        pos: 'Pos.',
    },
    messages: {
        deleteConversation: 'Borrar conversación',
        newMessage: 'Nuevo mensaje',
        search: 'Buscar por nombre de club...',
        enterMessage: 'Redacta un mensaje...',
        noChannelsFound: '¡No se han encontrado canales!',
        today: 'Hoy',
        yesterday: 'Ayer',
        noClubsMatch:
            'Ningún club coincide con su búsqueda. Inténtalo de nuevo...',
    },
    notifications: {
        hasBeenPitched: 'ha sido ofertado. ¡Buena suerte!',
        yourMessageTo: 'Tu mensaje a',
        hasBeenSent: 'ha sido enviado',
        somethingWentWrong: 'Algo ha salido mal, inténtalo de nuevo',
    },
    performance: {
        competition: 'Competición',
        apps: 'Aplicaciones',
        startingApps: 'Iniciar aplicaciones',
        goals: 'Goles',
        assists: 'Asistencias',
        minPerGoal: 'Minutos por gol',
        minPerAssist: 'Minutos por asistencia',
        totalMinPlayed: 'Total minutos jugados',
        avgMinPlayed: 'Promedio minutos jugados',
        performanceData: 'Datos de rendimiento',
        total: 'Total',
    },
    playerAds: {
        alert: {
            gettingOld:
                'Uno o varios anuncios llevan demasiado tiempo activos:',
            considerUpdating: 'Actualiza o elimina tus anuncios antiguos',
            yesStillLooking: 'Sí, todavía estoy buscando',
            remove: 'Eliminar',
            areYouStillLooking: '¿Todavía estás buscando un',
            keepUpToDate: 'Asegúrate de mantener tus anuncios actualizados.',
            budget: 'Presupuesto:',
            upTo: 'Hasta',
            to: 'A',
        },
        getStarted: {
            findMember: 'Encuentra el próximo miembro de tu plantilla aquí',
            startWithAd:
                'Alrededor del 50 % de los traspasos de jugadores comienzan con un anuncio.',
            getStarted: 'Comienza ahora',
            createAd: 'Crea tu anuncio',
        },
        myAds: {
            activeAds: 'Anuncios activos',
            removedAds: 'Anuncios eliminados',
        },
        noPitches: {
            youHave0: 'Tienes 0 ofertas nuevas',
            butYouHave: 'Pero tienes',
            yourAdsAreLive:
                'Tus anuncios han sido publicados. Cuando haya nuevas ofertas, aparecerán aquí.',
            savedPitch: 'oferta guardada',
            savedPitches: 'ofertas guardadas',
        },
        pitch: {
            savePitch: 'Guardar oferta',
            dismiss: 'Descartar y pasar al siguiente',
            dismiss2: 'Descartar',
        },
        pitchPlayer: {
            age: 'Edad',
            yrs: 'años',
            askingPrice: 'Precio de venta',
            loanFee: 'Cuota de cesión',
            notAvailable: 'No disponible',
        },
        main: {
            newPlusPitchHeader: 'PLUSOFERTA',
            removedOn: 'Eliminado el',
            upTo: 'Hasta',
            pitches: 'Ofertas',
            pitch: 'Oferta',
            received: 'Recibidos',
            saved: 'Guardados',
            interests: 'Intereses',
            interest: 'Interes',
            declared: 'Mostrados',
            updated: 'ACTUALIZADO',
            updateAd: 'Actualizar anuncio',
            remove: 'Eliminar',
            activity: 'Actividad',
            savedPitches: 'Ofertas guardadas',
            myAds: 'Mis anuncios',
            createNewAd: 'Crear un nuevo anuncio',
            wantMoreData: '¿Quieres más datos sobre los jugadores?',
            whenYouSave:
                'Cuando guardas una oferta, tienes acceso a herramientas de análisis y puedes comparar un jugador con el tuyo propio o con otros jugadores ofertados.',
            monthlyLoanFee: 'Cuota mensual de cesión:',
            transferFee: 'Cuota de traspaso:',
            to: 'A',
        },
        actions: {
            club: 'Club',
            clubs: 'Clubs',
            pitches: 'Ofertas',
            pitch: 'Ofert',
            newSuperPitch: 'nueva Super ',
            newPlus: 'nueva Plus',
            received: 'recibida',
            superPitchReceived: 'SUPEROFERTAR RECIBIDA',
            buy: 'comprar',
            loan: 'ceder',
            newPitchesForYourAd: 'nuevas ofertas para tu anuncio para',
            newPitchForYourAd: 'nuevas ofert para tu anuncio para',
            to: 'a',
            withBudgetUpTo: 'con presupuesto de hasta',
            tip: 'SUGERENCIA:',
            inDoubt:
                'En caso de duda, puedes guardar una oferta para analizarla con más detenimiento',
        },
        summary: {
            pitch: 'Oferta',
            pitches: 'Ofertas',
            new: 'nuevo',
            ad: 'Anuncio',
            ads: 'Anuncios',
            active: 'activos',
            saved: 'guardados',
        },
        savedPitch: {
            newMessage: 'Nuevo mensaje',
            remove: 'Eliminar',
            compareTo: 'Comparar con...',
            type: 'Tipo...',
            profile: 'Perfil',
            radar: 'Radar',
            table: 'Tabla',
            season: 'Temporada',
        },
        savedPitches: {
            newPitch: 'nueva oferta',
            newPitches: 'nuevas ofertas',
            superPitch: 'SUPEROFERTA',
            buy: 'comprar',
            loan: 'ceder',
            forYourAd: 'para tu anuncio para',
            to: 'a',
            withBudgetUpTo: 'con presupuesto de hasta',
        },
        upgradeOffer: {
            wantMoreAds: '¿Quieres hacer más anuncios?',
            itTakes2Mins:
                'Solo te llevará 2 minutos y podrás cambiarlos más tarde',
            getStarted: 'Comienza ahora por',
            only20: 'solo 20 &#163; al mes',
            upgradeNow: 'Actualiza ahora',
            remindLater: 'Recordar más tarde',
            upgradeOffer: 'Oferta de actualización',
        },
        adPitches: {
            delivered: 'ENTREGADO',
            interest: 'INTERÉS',
            saved: 'GUARDADO',
            opened: 'ABIERTO',
        },
        pitchStatus: {
            delivered: 'Entregado',
            interest: 'Interés mostrado',
            saved: 'Guardado',
            opened: 'Abierto',
            dismissed: 'Descartado',
            removed: 'Eliminado',
        },
        formatAd: {
            buy: 'comprar',
            loan: 'ceder',
            age: 'Edad',
            min: 'Min.',
            cm: 'cm',
            updated: 'Actualizado',
            ago: 'hace',
            lastUpdatedFuture:
                'La fecha «lastUpdated» no debe estar situada en el futuro',
            updatedToday: 'Actualizado hoy',
            day: 'día',
            week: 'semana',
            month: 'mes',
            year: 'año',
        },
        editor: {
            insertAmount: 'Indicar cantidad',
            minimumValueRequired: 'Minimum required value is %{value}',
            multipleAds: 'Anuncios de jugadores [Múltiples anuncios]',
            position: 'Posición:',
            toLoan: 'Para ceder',
            toBuy: 'Para comprar',
            currency: 'Moneda',
            select: 'Seleccionar posición...',
            transferFee: 'Cuota de traspaso',
            upTo: 'hasta:',
            monthlyLoanFee: 'Cuota mensual de cesión',
            mo: '/mes',
            createNewAds: 'Crear nuevos anuncios',
            createAdRecentSearch:
                'Crear un anuncio basado en tu búsqueda reciente',
            planSummerTransfers: 'Planifica tus traspasos de verano',
            increaseChances:
                'La creación de anuncios aumenta tus posibilidades de éxito en más de un 50 %',
            cancel: 'Cancelar',
            done: '¡Listo!',
            launchAd: 'Publicar anuncio',
            createNewAd: 'Crear un nuevo anuncio',
            age: 'Edad',
            yrs: 'años',
            minimumHeight: 'Altura mínima:',
            cm: 'cm',
            ft: 'pies',
            in: 'pulgadas',
        },
    },
    playerRanking: {
        title: 'Clasificación de jugador',
        trial: 'Nueva caracteristica! Estás en un juicio.',
        noData: 'No hay datos estadísticos para la clasificación',
        show10More: 'Mostrar 10 más ...',
        show10First: 'Mostrar los primeros 10',
        all: 'TODAS',
        myShortList: 'Mi lista corta',
        compareTo: 'Comparar con',
        position: 'Posición',
        statistics: {
            scoringContribution: 'Contribución de puntuación',
            scoringContributionDesc: '(objetivos + asistencias)',
            contribution: 'Contribución de puntuación',
            contributionDesc: '(objetivos + asistencias)',
            goalEfficiency: 'Eficiencia del objetivo',
            goalEfficiencyDesc: '(Objetivos / xG)',
            finishing: 'Acabado',
            finishingDesc: '(xG / Shots)',
            goals: 'Objetivos',
            assists: 'Asistencias',
            xG: 'xG',
            xA: 'xA',
            succesfuldribbles: 'Dribbles exitosos',
            shots: 'Disparos',
            shotsOnTarget: 'Disparos a puerta',
            passesAccuracy: 'Precisión de pases',
            forwardPassesAccuracy: 'Precisión de pases hacia adelante',
            passesToFinalThirdAccuracy: 'Pasa a la tercera precisión final',
            longPassesAccuracy: 'Precisión de pases largos',
            interceptions: 'Intercepciones',
            aerialDuelsWon: 'Duelos aéreos ganados',
            defensiveDuelsWon: 'Duelos defensivos ganados',
            saves: 'Guarda',
            goalkeeping: 'Portería',
            goalkeepingDesc: '(CG / xCG)',
            succesfulCrosses: 'Cruces exitosos',
            crossesAccuracy: 'Precisión de cruces',
            minutesPlayed: 'Minutos jugados',
            totalGoals: 'Total de objetivos',
            totalSuccessfulDribbles: 'Minutos jugados',
            totalShots: 'Minutos jugados',
            totalPasses: 'Pases totales',
            totalForwardPasses: 'Total de pases hacia adelante',
            totalPassesToFinalThird: 'Total de pases al tercio final',
            totalLongPasses: 'Total de pases largos',
            totalInterceptions: 'Minutos jugados',
            totalAerialDuels: 'Total duelos aéreos',
            totaDefensiveDuels: 'Duelos defensivos totales',
            totalSaves: 'Minutos jugados',
            totalCrosses: 'Cruces totales',
        },
    },
    playerComparison: {
        main: {
            playersComparison: 'Comparación de jugadores',
            noChartData: 'No existen datos gráficos disponibles...',
            noData: 'No existen datos disponibles...',
        },
        filters: {
            season: 'Temporada',
            searchPlayer: 'Buscar jugador...',
            type: 'Tipo...',
            profile: 'Perfil',
        },
        table: {
            dataIsComparing:
                '* Se está comparando el rendimiento de los jugadores de la liga nacional',
            statsPer90: '** Todas las estadísticas en 90 minutos',
        },
        constants: {
            minutesPlayed: 'Minutos jugados',
            defensiveStatsP90: 'Estadísticas defensivas P90',
            shotsAgainst: 'Tiros contra',
            saves: 'Paradas',
            concededGoals: 'Goles recibidos',
            xCG: 'xGC',
            offensiveStatsP90: 'Estadísticas ofensivas P90',
            passesAccuracy: 'Precisión en pases',
            longPassesAccuracy: 'Precisión en pases largos',
            forwardPassesAccuracy: 'Precisión en pases hacia delante',
            height: 'Altura',
            defensiveDuelsWon: 'Juegos defensivos ganados',
            aerialDuelsWon: 'Juegos aéreos ganados',
            interceptions: 'Robos',
            scoringContribution: 'Contribución a la puntuación',
            shots: 'Tiros',
            crossesAccuracy: 'Precisión en pases cruzados',
            passesToFinalThirdAccuracy: 'Precisión en pases al tercio final',
            shotsOnTarget: 'Tiros a meta',
            successfulCrosses: 'Pases cruzados correctos',
            successfulDribbles: 'Regates correctos',
            xG: 'xG',
            goals: 'Goles',
            assists: 'Asistencias',
            xA: 'xA',
            season: 'Temporada',
        },
        formatPlayer: {
            average: 'Promedio',
        },
    },
    playerInfo: {
        notAvailable: 'no disponible',
        declareInterest: 'Mostrar interés',
        notInterest: 'no existe interés',
    },
    positions: {
        goalkeeper: 'Portero',
        leftBack: 'Lateral izquierdo',
        rightBack: 'Lateral derecho',
        centerBack: 'Central',
        defensiveMidfielder: 'Centrocampista defensivo',
        centralMidfielder: 'Centrocampista',
        attackingMidfielder: 'Centrocampista de ataque',
        winger: 'Extremo',
        forward: 'Delantero',
    },
    positionSelector: {
        primaryPosition: 'Posición principal',
        secondaryPosition: 'Posición secundaria',
        none: 'ninguno',
    },
    removePlayer: {
        areYouSure: '¿Estás seguro de que quieres eliminar a',
        playerWillBeRemoved: 'El jugador será eliminado de tu plantilla',
        reason: 'Motivo para la eliminación',
        chooseReason: 'Elige el motivo...',
        removePlayer: 'Eliminar jugador',
        cancel: 'Cancelar',
        reasons: {
            outOnLoan: 'El jugador está cedido',
            retired: 'El jugador se ha retirado',
            released: 'El jugador ha sido rescindido',
            leftTheClub: 'El jugador se ha ido del club',
            otherReason: 'Otra razón',
        },
    },
    accessRestricted: {
        dontHavePermission:
            'Lo sentimos, no tienes permiso para usar esta función. Hay actualizaciones disponibles.',
        weWillBeInTouch: 'Gracias. Contactaremos contigo con más información',
        restrictedAccess: 'Acceso restringido',
        close: 'Cerrar',
        moreInfo: 'Más información',
        newMessagesLimitIsRichedOut:
            'You have reached your 5 new messages limit per 24 hours. Try again, later. For any questions, please contact <a href="mailto:support@transferroom.com">support@transferroom.com</a>',
    },
    messaging: {
        messageButton: 'MENSAJE',
        message: 'Mensaje...',
        cancel: 'Cancelar',
        send: 'Enviar',
        messaging: 'Mensajes',
        sendMessage: 'Enviar mensaje',
    },
    shortlist: {
        shortlisted: 'Preseleccionado',
        addToShortlist: 'Añadir a la preselección',
    },
    superPitch: {
        pitchCreated: {
            hasBeenSuperPitched: 'ha sido inclinado a',
            clubs: 'clubes',
            anotherPlayer: 'Pitch Plus a otro jugador',
        },
        createFlow: {
            superPitch: 'PITCH PLUS',
            newFeature: 'NUEVA FUNCIÓN',
            pitchYourPlayer:
                'Oferta tu jugador a un club específico o a toda una liga',
            leftThis: 'restantes este',
            month: 'mes',
            pitchPlayerNow: '¡Oferta el jugador ahora!',
            clear: 'Borrar',
            readyToGo: '¡Listo! Pulsa el botón Oferta.',
        },
        playerAdd: {
            selectPlayer: 'SELECCIONAR JUGADOR',
            selectPlayerName: 'Seleccionar nombre de jugador...',
            cancel: 'Cancelar',
            choosePlayer: 'Elegir jugador...',
            player: 'Jugador',
            startByChoosing: 'Empieza eligiendo el jugador para la PITCH PLUS.',
        },
        setPrice: {
            setPriceFor: 'ESTABLECER PRECIO PARA',
            yes: 'Sí',
            no: 'No',
            askingPrice: 'Precio de venta',
            sellOn: 'Reventa',
            forLoan: 'Para cesión',
            monthlyLoanFee: 'Cuota mensual de cesión',
            price: 'Precio',
            setPrice: 'Establecer precio...',
            forSale: 'En venta',
            nowSetPrice:
                'Ahora puedes establecer el precio y el tipo de traspaso.',
            cancel: 'Cancelar',
            pm: 'pm',
        },
        setRecipients: {
            zeroSelected: '0 seleccionados',
            selectLeagues: 'Seleccionar ligas de la lista',
            recipientsUpTo: 'Destinatarios (hasta',
            selectRecipients: 'SELECCIONAR DESTINATARIOS',
            selectClubName: 'Seleccionar el nombre del club o la liga...',
            clubsLimit: 'límite de clubes',
            reached:
                'alcanzado. Revisa los clubes seleccionados en la lista de la izquierda.',
            clearSelection: 'Borrar selección',
            cancel: 'Cancelar',
            saveChanges: 'Guardar cambios',
            readyToGo:
                'Elige un club o una liga entera, ¡y estarás listo para empezar!',
            chooseRecipients: 'Seleccionar destinatarios...',
            recipients: 'Destinatarios',
        },
        utils: {
            club: 'club',
            clubs: 'clubes',
            league: 'liga',
            leagues: 'ligas',
        },
    },
    pitchPlus: {
        getAccess: {
            getAccessNow: {
                getAccessTo: `¡OBTENGA ACCESO A`,
                plusPitch: 'PLUS PITCH',
                now: 'AHORA!',
            },
            description1:
                '1 de cada 4 Plus Pitch resulta en que los jugadores encuentren un nuevo club.',
            description2:
                'Más de x5 mayor índice de éxito que los lanzamientos normales.',
            requestInfoButton: 'INFORMACIÓN REQUERIDA',
        },
        navigationPanel: {
            leftThisMonth: 'IZQUIERDO ESTE MES',
            newPlusPitchButton: 'Nuevo Plus Pitch',
        },
        createFirst: {
            createFirstNow: {
                createYourFirst: '¡CREA TU PRIMER',
                plusPitch: 'PLUS PITCH',
                now: 'AHORA MISMO!',
            },
            description1:
                '1 de cada 4 Plus Pitch resulta en que los jugadores encuentren un nuevo club.',
            description2:
                'Más de x5 mayor índice de éxito que los lanzamientos normales.',
            createNewPlusPitchButton: 'CREAR NUEVO PLUS PITCH',
        },
        plusPitchItem: {
            plusPitched: 'CREADO',
            forSale: 'En venta:',
            forLoan: 'Para cesión:',
            circles: {
                low: 'BAJO',
                medium: 'MEDIO',
                good: 'BUENO',
                high: 'ALTO',
                veryhigh: 'MUY ALTO',
                max: 'MAX',
                readStatus: 'Leer estado',
                interestRate: 'Tasa de interés',
            },
            reactions: {
                undefined: 'Indefinido',
                awaitingResponse: 'Esperando respuesta ...',
                opened: 'Abrió',
                savedPitch: 'Tono guardado ...',
                declaredInterest: 'Interés declarado',
                dismissedPitch: 'Ya no estoy interesado ...',
                removed: 'Remoto',
                awaitingResponseHeader: 'Awaiting response from',
                and: 'and',
                otherClubs: 'other clubs...',
                otherClub: 'other club...',
                close: 'Close...',
            },
            clubHasSavedPlusPitch:
                'club ha guardado el tono positivo. Puedes probar su interés',
            clubsHaveSavedPlusPitch:
                'clubes han salvado el tono positivo. Puedes probar su interés',
            testInterestNowButton: 'PRUEBA DE INTERÉS AHORA!',
            testInterestButton: 'PRUEBA DE INTERÉS',
            interestTested: 'Interés probado el',
            interestCanBeTested: 'El interés se puede probar el',
            show: 'Mostrar',
            more: 'más ...',
            of: 'de',
            keepSavedPitch:
                'Asegúrate de mantener jugador en ofertas guardadas',
        },
        generalPerformance: {
            heading: 'GENERAL PITCH RENDIMIENTO',
            basedOn: 'Basado en',
            quality: 'Calidad',
            rates: {
                low: 'BAJO',
                good: 'BUENO',
                veryGood: 'MUY BIEN',
                great: 'EXCELENTE',
                max: 'MAX',
            },
            agentTip: 'Consejo de agente Plus Pitch:',
            tipDescription:
                'Esta es su oportunidad de comunicar sus expectativas directamente al mercado. Asegúrese de hacer que los precios sean atractivos y seleccione una audiencia relevante de clubes para lograr los mejores resultados posibles.',
            plusPitches: 'plus pitches',
        },
        pitchCreated: {
            hasBeenPlusPitched: 'ha sido Plus Pitched a',
            club: 'club',
            clubs: 'clubes',
            anotherPlayer: 'Plus Pitch a otro jugador',
        },
        createFlow: {
            createNewPlusPitchButton: 'CREAR NUEVO PLUS PITCH',
            plusPitch: 'PLUS PITCH',
            newFeature: 'NUEVA FUNCIÓN',
            startByChoosing: 'Empieza eligiendo el jugador para la Plus Pitch.',
            pitchYourPlayer:
                'Oferta tu jugador a un club específico o a toda una liga',
            leftThis: 'restantes este',
            month: 'mes',
            pitchPlayerNow: '¡Oferta el jugador ahora!',
            close: 'Cerrar',
            readyToGo: '¡Listo! Pulsa el botón Oferta.',
        },
        playerAdd: {
            selectPlayer: 'SELECCIONAR JUGADOR',
            selectPlayerName: 'Seleccionar nombre de jugador...',
            cancel: 'Cancelar',
            choosePlayer: 'Elegir jugador...',
            player: 'Jugador',
            startByChoosing:
                'Empieza eligiendo el jugador para la Superoferta.',
        },
        setPrice: {
            setPriceFor: 'ESTABLECER PRECIO PARA',
            yes: 'Sí',
            no: 'No',
            askingPrice: 'Precio de venta',
            sellOn: 'Reventa',
            forLoan: 'Para cesión',
            monthlyLoanFee: 'Cuota mensual de cesión',
            price: 'Precio',
            setPrice: 'Establecer precio...',
            forSale: 'En venta',
            nowSetPrice:
                'Ahora puedes establecer el precio y el tipo de traspaso.',
            cancel: 'Cancelar',
            pm: 'pm',
            setPriceButton: 'Establecer precio',
        },
        setRecipients: {
            zeroSelected: '0 seleccionados',
            selectLeagues: 'Seleccionar ligas de la lista',
            recipientsUpTo: 'Destinatarios (hasta',
            selectRecipients: 'SELECCIONAR DESTINATARIOS',
            selectClubName: 'Seleccionar el nombre del club o la liga...',
            clubsLimit: 'límite de clubes',
            reached:
                'alcanzado. Revisa los clubes seleccionados en la lista de la izquierda.',
            clearSelection: 'Borrar selección',
            cancel: 'Cerrar',
            saveChanges: 'Agregar recipientes',
            readyToGo:
                'Elige un club o una liga entera, ¡y estarás listo para empezar!',
            chooseRecipients: 'Seleccionar destinatarios...',
            recipients: 'Destinatarios',
        },
        utils: {
            club: 'club',
            clubs: 'clubes',
            league: 'liga',
            leagues: 'ligas',
        },
        testInterest: {
            forSale: 'En venta',
            forLoan: 'Para cesión',
            sendMessage: 'ENVIAR MENSAJE',
            deleteSavedPitch: 'BORRAR OFERTA GUARDADA',
            thisClub: 'este club',
            theseClubs: 'estos club',
            whenCanYou: 'Cuándo puedes probar el interés?',
            canTestDaysAfter:
                'Puede probar el interés en los jugadores %{number} días después de que se haya guardado su lanzamiento.',
            testInterestWith: 'Prueba de interés con',
            testInterest: 'PRUEBA DE INTERÉS',
            plusPitched: 'CREADO',
            alreadyTestedInterest: 'You already tested interest with them',
            savedThePitch: 'Salvó el terreno de juego',
            cannotTestBefore: 'You cannot test their interest before',
            wouldLikeYouToRespond:
                'desea que responda en una Plus Pitch que ha guardado',
            timesOut: 'SALVADO PLUS PITCH - SE ACABÓ EL TIEMPO EN',
            ifYouDoNotReact:
                'IMPORTANTE: Si no reaccionas a estos, se agotarán los tiempos de espera y el tono guardado se eliminará automáticamente.',
            rePlusPitchOf: 'Re: Plus pitch de',
            cancel: 'CANCELAR',
        },
    },
    superPitch2: {
        getAccess: {
            getAccessNow: {
                getAccessTo: 'GET ACCESS TO',
                plusPitch: 'SUPER PITCH',
                now: 'NOW!',
            },
            description1:
                '1 in every 4 Super Pitch results in players finding a new club.',
            description2:
                'More than x5 higher success rate than normal pitches.',
            requestInfoButton: 'REQUEST INFORMATION',
        },
        navigationPanel: {
            leftThisMonth: 'LEFT THIS MONTH',
            newPlusPitchButton: 'New Super Pitch',
        },
        createFirst: {
            createFirstNow: {
                createYourFirst: 'CREATE YOUR FIRST',
                plusPitch: 'SUPER PITCH',
                now: 'NOW!',
            },
            description1:
                '1 in every 4 Super Pitch results in players finding a new club.',
            description2:
                'More than x5 higher success rate than normal pitches.',
            createNewPlusPitchButton: 'CREATE NEW SUPER PITCH',
        },
        plusPitchItem: {
            plusPitched: 'SUPER PITCHED',
            forSale: 'For Sale:',
            forLoan: 'For Loan:',
            circles: {
                low: 'LOW',
                medium: 'MEDIUM',
                good: 'GOOD',
                high: 'HIGH',
                veryhigh: 'VERY HIGH',
                max: 'MAX',
                readStatus: 'Read Status',
                interestRate: 'Interest Rate',
            },
            reactions: {
                undefined: 'Undefined',
                awaitingResponse: 'Awaiting response...',
                opened: 'Opened',
                savedPitch: 'Saved pitch ...',
                declaredInterest: 'Declared interest',
                dismissedPitch: 'Dismissed pitch ...',
                removed: 'Removed',
                awaitingResponseHeader: 'Awaiting response from',
                and: 'and',
                otherClubs: 'other clubs...',
                otherClub: 'other club...',
                close: 'Close...',
            },
            clubHasSavedPlusPitch:
                'club has saved the super pitch. You can test their interest',
            clubsHaveSavedPlusPitch:
                'clubs have saved the super pitch. You can test their interest',
            testInterestNowButton: 'TEST INTEREST NOW!',
            testInterestButton: 'TEST INTEREST',
            interestTested: 'Interest tested',
            interestCanBeTested: 'Interest can be tested',
            show: 'Show',
            more: 'more...',
            of: 'of',
            keepSavedPitch: 'Keep player in saved pitches',
        },
        generalPerformance: {
            heading: 'GENERAL PITCH PERFORMANCE',
            basedOn: 'Based on',
            quality: 'Quality',
            rates: {
                low: 'LOW',
                good: 'GOOD',
                veryGood: 'VERY GOOD',
                great: 'GREAT',
                max: 'MAX',
            },
            agentTip: 'Super Pitch Agent Tip:',
            tipDescription:
                'This is your opportunity to communicate your expectations directly to the market. Make sure to make the pricing attractive and select a relevant audience of clubs to achieve the best possible results.',
            plusPitches: 'super pitches',
        },
        pitchCreated: {
            hasBeenPlusPitched: 'has been Super Pitched to',
            club: 'club',
            clubs: 'clubs',
            anotherPlayer: 'Super Pitch Another player',
        },
        createFlow: {
            createNewPlusPitchButton: 'CREATE NEW SUPER PITCH',
            plusPitch: 'SUPER PITCH',
            newFeature: 'NEW FEATURE',
            startByChoosing:
                'Start by choosing a player you want to Super Pitch!',
            pitchYourPlayer:
                'Pitch your player to a specific club or a whole league',
            leftThis: 'left this',
            month: 'month',
            pitchPlayerNow: 'Pitch player now!',
            clear: 'Close',
            readyToGo: 'Ready to go now! Just click Pitch Button.',
        },
        playerAdd: {
            selectPlayer: 'SELECT PLAYER',
            selectPlayerName: 'Select player name...',
            cancel: 'Cancel',
            choosePlayer: 'Choose player...',
            player: 'Player',
            startByChoosing:
                'Start by choosing a player you want to Super Pitch!',
        },
        setPrice: {
            setPriceFor: 'SET PRICE FOR',
            yes: 'Yes',
            no: 'No',
            askingPrice: 'Asking price',
            sellOn: 'Sell-on',
            forLoan: 'For Loan',
            monthlyLoanFee: 'Monthly loan fee',
            price: 'Price',
            setPrice: 'Set price...',
            forSale: 'For Sale',
            nowSetPrice: 'Now set the price and transfer type!',
            cancel: 'Cancel',
            pm: 'pm',
            setPriceButton: 'Set price',
        },
        setRecipients: {
            zeroSelected: '0 Selected',
            selectLeagues: 'Select leagues from the list',
            recipientsUpTo: 'Recipients (up to',
            selectRecipients: 'ADD RECIPIENTS',
            selectClubName: 'Select club name or league...',
            clubsLimit: 'clubs limit',
            reached: 'reached. Review clubs selected in the left list.',
            clearSelection: 'Clear Selection',
            cancel: 'Close',
            saveChanges: 'Add Recipients',
            readyToGo: 'Choose club or whole league, and you are ready to go!',
            chooseRecipients: 'Choose recipients...',
            recipients: 'Recipients',
        },
        utils: {
            club: 'club',
            clubs: 'clubs',
            league: 'league',
            leagues: 'leagues',
        },
        testInterest: {
            forSale: 'To Buy',
            forLoan: 'To Loan',
            sendMessage: 'SEND MESSAGE',
            deleteSavedPitch: 'DELETE SAVED PITCH',
            thisClub: 'this club',
            theseClubs: 'these clubs',
            whenCanYou: 'When can you test interest?',
            canTestDaysAfter:
                'You can test interest in players %{number} days after your pitch has been saved.',
            testInterestWith: 'Test interest with',
            testInterest: 'TEST INTEREST',
            plusPitched: 'SUPER PITCHED',
            alreadyTestedInterest: 'You already tested interest with them',
            savedThePitch: 'Saved the pitch',
            cannotTestBefore: 'You cannot test their interest before',
            wouldLikeYouToRespond:
                'would like you to respond on a Super Pitch you have saved',
            timesOut: 'SAVED SUPER PITCH - TIMES OUT IN',
            ifYouDoNotReact:
                'IMPORTANT: If you do not react to these, they will time out and the saved pitch will be deleted automatically.',
            rePlusPitchOf: 'Re: Super pitch of',
            cancel: 'CANCEL',
        },
    },
    playerSimilarity: {
        similarPlayers: 'Jugadores Similares',
    },
    video: {
        whatClubsSay: '¿Qué dicen los clubes sobre TransferRoom?',
        watchInsights:
            'Aprende cómo utilizan TransferRoom los principales clubes para ahorrar tiempo y dinero.',
        whatOtherClubsSay: 'QUÉ DICEN OTROS CLUBES',
        allVideos: 'TODOS LOS VÍDEOS',
    },
    pager: {
        pageSize: 'Tamaño de página:',
        page: 'Página',
        of: 'de',
        to: 'a',
    },
    agGrid: {
        yes: 'Sí',
        no: 'No',
        name: 'Nombre',
        currentImage: 'Imagen actual',
        deleteImage: 'Eliminar imagen',
        dropFilesHere: 'Suelta los archivos aquí...',
        tryDropping:
            'Suelta los archivos aquí o haz clic para seleccionar los archivos que deseas cargar.',
    },
    askingPrice: {
        askingPrice: 'Precio de venta',
        priceUponRequest: 'Precio previa solicitud',
        sellOn: '% de reventa',
        availableForSale: 'Disponible para venta',
    },
    availabilityBadge: {
        available: 'Disponible',
        toBeReleased: 'PARA SER LANZADO',
    },
    planSummerTransferBanner: {
        planSummerTransfers: 'Planifica tus traspasos de verano',
        creatingAds:
            'La creación de anuncios aumenta tus posibilidades de éxito un 50 % o más',
        getStarted: 'Comienza ahora',
    },
    superPitchAnnouncement: {
        newFeature: 'NUEVA FUNCIÓN',
        pitchYourPlayer:
            'Plusoferta: oferta a tu jugador a un club específico o a una liga completa',
        leftThisMonth: 'restantes este mes',
        newSuperPitch: 'Nueva plusoferta',
        leftThis: 'restantes este',
        month: 'mes',
    },
    transferStatusButton: {
        makeAvailable: 'Colocar en el mercado',
    },
    calendar: {
        weekDaysAbbreviated: {
            sunday: 'D',
            monday: 'L',
            tuesday: 'M',
            wednesday: 'M',
            thursday: 'M',
            friday: 'V',
            saturday: 'D',
        },
        months: {
            january: 'Enero',
            february: 'Febrero',
            march: 'Marzo',
            april: 'Abril',
            may: 'Mayo',
            june: 'Junio',
            july: 'Julio',
            august: 'Agosto',
            september: 'Septiembre',
            october: 'Octubre',
            november: 'Noviembre',
            december: 'Diciembre',
        },
        monthsAbbreviated: { //TODO: fill with translations
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'May',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Oct',
            nov: 'Nov',
            dec: 'Dec',
        },
        weekDays: {
            sunday: 'Domingo',
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miércoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sábado',
        },
    },
    clubEntry: {
        club: 'Club',
        exp: 'exp.',
        onLoanto: 'Cedido a',
    },
    contractExpiry: {
        month: 'mes',
        months: 'meses',
    },
    dateRangePicker: {
        selectFirst: 'Selecciona el primer día.',
        selectLast: 'Selecciona el último día.',
        to: 'a',
    },
    filter: {
        clear: 'Borrar',
        squad: 'Plantilla',
        status: 'Estado',
        age: 'Edad',
        contractExpiry: 'Vencimiento del contrato',
        position: 'Posición',
        league: 'Liga',
        marketValue: 'Valor de mercado',
        gsnPotential: 'GSN Potencial'
    },
    contractExpirySelector: {
        yr: 'año',
        mo: 'mes',
    },
    feeSelector: {
        pm: 'pm',
    },
    height: {
        cm: 'cm',
        feet: 'pies',
    },
    helpIcons: {
        monthlyLoanFeeIncludes:
            'La cuota mensual de cesión incluye la cuota del club y el salario del jugador.',
        sellOnIsPercentage:
            'El porcentaje de reventa es el porcentaje debido por las ganancias generadas por un jugador traspasado que es revendido a un tercero.',
        exportPdf: 'Export profile to a PDF file'
    },
    logout: {
        logout: 'Cerrar sesión...',
    },
    monthlyLoanFeeEntry: {
        availableForLoan: 'Disponible para cesión',
        monthlyLoanFee: 'Cuota mensual de cesión:',
        priceUponRequest: 'Precio previa solicitud',
        pm: 'pm',
    },
    nationalityEntry: {
        nationality: 'Nacionalidad',
    },
    noResult: {
        loading: 'Cargando',
        noResults: 'No hay resultados. La lista está vacía.',
    },
    paging: {
        showingResults: 'Mostrando resultados',
    },
    pitchPlayerButton: {
        pitchPlayer: 'Ofertar jugador',
    },
    positionEntry: {
        position: 'Posición',
        secondaryPos: 'Pos. secundaria',
    },
    weight: {
        kg: 'kg',
        lbs: 'lb',
    },
    main: {
        loading: 'Cargando...',
    },
    buttons: {
        ourButtons: 'Nuestros botones',
        effectLarge: 'efecto importante:',
    },
    mySquad: {
        advancedFilter: {
            showResults: 'Mostrar resultados',
            allPlayers: 'Todos los jugadores',
            forLoan: 'Para cesión',
            forSale: 'En venta',
            forFree: 'Gratis',
            outOnLoad: 'Fuera de cesión',
        },
        grid: {
            removePlayer: 'Eliminar jugador',
            choosePlayer: 'Elegir jugador...',
            edit: 'Editar',
            filter: 'FILTRAR',
            cancel: 'Cancelar',
            save: 'Guardar',
        },
        transferStatusButton: {
            sale: 'Venta',
            loan: 'Cesión',
            makeAvailable: 'Colocar en el mercado',
            seeProfile: 'Ver perfil',
            for: 'Para',
        },
        uploadVideo: {
            video: 'Vídeo',
            insertLink: 'Insertar enlace a (YouTube o Vimeo)...',
            cancel: 'Cancelar',
            saveChanges: 'Guardar cambios',
            max2Videos: 'Solo están permitidos 2 vídeos por jugador',
        },
        recommendedMatch: {
            heading: 'RECOMMENDATION MATCH',
            isLookingTo: 'is looking to',
            buy: 'buy',
            loan: 'loan',
            a: 'a',
            transferFee: 'Transfer Fee',
            loanFee: 'Loan Fee',
            upTo: 'up to',
            doYouWantToPitch: 'Do you want to pitch',
            pitch: 'PITCH',
            decline: 'DECLINE',
        },
    },
    delayDayBanner: {
        days: 'DÍAS',
        dealDay: 'Día del trato',
        hours: 'HORAS',
        minutes: 'MINUTOS',
        seconds: 'SEGUNDOS',
        learnMore: 'MÁS INFORMACIÓN',
    },
    playerSearch: {
        titleAll: 'TODOS LOS JUGADORES',
        titleShortList: 'MI LISTA CORTA',
        titleSimilarTo: 'SIMILAR A',
        new: 'NUEVO',
        selectSorting: 'SELECCIONAR ORDENACIÓN',
        sortBy: 'Ordenador por',
        sortByIncreasing: 'Creciente',
        sortByDecreasing: 'Decreciente',
        applySorting: 'Aplicar ordenación',
        sortbyAvailability: 'Disponibilidad',
        bannerWebinar: {
            webinar: 'Webinar',
            marketValue: 'Market Value',
            date: '8th April, 2pm BST',
            dayLeft: 'DAY LEFT',
            daysLeft: 'DAYS LEFT',
        },
        dropDown: {
            requestedLoanContribution: 'Cuota de cesión solicitada',
            requestLoanContribution: 'Solicitar cuota de cesión',
            requestedLoanAvailability: 'Disponibilidad de cesión solicitada',
            requestLoanAvailability: 'Solicitar disponibilidad de cesión',
            profile: 'Perfil',
            yrs: 'años',
            newMessage: 'Nuevo mensaje',
            requestedAskingPrice: 'Precio de venta solicitado',
            requestAskingPrice: 'Solicitar precio de venta',
            requestedTransferAvailability:
                'Disponibilidad de traspaso solicitada',
            requestTransferAvailability: 'Solicitar disponibilidad de traspaso',
            sendByEmail: 'Enviar por correo electrónico',
        },
        filter: {
            showResults: 'Mostrar resultados',
            createLeaguesList: 'Crear lista de ligas',
            forLoan: 'Para cesión',
            forSale: 'En venta',
            forFree: 'Gratis',
            toBeReleased: 'Para ser lanzado',
            age: 'Edad',
            contractExpiry: 'Vencimiento del contrato',
            position: 'Posición',
            league: 'Liga',
        },
        grid: {
            searchPlayerName: 'Buscar nombre de jugador o club...',
            filter: 'FILTRAR',
        },
        menu: {
            allPlayers: 'Todos los jugadores',
            myShortlist: 'Mi preselección',
        },
        customColumns: {
            of: 'de',
            columnsSelected: 'columnas seleccionadas',
            performanceData: 'DATOS DE RENDIMIENTO',
        },
        main: {
            playerSearch: 'Búsqueda de jugadores',
            watchVideo: 'Aprende vídeo del jugador',
        },
        similarTo: {
            suggestionTip:
                'Utilice el motor de búsqueda para ejecutar instantáneamente nuestro algoritmo en la base de datos de 50K jugadores para encontrar el más similar en términos de su nivel y rendimiento',
        },
        recommendationBanner: {
            heading:
                'Get intelligent player recommendations from our data analyst engine',
            description:
                'Simply complete the ad we have already made ready for you and get 5 player recommendations right away',
            getYourRecommendationButton: 'Get your recommendations now',
        },
    },
    playerProfile: {
        webinar: {
            panelHeading: 'Webinar: Market Value'
        },
        marketValue: {
            panelHeading: 'MARKET VALUE',
            showMeButton: 'SHOW ME!',
            landing: {
                valuationAvailable: 'PLAYER VALUATION AVAILABLE!',
                inCooperation: 'In cooperation with 21st Club',
                interested: 'INTERESTED?',
                contributeWithOpinion: 'Contribute with your own opinion to see the valuation',
                placeholder: 'Enter your valuation ...'
            },
            chart: {
                marketValue: 'Market Value',
                club: 'Club',
                selection: {
                    latest12Months: 'Latest 12 months',
                    latest2Years: 'Latest 2 years',
                    latest3Years: 'Latest 3 years',
                    latest4Years: 'Latest 4 years',
                    latest5Years: 'Latest 5 years',
                },
                clubValuation: '21st Club valuation',
                tipText: `21st Club uses data to account for a player’s
                performance levels and age to provide an objective assessment
                of his transfer value, based on recent market conditions and transactions.`,
                lastUpdated: 'Last updated',
                requestedAskingPrice: 'Precio de venta solicitado',
                requestAskingPrice: 'Solicitar precio de venta',
                requestedAvailability: 'Requested availability',
                requestAvailability: 'Request availability',
            }
        },
        gsnPerformance: {
            panelHeading: 'GSN INDEX - PERFORMANCE AND POTENTIAL',
            chartDescription: 'Global Soccer Network is the first rating agency for professional footballers and the creators of the unique rating system, the GSN-Index.',
            performance: 'Performance',
            potential: 'Potential',
            compareTo: 'Compare to ...',
            dropdownTitle: 'Performance & Potential Ranking Tables',
            dropdown: {
                position: 'Position',
                my1stTeam: 'My 1st Team',
                myShortlist: 'My Shortlist',
                manageShortlist: 'MANAGE SHORTLIST',
                addPlayers: 'ADD PLAYERS',
                rank: 'RANK',
                player: 'PLAYER',
                perf: 'PERF.',
                potential: 'POTENTIAL',
                all: 'ALL',
                youDontHavePlayersInShortlist: 'You do not have players on your shortlist',
                addPlayersToMyShortlist: 'ADD PLAYERS TO MY SHORTLIST'
            },
            modal: {
                heading: "MANAGE YOUR SHORTLIST",
                playersOnShortlist: 'Players on Shortlist',
                searchPlaceholder: 'Type a player name',
                tip: 'Select players on the right to add to your shortlist',
                saveBtn: 'Save'
            }
        },
        comparison: {
            playerComparsion: 'Comparación de jugadores',
        },
        passport: {
            playerPassport: 'Pasaporte del jugador',
            height: 'Altura',
            foot: 'Pie',
            newMessage: 'Nuevo mensaje',
        },
        summary: {
            yrs: 'años',
            marketValue: 'Valor de mercado',
        },
        transferHistory: {
            transferHistory: 'Historial de traspasos',
            date: 'Fecha',
            from: 'Desde',
            to: 'A',
            type: 'Tipo',
            price: 'Precio',
            noTransferHistory: 'No existe historial de traspasos',
            show: 'Mostrar',
            more: 'más...',
        },
        transferBenchmarks: {
            player: 'Player',
            percentMatch: '% Match',
            age: 'Age',
            year: 'Year',
            from: 'From',
            to: 'To',
            fee: 'Fee',
            showMore: 'Show %{number} more',
        },
        video: {
            playerVideo: 'Vídeo del jugador',
        },
    },
    shortList: {
        shortList: 'Preselección',
    },
    playerGrid: {
        player: 'Jugador',
        squad: 'Plantilla',
        position: 'Posición',
        age: 'Edad',
        gsnPotential: 'GSN Potencial',
        gsnPerformance: 'GSN Actuación',
        contractExpiry: 'Vencimiento del contrato',
        transferStatus: 'Estado del traspaso',
        matchMaker: 'Buscador de encuentros',
        height: 'Altura',
        foot: 'Pie',
        league: 'Liga',
        club: 'Club',
        marketValue: 'Valor de mercado',
        recommendation: 'La recomendación',
        faPlayerPoint: 'GBE Status',
    },
    utils: {
        cm: 'cm',
    },
    upgradeModal: {
        thankYou: 'Thank you',
        forYourInterest: 'for your interest!',
        weWillBeInTouch: 'We will be in touch shortly',
        close: 'Cerrar'
    },
    positionAbbreviations: {
        GK: 'POR',
        LB: 'LI',
        RB: 'LD',
        CB: 'DC',
        DM: 'CD',
        CM: 'CEN',
        AM: 'CO',
        W: 'E',
        F: 'D',
    },
    positionFullNames: {
        GK: 'Portero',
        LB: 'Lateral izquierdo',
        RB: 'Lateral derecho',
        CB: 'Central',
        DM: 'Centrocampista defensivo',
        CM: 'Centrocampista',
        AM: 'Centrocampista de ataque',
        W: 'Extremo',
        F: 'Delantero',
    },
    apiTexts: {
        withoutClub: 'Sin Club',
        transferTypesHistory: {
            1: 'Traspaso',
            2: 'Traspaso gratis',
            3: 'Cesión',
            4: 'Volver de la cesión',
            5: 'Agente libre',
            6: 'Cambio de jugador',
            7: 'Prueba',
        },
        transferTypes: {
            1: 'Para comprar',
            2: 'Para ceder',
        },
        dayAgo: '%{number} día atrás',
        daysAgo: '%{number} días atrás',
        monthAgo: '%{number} mes atrás',
        monthsAgo: '%{number} meses atrás',
        yearAgo: '%{number} año atrás',
        yearsAgo: '%{number} años atrás',
        weekAgo: '%{number} semana atrás',
        weeksAgo: '%{number} semanas atrás',
        hourAgo: '%{number} hora atrás',
        hoursAgo: '%{number} horas atrás',
        minuteAgo: '%{number} minuto atrás',
        minutesAgo: '%{number} minutos atrás',
        secondAgo: '%{number} segundo atrás',
        secondsAgo: '%{number} segundos atrás',
        foot: {
            left: 'Derecho',
            right: 'Izquierdo',
        },
        userStatus: {
            active: 'Activo',
            pendinginvitation: 'Invitación Pendiente',
        },
        areas: {
            1: 'África',
            2: 'Asia',
            3: 'Europa',
            4: 'América Central',
            5: 'América del Norte',
            6: 'América del Sur',
            7: 'Oceanía',
            8: 'Mundo',
            9: 'Afganistán',
            10: 'Albania',
            11: 'Argelia',
            12: 'Samoa Americana',
            13: 'Andorra',
            14: 'Angola',
            15: 'Anguilla',
            16: 'Antártida',
            17: 'Antigua y Barbuda',
            18: 'Emirates Árabes',
            19: 'Argentina',
            20: 'Armenia',
            21: 'Aruba',
            22: 'Australia',
            23: 'Austria',
            24: 'Azerbaiyán',
            25: 'Bahamas',
            26: 'Bahrein',
            27: 'Bangladesh',
            28: 'Barbados',
            29: 'Bielorrusia',
            30: 'Bélgica',
            31: 'Belice',
            32: 'Benin',
            33: 'Bermudas',
            34: 'Bután',
            36: 'Bolivia',
            37: 'Bonaire, Sint Eustatius y Saba',
            38: 'Bosnia-Herzegovina',
            39: 'Botswana',
            40: 'Isla Bouvet',
            41: 'Brasil',
            42: 'Territorio Británico del Océano Índico',
            43: 'Islas Vírgenes Británicas',
            44: 'Brunei Darussalam',
            45: 'Bulgaria',
            46: 'Burkina Faso',
            47: 'Burundi',
            48: 'Camboya',
            49: 'Camerún',
            50: 'Canadá',
            51: 'Cabo Verde',
            52: 'Islas Caimán',
            53: 'República Centroafricana',
            54: 'Chad',
            55: 'Chile',
            56: 'China PR',
            57: 'China Taipéi',
            58: 'Isla De Navidad, Isla Christmas',
            59: 'Islas Cocos',
            60: 'Colombia',
            61: 'Comores',
            62: 'República del Congo',
            63: 'República Democrática del Congo',
            64: 'Islas Cook',
            65: 'Costa Rica',
            66: 'Costa de Marfil',
            67: 'Croacia',
            68: 'Cuba',
            69: 'Curazao',
            70: 'Chipre',
            71: 'República Checa',
            72: 'Dinamarca',
            73: 'Djibouti, Yibuti',
            74: 'Dominica',
            75: 'Dominicana, República',
            76: 'Timor Oriental',
            77: 'Ecuador',
            78: 'Egipto',
            79: 'El Salvador',
            80: 'Inglaterra',
            81: 'Guinea Ecuatorial',
            82: 'Eritrea',
            83: 'Estonia',
            84: 'Etiopía',
            85: 'Islas Malvinas',
            86: 'Islas Feroe',
            87: 'Fiyi',
            88: 'Finlandia',
            89: 'Francia',
            90: 'Francia, metropolitana',
            91: 'Guayana Francesa',
            92: 'Polinesia Francesa',
            93: 'Tierras Australes y Antárticas Francesas',
            94: 'Gabón',
            95: 'Gambia',
            96: 'Georgia',
            97: 'Alemania',
            98: 'Ghana',
            99: 'Gibraltar',
            100: 'Gran Bretaña',
            101: 'Grecia',
            102: 'Groenlandia',
            103: 'Granada',
            104: 'Guadalupe',
            105: 'Guam',
            106: 'Guatemala',
            107: 'Bailía de Guernsey',
            108: 'República Guinea',
            109: 'Guinea Bissau',
            110: 'Guyana',
            111: 'Haiti',
            112: 'Santa Sede, Vaticano, Ciudad del Vaticano',
            113: 'Honduras',
            114: 'Hong Kong',
            115: 'Hungría',
            116: 'Islandia',
            117: 'India',
            118: 'Indonesia',
            119: 'Irán',
            120: 'Iraq',
            121: 'Irlanda',
            122: 'Isla de Man',
            123: 'Israel',
            124: 'Italia',
            125: 'Jamaica',
            126: 'Japón',
            127: 'Jersey',
            128: 'Jordania',
            129: 'Kazajstán',
            130: 'Kenia',
            131: 'Kiribati',
            132: 'Corea del Norte',
            133: 'Corea del Sur',
            134: 'Kosovo',
            135: 'Kuwait',
            136: 'Kirguistán',
            137: 'Laos',
            138: 'Letonia',
            139: 'Líbano',
            140: 'Lesotho',
            141: 'Liberia',
            142: 'Libia',
            143: 'Liechtenstein',
            144: 'Lituania',
            145: 'Luxemburgo',
            146: 'Macao',
            147: 'Macedonia',
            148: 'Madagascar',
            149: 'Malawi',
            150: 'Malasia',
            151: 'Maldivas',
            152: 'Malí',
            153: 'Malta',
            154: 'Islas Marshall',
            155: 'Martinica',
            156: 'Mauritania',
            157: 'Mauricio',
            159: 'Mayotte',
            160: 'México',
            161: 'Micronesia, Estados Federados de',
            162: 'Moldavia',
            163: 'Mónaco',
            164: 'Mongolia',
            165: 'Montenegro',
            166: 'Montserrat',
            167: 'Marruecos',
            168: 'Mozambique',
            169: 'Myanmar, Birmania',
            170: 'Namibia',
            171: 'Nauru',
            172: 'Nepal',
            173: 'Países Bajos',
            174: 'Antillas Holandesas',
            175: 'Nueva Caledonia',
            176: 'Nueva Zelanda',
            177: 'Nicaragua',
            178: 'Niger',
            179: 'Nigeria',
            180: 'Niue',
            181: 'Isla Norfolk',
            182: 'Irlanda del Norte',
            183: 'Marianas del Norte',
            184: 'Noruega',
            185: 'Omán',
            186: 'Pakistán',
            187: 'Palau',
            188: 'Territorios Palestinos',
            189: 'Panamá',
            190: 'Papúa-Nueva Guinea',
            191: 'Paraguay',
            192: 'Perú',
            193: 'Filipinas',
            194: 'Isla Pitcairn',
            195: 'Polonia',
            196: 'Portugal',
            197: 'Puerto Rico',
            198: 'Qatar',
            199: 'Reunión',
            200: 'Rumanía',
            201: 'Federación Rusa',
            202: 'Ruanda',
            203: 'San Martín',
            204: 'Samoa',
            205: 'San Marino',
            206: 'Santo Tomé y Príncipe',
            207: 'Arabia Saudita',
            208: 'Escocia',
            209: 'Senegal',
            210: 'Serbia',
            211: 'Serbia and Montenegro',
            212: 'Seychelles',
            213: 'Sierra Leona',
            214: 'Singapur',
            215: 'San Martín',
            216: 'Eslovaquia',
            217: 'Eslovenia',
            218: 'Islas Salomón',
            219: 'Somalia',
            220: 'Sudáfrica',
            221: 'Islas Georgias del Sur y Sándwich del Sur',
            222: 'Sudán del Sur',
            223: 'España',
            224: 'Sri Lanka',
            225: 'Santa Helena',
            226: 'San Cristobal y Nevis',
            227: 'Santa Lucía',
            228: 'San Pedro y Miquelón',
            229: 'San Vincente y Granadinas',
            230: 'Sudán',
            231: 'Surinam',
            232: 'Islas Svalbard y Jan Mayen',
            233: 'Swazilandia',
            234: 'Suecia',
            235: 'Suiza',
            236: 'Siria',
            237: 'Tahití',
            238: 'Taiwan, province of china',
            239: 'Tajikistan',
            240: 'Tanzania',
            241: 'Tailandia',
            242: 'Togo',
            243: 'Tokelau',
            244: 'Tonga',
            245: 'Trinidad y Tobago',
            246: 'Túnez',
            247: 'Turquía',
            248: 'Turkmenistan',
            249: 'Islas Turcas y Caicos',
            250: 'Tuvalu',
            251: 'Uganda',
            252: 'Ucrania',
            253: 'Emiratos Árabes Unidos',
            254: 'Estados Unidos',
            255: 'Islas Ultramarinas Menores de los Estados Unidos',
            256: 'Uruguay',
            257: 'Islas Vírgenes de los Estados Unidos',
            258: 'Uzbekistán',
            259: 'Vanuatu',
            260: 'Venezuela',
            261: 'Vietnam',
            262: 'Islas Virgenes Británicas',
            263: 'Islas Virgenes Americanas',
            264: 'Gales',
            265: 'Wallis y Futuna',
            266: 'Sáhara Occidental',
            267: 'Yemen',
            268: 'Zambia',
            269: 'Zanzíbar',
            270: 'Zimbabwe',
        },
        timeUnits: {
            now: 'ahora',
            day: 'día',
            days: 'días',
            month: 'mes',
            months: 'meses',
            hour: 'hora',
            hours: 'horas',
            minute: 'minuto',
            minutes: 'minutos',
            week: 'semana',
            weeks: 'semanas',
        },
        timeAgo: {
            today: 'Hoy',
            yesterday: 'Ayer',
            thisWeek: 'This week',
            daysAgo: 'days ago',
            weekAgo: 'A week ago',
            weeksAgo: 'weeks ago',
            monthAgo: 'A month ago',
            monthsAgo: 'months ago',
            yearAgo: 'A year ago',
            yearsAgo: 'years ago'
        },
        monthUnitsShort: {
            0: 'Genn',
            1: 'Febbr',
            2: 'Mar',
            3: 'Apr',
            4: 'Magg',
            5: 'Giugno',
            6: 'Luglio',
            7: 'Ag',
            8: 'Sett',
            9: 'Ott',
            10: 'Nov',
            11: 'Dic',
        }
    },
    adForceUpdate: {
        updateYourAds: 'Update your Ads',
        moreThan2MonthsAge:
            'You posted your ads more than 2 months ago. Please update your ads so other clubs knows if your are still looking.',
        updateAllAds: 'UPDATE ALL (%{number}) ADS',
        reviewYourAds: 'REVIEW YOUR ADS',
        updated: 'UPDATED',
        stillLooking: 'YES I AM STILL LOOKING',
        remove: 'REMOVE',
    },
};
