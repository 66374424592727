import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { UserSummitModel } from 'api/virtual-summit/models/summit-invitationl';
import MiscEventService from 'api/virtual-summit/misc-event.service';
import { modalOpen, modalClose } from 'store/modals/modalsActions';
import { EVENT_BLOG_IFRAME_MODAL } from 'constants/modals';
import LiveChatController from 'services/live-chat-controller';
import { EventAgendaDayModel } from 'api/virtual-summit/models/event-agenda';
import { ISTANBUL_SUMMIT_NOVEMBER_2024_ID } from '../components/authorized-event-page/events-map/constants';

class MiscEventState {
    isEventDataLoading: boolean;
    attendees: Array<any>;
    isRegistered: boolean;
    isRegistrationShown: boolean;

    isUpcommingSummitDataLoading: boolean;
    upcommingSummitData: UserSummitModel;
    agendaDays: EventAgendaDayModel[];
}

const defaultState: MiscEventState = {
    isEventDataLoading: false,
    attendees: [],
    isRegistered: false,
    isRegistrationShown: false,

    isUpcommingSummitDataLoading: false,
    agendaDays: [],
    upcommingSummitData: {
        id: undefined,
        userId: undefined,
        leadUserId: undefined,
        leadUserName: '',
        user: {},
        isUserRegistered: null,
        createdAt: null,
        timestamp: null,
        isTransferIn: false,
        transferInPositions: [],
        transferInStepCompleted: false,
        isTransferOut: false,
        transferOutPlayers: [],
        transferOutStepCompleted: false,
        isGeneralNetworking: false,
        generalNetworkingStepCompleted: false,
        generalNetworkingClubs: [],
        positionNames: [],
        extraAttendees: [],
        registerNowVisible: true,
        invitedAgncies: [],
        invitedAgncySuggestionCount: 0,
        pendingAgencyRequest: false,
    },
};

const stateController = new StateController<MiscEventState>('MISC_EVENT', defaultState);

class Actions {
    public static getEventData(squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await MiscEventService.loadEventData(squadId);
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        attendees: data.registeredSquads,
                        isRegistered: data.isLoggedSquadRegistered,
                        isRegistrationShown: data.isRegistrationShown,
                        offlineEventTimeZone: data.offlineEventTimeZone,
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    }
    public static getUserSummitsData() {
        return async (dispatch, getState: () => AppState) => {
            const userId = getState().auth.userId;
            const squadId = getState().auth.squadId;
            try {
                const data = await MiscEventService.loadUserSummitData(userId);
                const { offlineEventTimeZone } = await MiscEventService.loadEventData(squadId);

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        upcommingSummitData: {
                            ...prevState.upcommingSummitData,
                            offlineEventTimeZone,
                            ...data,
                        },
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static openBlogIframeModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                modalOpen({
                    id: EVENT_BLOG_IFRAME_MODAL,
                    className: 'event-blog-frame-modal',
                    customToggle: Actions.closeBlogIframeModal,
                })
            );
            if (window.innerWidth < 1300) {
                LiveChatController.hide();
            }
        };
    }
    public static closeBlogIframeModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(modalClose(EVENT_BLOG_IFRAME_MODAL));
            LiveChatController.show();
        };
    }

    public static getEventAgenda(eventId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await MiscEventService.loadEventAgenda(eventId);
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        agendaDays: data,
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    }
}

class Selectors {
    public static selectIsLoading = (state: AppState) =>
        state.events.misc.isEventDataLoading ||
        state.events.misc.isUpcommingSummitDataLoading ||
        state.events.virtualSummit.meetingPreparation.isLoading;

    public static selectIsRegisteredForSummit = (state) => {
        let substate = state.events.misc.upcommingSummitData;

        if (substate.userId === undefined) return null;
        if (substate.isUserRegistered !== null) return substate.isUserRegistered;
        return null;
    };
    public static isShowSearchBanner = (state: AppState) => {
        return (
            state.auth?.eventId === ISTANBUL_SUMMIT_NOVEMBER_2024_ID &&
            !Selectors.selectIsRegisteredForSummit(state) &&
            state.auth?.isEvent
        );
    };
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    MiscEventState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
};
