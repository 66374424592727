import { combineReducers } from 'redux';
import * as GridController from './grid.controller';

export class YourRequirementsState {
    grid: GridController.State;
}

export const Reducers = combineReducers({
    grid: GridController.Reducer,
});
