import axios from 'axios-config';
import { ComparisonModel, ComparisonLists, ComparisonRequest, Comparisonitem } from './models/comparison'
import { SuperPitchCreate } from './models/super-pitch-create'
import { ReceiverSuperPitch } from './models/received-super-pitch'
import { CancelToken } from 'axios';
import { PlayerModel } from 'store/entities/players/player.model';

export class SuperPitchService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async superPitchesFetch(squadId: number, userId: number): Promise<any> {
        const { data } = await axios.get(
            `api/SuperPitch/MyPitches/${squadId}/${userId}?offsetInMinutes=${-(new Date().getTimezoneOffset())}`,
            this.axiosConfig
        )
        return data
    }

    public static async testInterest(squadId: number, userId: number, pitchId: number, pitchSquadId: number): Promise<any> {
        const { data } = await axios.post(
            `api/SuperPitch/TestInterest/${squadId}/${userId}/${pitchId}`,
            { pitchSquadIds: [pitchSquadId] },
            this.axiosConfig
        );
        return data;
    }

    public static async testInterestBatch(squadId: number, pitchId: number, pitchSquadIds: Array<number>): Promise<any> {
        const { data } = await axios.post(
            `api/SuperPitch/TestInterestBatch`,
            { squadId: squadId, pitchId: pitchId, pitchSquadIds: pitchSquadIds },
            this.axiosConfig
        );
        return data;
    }

    public static async activeSquadsFetch(): Promise<any> {
        const { data } = await axios.get(
            `api/Competition/GetCompetitionWithActiveSquads?showOnlyPrimary=true`,
            this.axiosConfig
        )
        return data
    }

    public static async loadComparisonData(userId: number, squadId: number, playerId: number, payload: ComparisonRequest): Promise<ComparisonModel> {
        const { data } = await axios.post(
            `api/SuperPitch/ComparisonSelection/${userId}/${squadId}/${playerId}`,
            payload,
            this.axiosConfig
        )
        return data
    }

    public static async getSuggestedPlayer(mainPlayerId: number, comparisonKey: string, squadIds: Array<number>): Promise<Array<Comparisonitem>>{
        const { data } = await axios.post(
            `api/SuperPitch/ComparisonAutosuggestPlayers/${mainPlayerId}/${comparisonKey}`,
            squadIds,
            this.axiosConfig
        )
        return data
    }

    public static async loadComparisonDataOnSelect(playerId: number, payload: ComparisonRequest): Promise<ComparisonLists> {
        const { data } = await axios.post(
            `api/SuperPitch/Comparison/${playerId}`,
            payload,
            this.axiosConfig
        )
        return data
    }

    public static async comparisonSearchSuggestedPlayers(mainPlayerId: number, characteristicKey: string, searchKey: string): Promise<any> {
        const { data } = await axios.get(
            `api/SuperPitch/ComparisonSearchSuggestedPlayers/${mainPlayerId}/${characteristicKey}/?searchKey=${searchKey}`,
            this.axiosConfig
        )
        return data
    }

    public static async createSuperPitch(squadId: number, userId: number, payload: SuperPitchCreate): Promise<any> {
        const { data } = await axios.post(
            `api/SuperPitch/Create/${squadId}/${userId}`,
            payload,
            this.axiosConfig
        )
        return data
    }

    public static async getReceivedSuperpitches(cancelToken?: CancelToken): Promise<Array<ReceiverSuperPitch>> {
        const { data } = await axios.get(
            `api/SuperPitch/GetReceivedPitches`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async getSavedSuperpitches(cancelToken?: CancelToken): Promise<Array<ReceiverSuperPitch>> {
        const { data } = await axios.get(
            `api/SuperPitch/GetSavedPitches/-1/-1`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async dismissPitch(id: number) {
        const { data } = await axios.post(
            `api/SuperPitch/DismissPitchSquad/${id}`,
            {},
            this.axiosConfig
        )
        return data
    }

    public static async dismissPitchTI(superPitchSquadId: number) {
        const { data } = await axios.post(
            `api/SuperPitch/DismissTi/${superPitchSquadId}`,
            {},
            this.axiosConfig
        )
        return data
    }

    public static async deleteTI(superPitchSquadId: number) {
        const { data } = await axios.get(
            `api/SuperPitch/DeleteTestInterest/${superPitchSquadId}`,
            this.axiosConfig
        )
        return data
    }

    public static async removeSuperPitch(squadId: number, userId: number, pitchId: number): Promise<any> {
        return await axios.post(
            `api/SuperPitch/Remove/${squadId}/${userId}/${pitchId}`,
            {},
            this.axiosConfig
        )
    }

    public static async saveSuperPitch(pitchId: number): Promise<any> {
        return await axios.post(
            `api/SuperPitch/SavePitchSquad/${pitchId}`,
            {},
            this.axiosConfig
        )
    }

    public static async declareInterestPitchSquad(squadPitchId: number): Promise<any> {
        return await axios.post(
            `api/SuperPitch/DeclareInterestPitchSquad/${squadPitchId}`,
            {},
            this.axiosConfig
        )
    }

    public static async shortlistToggle(playerId: number, isInShortList: boolean): Promise<any> {
        if (isInShortList) {
            const { data } = await axios.delete(
                `api/ShortList/1/${playerId}`,
                this.axiosConfig
            )
            return data
        } else {
            const { data } = await axios.put(
                `api/ShortList/1/${playerId}`,
                {},
                this.axiosConfig
            )
            return data
        }
    }

    public static async loadMySquadPlayers(currentClubId: number, currentUserId: number, currentSquadId: number, payload: any): Promise<{ output: PlayerModel[] }> {
        const { data } = await axios.post(
            `api/MySquad/GetPlayersByFilterWithPagination/${currentClubId}/${currentSquadId}/${currentUserId}`,
            payload,
            this.axiosConfig
        )
        return data;
    }
}