import React from 'react';
import styled, { css } from 'styled-components';
import CTAButton from './cta-button';
import { maxDevice } from 'media/styles/mixins/breakpoints';
import Vector from 'media/images/events/2024-november-istanbul/Vector5.png';

interface OwnProps {
    isLoading: boolean;
    onRegister: () => void;
    isRegistered: boolean;
    isRegistrationClosed: boolean;
    title: string;
    date: string;
    altDate?: string;
    eventLocation?: string;
    registerBtnText?: string;
    className?: string;
    showLimitedButton?: boolean;
    description?: string;
    darkBackgroundMode?: boolean;
    altTitle?: string;
    altClassName?: string;
}

export default function BannerNotRegistered({
    isLoading = false,
    onRegister,
    isRegistered,
    isRegistrationClosed = false,
    title,
    date,
    altDate = null,
    eventLocation,
    registerBtnText,
    className,
    showLimitedButton = true,
    description,
    darkBackgroundMode = false,
    altTitle = null,
    altClassName = null,
}: OwnProps) {
    return (
        <Banner className={className} blackIcons={false}>
            <div className='title'>
                <p>
                    <span>
                        The bridge to <strong>world football's</strong>
                    </span>
                </p>
                <p>
                    <span className='highlight'>DECISION-MAKERS</span>
                </p>
            </div>

            <div className='event-label'>
                <div className='location-summit'>
                    <span>İstanbul</span>
                    <span className='separator' />
                    <span>TransferRoom Summit</span>
                </div>
                <label>
                    <div className='label-item'>
                        <div>İstanbul</div>
                    </div>
                    <div className='label-item'>
                        <div>Nov 11 + 12</div>
                    </div>
                </label>
                <label>
                    <div className='label-item'>
                        <div>Online</div>
                    </div>
                    <div className='label-item'>
                        <div>Nov 14</div>
                    </div>
                </label>
                <CTAButton
                    isLoading={isLoading}
                    isRegistrationNotYetOpened={false}
                    isRegisrtarionClosed={isRegistrationClosed}
                    isRegistered={isRegistered}
                    onRegister={onRegister}
                    registerBtnText={registerBtnText}
                    isGreenMode={false}
                />
            </div>
        </Banner>
    );
}
const Banner = styled.section<any>`
    color: white;
    height: auto;
    text-align: center;
    background-color: #0e854c;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 100%;
    padding: 51px 20px 60px;

    @media ${maxDevice.mobileS} {
        padding: 100px 30px 63px;
    }

    .title {
        margin: 0 0 15px;
        font-size: 54px;
        p {
            & > span {
                color: #fff;
                text-align: center;
                font-size: 45px;
                font-weight: 700;
                line-height: normal;

                @media ${maxDevice.tabletM} {
                    font-size: 35px;
                }

                @media ${maxDevice.mobileM} {
                    text-shadow: 0px 4px 16px rgba(19, 26, 54, 0.5);
                    font-size: 28px;
                }

                & > b {
                    font-family: Inter;
                    font-weight: 600;
                }
            }
        }
    }
    .subtitle {
        display: block;
        margin-bottom: 25px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: normal;

        @media ${maxDevice.tabletM} {
            font-size: 16px;
        }

        @media ${maxDevice.mobileS} {
            display: none;
        }
    }
    .highlight {
        background-color: #0e9655;
        color: white;
        padding: 10px 10px;
        font-weight: 700;
    }

    .location-summit {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        gap: 10px;
        @media ${maxDevice.desktop} {
            font-size: 15px;
        }
    }

    .separator {
        background: transparent url(${Vector}) no-repeat;
        width: 1px;
        height: 29.513px;
        stroke-width: 1.115px;
        stroke: #fff;
        flex-shrink: 0;
        margin: 0 12px;
    }
    .event-label {
        padding-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin-bottom: 30px;

        @media ${`(max-width: 860px)`} {
            flex-direction: column;
            align-items: center;
            margin-bottom: 23px;
            gap: 10px;
        }
        @media ${maxDevice.mobileS} {
            flex-direction: column;
            align-items: center;
            margin-bottom: 23px;
            gap: 10px;
        }

        & > label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0;
            border-radius: 69.067px;
            border: 1.233px solid #fff;
            background: rgba(255, 255, 255, 0.1);

            @media ${maxDevice.mobileM} {
                border-radius: 56px;
                border: 1px solid #fff;
            }

            .label-item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 37px;

                @media ${maxDevice.mobileM} {
                    height: 30px;
                }
                @media ${maxDevice.desktop} {
                    height: 40px;
                    font-size: 15px;
                }

                & div {
                    color: #fff;
                    font-size: 18.5px;
                    font-weight: 700;
                    line-height: normal;
                    padding: 0 12px;

                    @media ${maxDevice.mobileM} {
                        font-size: 15px;
                    }
                    @media ${maxDevice.desktop} {
                        font-size: 15px;
                    }
                    & span {
                        font-family: Inter;
                        font-weight: 600;
                    }
                }

                &:first-child {
                    border-right: 1.233px solid #fff;
                    border-radius: 69.067px 0px 0px 69.067px;
                    background: rgba(255, 255, 255, 0.25);
                }
            }

            @media ${maxDevice.mobileM} {
                font-size: 17px;
                max-width: fit-content;
            }
        }
    }

    .confirmed-label {
        padding-top: 7px;
        padding-bottom: 9px;
        background-color: white;
        display: inline-block;
        /* width: fit-content; */
        width: 206px;
        margin: auto 0;
        position: relative;
        border-radius: 12px;
        text-transform: uppercase;
        color: #0e9655;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        span {
            top: 1px;
            position: relative;
            display: flex;
            justify-content: center;
            padding-right: 3px;
            white-space: nowrap;
        }
        &__icon {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #0e9655;
            position: relative;
            margin-right: 5px;
            > i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 11px;
            }
        }
    }
    .spaces {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        .label {
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            padding: 10px 20px;
            background-color: #ffffff;
            width: 250px;
            color: red;
        }
    }

    & > button {
        background: #fff;
        color: #131a36;
        font-size: 160px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.16px;
        min-height: 50px;
        border-radius: 5px;
        border: 1px solid #fff;

        @media ${maxDevice.mobileM} {
            min-height: 48px;
            font-size: 14px;
            letter-spacing: -0.14px;
        }

        &:hover {
            background: #fff;
            opacity: 0.9;
        }

        @media ${maxDevice.mobileS} {
            width: calc(100% - 20px);
            min-height: 48px;
        }
    }
`;
