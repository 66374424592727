import Range from 'api/core/range';
import { SearchPlayerPositionEnum } from './player-positions';
import { FootTypeEnum } from 'api/core/foot-type';

export interface SearchRequest {
    playerFilter: SearchPlayerFilter;
    // clubsAndAgentsFilter: OnlyAgentsAndClubsFilter;
    shortListOnly: boolean;
    sortByKey: SortByKeyEnum;
    keyword: string;
    pageNumber: number;
    pageSize: number;
}

export interface SearchCoachRequest {
    searchCoachFilter: SearchCoachFilter;
    coachesSet: number[];
    sortByKey: SortByKeyEnum;
    keyword: string;
    pageNumber: number;
    pageSize: number;
}

export interface SearchPlayerFilter {
    age: Range<number>;
    gbePass?: boolean;
    showInterestedInClubPlayers?: boolean;
    minHeight?: number;
    maxHeight?: number;
    rating?: Range<number>;
    contractExpiryMonthesMax?: number;
    leaguesList: number[];
    previousLeaguesList: number[];
    nationalityList: number[];
    position?: SearchPlayerPositionEnum;
    // public positionList: Array<string>;
    showOnlyFreeAgentPlayers: boolean;
    showOnlyAvailablePlayers: boolean;
    showOnlyPlayersWithSuspendedContract: boolean;
    showOnlyPlayersLookingForAgent: boolean;
    showOnlyPlayersOnReleaseList: boolean;
    transferFee?: Range<number>;
    anuallGrossSalaryYearly?: Range<number>;
    loanFee?: Range<number>;
    marketValue?: Range<number>;
    minutesPlayed?: Range<number>;
    footTypes: FootTypeEnum[];
    positionRoles: number[];
}

export interface SearchCoachFilter {
    minExperienceInMonths: number | null;
    hasPlayingCareer: boolean | null;
    leaguesList: number[] | null;
    achievementList: AchievementItem[] | null;
    achievementAgencyList: AchievementAgencyTypeEnum[] | null;
    rating: Range<number> | null;
    trustInYouth: Range<number> | null;
    playingStyleList: PlayingStyleEnum[] | null;
    formationList: FormationEnum[] | null;
    nationalityList: number[] | null;
    maxAge: number | null;
    licenseTypeList: CoachingLicenseTypeEnum[] | null;
    showOnlyFreeAgentCoaches: boolean | null;
    isHighSuitability: boolean | null;
}

export interface SearchStaffFilter {
    minExperienceInMonths: number | null;
    hasPlayingCareer: boolean | null;
    leaguesList: number[] | null;
    achievementList: AchievementItem[] | null;
    achievementAgencyList: AchievementAgencyTypeEnum[] | null;
    rating: Range<number> | null;
    trustInYouth: Range<number> | null;
    playingStyleList: PlayingStyleEnum[] | null;
    formationList: FormationEnum[] | null;
    nationalityList: number[] | null;
    maxAge: number | null;
    licenseTypeList: CoachingLicenseTypeEnum[] | null;
    showOnlyFreeAgentCoaches: boolean | null;
    isHighSuitability: boolean | null;
}

export interface AchievementItem {
    competitionId: number;
    achievementType: AchievementTypeEnum;
}

export interface AchievementAgencyItem {
    competitionId: number;
    achievementType: AchievementAgencyTypeEnum;
}

export enum SortByKeyEnum {
    Availability = 0,
    XtvHighToLow = 1,
    XtvLowToHigh = 2,
    AgeHighToLow = 3,
    AgeLowHigh = 4,
    ContractExpiry = 5,
    ByRelevant = 6,
    DateWhenAddedToShortList = 7,
    Similarity = 8,
    RatingHighToLow = 9,
    RatingLowToHigh = 10,
    AvailableSinceNewestToOldest = 11,
    AvailableSinceOldestToNewest = 12,
    Alphabetically = 13,
    AlphabeticallyReversed = 14,
    TrustInYouthHighToLow = 15,
    PotentialRatingHighToLow = 16,
    MaxSpeedHighToLow = 17,
    RecentlyAdded = 18,
    ByRelevanceAgency = 19,
}

export interface SearchAgenciesRequest {
    sortByKey: SortByKeyEnum;
    keyword: string;
    pageNumber: number;
    pageSize: number;
}

export enum AchievementTypeEnum {
    Default = 0,
    Champion = 1,
    PromotedFrom = 2,
    PromotedTo = 3,
    RelegatedFrom = 4,
    RelegatedTo = 5,
    IndividualAward = 6,
}

export enum AchievementAgencyTypeEnum {
    Default = 0,
    DomesticLeagueWinner = 1,
    DomesticCupWinner = 2,
    ContinentialCupWinner = 3,
    AchievedPromotion = 4,
}

export enum FormationEnum {
    Default = 0,
    F4321 = 1,
    F451 = 2,
    F433 = 3,
    F4141 = 4,
    F4222 = 5,
    F442 = 6,
    F4411 = 7,
    F4231 = 8,
    F4213 = 9,
    F4132 = 10,
    F4312 = 11,
    F541 = 12,
    F3421 = 13,
    F343 = 14,
    F532 = 15,
    F352 = 16,
    F3511 = 17,
    F3412 = 18,
}

export enum CoachingLicenseTypeEnum {
    Default = 0,
    ProLicense = 1,
    ALicense = 2,
    BLicense = 3,
    CLicense = 4,
}

export enum PlayingStyleEnum {
    Default = 0,
    FavoursPossession = 1,
    FavoursDirectAttack = 2,
    FavoursHighPress = 3,
    FavoursLowBlock = 4,
}

export enum HeadCoachExperienceEnum {
    Default = 0,
    OverTwoYears = 1,
    OverFiveYears = 2,
    OverEightYears = 3,
    OverTenYears = 4,
    HasPlayingCareer = 5,
}

export interface SearchStaffProfileRequest {
    filter: SearchStaffProfileFilter;
    sortByKey: SortByKeyEnum;
    keyword: string;
    pageNumber: number;
    pageSize: number;
}

export interface SearchStaffProfileFilter {
    staffTypeId: number | null;
    minExperienceYears: number | null;
    hasPlayingCareer: boolean | null;
    showOnlyFreeAgents: boolean | null;
    leagueIds: number[];
    nationalityAreaIds: number[];
    languageIds: number[];
    licenseTypeIds: CoachingLicenseTypeEnum[];
    eligibilityStaffTypeId: number | null;
}
