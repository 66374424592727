import { applyMiddleware, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './rootEpic';
import rootReducer from './root.reducer.ts';
import { loadState } from 'store/localStorageState';
import { disposeLoginUiState } from 'store/auth/auth.actions'
import {
    loadTranslations,
    syncTranslationWithStore,
} from 'react-redux-i18n';

import { translationsEn } from 'localization/localization.en';
import { translationsIt } from 'localization/localization.it';
import { translationsEs } from 'localization/localization.es';

const epicMiddleware = createEpicMiddleware();

export default function configureStore(initialState) {
    const middleware = [thunk, epicMiddleware];

    const translationsObject = {
        en: translationsEn,
        it: translationsIt,
        es: translationsEs,
    };

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (
        isDevelopment &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION__
    ) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    let prevState = loadState();

    const store = createStore(
        rootReducer,
        { ...initialState, ...prevState },
        compose(
            applyMiddleware(...middleware),
            ...enhancers,
        ),
    );

    store.dispatch(disposeLoginUiState());
    syncTranslationWithStore(store);
    store.dispatch(loadTranslations(translationsObject));

    epicMiddleware.run(rootEpic);
    return store;
}
