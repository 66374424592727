import { SubscriptionPlanEnum } from 'constants/enums';

export const getIsRestrictedEUUKFreemium = (subscriptionPlan: SubscriptionPlanEnum) => {
    return [SubscriptionPlanEnum.EUFreemium, SubscriptionPlanEnum.UKFreemium].includes(
        subscriptionPlan,
    );
};

export const getIsRestrictedEntrySubscriptions = (subscriptionPlan: SubscriptionPlanEnum) => {
    return [
        SubscriptionPlanEnum.EntryPackage,
        SubscriptionPlanEnum.EntryPackageLatam,
        SubscriptionPlanEnum.UKStarter,
        SubscriptionPlanEnum.UKFreemium,
        SubscriptionPlanEnum.EUFreemium,
    ].includes(subscriptionPlan);
};
