import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor';
import { agencyPaths } from 'routes/paths';
import AgencyRequirementsService from 'api/agency/your-requirements/your-requirements.service';
import {
    FilterCriteria,
    PaginationState,
    RequirementsFilterRequest,
    AgencyRequirement,
    AgencyRequirementPlayer,
} from 'api/agency/your-requirements/your-requirements.models';

const DEFAULT_PAGE_SIZE = 20;

class YourRequirementsGridState {
    isLoading: boolean;
    pagination: PaginationState;
    filterCriteria: FilterCriteria;
    requirements: AgencyRequirement[];
    selectedRequirement: AgencyRequirement;
    filterVisible: boolean;
    processingIds: number[];
    pitchedPlayers: AgencyRequirementPlayer[];
}

const defaultFilterCriteria: FilterCriteria = {
    isForLoan: false,
    isForSale: false,
    keyword: '',
    positionCodes: [],
    isLeftFooted: false,
};

const defaultPagination: PaginationState = {
    currentPage: 1,
    pageCount: 0,
    rowCount: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    totalLength: 0,
};

const defaultState: YourRequirementsGridState = {
    isLoading: true,
    processingIds: [],
    pagination: defaultPagination,
    requirements: [],
    filterVisible: false,
    filterCriteria: defaultFilterCriteria,
    selectedRequirement: null,
    pitchedPlayers: [],
};

const stateController = new StateController<YourRequirementsGridState>(
    'AGENCY/PITCH_PAGE/YOUR_REQUIREMENTS_GRID',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static initGrid() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadRequirements());
        };
    }

    public static onPageTurn(paginationIndex) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    pagination: {
                        ...prevState.pagination,
                        currentPage: paginationIndex,
                    },
                }))
            );
            dispatch(Actions.loadRequirements());
        };
    }

    public static loadRequirements() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    isLoading: true,
                }))
            );

            const request = Selectors.getFilterRequestInfo(getState());
            const data = await AgencyRequirementsService.getAgencyRequirements(request);

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    requirements: data.pageItems,
                    pagination: {
                        ...prevState.pagination,
                        rowCount: data.total,
                        pageSize: data.pageSize,
                        pageCount: data.pageCount,
                    },
                    isLoading: false,
                }))
            );

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                }))
            );
        };
    }

    public static onFilterChange(filter: FilterCriteria) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    pagination: { ...defaultPagination },
                    filterCriteria: { ...filter },
                }))
            );
            dispatch(Actions.loadRequirements());
        };
    }

    public static selectRequirment(requirement: AgencyRequirement) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    selectedRequirement: { ...requirement },
                }))
            );
        };
    }

    public static searchByKeyword(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let criteria = Selectors.getFilterCriteria(state);
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    pagination: { ...defaultPagination },
                    filterCriteria: { ...criteria, keyword },
                }))
            );
            dispatch(Actions.loadRequirements());
        };
    }

    public static updateFilterVisibleState(isVisible: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    filterVisible: isVisible,
                }))
            );
        };
    }

    public static pitch() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(Actions.loadRequirements());
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static editPitchRequirement() {
        return (dispatch, getState: () => AppState) => {
            const id = Selectors.getSelectedRequirement(getState()).agentRequirementId;
            historyAccessor.push(`${agencyPaths.pitchRequirement}?adId=${id}`);
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState): YourRequirementsGridState =>
        state.agency.yourRequirements.grid;
    public static getRequirements = (state: AppState) => Selectors.getRoot(state).requirements;
    public static getIsLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static getPagination = (state: AppState) => Selectors.getRoot(state).pagination;
    public static getFilterCriteria = (state: AppState) => Selectors.getRoot(state).filterCriteria;
    public static getFilterRequestInfo = (state: AppState): RequirementsFilterRequest => {
        const pagination = Selectors.getPagination(state);
        const creteria = Selectors.getFilterCriteria(state);

        let res = new RequirementsFilterRequest();
        res.isForLoan = creteria.isForLoan;
        res.isForSale = creteria.isForSale;
        res.keyword = creteria.keyword;
        res.page = pagination.currentPage;
        res.pageSize = pagination.pageSize;
        res.positionCodes = creteria.positionCodes;
        res.isLeftFooted = creteria.isLeftFooted;

        return res;
    };
    public static getSelectedRequirement = (state: AppState) =>
        Selectors.getRoot(state).selectedRequirement;
    public static getProcessingIds = (state: AppState) => Selectors.getRoot(state).processingIds;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    YourRequirementsGridState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
};
