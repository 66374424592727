import axios from 'axios-config';

import { CancelToken } from 'axios';
import {
    ProfileCareerDraftResponse,
    SavePositionRequest,
    SaveProfileAboutTextRequest,
    SaveProfileAgeRequest,
    SaveProfileCareerRequest,
    SaveProfileHighlightsRequest,
    SaveProfileNameRequest,
    SaveProfilePersonalSummaryRequest,
    SaveProfileSaveKeySkillsRequest,
    StaffProfileModel,
} from './model';
import { CommonListResponse } from 'api/core/common-list-response';
import { SquadShortInfo } from 'api/core/squad-short-info';

export class StaffProfileService {
    private static axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    public static async getCurrentProfile(): Promise<StaffProfileModel> {
        const { data } = await axios.get('api/StaffProfile/Current', this.axiosConfig);
        return data;
    }

    public static async getProfile(staffId: number): Promise<StaffProfileModel> {
        const { data } = await axios.get(`api/StaffProfile/Profile/${staffId}`, this.axiosConfig);
        return data;
    }

    public static async uploadImage(
        file: string | Blob,
        name: string,
        onUploadProgressCallback: (progressEvent: any) => void,
        cancelToken: CancelToken
    ) {
        let formData = new FormData();
        formData.append(name, file);
        let res = await axios.post('api/StaffProfile/UploadImage', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: onUploadProgressCallback,
            cancelToken: cancelToken,
        });
        return res;
    }

    public static async uploadBackgroundImage(
        file: string | Blob,
        name: string,
        onUploadProgressCallback: (progressEvent: any) => void,
        cancelToken: CancelToken
    ) {
        let formData = new FormData();
        formData.append(name, file);
        let res = await axios.post('api/StaffProfile/UploadBackgroundImage', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: onUploadProgressCallback,
            cancelToken: cancelToken,
        });
        return res;
    }

    public static async saveName(payload: SaveProfileNameRequest) {
        const { data } = await axios.post(`api/StaffProfile/SaveName`, payload, this.axiosConfig);
        return data;
    }

    public static async savePosition(payload: SavePositionRequest) {
        const { data } = await axios.post(
            `api/StaffProfile/SavePosition`,
            payload,
            this.axiosConfig
        );
        return data;
    }

    public static async saveAboutText(payload: SaveProfileAboutTextRequest) {
        const { data } = await axios.post(
            `api/StaffProfile/SaveAboutText`,
            payload,
            this.axiosConfig
        );
        return data;
    }

    public static async saveAge(payload: SaveProfileAgeRequest) {
        const { data } = await axios.post(`api/StaffProfile/SaveAge`, payload, this.axiosConfig);
        return data;
    }

    public static async saveHighlights(payload: SaveProfileHighlightsRequest) {
        const { data } = await axios.post(
            `api/StaffProfile/SaveHighlights`,
            payload,
            this.axiosConfig
        );
        return data;
    }

    public static async savePersonalSummary(payload: SaveProfilePersonalSummaryRequest) {
        const { data } = await axios.post(
            `api/StaffProfile/SavePersonalSummary`,
            payload,
            this.axiosConfig
        );
        return data;
    }

    public static async saveKeySkills(payload: SaveProfileSaveKeySkillsRequest) {
        const { data } = await axios.post(
            `api/StaffProfile/SaveKeySkills`,
            payload,
            this.axiosConfig
        );
        return data;
    }

    public static async saveCareer(payload: SaveProfileCareerRequest) {
        const { data } = await axios.post(`api/StaffProfile/SaveCareer`, payload, this.axiosConfig);
        return data;
    }

    public static async getCoachingLicenses(): Promise<CommonListResponse> {
        const { data } = await axios.get('api/StaffProfile/CoachingLicenses', this.axiosConfig);
        return data;
    }

    public static async getSkills(): Promise<CommonListResponse> {
        const { data } = await axios.get('api/StaffProfile/Skills', this.axiosConfig);
        return data;
    }

    public static async getStaffTypesForFilter(): Promise<CommonListResponse> {
        const { data } = await axios.get('api/StaffProfile/StaffTypesForFilter', this.axiosConfig);
        return data;
    }

    public static async getProfileCareerDraft(): Promise<ProfileCareerDraftResponse> {
        const { data } = await axios.get('api/StaffProfile/ProfileCareerDraft', this.axiosConfig);
        return data;
    }

    public static async getSquadsShortInfo(
        keyword: string,
        cancelToken: CancelToken
    ): Promise<SquadShortInfo[]> {
        const { data } = await axios.get(`api/Squad/SuggestSquads/0?keyword=${keyword}&take=200`, {
            ...this.axiosConfig,
            cancelToken: cancelToken,
        });
        return data.map((x) => ({
            id: x.id,
            clubId: x.clubId,
            shortName: x.shortName,
            name: x.englishOfficialName,
            league: x.league,
            country: x.country,
            logo: x.logo,
            isLogoRestricted: x.isLogoRestricted,
        }));
    }
}
