export const currency = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    //CHF: 'CHF',
};

export const currencyById = {
    1: '€',
    2: '$',
    3: '£',
    //4: 'CHF',
}
