import axios from 'axios-config';
import { CancelToken } from 'axios';
import {
    PlayerPositionsResponse,
    AgencyRequirementsResponse,
    RequirementsFilterRequest,
} from './your-requirements.models';

export default class AgencyRequirementsService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };
    public static async getPlayerPositions(): Promise<PlayerPositionsResponse> {
        const { data } = await axios.get(`api/v3/Search/PlayerPositions`, this.axiosConfig);
        return data;
    }

    public static async getAgencyRequirements(
        request: RequirementsFilterRequest
    ): Promise<AgencyRequirementsResponse> {
        const { data } = await axios.post(
            `api/v2/AgentRequirement/GetRequirementsList`,
            request,
            this.axiosConfig
        );
        return data;
    }
}
