import { CancelToken } from 'axios';
import axios from 'axios-config';
import { AgencyModel, MarketAreaModel, AgencyInfoModel, AgentPlayerRepresentationRequestModel, RecommendationModel, PlayerMarkedInterestAreaSourceEnum } from './models';
import { ChangeAgencyConnectionDetailsRequest } from "../player-side/models/add-agency-connection-request";

export default class AgencyFinderService {
  private static axiosConfig = {
    headers: { 'Content-Type': 'application/json' },
  };

  public static async saveToTempDataTableValues(userId: number): Promise<any> {
    const { data } = await axios.post<any>(
      `api/v2/AgencyFinder/SaveToTempDataTableValues/${userId}`,
      null,
      this.axiosConfig
    );

    return data;
  }

  public static async savePlayerInterestedMarkets(areaIds: Array<number>, source: PlayerMarkedInterestAreaSourceEnum): Promise<any> {
    const { data } = await axios.post<any>(
      `api/v2/AgencyFinder/PlayerInterestedMarkets`,
      { areaIds: areaIds, source: source },
      this.axiosConfig
    );

    return data;
  }

  public static async sendRepresentationRequestToPlayer(playerId: number): Promise<any> {
    const { data } = await axios.post<any>(
      `api/v2/AgencyFinder/SendRepresentationRequestToPlayer/${playerId}`,
      null,
      this.axiosConfig
    );

    return data;
  }

  public static async getMarkets(qty: number, topMarketsToSkipQty: number): Promise<Array<MarketAreaModel>> {
    const { data } = await axios.get(
      `api/AgencyPublicProfile/GetFirstAgencies/${qty}?numberOfTopMarketsToSkip=${topMarketsToSkipQty}`,
      this.axiosConfig
    );
    return data;
  }

  public static async getTopMarkets(qty: number): Promise<Array<MarketAreaModel>> {
    const { data } = await axios.get(
      `api/AgencyPublicProfile/GetTopProfiles/${qty}`,
      this.axiosConfig
    );
    return data;
  }

  public static async getAgencySuggestions(keyword: string, cancelToken: CancelToken) {
    const { data } = await axios.post<AgencyModel[]>('api/v2/AgencyFinder/Suggestions', {
      keyword
    }, { ...this.axiosConfig, cancelToken: cancelToken })
    return data;
  }

  public static async getAgencyInfo(): Promise<AgencyInfoModel> {
    const { data } = await axios.get(
      `api/v2/AgencyFinder/GetAgencyInfoForPlayerApp`,
      this.axiosConfig
    );
    return data;
  }

  public static async updateAgencyRequest(playerId: number, isOpen: boolean): Promise<any> {
    const { data } = await axios.post<any>(
      `api/v2/AgencyFinder/UpdateAgencyRequestVisibility`,
      {
        playerId,
        isOpen
      },
      this.axiosConfig
    );
    return data;
  }

  public static async changeAgentDetails(agent: ChangeAgencyConnectionDetailsRequest) {
    const { data } = await axios.post(
      `api/v2/PayerPreference/ChangeDetails`,
      agent,
      this.axiosConfig
    )
    return data;
  }

  public static async sendRepresentationRequestToAgency(agencyIds: number[]) {
    await axios.post('api/v2/AgencyFinder/SendRepresentationRequestToAgency', {
      agencyIds
    }, this.axiosConfig)
  }

  public static async addAgency(agencyId: number) {
    const { data } = await axios.post(
      `api/v2/AgencyFinder/AddAgency/${agencyId}`,
      this.axiosConfig
    );
    return data;
  }

  // To add a test mode for credit swap use 'x-date': dateTest? dateTest : new Date().toISOString(),
  public static async removeAgency() {
    const { data } = await axios.post(
      `api/v2/PayerPreference/RemoveAgency`,
      null,
      this.axiosConfig
    )
    return data;
  }
  // public static async removeAgency(dateTest: string) {
  //   const { data } = await axios.post(
  //     `api/v2/PayerPreference/RemoveAgency`,
  //     null,
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'x-date': dateTest ? dateTest : new Date().toISOString(),
  //       }
  //     }
  //   )
  //   return data;
  // }

  public static async getAgentPlayerRepresentationList(): Promise<Array<AgentPlayerRepresentationRequestModel>> {
    const { data } = await axios.get(
      `api/v2/AgencyFinder/GetAgentPlayerRepresenatationList`,
      this.axiosConfig
    );
    return data;
  }

  public static async getRecommendations(): Promise<Array<RecommendationModel>> {
    const { data } = await axios.get(
      `api/v2/AgencyFinder/Recommendations`,
      this.axiosConfig
    );
    return data;
  }

  public static async getCountryAgencies(areaId: number) {
    const { data } = await axios.get<AgencyModel[]>(
      `api/v2/AgencyFinder/CountryAgencies/${areaId}`,
      this.axiosConfig
    );
    return data;
  }

  public static async getRecommendedAgencies(competitionIds: number[]) {
    const { data } = await axios.post<AgencyModel[]>('api/v2/AgencyFinder/RecommendedAgencies', {
      competitionIds
    }, this.axiosConfig)
    return data;
  }

  public static async RemoveDisconnectedFromAgentInvitation() {
    const { data } = await axios.post(
      `api/v2/AgencyFinder/RemoveDisconnectedFromAgentInvitation`,
      this.axiosConfig
    );
    return data;
  }
}
