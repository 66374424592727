import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import positionsWithoutAds from './positions-without-ads.reducer';

export default combineReducers({
    positionsWithoutAds: positionsWithoutAds,
});

const path = state => state.playerAds;
const pathFilter = state => state.playerSearch;

export const getSelectedPositionsInFilter = createSelector(
    pathFilter,
    filter => filter.filter.criteria.positions,
);

export const getPositionsWithoutAds = createSelector(
    path,
    ads => ads.positionsWithoutAds,
);


