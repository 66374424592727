import { StateController } from 'utils/action-declaration'
import { AppState } from "root.reducer";
import PasswordValidation from "utils/validate-password";
import UserPasswordService from "api/user-password/user-password";
import { getAuth } from "store/auth/authReducer";
import AgencySettingsService from "api/agency/agent/agent-profile/agent-profile.service";
import AgencyFinderService from "api/player-v2/agency-finder.service";
import { PlayerPreferencesService } from 'api/player-preferences/player-preferences.service';
import { SettingsInitialInfoRequest } from 'api/player-preferences/model';
import { CurrencyEnum } from 'api/agency/agent/landing/strategy-modal-models';
import { PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS } from 'store/actionTypes';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { commonPagesPaths } from "routes/paths";
import { getPlayerUserPermissions } from 'store/userPreference/userPreference.reducer';
import { Actions as EmailActions } from "app/change-email/change-email.controller"
import { PlayerOnBoardIntentionPeriodEnum, PlayerPreferencesOption } from "pages/player-v2/player-preferences/player-preferences.controller";

export enum SettingsSteps {
    Main = 1,
    ChangePassword = 2,
    PasswordUpdated = 3,
    AgentRequest,
    Documents,
    ChangeEmail,
    TransferPreferences,
    ThankYouMessage
}

export enum TransferPreferencesSteps {
    PreferencesSelector = 1,
    TransferAvailability = 2,
    MotivationPreference = 3,
    NotLookingMoveNow = 4,
    PlayingAbroad = 5,
    ExpectedGrossSalary = 6,
}

class State {
    isProcessing: boolean;
    step: SettingsSteps;
    playerPreferences: {
        preferencesStep: TransferPreferencesSteps,
        formattedInputValue: string,
        selectedCurrencyId: CurrencyEnum,
        selectedPeriodId: PlayerOnBoardIntentionPeriodEnum,
        salaryExpectation: number,
        transferResponseId: number,
        motivationResponseId: number,
        relocationResponseId: number,
    };
    settingsInitialInfo: SettingsInitialInfoRequest;
    passwordUpdates: {
        isLoading: boolean,
        password: string,
        confirmedPassword: string,
        newPassword: string,
        newPasswordError: any,
        confirmedPasswordError: any,
        isProcessing: boolean,
        isValidPassword: boolean,
        isOldPasswordValid: boolean | null,
    };
    // emailChange: {
    //     isLoading: boolean,
    //     newEmail: string,
    //     confirmedEmail: string,
    //     newEmailError: boolean,
    //     confirmedEmailError: boolean,
    // }
    isActivatorToggleChecked: boolean;
    isActivatorToggleCheckedLocal: boolean;
}

const defaultState: State = {
    isProcessing: false,
    step: SettingsSteps.Main,
    playerPreferences: {
        preferencesStep: TransferPreferencesSteps.PreferencesSelector,
        formattedInputValue: '',
        selectedCurrencyId: CurrencyEnum.EUR,
        selectedPeriodId: PlayerOnBoardIntentionPeriodEnum.Year,
        salaryExpectation: null,
        transferResponseId: null,
        motivationResponseId: null,
        relocationResponseId: null,
    },
    settingsInitialInfo: null,
    passwordUpdates: {
        isLoading: false,
        password: "",
        confirmedPassword: "",
        newPassword: "",
        newPasswordError: null,
        confirmedPasswordError: null,
        isProcessing: false,
        isValidPassword: true,
        isOldPasswordValid: null,
    },
    // emailChange: {
    //     isLoading: false,
    //     newEmail: '',
    //     newEmailError: false,
    //     confirmedEmail: '',
    //     confirmedEmailError: false,
    // },
    isActivatorToggleChecked: true,
    isActivatorToggleCheckedLocal: true
}

const stateController = new StateController<State>(
    'PLAYER/SETTINGS',
    defaultState
)

class Actions {
    public static init() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendUserActivity('Clicked Settings', `My Page [Settings]`));

            await dispatch(Actions.getSettingsInitialInfo());
        }
    }

    public static getSettingsInitialInfo() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isProcessing: true }));

            try {
                const data = await PlayerPreferencesService.getSettingsInitialInfo();

                dispatch(stateController.setState((prevState => ({
                    ...prevState,
                    settingsInitialInfo: data,
                    playerPreferences: {
                        ...prevState.playerPreferences,
                        transferResponseId: data.playerOnBoardIntention?.transferResponseId,
                        motivationResponseId: data.playerOnBoardIntention?.motivationResponseId,
                        relocationResponseId: data.playerOnBoardIntention?.relocationResponseId,
                        salaryExpectation: data.playerOnBoardIntention?.minGrossSalary,
                        selectedCurrencyId: data.playerOnBoardIntention?.currencyId,
                        selectedPeriodId: data.playerOnBoardIntention?.periodId
                    }
                }))));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

    public static sendUserActivity(message: string, pageName: string) {
        return (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId;
            dispatch(userActivityInsert({
                Message: message,
                PageName: pageName,
                PlayerId: playerId,
            }))
        }
    }
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            const { isActivatorToggleCheckedLocal, settingsInitialInfo } = Selectors.getRoot(getState());

            dispatch(stateController.setState({
                ...defaultState,
                isActivatorToggleCheckedLocal: isActivatorToggleCheckedLocal,
                settingsInitialInfo: {
                    isTransferPreferenceAvailable: settingsInitialInfo.isTransferPreferenceAvailable,
                    isTransferPreferenceFlowFullyCompleted: settingsInitialInfo.isTransferPreferenceFlowFullyCompleted,
                    playerOnBoardIntention: settingsInitialInfo.playerOnBoardIntention
                }
            }));
            dispatch(EmailActions.dispose());
        }
    }
    public static clickBackBtn() {
        return (dispatch) => {
            dispatch(Actions.sendUserActivity('Clicked Back to Settings', `Settings [Agent Requests]`))
        }
    }
    public static setStep(step: SettingsSteps) {
        return async (dispatch) => {
            dispatch(stateController.setState({ step: step }));

            if (step === SettingsSteps.AgentRequest) {
                dispatch(Actions.sendUserActivity('Opened Agent Requests', `My Page [Settings]`))
            }

            if (step === SettingsSteps.ChangePassword) {
                dispatch(Actions.sendUserActivity('Opened Change Password', `My Page [Settings]`))
            }

            if (step === SettingsSteps.Documents) {
                dispatch(Actions.sendUserActivity('Opened Documents', `My Page [Settings]`))
            }
        }
    }
    public static openToAgentRequest() {
        return async (dispatch) => {
            dispatch(Actions.setStep(SettingsSteps.AgentRequest));
        }
    }

    public static setAgentRequest() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const { playerId } = getAuth(state);
            const { isOpenToAgencyRequests } = getPlayerUserPermissions(state);
            let message = `Activated`;
            try {
                const newValue = !isOpenToAgencyRequests;
                if (!newValue) {
                    message = `Deactivated`;
                }
                dispatch(Actions.sendUserActivity(message, 'My Page [Settings - Agent Requests]'));
                //dispatch(MyAgencyController.toggleIsOpenToAgencyRequests());
                dispatch({ type: PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS, payload: { data: newValue } });
                await AgencyFinderService.updateAgencyRequest(playerId, newValue);
            } catch (e) {
                dispatch({ type: PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS, payload: { data: isOpenToAgencyRequests } });
            }
        }
    }

    public static initAgencyFinderActivator() {
        return (dispatch, getState: () => AppState) => {
            const state = getState();
            const { isOpenToAgencyRequests } = getPlayerUserPermissions(state);
            //dispatch(stateController.setState({ isActivatorToggleCheckedLocal: isOpenToAgencyRequests }));
            dispatch(stateController.setState({ isActivatorToggleChecked: isOpenToAgencyRequests }));
        }
    }

    public static setIsActivatorToggleChecked(value: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ isActivatorToggleChecked: value }));
        }
    }

    public static setIsActivatorToggleButtonChecked(value: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ isActivatorToggleCheckedLocal: value }));
        }
    }

    public static toggleAgencyRequest() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const { playerId } = getAuth(state);
            const isActivatorToggleChecked = Selectors.isActivatorToggleChecked(getState());
            if (isActivatorToggleChecked) {
                dispatch(userActivityInsert({
                    PageName: 'Agent Finder [Settings]',
                    Message: `Turned ON Open to Agent Requests`,
                    PlayerId: playerId,
                }))
            }

        }
    }

    public static onContinue() {
        return async (dispatch, getState: () => AppState) => {
            const isActivatorToggleChecked = Selectors.isActivatorToggleChecked(getState());
            const { playerId } = getAuth(getState());
            // dispatch(userActivityInsert({
            //     PageName: 'Agent Finder [Settings]',
            //     Message: `Clicked Continue`,
            //     PlayerId: playerId,
            // }))
            //
            try {
                await AgencyFinderService.updateAgencyRequest(playerId, isActivatorToggleChecked);
            } catch (err) {
                console.log(err);
            }

            if (isActivatorToggleChecked) {
                dispatch({ type: PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS, payload: { data: true } });
            }
        }
    }

    public static onLeaveActivator() {
        return (dispatch, getState: () => AppState) => {
            const isActivatorToggleChecked = Selectors.isActivatorToggleChecked(getState());

            if (isActivatorToggleChecked) {
                dispatch({ type: PLAYER_UPDATE_IS_OPEN_TO_AGENCY_REQUESTS, payload: { data: true } });
            }
        }
    }

    public static openDocument = (url: string) => (dispatch) => {
        if (url === commonPagesPaths.playerTermsOfService) {
            dispatch(Actions.sendUserActivity('Opened Terms of Service', `My Page [Settings - Documents]`))
        }
        if (url === commonPagesPaths.privacyPolicyB2C) {
            dispatch(Actions.sendUserActivity('Opened Privacy Policy', `My Page [Settings - Documents]`))
        }
        if (url === commonPagesPaths.newDataProcessingTerms) {
            dispatch(Actions.sendUserActivity('Opened Data Processing Terms', `My Page [Settings - Documents]`))
        }
        if (url === commonPagesPaths.playerHealthConsent) {
            dispatch(Actions.sendUserActivity('Opened Health Consent', `My Page [Settings - Documents]`))
        }
        if (url === commonPagesPaths.playerCodeOfConduct) {
            dispatch(Actions.sendUserActivity('Opened Code of Conduct', `My Page [Settings - Documents]`))
        }

        window.open(url, '_blank')
    }

    /*--------Password Updates------*/
    public static setPassword(password: string) {
        return async dispatch => {
            dispatch(stateController.setState((prevState => ({
                ...prevState,
                passwordUpdates: {
                    ...prevState.passwordUpdates,
                    password: password,
                }
            }))));
        }
    }

    public static setNewPassword(newPassword: string) {
        return (dispatch, getState: () => AppState) => {
            const confirmedPassword = Selectors.getConfirmedPassword(getState())
            const validation = PasswordValidation.validate(newPassword)
            const confirmedPasswordError = PasswordValidation.validateConfirmPassword(newPassword, confirmedPassword);

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                passwordUpdates: {
                    ...prevState.passwordUpdates,
                    newPassword,
                    newPasswordError: validation.rules,
                    isValidPassword: validation.isSuccess,
                    confirmedPasswordError,
                }
            })));
        }
    }

    public static setConfirmedPassword(confirmedPassword: string) {
        return (dispatch, getState: () => AppState) => {
            const password = Selectors.getNewPassword(getState())
            const confirmedPasswordError = PasswordValidation.validateConfirmPassword(password, confirmedPassword);
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                passwordUpdates: {
                    ...prevState.passwordUpdates,
                    confirmedPassword,
                    confirmedPasswordError
                }
            })));
        }
    }

    public static cancelChangedPassword() {
        return (dispatch) => {
            dispatch(Actions.sendUserActivity('Cancelled', `My Page [Settings - Change Password] `))
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                passwordUpdates: {
                    ...defaultState,
                }
            })));
            dispatch(Actions.setStep(SettingsSteps.Main));
        }
    }
    public static cancelChangedEmail() {
        return (dispatch) => {
            dispatch(Actions.sendUserActivity('Cancelled ', 'My Page [Settings - Change Email] '));
            dispatch(EmailActions.dispose());
            dispatch(Actions.setStep(SettingsSteps.Main));
        }
    }

    public static saveNewPassword() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const state = getState();
                const oldPassword = Selectors.getPassword(state);
                const confirmNewPassword = Selectors.getConfirmedPassword(state);
                const newPassword = Selectors.getNewPassword(state);

                if (!oldPassword || !confirmNewPassword || !newPassword) {
                    return;
                }

                const validation = PasswordValidation.validate(newPassword)
                if (!validation.isSuccess) {
                    dispatch(stateController.setState((prevState) => ({
                        ...prevState,
                        passwordUpdates: {
                            newPasswordError: validation.rules,
                            isValidPassword: validation.isSuccess,
                        }
                    })));
                    return
                }

                const confirmedPasswordError = PasswordValidation.validateConfirmPassword(newPassword, confirmNewPassword);

                if (confirmedPasswordError) {
                    dispatch(stateController.setState((prevState) => ({
                        ...prevState,
                        passwordUpdates: {
                            newPasswordError: null,
                            confirmedPasswordError
                        }
                    })));
                    return;
                }

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    passwordUpdates: {
                        ...prevState.passwordUpdates,
                        isLoading: true
                    }
                })));

                const passwordData = await UserPasswordService.updatePassword({ password: oldPassword, newPasword: newPassword });

                if (passwordData.currentPasswordWrong) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        passwordUpdates: {
                            newPasswordError: null,
                            rePasswordError: null,
                            isValidPassword: true,
                            isOldPasswordValid: false,
                        }
                    })));
                } else if (!passwordData.isSuccess) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        passwordUpdates: {
                            newPasswordError: passwordData.rules,
                            rePasswordError: null,
                            isValidPassword: false,
                            isOldPasswordValid: !passwordData.currentPasswordWrong,
                        }
                    })));
                } else {
                    dispatch(stateController.setState((prevState) => ({
                        ...prevState,
                        passwordUpdates: {
                            ...defaultState,
                        }
                    })));
                    dispatch(Actions.sendUserActivity('Saved', `My Page [Settings - Change Password] `))
                    dispatch(Actions.setStep(SettingsSteps.PasswordUpdated));
                }
            } catch (error) {
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    passwordUpdates: {
                        isLoading: false,
                    }
                })))
            }
        }
    }

    public static validateOldPassword() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                passwordUpdates: {
                    ...prevState.passwordUpdates,
                    isOldPasswordValid: null,
                }
            })))
            const userId = getAuth(getState()).userId;
            const password = Selectors.getPassword(getState())

            try {
                if (userId && password) {
                    const isOldPasswordValid = await AgencySettingsService.validatePassword(
                        userId,
                        password,
                    );
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        passwordUpdates: {
                            ...prevState.passwordUpdates,
                            isOldPasswordValid,
                        }
                    })))
                }
            } catch {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    passwordUpdates: {
                        isOldPasswordValid: null,
                    }
                })))
            }
        }
    }


    //_____________Player Preferences______________

    public static setPlayerPreferencesStep(step: TransferPreferencesSteps) {
        return (dispatch) => {
            dispatch(stateController.setState((prevState => ({
                ...prevState,
                playerPreferences: {
                    ...prevState.playerPreferences,
                    preferencesStep: step,
                }
            }))));
        }
    }

    public static setCurrency(currency: CurrencyEnum) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState => ({
                ...prevState,
                playerPreferences: {
                    ...prevState.playerPreferences,
                    selectedCurrencyId: currency,
                }
            }))));
        }
    }

    public static setPeriod(period: PlayerOnBoardIntentionPeriodEnum) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState => ({
                ...prevState,
                playerPreferences: {
                    ...prevState.playerPreferences,
                    selectedPeriodId: period,
                }
            }))));
        }
    }

    public static setSalaryExpectation(value: number) {
        return (dispatch) => {
            dispatch(stateController.setState((prevState => ({
                ...prevState,
                playerPreferences: {
                    ...prevState.playerPreferences,
                    salaryExpectation: value,
                }
            }))));
        }
    }

    public static setFormattedInputValue(value: string) {
        return (dispatch) => {
            dispatch(stateController.setState((prevState => ({
                ...prevState,
                playerPreferences: {
                    ...prevState.playerPreferences,
                    formattedInputValue: value,
                }
            }))));
        }
    }

    public static setTransferAvailabilityResponseId(option: PlayerPreferencesOption) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState => ({
                ...prevState,
                playerPreferences: {
                    ...prevState.playerPreferences,
                    transferResponseId: option
                }
            }))));
        }
    }

    public static setMotivationPreferenceResponseId(option: PlayerPreferencesOption) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState => ({
                ...prevState,
                playerPreferences: {
                    ...prevState.playerPreferences,
                    motivationResponseId: option,
                }
            }))));
        }
    }

    public static setRelocationResponseId(option: PlayerPreferencesOption) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState => ({
                ...prevState,
                playerPreferences: {
                    ...prevState.playerPreferences,
                    relocationResponseId: option,
                }
            }))));
        }
    }

    public static addPlayerOnBoardIntention() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isProcessing: true }));

            try {
                const {
                    transferResponseId,
                    motivationResponseId,
                    relocationResponseId,
                    salaryExpectation,
                    selectedCurrencyId,
                    selectedPeriodId,
                } = Selectors.getPlayerPreferences(getState());

                const isNotLookingToMove = transferResponseId === PlayerPreferencesOption.NotLookingMoveNow;

                const request = {
                    transferResponseId,
                    motivationResponseId: isNotLookingToMove ? null : motivationResponseId,
                    relocationResponseId: isNotLookingToMove ? null : relocationResponseId,
                    minGrossSalary: isNotLookingToMove ? null : salaryExpectation,
                    currencyId: isNotLookingToMove ? null : selectedCurrencyId,
                    periodId: isNotLookingToMove ? null : selectedPeriodId,
                };

                await PlayerPreferencesService.addPlayerOnBoardIntention(request);

                dispatch(stateController.setState((prevState => ({
                    ...prevState,
                    settingsInitialInfo: {
                        ...prevState.settingsInitialInfo,
                        playerOnBoardIntention: {
                            ...request
                        },
                    },
                    playerPreferences: {
                        preferencesStep: TransferPreferencesSteps.PreferencesSelector,
                        selectedCurrencyId: request.currencyId,
                        selectedPeriodId: request.periodId,
                        salaryExpectation: request.minGrossSalary,
                        transferResponseId: request.transferResponseId,
                        motivationResponseId: request.motivationResponseId,
                        relocationResponseId: request.relocationResponseId,
                    }
                }))));

                if (isNotLookingToMove) {
                    dispatch(Actions.setStep(SettingsSteps.Main));

                    dispatch(stateController.setState((prevState => ({
                        ...prevState,
                        settingsInitialInfo: {
                            ...prevState.settingsInitialInfo,
                            isTransferPreferenceFlowFullyCompleted: false,
                        },
                    }))));
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        }
    }

}

class Selectors {
    public static getRoot = (state: AppState): State => state.playerV2.settings;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).step;
    public static getConfirmedPassword = (state: AppState) => Selectors.getRoot(state).passwordUpdates.confirmedPassword;
    public static getNewPassword = (state: AppState) => Selectors.getRoot(state).passwordUpdates.newPassword;
    public static getPassword = (state: AppState) => Selectors.getRoot(state).passwordUpdates.password;
    public static getNewPasswordError = (state: AppState) => Selectors.getRoot(state).passwordUpdates.newPasswordError;
    public static getConfirmedPasswordError = (state: AppState) => Selectors.getRoot(state).passwordUpdates.confirmedPasswordError;
    public static isValidPassword = (state: AppState) => Selectors.getRoot(state).passwordUpdates.isValidPassword;
    public static isOldPasswordValid = (state: AppState) => {
        const isValid = Selectors.getRoot(state).passwordUpdates.isOldPasswordValid;
        return isValid || isValid === null
    }
    public static isAgentRequestSection = (state: AppState) => getPlayerUserPermissions(state)?.isAgentFinderAvailable ?? false;
    public static isActivatorToggleChecked = (state: AppState) => Selectors.getRoot(state).isActivatorToggleChecked;
    public static isActivatorToggleCheckedLocal = (state: AppState) => Selectors.getRoot(state).isActivatorToggleCheckedLocal;
    public static getPlayerOnBoardIntention = (state: AppState) => Selectors.getRoot(state).settingsInitialInfo?.playerOnBoardIntention;
    public static getPlayerPreferences = (state: AppState) => Selectors.getRoot(state).playerPreferences;
    public static isTransferPreferenceAvailable = (state: AppState) => Selectors.getRoot(state).settingsInitialInfo?.isTransferPreferenceAvailable;
    public static isTransferPreferenceFlowFullyCompleted = (state: AppState) => Selectors.getRoot(state).settingsInitialInfo?.isTransferPreferenceFlowFullyCompleted;
    public static isProcessing = (state: AppState) => Selectors.getRoot(state).isProcessing;
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}
