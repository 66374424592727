export enum UserTypeEnum {
    GlobalAdmin = 1,
    MasterUser = 2,
    AdditionalUser = 3,
    Admin = 4,
    AdminEditor = 5,
    FriendlyMatchSupervisor = 6,
    SummitParticipant = 7,
    SalesSupervisor = 8,
    Agency = 9,
    Player = 10,
    Staff = 11,
    PersonalClubAccount = 12
};

export enum SubscriptionPlanEnum {
    //NLFreemium = 1,
    UKFreemium = 2,
    //UKManager = 3,
    //UKBudget = 4,
    //UKPro = 5,
    //UKPremium = 6,
    SuperVip = 7,
    EUFreemium = 8,
    Pro = 9,
    Premium = 10,
    Vip = 11,
    UKStarter = 12,
    EntryPackage = 13,
    EntryPackageLatam = 14
};

export enum PitchInsightsSubscriptionEnum {
    None = 0,
    Pro = 1,
    Premium = 2,
    Vip = 3
}


export const userLinkTypeEnum = {
    PlayerAd: 1
};

export enum UserActivityType {
    Action = 1
}

export enum ActionType {
    Common = 0,
    Accepted = 1,
    AddToShortlist = 2,
    CompareTo = 3,
    DeclaredInterest = 4,
    ListedPlayer = 5,
    Search = 6,
    SentNewMessage = 7,
    TestedInterest = 8,
    LaunchedPlayerAd = 9,
    Printscreen = 10
}

export enum PageType {
    Common = 0,
    Home = 1,
    Messaging = 2,
    Squad = 3,
    Pitch = 4,
    Search = 5,
    PlayerProfile = 6,
    PlayerAds = 7,
    Friendlies = 8,
    Events = 9,
    FinancialInsights = 10,
    VirtualLoanAssistant = 11,
    GBE_Calculator = 12,
    SubscriptionBanner = 13,
    CustomerReaction = 14,
    EditProfile = 15,
    ForceUpdate = 16,
    Agents = 17,
    EventVideo = 18,
    LegalDocumentation = 19,
    LandingPage = 20,
    Mail = 21,
    Agency = 22,
    External = 23,
    AgencyHome = 24,
    AgencyPlayers = 25,
    AgencyEvents = 26,
    AgencyOnboarding = 27,
    PlayerSide = 28,
    PlayerOnboarding = 29,
    AgentToPlayerRequest = 30,
    PlayerPage = 31,
    Settings = 32,
    AgencyConfirmation = 33,
    Representation = 34,
    AgentFinder = 35,
    TransferConfirmation = 36,
    PlayerRefferal = 37,
    PushNotifications = 38,
    CoachProfile = 39,
    RnClubNavigation = 40,
    RnClubLogin = 41,
    RnClubTransferOut = 42,
    RnClubTransferIn = 43,
    AgencyCoaches = 44,
    CoachPage = 45,
}

export enum UserStatusEnum {
    Active = 0,
    PendingInvitation = 1,
    Inactive = 2,
}

export enum PriceTypeEnum {
    Buy = 1,
    Loan = 2
}

export enum ReportsPitchTypeEnum {
    Pitch = 0,
    PlusPitch = 1,
    SuperPitch = 2,
    AgentPitch = 3,
    AgentPlusPitch = 4,
    AgentCustomPitch = 5,
}

export enum CoachAccessSubscriptionEnum {
    None = 0,
    //Pitch = 1,
    Full = 2,
    Database = 3,
}

export enum StaffProductSubscriptionEnum {
    None = 0,
    Lite = 1,
    Paid = 2
}