import * as redux from 'redux';
import * as SearchAutosuggestController from 'pages/PlayerSearch-v3/personal-account/redux/autosuggest.controller';
import * as GridController from 'pages/PlayerSearch-v3/personal-account/redux/grid.controller';
import * as FilterController from 'pages/PlayerSearch-v3/personal-account/redux/filter.controller';

export class PersonalAccountState {
    search: SearchAutosuggestController.State;
    grid: GridController.State;
    filter: FilterController.State;
}

export function combineReducers() {
    const PersonalAccountReducers = {
        search: SearchAutosuggestController.Reducer,
        grid: GridController.Reducer,
        filter: FilterController.Reducer,
    };

    return redux.combineReducers(PersonalAccountReducers);
}
