import { StateController } from 'utils/action-declaration';

import { AppState } from 'root.reducer'
import { getAuthSquadId } from 'store/auth/authReducer'
import { SuperPitchService } from 'api/super-pitch/super-pitch'
import { ReceiverSuperPitch } from 'api/super-pitch/models/received-super-pitch'
import { ComparisonLists, ComparisonRequest } from 'api/super-pitch/models/comparison'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import * as CommonController from 'pages/player-ads/redux/common.controller'
import * as MessageSend from 'pages/landing-page/redux/message.send.controller'
import { messageSend } from 'app/send-message/actions/send-message.actions'
import { Actions as PlayerStatisticActions } from './player-statistic.controller';
import { ActionType, PageType, UserActivityType } from 'constants/enums';

class SuperPitchesState {
    superPitches: Array<ReceiverSuperPitch>;
    isLoading: boolean;
    initialized: boolean;
    processingPitchIds: Array<number>;
    declareInterestProcessingIds: number[];
    superPitchComparisonData: { [id: number]: ComparisonLists; }
}

const defaultState: SuperPitchesState = {
    superPitches: [],
    isLoading: false,
    initialized: false,
    processingPitchIds: [],
    declareInterestProcessingIds: [],
    superPitchComparisonData: {}
}

const stateController = new StateController<SuperPitchesState>(
    "LANDING_PAGE/ALL_SUPER_PITCHES",
    defaultState
);

class SuperPitchSendMessageSpecification implements MessageSend.IMessageSendSpecificaion {
    private dispatch;
    private toSquadId: number;
    private playerId: number;
    private superPitchId: number;
    private squadSuperPitchId: number;
    private isSavedMode: boolean;

    constructor(dispatch, toSquadId: number, playerId: number, superPitchId: number, squadSuperPitchId: number) {
        this.dispatch = dispatch;
        this.toSquadId = toSquadId;
        this.playerId = playerId;
        this.superPitchId = superPitchId;
        this.squadSuperPitchId = squadSuperPitchId;
    }

    public async sendMessage(session: MessageSend.MessageSendSession, message: string, checked: boolean): Promise<any> {

        this.dispatch(userActivityInsert({
            PageName: 'Home [Test Super Pitch]',
            Message: 'Sent New Message',
            PlayerId: this.playerId,
            ClubId: this.toSquadId,
            ActionType: ActionType.SentNewMessage,
            PageType: PageType.Home,
            UserActivityType: UserActivityType.Action
        }));

        if (checked) {
            this.dispatch(Actions.deleteTestInterest(this.superPitchId, this.squadSuperPitchId))

        } else {
            this.dispatch(Actions.dismissTestInterestPitch(this.superPitchId, this.squadSuperPitchId))
        }

        await this.dispatch(messageSend(this.toSquadId, message, null, session.officialName, session.mesageTypeId, session.subject, false, (this.squadSuperPitchId || this.superPitchId)));
    }

    public cancelMessage() {
        this.dispatch(userActivityInsert({
            PageName: 'Home [Test Super Pitch]',
            Message: 'Cancelled New Message',
            PlayerId: this.playerId,
            ClubId: this.toSquadId,
            PageType: PageType.Home
        }));
    }
}

class Actions {
    public static dispose(): any {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static preloadComparisonInfo(id: number): any {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();

            let currentDict = { ...state.landingPage.superPitches.superPitchComparisonData };
            if (Object.keys(currentDict).includes(id.toString())) {
                throw new Error("Data for pitch is preloaded.");
            }
            let pitch = state.landingPage.superPitches.superPitches.find(x => x.squadSuperPitchId == id);
            let statistic = null;
            if (pitch.comparisonKeys.length > 0) {
                let payload = new ComparisonRequest();
                payload.keys = pitch.comparisonKeys;
                payload.superPitchId = pitch.superPitchId;

                statistic = await SuperPitchService.loadComparisonDataOnSelect(pitch.playerInfo.playerId, payload);
            }

            currentDict[id] = { ...statistic };

            dispatch(stateController.setState({ superPitchComparisonData: currentDict }))

        }
    }

    public static loadAllsuperPitches() {
        return async (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState({ isLoading: true }));
            let superPitches = await SuperPitchService.getReceivedSuperpitches();
            dispatch(stateController.setState({ isLoading: false, superPitches: superPitches, initialized: true }));
        }
    }

    public static declareInterest(superpitchId: number, squadSuperPitchId: number, pageName: string) {
        return async (dispatch, getState: () => AppState) => {

            if (pageName === undefined || pageName === null || pageName === '') {
                pageName = 'Home [Super Pitch]';
            }

            let superPitches = getState().landingPage.superPitches.superPitches;
            let pitch = this.getPitch(superpitchId, superPitches);

            const shortlistedPlayers = getState().landingPage.playerStatistic.shortListedPlayers;
            if (!shortlistedPlayers.includes(pitch.playerInfo.playerId)) {
                dispatch(PlayerStatisticActions.togglePlayerInShortList(pitch.playerInfo.playerId, false));
            }

            await dispatch(
                this.proceedPitch(
                    superpitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            dispatch(stateController.setState(prevState => ({ 
                                ...prevState,
                                declareInterestProcessingIds: [...prevState.declareInterestProcessingIds, superpitchId]
                            })))
                            await SuperPitchService.declareInterestPitchSquad(squadSuperPitchId);
                            if (pitch != null) {
                                dispatch(userActivityInsert({
                                    PageName: pageName,
                                    Message: 'Declared Interest',
                                    PlayerId: pitch.playerInfo.playerId,
                                    ClubId: pitch.playerInfo.parentSquad.id,
                                    ActionType: ActionType.DeclaredInterest,
                                    PageType: PageType.Home,
                                    UserActivityType: UserActivityType.Action
                                }));
                            }
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        } finally {
                            dispatch(stateController.setState(prevState => ({ 
                                ...prevState,
                                declareInterestProcessingIds: prevState.declareInterestProcessingIds.filter(item => item !== superpitchId)
                            })))
                        }
                    })
                )
            );
            //A.Z potential refresh
        }
    }

    public static deleteTestInterest(squadPitchId: number, squadSuperPitchId: number): any {
        return async (dispatch, getState) => {
            await dispatch(
                this.proceedPitch(
                    squadPitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await SuperPitchService.deleteTI(squadSuperPitchId);
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        }
                    })
                )
            );
        }
    }

    public static dismissTestInterestSupperPitch(superpitchId: number, squadSuperPitchId: number) {
        return async (dispatch, getState: () => AppState) => {
            let superPitches = getState().landingPage.superPitches.superPitches;
            let pitch = this.getPitch(superpitchId, superPitches);

            dispatch(
                this.proceedPitch(
                    superpitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await SuperPitchService.dismissPitch(squadSuperPitchId);
                            dispatch(userActivityInsert({
                                PageName: 'Home [Test Super Pitch]',
                                Message: 'Delete Saved Pitch',
                                PlayerId: pitch.playerInfo.playerId,
                                ClubId: pitch.playerInfo.parentSquad.id,
                                PageType: PageType.Home
                            }))
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        }
                    })
                )
            )
        }
    }

    public static dismissPitch(superpitchId: number, squadSuperPitchId: number, pageName: string) {
        return async (dispatch, getState: () => AppState) => {

            if (pageName === undefined || pageName === null || pageName === '') {
                pageName = 'Home [Super Pitch]';
            }

            let message = "Deleted Saved Pitch";
            if (pageName === 'Home [Super Pitch]') {
                message = "Dismissed Pitch"
            }

            let superPitches = getState().landingPage.superPitches.superPitches;
            let pitch = this.getPitch(superpitchId, superPitches);


            await dispatch(
                this.proceedPitch(
                    superpitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await SuperPitchService.dismissPitch(squadSuperPitchId);
                            if (pitch != null) {
                                dispatch(userActivityInsert({
                                    PageName: pageName,
                                    Message: message,
                                    PlayerId: pitch.playerInfo.playerId,
                                    ClubId: pitch.playerInfo.parentSquad.id,
                                    PageType: PageType.Home
                                }));
                            }
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        }
                    })
                )
            );
            //A.Z potential refresh
        }
    }

    public static dismissTestInterestPitch(superpitchId: number, squadSuperPitchId: number) {
        return async (dispatch) => {
            dispatch(
                this.proceedPitch(
                    superpitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await SuperPitchService.dismissPitchTI(squadSuperPitchId);
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        }
                    })
                )
            )
        }
    }

    public static savePitch(superpitchId: number, squadSuperPitchId: number) {
        return async (dispatch, getState: () => AppState) => {
            let superPitches = getState().landingPage.superPitches.superPitches;
            let pitch = this.getPitch(superpitchId, superPitches);



            await dispatch(
                this.proceedPitch(
                    superpitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await SuperPitchService.saveSuperPitch(squadSuperPitchId);
                            if (pitch != null) {
                                dispatch(userActivityInsert({
                                    PageName: 'Home [Super Pitch]',
                                    Message: 'Saved Pitch',
                                    PlayerId: pitch.playerInfo.playerId,
                                    ClubId: pitch.playerInfo.parentSquad.id,
                                    PageType: PageType.Home
                                }));
                            }
                            resolve(true)
                        } catch(e) {
                            console.error(e)
                            reject(e)
                        }
                    })
                )
            );
            //A.Z potential refresh
        }
    }

    public static shortlistToggle(playerId: number, isInShortlist: boolean) {
        return async (dispatch, getState: () => AppState) => {

            const updateShortlistState = (playerId: number, newShortlistState: boolean) => {
                const substate = getState().landingPage.superPitches

                substate.superPitches.filter(item => item.playerInfo.playerId === playerId)
                    .forEach(superPitch => superPitch.playerInfo.isInShortList = newShortlistState)

                dispatch(stateController.setState({
                    superPitches: [...substate.superPitches]
                }))
            }

            updateShortlistState(playerId, !isInShortlist)
            try {
                await SuperPitchService.shortlistToggle(playerId, isInShortlist)
            } catch (e) {
                updateShortlistState(playerId, isInShortlist)
                console.error(e)
            }
        }
    }

    public static deleteTiFormState(id: number): any {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let pitches = state.landingPage.superPitches.superPitches;
            let pitchesWithoutDismissed = pitches.filter(x => x.squadSuperPitchId != id);
            dispatch(stateController.setState({ superPitches: pitchesWithoutDismissed }))
            //A.Z potential refresh
        }
    }

    private static proceedPitch(pitchId: number, action: () => Promise<any>) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let currentProcesses = getState().landingPage.superPitches.processingPitchIds;
            dispatch(stateController.setState({ processingPitchIds: [...currentProcesses, pitchId] }))

            try {
                await action();
                dispatch(CommonController.Actions.loadCounters());
                currentProcesses = getState().landingPage.superPitches.processingPitchIds;

                let pitchesGroup = getState().landingPage.superPitches.superPitches;
                let refreshedPitchesGroup = pitchesGroup.filter(x => x.superPitchId != pitchId);

                dispatch(stateController.setState(
                    { superPitches: refreshedPitchesGroup })
                )
                resolve(true)
            } catch (e) {
                console.error(e)
                reject(e)
            } finally {
                dispatch(stateController.setState(
                    { processingPitchIds: [...currentProcesses.filter(x => x != pitchId)], })
                )
            }
        })
    }

    private static getPitch(superPitchId: number, superPitches: any) {
        let pitch = null;
        superPitches.map(p => {
            if (p.superPitchId == superPitchId) {
                pitch = p;
            }
        });

        return pitch;
    }

    public static sendMessage(superPitchId: number) {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();

            let pitch = state.landingPage.superPitches.superPitches.find(x => x.superPitchId == superPitchId);
            if (pitch != null) {
                dispatch(userActivityInsert({
                    PageName: 'Home [Test Super Pitch]',
                    Message: 'Opened New Message',
                    PlayerId: pitch.playerInfo.playerId,
                    ClubId: pitch.playerInfo.parentSquad.id,
                    PageType: PageType.Home
                }));
            }

            let session: MessageSend.MessageSendSession = {
                subject: "RE: " + pitch.playerInfo.fullName + " Super Pitch",
                logo: pitch.createdBySquadLogo,
                checkBoxTitle: 'Keep player in saved pitches',
                officialName: pitch.playerInfo.parentSquad.name,
                leagueName: pitch.playerInfo.parentSquad.league + (pitch.playerInfo.parentSquad && pitch.playerInfo.parentSquad.areaName ?
                    `, ${pitch.playerInfo.parentSquad.areaName}` : "") || "",
                mesageTypeId: 7,
            }

            dispatch(
                MessageSend.Actions.openSession(
                    session,
                    new SuperPitchSendMessageSpecification(dispatch, pitch.playerInfo.parentSquad.id, pitch.playerInfo.playerId, superPitchId, pitch.squadSuperPitchId)
                )
            )
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SuperPitchesState as State,
    Actions as Actions,
    stateController as Controller
};



