import React from 'react'
import {
    monthDiffSigned,
    displayPlayerName,
    displayPlayerHeight,
    displayPlayerFoot,
} from './utils';
import DynamicClubName from 'ts-components/utils/dynamic-club-name/dynamic-club-name'
import { translate } from '../services/localization';

export default function getColumns() {
    return {
        star: {
            id: 'star',
            header: '',
            accessor: player => player.isInShortList,
            style: { width: 40 },
            mandatory: true,
        },
        // similarityPercentage: {
        //     id: 'similarityPercentage',
        //     header: '',
        //     accessor: player => player.similarityPercentage,
        //     style: { width: 40 },
        //     mandatory: true,
        // },
        name: {
            id: 'name',
            sortKey: 'name',
            header: translate('playerGrid.player'),
            style: { width: 150 },
            accessor: player => displayPlayerName(player),
            mandatory: true,
            defaultSortDiraction: 'asc',
        },
        height: {
            id: 'height',
            sortKey: 'height',
            header: translate('playerGrid.height'),
            style: { width: 100 },
            accessor: player => displayPlayerHeight(player),
            defaultSortDiraction: 'desc',
        },
        foot: {
            id: 'foot',
            sortKey: 'foot',
            header: translate('playerGrid.foot'),
            style: { width: 100 },
            accessor: player => displayPlayerFoot(player),
            defaultSortDiraction: 'asc',
        },
        league: {
            id: 'league',
            header: translate('playerGrid.league'),
            style: { width: 150 },
            accessor: player => player.league,
        },
        image: {
            id: 'image',
            header: '',
            accessor: () => '',
            style: { width: 50 },
            mandatory: true,
        },
        club: {
            id: 'club',
            header: translate('playerGrid.club'),
            sortKey: 'club',
            defaultSortDiraction: 'asc',
            accessor: player => {
                return [(player.currentSquad || {}).name]
                    .concat(
                        player.parentSquad &&
                            player.parentSquad.name !==
                            (player.currentSquad || {}).name
                            ? [player.parentSquad.name]
                            : [],
                    )
                    .join(', ');
            },
            style: { width: 120 },
        },
        squad: {
            id: 'squad',
            header: translate('playerGrid.squad'),
            accessor: player => {
                return (
                    <DynamicClubName
                        clubName={(player.currentSquad || {}).name || '-'}
                        shortClubName={(player.currentSquad || {}).shortName || (player.currentSquad || {}).name || '-'}
                        maxWidth={190}
                    />
                )
            },
        },
        position: {
            id: 'position',
            sortKey: 'position',
            defaultSortDiraction: 'asc',
            header: translate('playerGrid.position'),
            accessor: player => {
                const { firstPositionCode, secondPositionCode } = player;

                if (!firstPositionCode) {
                    return '-';
                }

                const translatedFirstPosition = translate(
                    `positionAbbreviations.${firstPositionCode}`,
                );
                const translatedSecondPosition = translate(
                    `positionAbbreviations.${secondPositionCode}`,
                );
                const firstPositionPart = [translatedFirstPosition || ''];

                const doShowSecondPosition =
                    secondPositionCode &&
                    firstPositionCode !== secondPositionCode;

                const secondPositionPart = doShowSecondPosition
                    ? [`(${translatedSecondPosition})`]
                    : [];

                return firstPositionPart.concat(secondPositionPart).join(' ');
            },
        },
        age: {
            id: 'age',
            sortKey: 'age',
            header: translate('playerGrid.age'),
            accessor: player => player.age || '-',
            defaultSortDiraction: 'desc',
        },
        contractExpiry: {
            id: 'contractExpiry',
            header: translate('playerGrid.contractExpiry'),
            sortKey: 'contractExpiry',
            style: { width: 160 },
            accessor: player => {
                const diff = monthDiffSigned(
                    new Date(player.contractExpiration || new Date()),
                    new Date(),
                );
                return diff;
            },
            defaultSortDiraction: 'desc',
        },
        // gsnPotential: {
        //     id: 'gsnPotential',
        //     sortKey: 'gsnpotential',
        //     header: translate('playerGrid.gsnPotential'),
        //     accessor: player => player.gsnPerformance ? player.gsnPerformance : '-',
        //     defaultSortDiraction: 'desc',
        // },
        faPlayerPoint: {
            id: 'faPlayerPoint',
            // sortKey: 'gsnpotential',
            header: translate('playerGrid.faPlayerPoint'),
            accessor: (player) => {
                var type = player.faPlayerPoint && player.faPlayerPoint.result ? player.faPlayerPoint.result : '-';
                if(type &&type.toLowerCase().indexOf("youth exceptions")!==-1) {
                    type = 'Youth Exception'
                } else  if(type &&type.toLowerCase().indexOf("exception")!==-1){
                    type = 'Exception'
                } 
                return type;
            },
            defaultUK: true,
            // defaultSortDiraction: 'desc',
        },
        // gsnPerformance: {
        //     id: 'gsnPerformance',
        //     sortKey: 'gsnperformance',
        //     header: translate('playerGrid.gsnPerformance'),
        //     accessor: player => player.gsnSkill ? player.gsnSkill : '-',
        //     defaultSortDiraction: 'desc',
        // },
        onLoanSquadTillDate: {
            id: 'onLoanSquadTillDate',
            header: translate('playerGrid.contractExpiry'),
            style: { width: 160 },
            accessor: player => {
                const diff = monthDiffSigned(
                    new Date(player.onLoanSquadTillDate || new Date()),
                    new Date(),
                );
                return diff;
            },
        },
        transferStatus: {
            id: 'transferStatus',
            sortKey: 'availability',
            defaultSortDiraction: 'asc',
            header: translate('playerGrid.transferStatus'),
            accessor: () => true,
            mandatory: true,
        },
        declareInterest: {
            id: 'declareInterest',
            header: '',
            accessor: () => '',
            style: { width: 125 },
            mandatory: true,
        },
        matchMaker: {
            id: 'matchMaker',
            header: translate('playerGrid.matchMaker'),
            accessor: () => '',
            mandatory: true,
        },
        chevron: {
            id: 'chevron',
            header: '',
            accessor: () => '',
            style: { width: 50 },
            mandatory: true,
        },
        placeholder: {
            id: 'placeholder',
            header: '',
            accessor: () => '',
            style: { width: 80 },
            mandatory: true,
        },
        actions: {
            id: 'actions',
            header: '',
            accessor: () => '',
            style: { width: 70 },
            mandatory: true,
        },
        // marketValue: {
        //     id: 'marketValue',
        //     header: translate('playerGrid.marketValue'),
        //     style: { width: 40 },
        //     accessor: player => player.marketValue || '-',
        //     sortKey: 'marketValue',
        //     defaultSortDiraction: 'desc',
        // },
        minutesPlayed: {
            id: 'minutesPlayed',
            header: translate('playerComparison.constants.minutesPlayed'),
            style: { width: 70 },
            accessor: player => getStatisticOrEmpty(player, 'minutesPlayed'),
            statistic: true,
            sortKey: 'minutesPlayed',
            defaultSortDiraction: 'desc',
        },
        scoringContribution: {
            id: 'scoringContribution',
            header: translate('playerComparison.constants.scoringContribution'),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(player, 'scoringContribution'),
            statistic: true,
            sortKey: 'scoringContribution',
            defaultSortDiraction: 'desc',
        },
        goals: {
            id: 'goals',
            header: translate('playerComparison.constants.goals'),
            style: { width: 70 },
            accessor: player => getStatisticOrEmptyFormatted(player, 'goals'),
            statistic: true,
            sortKey: 'goals',
            defaultSortDiraction: 'desc',
        },
        assists: {
            id: 'assists',
            header: translate('playerComparison.constants.assists'),
            style: { width: 70 },
            accessor: player => getStatisticOrEmptyFormatted(player, 'assists'),
            statistic: true,
            sortKey: 'assists',
            defaultSortDiraction: 'desc',
        },
        xG: {
            id: 'xG',
            header: translate('playerComparison.constants.xG'),
            style: { width: 70 },
            accessor: player => getStatisticOrEmptyFormatted(player, 'xG'),
            statistic: true,
            sortKey: 'xG',
            defaultSortDiraction: 'desc',
        },
        xA: {
            id: 'xA',
            header: translate('playerComparison.constants.xA'),
            style: { width: 70 },
            accessor: player => getStatisticOrEmptyFormatted(player, 'xA'),
            statistic: true,
            sortKey: 'xA',
            defaultSortDiraction: 'desc',
        },
        successfulDribbles: {
            id: 'successfulDribbles',
            header: translate('playerComparison.constants.successfulDribbles'),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(player, 'successfulDribbles'),
            statistic: true,
            sortKey: 'successfulDribbles',
            defaultSortDiraction: 'desc',
        },
        shots: {
            id: 'shots',
            header: translate('playerComparison.constants.shots'),
            style: { width: 70 },
            accessor: player => getStatisticOrEmptyFormatted(player, 'shots'),
            statistic: true,
            sortKey: 'shots',
            defaultSortDiraction: 'desc',
        },
        shotsOnTarget: {
            id: 'shotsOnTarget',
            header: translate('playerComparison.constants.shotsOnTarget'),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(player, 'shotsOnTarget'),
            statistic: true,
            sortKey: 'shotsOnTarget',
            defaultSortDiraction: 'desc',
        },
        passesAccuracy: {
            id: 'passesAccuracy',
            header: translate('playerComparison.constants.passesAccuracy'),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(player, 'passesAccuracy'),
            statistic: true,
            sortKey: 'passesAccuracy',
            defaultSortDiraction: 'desc',
        },
        forwardPassesAccuracy: {
            id: 'forwardPassesAccuracy',
            header: translate(
                'playerComparison.constants.forwardPassesAccuracy',
            ),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(player, 'forwardPassesAccuracy'),
            statistic: true,
            sortKey: 'forwardPassesAccuracy',
            defaultSortDiraction: 'desc',
        },
        passesToFinalThirdAccuracy: {
            id: 'passesToFinalThirdAccuracy',
            header: translate(
                'playerComparison.constants.passesToFinalThirdAccuracy',
            ),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(
                    player,
                    'passesToFinalThirdAccuracy',
                ),
            statistic: true,
            sortKey: 'passesToFinalThirdAccuracy',
            defaultSortDiraction: 'desc',
        },
        longPassesAccuracy: {
            id: 'longPassesAccuracy',
            header: translate('playerComparison.constants.longPassesAccuracy'),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(player, 'longPassesAccuracy'),
            statistic: true,
            sortKey: 'longPassesAccuracy',
            defaultSortDiraction: 'desc',
        },
        interceptions: {
            id: 'interceptions',
            header: translate('playerComparison.constants.interceptions'),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(player, 'interceptions'),
            statistic: true,
            sortKey: 'interceptions',
            defaultSortDiraction: 'desc',
        },
        aerialDuelsWon: {
            id: 'aerialDuelsWon',
            header: translate('playerComparison.constants.aerialDuelsWon'),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(player, 'aerialDuelsWon'),
            statistic: true,
            sortKey: 'aerialDuelsWon',
            defaultSortDiraction: 'desc',
        },
        defensiveDuelsWon: {
            id: 'defensiveDuelsWon',
            header: translate('playerComparison.constants.defensiveDuelsWon'),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(player, 'defensiveDuelsWon'),
            statistic: true,
            sortKey: 'defensiveDuelsWon',
            defaultSortDiraction: 'desc',
        },
        shotsAgainst: {
            id: 'shotsAgainst',
            header: translate('playerComparison.constants.shotsAgainst'),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(player, 'shotsAgainst'),
            statistic: true,
            sortKey: 'shotsAgainst',
            defaultSortDiraction: 'desc',
        },
        saves: {
            id: 'saves',
            header: translate('playerComparison.constants.saves'),
            style: { width: 70 },
            accessor: player => getStatisticOrEmptyFormatted(player, 'saves'),
            statistic: true,
            sortKey: 'saves',
            defaultSortDiraction: 'desc',
        },
        concededGoals: {
            id: 'concededGoals',
            header: translate('playerComparison.constants.concededGoals'),
            style: { width: 70 },
            accessor: player =>
                getStatisticOrEmptyFormatted(player, 'concededGoals'),
            statistic: true,
            sortKey: 'concededGoals',
            defaultSortDiraction: 'desc',
        },
        xCG: {
            id: 'xCG',
            header: translate('playerComparison.constants.xCG'),
            style: { width: 70 },
            accessor: player => getStatisticOrEmptyFormatted(player, 'xCG'),
            statistic: true,
            sortKey: 'xCG',
            defaultSortDiraction: 'desc',
        },
        video: {
            id: 'video',
            header: 'video',
            style: { width: 50 },
            accessor: player => getStatisticOrEmpty(player, 'video'),
            sortKey: 'video',
            defaultSortDiraction: 'desc',
        },
    };
}

const getStatisticOrEmptyFormatted = (player, statisticName) => {
    if (player.firstPlayer == null) return '';
    if (player.firstPlayer.minutesPlayed === 0) return '-';
    let props = player.firstPlayer[statisticName + 'Formatted'];
    if (props == null) return '';
    return props;
};

const getStatisticOrEmpty = (player, statisticName) => {
    if (player.firstPlayer == null) return '';
    let props = player.firstPlayer[statisticName];
    if (props == null) return '';
    return props;
};
