import Range from 'api/core/range';
import { IdName } from 'api/core/id-name';
import { FormattedPriceModel } from 'api/core/formatted-price-model';
import { PriceTypeEnum } from 'constants/enums';

export interface PlayerPositionsResponse {
    positions: PlayerPosition[];
}

export interface PlayersDetailsResponse {
    id: number;
    name: string;
    isPriority: boolean;
    clubName?: string;
    leagueName?: string;
}

export interface PlayerPosition {
    id: PlayerPositionEnum;
    name: string;
    shortName?: string;
}

export enum PlayerPositionEnum {
    Goalkeeper = 1,
    LeftBack = 2,
    RightBack = 3,
    CentreBack_All = 4,
    CentreBack_LeftFooted = 5,
    DefensiveMidfielder = 6,
    CentralMidfielder = 7,
    AttackingMidfielder = 8,
    Winger = 9,
    Forward = 10,
}

export class RequirementsFilterRequest {
    page: number;
    pageSize: number;
    isForSale: boolean;
    isForLoan: boolean;
    positionCodes: string[];
    keyword: string;
    isLeftFooted: boolean;
}

export class SquadShortInfo {
    id: number;
    name: string;
    shortName: string;
    league: string;
    areaName: string;
}

export interface FilterCriteria {
    isForLoan: boolean;
    isForSale: boolean;
    keyword: string;
    positionCodes: string[];
    isLeftFooted?: boolean;
}

export class AgencyRequirementsResponse {
    pageItems: AgencyRequirement[];
    pageCount: number;
    total: number;
    pageSize: number;
}
export class AgencyRequirement {
    agentRequirementId: number;
    updateAtFormatted: string;
    positionCode: string;
    squad: AgencyRequirementSquad;
    type: PriceTypeEnum;
    transferFee: FormattedPriceModel;
    loanFee: FormattedPriceModel;
    grossSalary: FormattedPriceModel;
    netSalary: FormattedPriceModel;
    clubContactName: string;
    transferWindow: string;
    minHeightText: string;
    ageText: string;
    players: AgencyRequirementPlayer[];
}
export interface AgencyRequirementSquad {
    id: number;
    clubId: number;
    shortName: string;
    name: string;
    league: string;
    country: string;
    logo: string;
    leagueLogo: string;
    isLogoRestricted: boolean;
}
export interface AgencyRequirementPlayer {
    playerId: number;
    playerName: string;
    matchText: string;
    ratingText: string;
    xTv: FormattedPriceModel;
    playerAdPitchedNoteStatusId: number;
    playerAdPitchedNoteStatusName: string;
}
export interface PaginationState {
    currentPage: number;
    pageCount: number;
    rowCount: number;
    pageSize: number;
    totalLength: number;
}

export enum PlayerPitchStatusEnum {
    NotYetPitched = 1,
    Pitched = 2,
    Rejected = 3,
    OnHold = 4,
    UnderNegotiation = 5,
    MedicalFormalities = 6,
    ContractSigning = 7,
    TransferComplete = 8,
}
