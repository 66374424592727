import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor';
import { AUTH_LOGIN, SWITCH_ACCOUNT_PROCESSING } from 'store/actionTypes';
import { userPaths, personalAccountPaths } from 'routes/paths';
import { MultiAccountAccessService } from 'api/club/multi-account-access/multi-account-access.service';
import { StaffAccountService } from 'api/staff/staff-account/staff-account.service';
import { ChangeSquadRequest } from 'api/club/multi-account-access/model';
import { UserTypeEnum } from 'constants/enums';
import config from 'config';

class Actions {
    public static loginSelectedClub(squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                // TODO: add loader on change club for multi access user
                //  - have issue with not loading UserPreferences - need to fix in club-routes
                // dispatch({
                //     type: SWITCH_ACCOUNT_PROCESSING,
                //     payload: true,
                // });

                const request: ChangeSquadRequest = {
                    squadId: squadId,
                };
                const data = await MultiAccountAccessService.changeSquad(request);

                if (data.isSuccess) {
                    await dispatch({ type: AUTH_LOGIN.SUCCESS, payload: { data: data.auth } });
                    historyAccessor.push('/');
                }
            } catch (e) {
                console.error(e);
            } finally {
                // dispatch({
                //     type: SWITCH_ACCOUNT_PROCESSING,
                //     payload: false,
                // });
            }
        };
    }

    public static onSwitchAccount(path?: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch({
                    type: SWITCH_ACCOUNT_PROCESSING,
                    payload: true,
                });
                const data = await StaffAccountService.switchAccount();

                if (data.isSuccess) {
                    if (data.auth.userTypeId === UserTypeEnum.PersonalClubAccount) {
                        window.open(
                            `${config.staffProductWebAppUrl}${path ?? personalAccountPaths.homePage}`,
                            '_self'
                        );
                    } else {
                        window.open(`${config.webAppUrl}${path ?? '/'}`, '_self');
                    }
                }
            } catch (e) {
                console.error(e);

                // since redirect happening anyway,
                // no need to hide loader but only in case of error
                dispatch({
                    type: SWITCH_ACCOUNT_PROCESSING,
                    payload: false,
                });
            }
        };
    }

    public static loginSelectedClubForPersonalAccountUser(squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch({
                    type: SWITCH_ACCOUNT_PROCESSING,
                    payload: true,
                });

                const data = await StaffAccountService.switchAccount();
                if (data.isSuccess) {
                    const request: ChangeSquadRequest = {
                        squadId: squadId,
                    };
                    const squadData = await MultiAccountAccessService.changeSquad(request);
                    if (data.isSuccess) {
                        await dispatch({
                            type: AUTH_LOGIN.SUCCESS,
                            payload: { data: squadData.auth },
                        });
                        window.open(`${config.webAppUrl}/`, '_self');
                    }
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch({
                    type: SWITCH_ACCOUNT_PROCESSING,
                    payload: false,
                });
            }
        };
    }

    public static onClaimYourProfileClick() {
        return async (dispatch, getState: () => AppState) => {
            window.open(`${config.webAppUrl}${userPaths.claimYourProfile}`, '_self');
        };
    }

    public static openSettings() {
        return () => {
            historyAccessor.push(userPaths.editProfileProfile);
        };
    }
}

export { Actions as Actions };
