import { SearchPlayerPositionEnum } from 'api/search-v3/model/player-positions';
import Range from 'api/core/range';
import { IdName } from 'api/core/id-name';
import {
    AchievementItem,
    CoachingLicenseTypeEnum,
    FormationEnum,
    HeadCoachExperienceEnum,
    PlayingStyleEnum,
} from 'api/search-v3/model/search-request';
import { CustomLeagueList } from 'api/search-v3/model/suggestion-list';
import { AvailableNationalityCriteria } from 'api/search-v3/model/search-filter-criteria';
import { LanguagesSelectItem } from 'api/staff/staff-profile/model';
import { NationalitySelectItem } from 'api/core/area-short-info';

export enum Tab {
    Players = 1,
    HeadCoaches = 2,
    Agencies = 3,
    StaffProfiles = 4,
}

export enum GridToggleState {
    None = 0,
    Search = 1,
    ShortList = 2,
    Similarity = 3,
    PlayersSearch = 4,
    HeadCoachesSearch = 5,
    AgenciesSearch = 6,
    LookingForAgent = 7,
    StaffProfilesSearch = 8,
}

export enum SearchMode {
    Default = 0,
    Filtered = 1,
}

export enum PaginationClickState {
    Number = 1,
    Left = 2,
    Right = 3,
}

export interface RangeValidationError {
    hasError: boolean;
    text: string | null;
}

export class Structure {
    position?: SearchPlayerPositionEnum;
    age: Range<number>;
    height?: Range<number>;
    isAboveSixFt: boolean;
    lessThanSixMonth: boolean;
    lessThanTwelveMonth: boolean;
    contractExpiryMonthes: number | null;
    isCurrentLeague: boolean;
    isCurrentAllLeagues: boolean;
    isPreviousLeague: boolean;
    isPreviousAllLeagues: boolean;
    leaguesList: IdName[];
    previousLeaguesList: IdName[];
    isCurrentNationality: boolean;
    nationalityList: IdName[];
    gbePass: boolean;
    showOnlyFreeAgentPlayers: boolean;
    transferFee: number;
    anuallGrossSalaryYearly: number;
    loanFee: number;
    minutesPlayed: Range<number>;
    rating: Range<number>;
    marketValue: Range<number>;
    coachRating: Range<number>;
    showOnlyAvailablePlayers: boolean;
    isOverTwoYears: boolean;
    isOverFiveYears: boolean;
    isOverEightYears: boolean;
    isOverTenYears: boolean;
    hasPlayingCareer: boolean;
    isHighSuitability: boolean;
    trustInYouth: Range<number>;
    isHighTrustInYouth: boolean;
    isMediumTrustInYouth: boolean;
    isUnder35: boolean;
    isUnder40: boolean;
    isUnder50: boolean;
    isUnder60: boolean;
    achievementList: AchievementItem[];
    playingStyleList: PlayingStyleEnum[];
    formationList: FormationEnum[];
    licenseTypeList: CoachingLicenseTypeEnum[];
    headCoachExperienceList: HeadCoachExperienceEnum[];
    isAmbitious: boolean;
    isMatchingMe: boolean;
    currentLeagueList: CustomLeagueList[];
    previousLeagueList: CustomLeagueList[];
    preferredFootLeft: boolean;
    preferredFootRight: boolean;
    preferredFootBoth: boolean;
    playingStyleRoles: number[];
    staffExperienceList: HeadCoachExperienceEnum[];
    currentStaffType: number | null;
    languages: LanguagesSelectItem[] | null;
    nationalities: NationalitySelectItem[] | null;
    showInterestedInClubPlayers: boolean;
}

export class SelectNationalityModalData {
    searchType: 'Player' | 'Coach';
    listKey: 'nationalityList';
    modalKey: 'coachAttributesFilterModal' | 'playerAttributesFilterModal';
    availableNationalities: AvailableNationalityCriteria[];
    selectedNationalities: number[];
}
