import _ from 'lodash';
import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { getCancelTokenSource } from 'axios-config';
import SearchService from 'api/search-v3/search.clubside.service';
import { Actions as GridActions, Selectors as GridSelectors } from './grid.controller';
// import { SuggestionActivityService } from './autosuggest.activity.service'
// import { PlayerActivityService } from './player-activity.service';
import { Selectors as FilterSelectors } from './filter.controller';
import { SuggestionList } from 'api/search-v3/model/suggestion-list';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { GridToggleState, Tab } from 'pages/PlayerSearch-v3/shared-components/models/shared-models';
import { getAuth } from 'store/auth/authReducer';
import config from 'config';

class SearchAutosuggestState {
    keyword: string;
    isLoading: boolean;
    wasLoaded: boolean;
    players: SuggestionList;
    headCoaches: SuggestionList;
    agencies: SuggestionList;
    staffProfiles: SuggestionList;
    activeTab: Tab;
}

const defaultState: SearchAutosuggestState = {
    keyword: '',
    players: null,
    headCoaches: null,
    agencies: null,
    staffProfiles: null,
    isLoading: false,
    wasLoaded: false,
    activeTab: Tab.Players,
};

const stateController = new StateController<SearchAutosuggestState>(
    'SQUAD_SEARCH/AUTOSUGGEST',
    defaultState
);

class Actions {
    public static createDebounce = () =>
        _.debounce((dispatch, getState: () => AppState) => {
            // const tabKey = GridSelectors.getGridState(getState());
            // if(tabKey == GridToggleState.Search)
            //     dispatch(FilterActions.setSearchModeToFiltered())
            dispatch(Actions.fetchSuggestions());
        }, 500);

    public static cancelToken = null;
    public static fetchDebounced = Actions.createDebounce();

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }));
            if (keyword.length > 0) {
                if (keyword.length > 2) {
                    Actions.fetchDebounced(dispatch, getState);
                }
            } else {
                dispatch(Actions.onKeywordClear());
            }
        };
    }

    public static setKeywordWithGridRefresh(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }));
            // dispatch(GridController.resetPage())
            // dispatch(GridController.refresh())
        };
    }

    public static setKeyword(keyword: string) {
        return (dispatch) => {
            dispatch(stateController.setState({ keyword }));
        };
    }

    public static onKeywordClearWithoutRefresh() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    keyword: '',
                    wasLoaded: false,
                    isLoading: false,
                    players: null,
                    headCoaches: null,
                    agencies: null,
                })
            );

            Actions.fetchDebounced.cancel();
            if (Actions.cancelToken) {
                Actions.cancelToken.cancel();
            }
            Actions.cancelToken = null;
            Actions.fetchDebounced = Actions.createDebounce();
        };
    }

    public static onKeywordClear() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.onKeywordClearWithoutRefresh());
            // dispatch(GridController.resetPage())
            // dispatch(GridController.refresh())
            // dispatch(FilterActions.loadSearchSuggestionSubsets())

            function processSearchModeReaction() {
                // Need to handle scenario when user clear search keyword while in In Filtered State
                // See: https://transferroom.visualstudio.com/TransferRoom/_wiki/wikis/TransferRoom.wiki/994/Player-Search-V3
                // if (FilterSelectors.isSearchModeInFilteredState(getState())) {
                //     // If user have any other filters active, then need to reSave latest filterSet to DB
                //     if (FilterSelectors.isFiltersInActiveState(getState())) {
                //         dispatch(FilterActions.saveLatestFilterSet())
                //     }
                //     // Else if there are no other active filters and no search subsets selected except keyword, than just move to Default SearchMode state (NOT Filtered)
                //     const isSubsetSelected = FilterSelectors.getRoot(getState()).searchSubset
                //     const isFiltersInActiveState = FilterSelectors.isFiltersInActiveState(getState())
                //     if (!isSubsetSelected && !isFiltersInActiveState) {
                //         dispatch(FilterActions.setSearchModeToDefault())
                //     }
                // }
            }
            processSearchModeReaction();
        };
    }

    public static fetchSuggestions() {
        return async (dispatch, getState: () => AppState) => {
            const keyword = Selectors.getKeyword(getState());
            // const isSimilarity = GridSelectors.isSimilarity(state)
            // const isInShortList = GridSelectors.isInShortList(state)
            const isSimilarity = false;
            const isInShortList = false;
            const playerSet =
                (FilterSelectors.getRoot(getState()).searchSubset || {}).playerSet || null;

            if (Actions.cancelToken) {
                Actions.cancelToken.cancel();
            }

            Actions.cancelToken = getCancelTokenSource();

            try {
                dispatch(stateController.setState({ isLoading: true }));
                // dispatch(GridController.resetPage());
                // dispatch(GridController.refresh());

                // if (FilterSelectors.getRoot(getState()).searchMode === SearchMode.Filtered) {
                //     dispatch(FilterActions.saveLatestFilterSet())
                // }

                let data = await SearchService.getSuggestions(
                    keyword,
                    false,
                    isSimilarity,
                    isInShortList,
                    playerSet,
                    Actions.cancelToken.token
                );

                if (data) {
                    dispatch(
                        stateController.setState({
                            wasLoaded: true,
                            players: data.players,
                            headCoaches: data.headCoaches,
                            agencies: data.agencies,
                            staffProfiles: data.staffProfiles,
                        })
                    );

                    dispatch(Actions.sendUserActivity(`Search: ${keyword}`));
                    // if (!isSimilarity) {
                    //     dispatch(SuggestionActivityService.searchValue(keyword, isInShortList))
                    // }
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static fetchSuggestionsSilentAndNoRefresh() {
        return async (dispatch, getState: () => AppState) => {
            const keyword = Selectors.getKeyword(getState());
            // const isSimilarity = GridSelectors.isSimilarity(state)
            // const isInShortList = GridSelectors.isInShortList(state)
            const isSimilarity = false;
            const isInShortList = false;
            const playerSet =
                (FilterSelectors.getRoot(getState()).searchSubset || {}).playerSet || null;

            if (!keyword.trim()) return;

            if (Actions.cancelToken) Actions.cancelToken.cancel();

            Actions.cancelToken = getCancelTokenSource();

            try {
                const data = await SearchService.getSuggestions(
                    keyword,
                    false,
                    isSimilarity,
                    isInShortList,
                    playerSet,
                    Actions.cancelToken.token
                );

                if (data) {
                    dispatch(
                        stateController.setState({
                            wasLoaded: true,
                            players: data.players,
                            headCoaches: data.headCoaches,
                            agencies: data.agencies,
                        })
                    );
                }
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static disposeState() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static saveLatestSearchKeyword(keyword: string = null) {
        return async (dispatch, getState: () => AppState) => {
            if (keyword == null) {
                const searchState = Selectors.getRoot(getState());
                keyword = searchState.keyword;
            }
            await SearchService.saveLatestSearchKeyword(keyword);
        };
    }

    public static onPlayerSelected(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            const player = Selectors.getPlayerSuggestionItem(getState())(playerId);
            const { title, currentClubId, agencyId } = player;

            // if (GridSelectors.isSimilarity(getState())) {
            //     dispatch(GridController.initSimilarity(playerId, player.currentClubId ? player.currentClubId : null));
            //     dispatch(Actions.onKeywordClearWithoutRefresh())
            // } else {
            //     window.open('/profile/' + playerId, '_blank');
            //     await dispatch(FilterActions.saveLatestFilterSet(player.title));
            //     dispatch(Actions.onKeywordClear())
            //     const { currentClubId, agencyId } = player;
            //     // dispatch(SuggestionActivityService.selectPlayer(player.id, player.title, currentClubId, currentClubId ? null : agencyId));
            // }

            dispatch(
                userActivityInsert({
                    PageName: `Search Bar`,
                    Message: `Selected ${title}`,
                    PlayerId: player.id,
                    AgencyId: !!currentClubId ? null : agencyId,
                    ClubId: currentClubId ? currentClubId : null,
                    PageType: PageType.Search,
                })
            );

            window.open('/profile/' + playerId, '_blank');
            await dispatch(Actions.saveLatestSearchKeyword(player.title));
            dispatch(Actions.onKeywordClear());
            // dispatch(SuggestionActivityService.selectPlayer(player.id, player.title, currentClubId, currentClubId ? null : agencyId));
        };
    }

    public static onAgencySelected(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            const agency = Selectors.getAgencySuggestionItem(getState())(agencyId);

            dispatch(
                userActivityInsert({
                    PageName: `Search Bar`,
                    Message: `Selected ${agency.title}`,
                    AgencyId: agencyId,
                    PageType: PageType.Search,
                })
            );

            // window.open('/agency-profile/' + agencyId, '_blank');
            // window.open(`${config.profileWebAppUrl}/agency-profile/${encodeURIComponent(agency.title)}`);
            window.open(`/agency-profile/${encodeURIComponent(agency.title)}`, '_blank');
            await dispatch(Actions.saveLatestSearchKeyword(agency.title));
            dispatch(Actions.onKeywordClear());
            // dispatch(SuggestionActivityService.selectAgency(agency.id, agency.title))
        };
    }

    public static onHeadCoachSelected(coachId: number) {
        return async (dispatch, getState: () => AppState) => {
            const headCoach = Selectors.getHeadCoachSuggestionItem(getState())(coachId);

            window.open(`/coach-profile/${coachId}`, '_blank');
            await dispatch(Actions.saveLatestSearchKeyword(headCoach.title));
            dispatch(Actions.onKeywordClear());
        };
    }

    public static onStaffSelected(staffId: number) {
        return async (dispatch, getState: () => AppState) => {
            const staffProfile = Selectors.getStaffProfileSuggestionItem(getState())(staffId);

            window.open(`${config.staffProductWebAppUrl}/staff-profile/${staffId}`, '_blank');
            await dispatch(Actions.saveLatestSearchKeyword(staffProfile.title));
            dispatch(Actions.onKeywordClear());
        };
    }

    public static setActiveTab = (activeTab: Tab) => {
        return (dispatch) => {
            dispatch(stateController.setState({ activeTab }));
        };
    };

    public static sendUserActivityOnTabChange(activeTab: Tab) {
        return (dispatch) => {
            if (activeTab === Tab.Agencies) {
                dispatch(Actions.sendUserActivity('Agencies'));
            }
            if (activeTab === Tab.Players) {
                dispatch(Actions.sendUserActivity('Players'));
            }
            if (activeTab === Tab.HeadCoaches) {
                dispatch(Actions.sendUserActivity('Head Coaches'));
            }
        };
    }

    public static setDefaultTab = () => {
        return (dispatch, getState: () => AppState) => {
            const activeTab = Tab.Players;
            dispatch(stateController.setState({ activeTab }));
        };
    };

    public static onReleaseListTooltipHover = (playerId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const item = Selectors.getPlayerSuggestionItem(getState())(playerId);
            // const gridState = GridSelectors.getGridState(getState());
            // dispatch(PlayerActivityService.openReleaseListTootip(
            //     playerId,
            //     item.currentClubId,
            //     item.agencyId,
            //     gridState,
            // ))
        };
    };

    // Search Input START //
    public static createSearchDebounce = () =>
        _.debounce((dispatch, getState: () => AppState) => {
            const isUserActivityTracked = true;
            dispatch(GridActions.refresh(isUserActivityTracked));
        }, 800);

    public static searchDebounced = Actions.createSearchDebounce();

    public static onSearchKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }));
            if (keyword.length > 0) {
                if (keyword.length > 2) {
                    Actions.searchDebounced(dispatch, getState);
                }
            } else {
                dispatch(Actions.onKeywordClear());
            }
        };
    }

    public static onSearchKeywordClear() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.onKeywordClearWithoutRefresh());
            // dispatch(GridActions.searchPlayers());
            dispatch(GridActions.refresh()); //SEARCH
            // dispatch(GridController.resetPage())
        };
    }

    public static gridSendUserActivity() {
        return (dispatch, getState: () => AppState) => {
            const searchState = Selectors.getRoot(getState());
            const gridState = GridSelectors.getGridState(getState());

            const gridName = () => {
                if (gridState === GridToggleState.PlayersSearch) {
                    return 'Players';
                }
                if (gridState === GridToggleState.HeadCoachesSearch) {
                    return 'Coaches';
                }
                if (gridState === GridToggleState.AgenciesSearch) {
                    return 'Agencies';
                }
                return '';
            };

            dispatch(
                userActivityInsert({
                    PageName: `${gridName()} Search Bar`,
                    Message: `Search: ${searchState.keyword}`,
                    PageType: PageType.Search,
                })
            );
        };
    }

    public static sendUserActivity(message) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                userActivityInsert({
                    PageName: 'Search Bar',
                    Message: message,
                    PageType: PageType.Search,
                })
            );
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState): SearchAutosuggestState => state.squadSearch.search;
    public static getKeyword = (state: AppState) => Selectors.getRoot(state).keyword;
    // public static getSuggestionItem = (state: AppState) => (id, type: SearchItemType) =>
    //     state.squadSearch.search.items.find(item => item.id === id && item.searchItemType === type)

    public static getPlayerSuggestionItem = (state: AppState) => (id: number) =>
        Selectors.getRoot(state).players.items.find((x) => x.id === id);

    public static getHeadCoachSuggestionItem = (state: AppState) => (id: number) =>
        Selectors.getRoot(state).headCoaches.items.find((x) => x.id === id);

    public static getAgencySuggestionItem = (state: AppState) => (id: number) =>
        Selectors.getRoot(state).agencies.items.find((x) => x.id === id);

    public static getStaffProfileSuggestionItem = (state: AppState) => (id: number) =>
        Selectors.getRoot(state).staffProfiles.items.find((x) => x.id === id);

    public static getHiddenTabs = (state: AppState) => {
        let hiddenTabs = [];
        const isStaffProductEnabled = getAuth(state).clubPermission?.isStaffProductEnabled;
        if (!isStaffProductEnabled) {
            hiddenTabs.push(Tab.StaffProfiles);
        }

        return hiddenTabs;
    };
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SearchAutosuggestState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
};
