import React from 'react';

import './tr-with-us.scss';

import ManCity from '../../media/images/landing/man_city.png';
import NewcastleLogo from '../../media/images/landing/club-3.png';
import BenficaLogo from '../../media/images/landing/club-4.png';
import WolverhamptonLogo from '../../media/images/landing/club-5.png';
import JuventusLogo from '../../media/images/landing/club-6.png';
import RomaLogo from '../../media/images/landing/club-7.png';
import LogoRscAnderlecht from '../../media/images/landing/logo_RSC_Anderlecht.png';
import LogoLiverpool from '../../media/images/landing/FC_Liverpool.png'
import CSKAlogo from '../../media/images/landing/club-9.png';
import ShakhtarLogo from '../../media/images/landing/club-10.png';

const TrWithUs = () => (
    <>
        <div className="separated home-header text-center">
            <h3>Connect directly with club executives from 51 countries</h3>
        </div>
        <div className="trWithUs">
            <ul>
                <li>
                    <img src={NewcastleLogo} alt="club-3" />
                </li>
                <li>
                    <img src={WolverhamptonLogo} alt="club-5" />
                </li>
                <li>
                    <img src={BenficaLogo} alt="club-4" />
                </li>
                <li>
                    <img src={ManCity} alt="man_city" />
                </li>
                <li>
                    <img src={LogoLiverpool} alt="liverpool" />
                </li>
                <li className="bottom-row">
                    <img src={JuventusLogo} alt="club-6" />
                </li>
                <li className="bottom-row">
                    <img src={RomaLogo} alt="club-7" />
                </li>
                <li className="bottom-row">
                    <img src={LogoRscAnderlecht} alt="logo_RSC_Anderlecht" />
                </li>
                <li className="bottom-row">
                    <img src={CSKAlogo} alt="club-9" />
                </li>
                <li className="bottom-row">
                    <img src={ShakhtarLogo} alt="club-10" />
                </li>
            </ul>
            <h3>And many more!</h3>
        </div>
    </>
);

export default TrWithUs;
