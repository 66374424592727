import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { SortByKeyEnum } from 'api/search-v3/model/search-request';
import { CancelTokenSource } from 'axios';
import {
    GridToggleState,
    SearchMode,
} from 'pages/PlayerSearch-v3/shared-components/models/shared-models';
import { SearchSuggestionSubset } from 'api/search/model/suggestion-subsets';
import {
    Actions as GridActions,
    Selectors as GridSelectors,
} from 'pages/PlayerSearch-v3/personal-account/redux/grid.controller';

class SortItem {
    id: SortByKeyEnum;
    title: string;
    shortTitle: string;
    sortDirection: 'asc' | 'desc';
}

const sortInfoMap = new Map<SortByKeyEnum, SortItem>([
    [
        SortByKeyEnum.ByRelevant,
        {
            id: SortByKeyEnum.ByRelevant,
            title: 'Relevance',
            shortTitle: 'Relevance',
            sortDirection: 'desc',
        },
    ],
]);

export const staffSortList: Array<SortItem> = [sortInfoMap.get(SortByKeyEnum.ByRelevant)];

export class SearchState {
    searchMode: SearchMode;
    searchSubset: SearchSuggestionSubset;
    searchSubsets: SearchSuggestionSubset[];
    sortBy: SortByKeyEnum;
}

const defaultState: SearchState = {
    searchMode: SearchMode.Filtered,
    searchSubset: null,
    searchSubsets: [],
    sortBy: SortByKeyEnum.ByRelevant,
};

const stateController = new StateController<SearchState>(
    'PERSONAL_ACCOUNT_SEARCH/FILTER',
    defaultState
);

class Actions {
    public static token: CancelTokenSource = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static initSorting(definedSorting: SortByKeyEnum = null) {
        return (dispatch, getState: () => AppState) => {
            const state = getState();
            const gridState = GridSelectors.getGridState(state);

            if (definedSorting != null) {
                dispatch(stateController.setState({ sortBy: definedSorting }));
                return;
            }

            dispatch(stateController.setState({ sortBy: SortByKeyEnum.ByRelevant }));
        };
    }

    public static applySorting(sortBy: SortByKeyEnum, column: string) {
        return (dispatch, getState: () => AppState) => {
            const gridState = GridSelectors.getGridState(getState());

            dispatch(stateController.setState({ sortBy }));
            dispatch(GridActions.resetPage());
            dispatch(GridActions.refresh());
        };
    }

    public static setSearchModeToFiltered() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ searchMode: SearchMode.Filtered }));
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.personalAccountSearch.filter;
    public static getSortBy = (state: AppState) => Selectors.getRoot(state).sortBy;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SearchState as State,
    Actions,
    Selectors,
    stateController as Controller,
};
