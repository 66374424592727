import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer';
import { FriendlyMatchModel } from 'api/friendly/friendly-invitation'
import { FriendlyMatchService } from 'api/friendly/friendly-match.service'
import { LandingPageService } from 'api/landing-page/landing-page-service';
import * as MessageSend from './message.send.controller'
import { messageSend } from 'app/send-message/actions/send-message.actions'
import { friendlyMatchDeclareInterestByMessage } from '../../../app/friendly-matches/store/actions/activities.actions';
import { Actions as MessagingController } from 'app/messages/store/messages.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { loadUserPreferenceInfo } from 'store/userPreference/userPreference.service';
import { ActionType, PageType, UserActivityType } from 'constants/enums';

export class FriendlyInvitationsState {
    isLoading: boolean;
    intivations: Array<FriendlyMatchModel>
    processingIds: Array<number>;
    declareInterestProcessingIds: Array<number>
}

const defaultState: FriendlyInvitationsState = {
    isLoading: false,
    intivations: [],
    processingIds: [],
    declareInterestProcessingIds: []
}

const stateController = new StateController<FriendlyInvitationsState>(
    "LANDING_PAGE/FRIENDLYINVITATION",
    defaultState
);

class SendTestPlusPitchSpecification implements MessageSend.IMessageSendSpecificaion {
    private dispatch;
    private toSquadId: number;
    private friendlyId: number;
    private friendlyAd: any;

    constructor(dispatch, toSquadId: number, friendlyId: number, friendlyAd: any) {
        this.dispatch = dispatch;
        this.toSquadId = toSquadId;
        this.friendlyId = friendlyId;
        this.friendlyAd = friendlyAd;
    }

    public async sendMessage(session: MessageSend.MessageSendSession, message: string, checked: boolean): Promise<any> {

        this.dispatch(userActivityInsert({
            PageName: 'Home [Received friendly invitation]',
            Message: 'Sent New Message',
            ClubId: this.toSquadId,
            ActionType: ActionType.SentNewMessage,
            PageType: PageType.Home,
            UserActivityType: UserActivityType.Action
        }));

        //await this.dispatch(Actions.onDeclareInterest(this.friendlyId, true))
        await this.dispatch(
            Actions.proceedFriendly(
                this.friendlyId,
                async () => {
                    await this.dispatch(friendlyMatchDeclareInterestByMessage(this.friendlyAd));
                    await this.dispatch(MessagingController.sendPushNotificationToSquad(this.toSquadId, 'New Message'));
                }
            )
        )
        
        await this.dispatch(messageSend(this.toSquadId, message, null, session.officialName, session.mesageTypeId, session.subject, false, this.friendlyId));
    }

    public cancelMessage() {
        this.dispatch(userActivityInsert({
            PageName: 'Home [Received friendly invitation]',
            Message: 'Cancelled New Message',
            ClubId: this.toSquadId,
            PageType: PageType.Home
        }));
    }
}


class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        }
    }

    public static onNewMessageSend(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let currentFriendly = state.landingPage.friendlyInvitations.intivations.find(x => x.id == id);

            let session: MessageSend.MessageSendSession = {
                subject: "RE: FRIENDLY INVITATION",
                checkBoxTitle: null,
                logo: currentFriendly.squadLogo,
                officialName: currentFriendly.squadName,
                leagueName: currentFriendly.league + ", " + currentFriendly.leagueArea,
                mesageTypeId: 5,
            }

            if (currentFriendly != null) {
                dispatch(userActivityInsert({
                    PageName: 'Home [Received friendly invitation]',
                    Message: 'Opened New Message',
                    ClubId: currentFriendly.squadId,
                    PageType: PageType.Home
                }));
            }

            await dispatch(
                MessageSend.Actions.openSession(
                    session,
                    new SendTestPlusPitchSpecification(dispatch, currentFriendly.squadId, currentFriendly.id, currentFriendly)
                )
            )
            dispatch(loadUserPreferenceInfo())
        }
    }

    public static onNotAvailableClick(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let currentFriendly = state.landingPage.friendlyInvitations.intivations.find(x => x.id == id);            

            await dispatch(
                this.proceedFriendly(
                    id,
                    async () => {
                        await FriendlyMatchService.setInvitationAsNotAvailable(id)

                        if (currentFriendly != null) {
                            dispatch(userActivityInsert({
                                PageName: 'Home [Received friendly invitation]',
                                Message: 'Not Available',
                                ClubId: currentFriendly.squadId,
                                PageType: PageType.Home
                            }));
                        }
                    }
                )
            )
            dispatch(loadUserPreferenceInfo())
        }

    }
    public static onDeclareInterest(id: number, withoutTracking?: boolean): any {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();
            let currentFriendly = state.landingPage.friendlyInvitations.intivations.find(x => x.id == id);

            dispatch(stateController.setState(prevState => ({ ...prevState, declareInterestProcessingIds: [...prevState.declareInterestProcessingIds, id] })))

            await dispatch(
                this.proceedFriendly(
                    id,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await FriendlyMatchService.declareInterestForFriendly(id)
                            dispatch(stateController.setState(prevState => ({ ...prevState, declareInterestProcessingIds: prevState.declareInterestProcessingIds.filter(item => item !== id) })))
                            
                            if (currentFriendly != null && !withoutTracking) {
                                dispatch(userActivityInsert({
                                    PageName: 'Home [Received friendly invitation]',
                                    Message: 'Declared Interest',
                                    ClubId: currentFriendly.squadId,
                                    ActionType: ActionType.DeclaredInterest,
                                    PageType: PageType.Home,
                                    UserActivityType: UserActivityType.Action
                                }));
                            }
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            dispatch(stateController.setState(prevState => ({ ...prevState, declareInterestProcessingIds: prevState.declareInterestProcessingIds.filter(item => item !== id) })))
                            reject(e)
                        }
                    })
                )
            )
            dispatch(loadUserPreferenceInfo())
        }
    }

    public static init = () => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            let invitations = await FriendlyMatchService.getFriendlyInivtations();
            dispatch(stateController.setState({ isLoading: false, intivations: invitations.filter(item => item.active && item.friendlyMatchResponse == null) }));
        }
    }

    public static proceedFriendly(friendlyInvitationId: number, action: () => Promise<any>) {
        return async (dispatch, getState: () => AppState) => {
            let currentProcesses = getState().landingPage.friendlyInvitations.processingIds;
            dispatch(stateController.setState({ processingIds: [...currentProcesses, friendlyInvitationId] }))
            try {
                await action();
                currentProcesses = getState().landingPage.friendlyInvitations.processingIds;

                let pitchesGroup = getState().landingPage.friendlyInvitations.intivations;
                let refreshedPitchesGroup = pitchesGroup.filter(x => x.id != friendlyInvitationId)

                dispatch(stateController.setState(
                    { intivations: refreshedPitchesGroup })
                );
                
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(
                    { processingIds: [...currentProcesses.filter(x => x != friendlyInvitationId)] })
                )
            }
        }
    }

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    FriendlyInvitationsState as State,
    Actions as Actions,
    stateController as Controller
};


