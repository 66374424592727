import { StateController } from 'utils/action-declaration';
import { AppState } from '../../../root.reducer';
import { MessagingService } from 'api/messaging/messaging.service';
import { setUnreadMessageCount } from 'store/liveData/liveData.service';
import { createAction } from 'redux-actions';
import * as types from 'store/actionTypes';
import {
    ConversationTypeEnum,
    MessageItem,
    MessageType,
    NewMessage,
} from 'api/messaging/models/message';
import { ChannelItem, ReceiverType } from 'api/messaging/models/get-channel-response';
import insertUserActivity from 'app/user-activity/actions/user-activity.actions';
import moment from 'moment';
import { ActionType, PageType, UserTypeEnum } from 'constants/enums';
import { Actions as ModalController } from 'components/modals/shared-modal-controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { getCancelTokenSource } from 'axios-config';
import { getAuth } from 'store/auth/authReducer';

export enum UserType {
    Squad = 1,
    Agency = 2,
    Player = 3,
}
export enum Tabs {
    All = 1,
    Clubs = 2,
    Agents = 3,
}

export type MessagesByDays = Array<{
    day: Date | string;
    messages: Array<MessageItem>;
}>;

export type SelectedSquad = {
    id: number;
    apiId?: number;
    name: string;
    shortName?: string;
    officialName?: string;
    englishOfficialName?: string;
    englishShortName?: string;
    city?: string;
    areaId?: number;
    type?: ReceiverType;
    category?: string;
    male?: boolean;
    logo?: string;
    oldApiId?: number;
    clubId?: number;
    areaName?: string;
    salesSupervisorUserId?: number;
    salesSupervisorUserName?: null;
    league?: string;
    mainSquadName?: string;
    subsquadNames?: any;
    mainSquadId?: number;
    isMainSquad?: boolean;
    allowedCompetitions?: Array<any>;
    allowedAdsCompetitions?: Array<any>;
    permissions?: Array<any>;
    allowedMaxTransferFee?: number;
    allowedMaxLoanFee?: number;
    allowedSuperPitchesCount?: number;
    allowedPlusPitchesCount?: number;
    pitchInsightsSubscription?: number;
    loanInsightsSubscription?: number;
    canSaleLoanPlayers?: boolean;
    canUseMatchMaker?: boolean;
    isAllLeaguesAvailable?: boolean;
    canSendMessages?: boolean;
    adsCanSeeClubNames?: boolean;
    adsCanPitchPlayers?: boolean;
    isAllAdsLeaguesAvailable?: boolean;
    availabilityNo?: boolean;
    financialInsights?: boolean;
    hasUsers?: boolean;
    hasPlayers?: boolean;
    users?: Array<any>;
    subscription?: null;
    competitionId?: number;
};

class MessagesState {
    userType: UserType;
    activeTab?: Tabs;

    isSearch: boolean;
    keyword: string;
    selectedSquad: SelectedSquad;

    channelId: string;
    newMessageAvailability?: {
        availableCount?: number;
        lockedTill?: any;
    };
    showNewMessageRestrictedPopup?: boolean;

    messagesLoading: boolean;
    messageSending: boolean;
    messageSent: boolean;
    receivedNewMessage: boolean;
    channelsLoading: boolean;
    isInterestedBtnLoading: boolean;
    isNotInterestedBtnLoading: boolean;

    channels: Array<ChannelItem>;
    channelRemoveId: string | null;

    messages: {
        items: MessagesByDays;
        isReadonly: boolean;
        isUnregisteredReceiver: boolean;
    };
}

const defaultState: MessagesState = {
    userType: null,
    activeTab: Tabs.All,

    isSearch: false,
    keyword: '',
    selectedSquad: null,

    channelId: null,
    newMessageAvailability: null,
    showNewMessageRestrictedPopup: false,

    messagesLoading: false,
    messageSending: false,
    messageSent: false,
    receivedNewMessage: false,
    channelsLoading: false,
    isInterestedBtnLoading: false,
    isNotInterestedBtnLoading: false,

    channels: [],
    channelRemoveId: null,

    messages: {
        items: [],
        isReadonly: null,
        isUnregisteredReceiver: null,
    },
};

const stateController = new StateController<MessagesState>('MESSAGES_NEW', defaultState);

class Actions {
    public static cancelTokenMessages = null;
    public static cancelTokenMessagesCount = null;
    public static cancelTokenChat = null;

    public static defineUserType() {
        return (dispatch, getState: () => AppState) => {
            const userType = Selectors.selectUserType(getState());
            dispatch(stateController.setState({ userType: userType }));
        };
    }

    public static sendUserActivity = (channelId, channel: ChannelItem) => {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const myEndpointId = Selectors.currentUserEndpointId(appState);
            const isUserSender = channel.sender.id === myEndpointId;
            const selectedSquadId = isUserSender ? channel.receiver.id : channel.sender.id;
            const selectedSquadName = isUserSender ? channel.receiver.name : channel.sender.name;
            const selectedSquadType = isUserSender ? channel.receiver.type : channel.sender.type;

            if (getState().messages.userType === UserType.Player) {
                dispatch(
                    userActivityInsert({
                        Message: `Selected Message`,
                        PageName: 'My Page [Messaging]',
                        PlayerId: channel.sender.id,
                        AgencyId: channel.receiver.id,
                    })
                );
            } else {
                if (
                    channel.conversationType === ConversationTypeEnum.PlayerToAgencyRepresentation
                ) {
                    dispatch(
                        userActivityInsert({
                            Message: `Selected Representation Request: ${selectedSquadName}`,
                            PageName: 'Messaging [Chat List]',
                            PlayerId: channel.sender.id,
                            AgencyId: channel.receiver.id,
                        })
                    );
                    dispatch(
                        userActivityInsert({
                            Message: `Clicked Representation Request Player Profile: ${selectedSquadName}`,
                            PageName: 'Messaging [Chat List]',
                            PlayerId: channel.sender.id,
                            AgencyId: channel.receiver.id,
                        })
                    );
                } else {
                    dispatch(
                        userActivityInsert({
                            Message: `Opened Chat`,
                            PageName: 'Messaging [Chat List]',
                            ClubId:
                                selectedSquadType === ReceiverType.Squad ? selectedSquadId : null,
                            AgencyId:
                                selectedSquadType === ReceiverType.Agency ? selectedSquadId : null,
                            PageType: PageType.Messaging,
                        })
                    );
                }
            }

            dispatch(
                Actions.setMessageSelectedSquad({
                    id: selectedSquadId,
                    name: selectedSquadName,
                    type: selectedSquadType,
                })
            );
            dispatch(Actions.getChannelMessages(channelId, false));
        };
    };
    public static changeTab = (newTab: Tabs) => async (dispatch, getState: () => AppState) => {
        dispatch(Actions.setMessageIsSearch(false));
        dispatch(Actions.disposeActiveChannel());
        dispatch(Actions.disposeSelectedSquad());
        dispatch(Actions.disposeMessages());
        dispatch(stateController.setState({ activeTab: newTab }));

        let uaMessage;
        switch (newTab) {
            case Tabs.Clubs:
                uaMessage = 'Selected Clubs';
                break;
            case Tabs.Agents:
                uaMessage = 'Selected Agents';
                break;
            default:
                uaMessage = 'Selected All';
                break;
        }

        dispatch(
            insertUserActivity({
                Message: uaMessage,
                PageName: 'Messaging',
                PageType: PageType.Messaging,
            })
        );
    };

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId;
            await dispatch(Actions.defineUserType());

            const userType = getState().messages.userType;
            if (userType === UserType.Player) {
                dispatch(Actions.loadPlayerChannels());
            } else if (userType === UserType.Agency) {
                dispatch(Actions.loadAgencyChannels());
            } else {
                dispatch(Actions.loadSquadChannels());
            }

            if (getState().messages.userType === UserType.Player) {
                dispatch(
                    userActivityInsert({
                        Message: 'Viewed Messages',
                        PageName: 'My Page [Messaging]',
                        PlayerId: playerId,
                    })
                );
            } else {
                dispatch(
                    userActivityInsert({
                        Message: 'Opened Messaging',
                        PageName: 'Messaging',
                        PageType: PageType.Messaging,
                    })
                );
            }
        };
    }

    public static rejectPlayerRepresentationRequest(messageId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const activeChannelId = getState().messages.channelId;
                dispatch(stateController.setState({ isNotInterestedBtnLoading: true }));
                await MessagingService.rejectPlayerRepresentationRequest(messageId);
                await dispatch(Actions.getChannelMessages(activeChannelId, true, true));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isNotInterestedBtnLoading: false }));
                dispatch(
                    userActivityInsert({
                        PageName: 'Messaging [Chat List]',
                        Message: `Clicked Representation Uninterested: ${getState().messages.selectedSquad.name}`,
                        PlayerId: getState().messages.selectedSquad.id,
                        AgencyId: getState().auth.agencyId,
                    })
                );
            }
        };
    }

    public static declareInterestPlayerRepresentationRequest(messageId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const activeChannelId = getState().messages.channelId;
                dispatch(stateController.setState({ isInterestedBtnLoading: true }));
                await MessagingService.declareInterestPlayerRepresentationRequest(messageId);
                await dispatch(Actions.getChannelMessages(activeChannelId, true, true));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isInterestedBtnLoading: false }));
                dispatch(
                    userActivityInsert({
                        PageName: 'Messaging [Chat List]',
                        Message: `Clicked Representation Interested: ${getState().messages.selectedSquad.name}`,
                        PlayerId: getState().messages.selectedSquad.id,
                        AgencyId: getState().auth.agencyId,
                    })
                );
            }
        };
    }

    public static loadSquadChannels(isHiddenRefresh: boolean = false) {
        return async (dispatch, getState: () => AppState) => {
            const squadId = getState().auth.squadId;
            if (!isHiddenRefresh) dispatch(stateController.setState({ channelsLoading: true }));
            try {
                if (Actions.cancelTokenChat) {
                    Actions.cancelTokenChat.cancel();
                }
                Actions.cancelTokenChat = getCancelTokenSource();

                const result = await MessagingService.getSquadChannels(
                    squadId,
                    Actions.cancelTokenChat.token
                );
                if (result) {
                    dispatch(stateController.setState({ channels: result.items }));
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ channelsLoading: false }));
            }
        };
    }
    public static loadAgencyChannels(isHiddenRefresh: boolean = false) {
        return async (dispatch, getState: () => AppState) => {
            const agencyId = getState().auth.agencyId;
            if (!isHiddenRefresh) dispatch(stateController.setState({ channelsLoading: true }));
            try {
                if (Actions.cancelTokenChat) {
                    Actions.cancelTokenChat.cancel();
                }
                Actions.cancelTokenChat = getCancelTokenSource();
                const result = await MessagingService.getAgencyChannels(
                    agencyId,
                    Actions.cancelTokenChat.token
                );
                if (result) {
                    dispatch(stateController.setState({ channels: result.items }));
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ channelsLoading: false }));
            }
        };
    }
    public static loadPlayerChannels(isHiddenRefresh: boolean = false) {
        return async (dispatch, getState: () => AppState) => {
            const playerId = getState().auth.playerId;
            if (!isHiddenRefresh) dispatch(stateController.setState({ channelsLoading: true }));
            try {
                if (Actions.cancelTokenChat) {
                    Actions.cancelTokenChat.cancel();
                }
                Actions.cancelTokenChat = getCancelTokenSource();
                const result = await MessagingService.getPlayerChannels(
                    playerId,
                    Actions.cancelTokenChat.token
                );
                if (result) {
                    dispatch(stateController.setState({ channels: result.items }));
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ channelsLoading: false }));
            }
        };
    }

    public static deleteChannel() {
        return async (dispatch, getState: () => AppState) => {
            const appState = getState();
            const channelId = appState.messages.channelRemoveId
                ? appState.messages.channelRemoveId
                : appState.messages.channelId;

            try {
                await MessagingService.deleteChat(channelId);
                const channel = appState.messages.channels.find((item) => item.id === channelId);

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        channelId: null,
                        channels: prevState.channels.filter((item) => item.id !== channelId),
                        messages: [],
                        selectedSquad: null,
                    }))
                );
                dispatch(Actions.updateUnreadMessagesCount());

                let removeMessageTextPostfix = 'Message';
                if (channel.subject === 'Friendly INTEREST') {
                    removeMessageTextPostfix = 'Friendly interest';
                } else if (channel.subject.endsWith('INTEREST')) {
                    removeMessageTextPostfix = 'Player Interest';
                } else if (channel.subject.toLowerCase().endsWith('friendly invitation')) {
                    removeMessageTextPostfix = 'Friendly Invitation';
                } else if (channel.subject === 'RE: Friendly invitation') {
                    removeMessageTextPostfix = 'Friendly Invitation';
                }

                const myEndpointId = Selectors.currentUserEndpointId(appState);
                const isUserDefinedAsSender = channel.sender.id === myEndpointId;
                let participantId = isUserDefinedAsSender ? channel.receiver.id : channel.sender.id;
                let participantType = isUserDefinedAsSender
                    ? channel.receiver.type
                    : channel.sender.type;

                dispatch(
                    insertUserActivity({
                        Message: `Delete: ${removeMessageTextPostfix}`,
                        PageName: 'Messaging [Chat List]',
                        ClubId: participantType === ReceiverType.Squad ? participantId : null,
                        AgencyId: participantType === ReceiverType.Agency ? participantId : null,
                        PageType: PageType.Messaging,
                    })
                );
            } catch (e) {
                console.error(e);
            }
        };
    }

    // public static setRemoveChannelId(id?: string) {
    //     return (dispatch, getState: () => AppState) => {
    //         const channelRemoveId = id ? id : getState().messages.channelId;
    //         dispatch(stateController.setState(prevState => ({
    //             ...prevState,
    //             channelRemoveId,
    //         })))
    //     }
    // }

    public static openRemoveModal(id?: string) {
        return (dispatch, getState: () => AppState) => {
            const channelRemoveId = id ? id : getState().messages.channelId;
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    channelRemoveId,
                }))
            );
            dispatch(ModalController.onOpen());
        };
    }

    public static sendMessage(message: string) {
        return async (dispatch, getState: () => AppState) => {
            const appState = getState();
            const auth = getAuth(appState);
            const state = appState.messages;
            const initiallyActiveTab = state.activeTab;
            const myEndpointId = Selectors.currentUserEndpointId(appState);

            let activeChannelId = state.channelId;
            let channel: ChannelItem = state.channels.find((item) => item.id === activeChannelId);
            const selectedSquad = state.selectedSquad;
            const isSearchView = state.isSearch;

            let receiverType: ReceiverType;
            let senderType: ReceiverType;
            let receiverId = selectedSquad.id;
            let shouldCreateNewChat;

            if (isSearchView) {
                shouldCreateNewChat = true;
                receiverType = selectedSquad.type;
                senderType = Selectors.currentUserReceiverType(appState);
            } else {
                shouldCreateNewChat = false;
                const isUserDefinedAsSender = channel.sender.id === myEndpointId;
                senderType = isUserDefinedAsSender ? channel.sender.type : channel.receiver.type;
                receiverType = isUserDefinedAsSender ? channel.receiver.type : channel.sender.type;
            }

            let createChat: (entityId: number, message: NewMessage) => Promise<any>;
            let sendMessage: (
                entityId: number,
                chatId: string,
                message: NewMessage
            ) => Promise<any>;
            switch (receiverType) {
                case ReceiverType.Agency:
                    createChat = MessagingService.createChatWithAgency;
                    sendMessage = MessagingService.sendMessageToAgency;
                    break;

                case ReceiverType.Player:
                    createChat = MessagingService.createChatWithPlayer;
                    sendMessage = MessagingService.sendMessageToPlayer;
                    break;

                case ReceiverType.Squad:
                default:
                    createChat = MessagingService.createChatWithSquad;
                    sendMessage = MessagingService.sendMessageToSquad;
                    break;
            }

            let messageType = MessageType.ChatMessage;
            let chatType = ConversationTypeEnum.Chat;
            let activeTab = Tabs.Clubs;

            if (senderType === ReceiverType.Squad && receiverType === ReceiverType.Agency) {
                messageType = MessageType.ClubAgencyChatMessage;
                chatType = ConversationTypeEnum.ClubAgencyChat;
                activeTab = Tabs.Agents;
            }

            try {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        messageSending: true,
                        messageSent: false,
                    }))
                );

                if (shouldCreateNewChat) {
                    const chat = await createChat.call(MessagingService, receiverId, {
                        messageType,
                        chatType,
                        text: message,
                        subject: 'New Message',
                    });

                    const channels = [
                        chat,
                        ...state.channels.filter((item) => item.id !== chat.id),
                    ];

                    await dispatch(
                        stateController.setState({
                            channelId: chat.id,
                            channels,
                            isSearch: false,
                            activeTab,
                        })
                    );
                    dispatch(Actions.updateMessagesAfterSend());
                } else {
                    await sendMessage.call(MessagingService, receiverId, activeChannelId, {
                        messageType,
                        text: message,
                    });
                }

                dispatch(Actions.trackSendMessageActivity());

                await dispatch(Actions.checkNewMessageLimit());
                const newMessageAvailabilityCount =
                    getState().messages.newMessageAvailability.availableCount;
                if (newMessageAvailabilityCount === 0) {
                    dispatch(
                        createAction(types.CREATE_NEW_MESSAGE_IS_LIMITED_POPUP)({ show: true })
                    );
                } else {
                    dispatch(
                        createAction(types.CREATE_NEW_MESSAGE_IS_LIMITED_POPUP)({ show: false })
                    );
                }

                // No need to update redux state if the user chose a different channel or tab
                const isActiveChannelChanged = activeChannelId != getState().messages.channelId;
                const isActiveTabChanged = initiallyActiveTab != getState().messages.activeTab;
                if (isActiveChannelChanged || isActiveTabChanged) return;

                const messagesByDaysUpdated = shouldCreateNewChat
                    ? []
                    : [...getState().messages.messages.items];
                const today = moment(new Date()).format('DD/MM/YYYY');

                let senderName = `${auth.userFullName}, ${auth.userPositionName}`;
                if (auth.userImpersonateId) {
                    senderName = `${auth.userImpersonateFullName}, ${auth.userImpersonatePositionName}`;
                }
                const newMessageItem: MessageItem = {
                    body: message,
                    id: Math.random() * 10000, // temporary id
                    isMineSquadMessage: true,
                    isUnread: true,
                    isDeleted: false,
                    responseType: null,
                    messageType: MessageType.ChatMessage,
                    senderId: myEndpointId,
                    senderName: senderName,
                    sentDate: new Date(),
                };

                if (messagesByDaysUpdated.find((item) => item.day === today)) {
                    messagesByDaysUpdated
                        .find((item) => item.day === today)
                        .messages.push(newMessageItem);
                } else {
                    messagesByDaysUpdated.push({ day: today, messages: [newMessageItem] });
                }

                if (shouldCreateNewChat) {
                    const userType = getState().messages.userType;
                    if (userType === UserType.Player) {
                        dispatch(Actions.loadPlayerChannels());
                    } else if (userType === UserType.Agency) {
                        dispatch(Actions.loadAgencyChannels());
                    } else {
                        dispatch(Actions.loadSquadChannels());
                    }

                    dispatch(
                        stateController.setState((prevState) => ({
                            ...prevState,
                            channelId: prevState.channels[0].id,
                            selectedSquad: {
                                id: prevState.channels[0].receiver.id,
                                name: prevState.channels[0].receiver.name,
                            },
                            messages: {
                                ...prevState.messages,
                                items: messagesByDaysUpdated,
                            },
                        }))
                    );
                } else {
                    dispatch(
                        stateController.setState((prevState) => ({
                            ...prevState,
                            channels: [
                                channel,
                                ...prevState.channels.filter((item) => item.id !== activeChannelId),
                            ],
                            channelId: channel.id,
                            messages: {
                                ...prevState.messages,
                                items: messagesByDaysUpdated,
                            },
                        }))
                    );
                }

                dispatch(stateController.setState({ isSearch: false }));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        messageSending: false,
                        messageSent: true,
                    }))
                );
            }
        };
    }

    public static trackSendMessageActivity() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const myEndpointId = Selectors.currentUserEndpointId(state);
            const playerId = state.auth.playerId;
            let activeChannel = Selectors.selectActiveChannel(state);
            let receiverId;
            let receiverType;

            if (!activeChannel) {
                receiverId = state.messages.selectedSquad.id;
                receiverType = state.messages.selectedSquad.type;
            } else {
                const isUserDefinedAsSender = activeChannel.sender.id === myEndpointId;
                if (isUserDefinedAsSender) {
                    receiverId = activeChannel.receiver.id;
                    receiverType = activeChannel.receiver.type;
                } else {
                    receiverId = activeChannel.sender.id;
                    receiverType = activeChannel.sender.type;
                }
            }

            if (getState().messages.userType === UserType.Player) {
                dispatch(
                    insertUserActivity({
                        Message: 'Sent Message',
                        PageName: 'My Page [Messaging]',
                        PlayerId: playerId,
                        AgencyId: activeChannel.receiver.id ? activeChannel.receiver.id : null,
                    })
                );
            } else {
                dispatch(
                    insertUserActivity({
                        Message: 'Sent New Message',
                        PageName: 'Messaging [Chat List]',
                        ClubId: receiverType === ReceiverType.Squad ? receiverId : null,
                        AgencyId: receiverType === ReceiverType.Agency ? receiverId : null,
                        ActionType: ActionType.SentNewMessage,
                        PageType: PageType.Messaging,
                    })
                );
            }
        };
    }

    public static updateMessagesAfterSend() {
        return async (dispatch, getState: () => AppState) => {
            const activeChannelId = getState().messages.channelId;
            dispatch(Actions.getChannelMessages(activeChannelId, true, true));
        };
    }

    public static setMessageIsSearch(isSearch: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ isSearch: isSearch }));
            dispatch(Actions.disposeMessages());
        };
    }

    public static setMessageSelectedSquad(selectedSquad: SelectedSquad) {
        return (dispatch) => {
            dispatch(stateController.setState({ selectedSquad: selectedSquad }));
        };
    }

    public static setMessageKeyword(keyword: string) {
        return (dispatch) => dispatch(stateController.setState({ keyword: keyword }));
    }
    public static clearActiveChannel = () => (dispatch) =>
        dispatch(stateController.setState({ channelId: null }));

    public static getChannelMessages(channelId, moveChannelToTop, hiddenRefresh = false) {
        return async (dispatch, getState: () => AppState) => {
            const initiallyActiveTab = getState().messages.activeTab;
            if (!hiddenRefresh)
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        messagesLoading: true,
                        messages: { items: [], isReadonly: false, isUnregisteredReceiver: false },
                    }))
                );

            try {
                dispatch(stateController.setState({ channelId: channelId }));

                const response = await MessagingService.getMessages(channelId);

                // No need to update redux state if the user chose a different channel or tab
                const isActiveChannelChanged = channelId != getState().messages.channelId;
                const isActiveTabChanged = initiallyActiveTab != getState().messages.activeTab;
                if (isActiveChannelChanged || isActiveTabChanged || !response) {
                    dispatch(stateController.setState({ messagesLoading: false }));
                    return;
                }

                const channels = getState().messages.channels;
                const updatedChannelList = moveChannelToTop
                    ? [
                          channels.find(({ id }) => id === channelId),
                          ...channels.filter(({ id }) => id !== channelId),
                      ]
                    : null;

                const messagesByDays = response.messages
                    .reverse()
                    .reduce((acc: MessagesByDays, item: MessageItem) => {
                        const date = moment(item.sentDate).format('DD/MM/YYYY');
                        if (acc.some((el) => el.day === date)) {
                            acc.find((e) => e.day === date).messages.push(item);
                            return acc;
                        } else {
                            acc.push({
                                day: date,
                                messages: [item],
                            });
                            return acc;
                        }
                    }, []);

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        messages: {
                            items: messagesByDays,
                            isReadonly: response.isReadonly,
                            isUnregisteredReceiver: response.isUnregisteredReceiver,
                        },
                        channels: updatedChannelList || channels,
                        messageSent: false,
                    }))
                );

                if (response.isReadonly) {
                    dispatch(Actions.notifyUserAboutChannelWasDeletedByOneSide(channelId));
                }
                dispatch(stateController.setState({ messagesLoading: false }));
            } catch (e) {
                console.error(e);
                dispatch(stateController.setState({ messagesLoading: false }));
            } finally {
                dispatch(stateController.setState({ receivedNewMessage: false }));
            }
        };
    }

    public static notifyUserAboutChannelChanges(channelId) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            let activeChanellId = state.messages.channelId;

            const userType = getState().messages.userType;
            if (userType === UserType.Player) {
                dispatch(Actions.loadPlayerChannels(true));
            } else if (userType === UserType.Agency) {
                dispatch(Actions.loadAgencyChannels(true));
            } else if (userType === UserType.Squad) {
                dispatch(Actions.loadSquadChannels(true));
            }

            if (channelId === activeChanellId) {
                const channel = state.messages.channels.find((item) => item.id === activeChanellId);
                if (channel.unreadMessagesCount === 0) {
                    dispatch(stateController.setState({ receivedNewMessage: true }));
                }

                dispatch(Actions.getChannelMessages(channelId, true, true));
            } else {
                dispatch(stateController.setState({ receivedNewMessage: false }));
            }
        };
    }

    public static hideNewMessageLimitPopup = () => (dispatch, getState) => {
        dispatch(createAction(types.CREATE_NEW_MESSAGE_IS_LIMITED_POPUP)({ show: false }));
    };

    public static checkNewMessageLimit() {
        return async (dispatch, getState) => {
            const { userId } = getState().auth;
            try {
                const newMessageAvailability = await MessagingService.checkNewMessageLimit(userId);
                dispatch(
                    stateController.setState({ newMessageAvailability: newMessageAvailability })
                );
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static updateUnreadMessagesCount() {
        return async (dispatch) => {
            try {
                if (Actions.cancelTokenMessagesCount) {
                    Actions.cancelTokenMessagesCount.cancel();
                }
                Actions.cancelTokenMessagesCount = getCancelTokenSource();

                let countOfUnReadMessages = await MessagingService.getUnreadMessagesCount(
                    Actions.cancelTokenMessagesCount.token
                );
                if (countOfUnReadMessages !== undefined) {
                    dispatch(setUnreadMessageCount(countOfUnReadMessages));
                }
            } catch (err) {
                console.log(err);
            }
        };
    }

    public static setChannelAsRead(channelId: string) {
        return (dispatch, getState: () => AppState) => {
            const state = getState();
            let currentActiveChannelId = state.messages.channelId;
            if (channelId === currentActiveChannelId) {
                const channel = getState().messages.messages.items;
                const items = [];

                channel.forEach(({ messages, day }) => {
                    const a = messages.map((item) => ({ ...item, isUnread: false }));

                    items.push({ messages: a, day });
                });

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        messages: { ...prevState.messages, items },
                    }))
                );
            }
        };
    }

    public static markChannelRead(channelId: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                await MessagingService.markChatAsRead(channelId);
                const channels = getState().messages.channels;
                const updatedChannels = [
                    ...channels.map((channel) =>
                        channel.id === channelId ? { ...channel, unreadMessagesCount: 0 } : channel
                    ),
                ];
                dispatch(stateController.setState({ channels: updatedChannels }));
                dispatch(Actions.updateUnreadMessagesCount());
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static markChatMessageAsReadById(channelId: string, messageId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().messages;
                const channels = [...substate.channels];
                const channelMessages = [...substate.messages.items];
                const allMessages = channelMessages.reduce(
                    (acc, item) => [...acc, ...item.messages],
                    []
                );
                if (Actions.cancelTokenMessages) {
                    Actions.cancelTokenMessages.cancel();
                }
                Actions.cancelTokenMessages = getCancelTokenSource();

                await MessagingService.markChatMessageAsReadById(
                    channelId,
                    messageId,
                    Actions.cancelTokenMessages.token
                );

                const updatedMessages = channelMessages.map((item) => {
                    const messagesPerDay = item.messages.map((message) => {
                        return message.id <= messageId &&
                            message.isUnread &&
                            !message.isMineSquadMessage
                            ? {
                                  ...message,
                                  isUnread: false,
                              }
                            : message;
                    });
                    return {
                        ...item,
                        messages: [...messagesPerDay],
                    };
                });
                const count =
                    allMessages.length -
                    allMessages.findIndex((message) => message.id === messageId) -
                    1;

                const updatedChannels = [
                    ...channels.map((channel) =>
                        channel.id === channelId
                            ? {
                                  ...channel,
                                  unreadMessagesCount: channel.unreadMessagesCount > 0 ? count : 0,
                              }
                            : channel
                    ),
                ];

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        messages: {
                            ...prevState.messages,
                            items: [...updatedMessages],
                        },
                        channels: updatedChannels,
                    }))
                );

                dispatch(Actions.updateUnreadMessagesCount());

                const userType = getState().messages.userType;
                if (userType === UserType.Player) {
                    dispatch(Actions.loadPlayerChannels(true));
                } else if (userType === UserType.Agency) {
                    dispatch(Actions.loadAgencyChannels(true));
                } else {
                    dispatch(Actions.loadSquadChannels(true));
                }
                dispatch(stateController.setState({ receivedNewMessage: false }));
            } catch (err) {
                console.error(err);
            }
        };
    }

    public static notifyUserAboutChannelWasDeletedByOneSide(channelId) {
        return (dispatch, getState: () => AppState) => {
            const channels = getState().messages.channels;
            const updatedChannels = [
                ...channels.map((item) =>
                    item.id === channelId ? { ...item, isReadonly: true } : item
                ),
            ];
            dispatch(
                stateController.setState({
                    channels: updatedChannels,
                })
            );
        };
    }
    public static sendPushNotificationToUser(userId, messageText) {
        return async (dispatch, getState: () => AppState) => {
            const { squadId, agencyId } = getState().auth;
            if (squadId) {
                let channelId = getState().messages.channelId;
                MessagingService.sendPushNotificationToUser({
                    channelId: channelId,
                    messageText: messageText,
                    receiverSquadId: userId,
                    receiverAgencyId: 0,
                    receiverPlayerId: 0,
                    squadId: squadId,
                    agencyId: 0,
                    playerId: 0,
                });
            } else {
                let channelId = getState().messages.channelId;
                MessagingService.sendPushNotificationToUserFromAgency({
                    channelId: channelId,
                    messageText: messageText,
                    receiverSquadId: userId,
                    receiverAgencyId: 0,
                    receiverPlayerId: 0,
                    squadId: 0,
                    agencyId: agencyId,
                    playerId: 0,
                });
            }
        };
    }
    public static sendPushNotificationToSquad(receiverSquadId, messageText) {
        return async (dispatch, getState: () => AppState) => {
            const { squadId, agencyId } = getState().auth;
            if (squadId) {
                let channelId = getState().messages.channelId;
                MessagingService.sendPushNotificationToSquad({
                    channelId: channelId,
                    messageText: messageText,
                    receiverSquadId: receiverSquadId,
                    receiverAgencyId: 0,
                    receiverPlayerId: 0,
                    squadId: squadId,
                    agencyId: 0,
                    playerId: 0,
                });
            } else {
                let channelId = getState().messages.channelId;
                MessagingService.sendPushNotificationToSquad({
                    channelId: channelId,
                    messageText: messageText,
                    receiverSquadId: receiverSquadId,
                    receiverAgencyId: 0,
                    receiverPlayerId: 0,
                    squadId: 0,
                    agencyId: agencyId,
                    playerId: 0,
                });
            }
        };
    }
    public static sendPushNotificationToPlayerOrAgency(
        receiverType: ReceiverType,
        senderId: number,
        receiverId: number,
        messageText: string
    ) {
        return async (dispatch, getState: () => AppState) => {
            if (receiverType === ReceiverType.Agency) {
                // message from Player to Agency
                let channelId = getState().messages.channelId;
                MessagingService.SendPushNotificationToAgencyFromPlayer({
                    squadId: 0,
                    agencyId: 0,
                    playerId: senderId,
                    channelId: channelId,
                    messageText: messageText,
                    receiverSquadId: 0,
                    receiverAgencyId: receiverId,
                    receiverPlayerId: 0,
                });
            }
            if (receiverType === ReceiverType.Player) {
                // message from Agency to Player
                let channelId = getState().messages.channelId;
                MessagingService.SendPushNotificationToPlayerFromAgency({
                    squadId: 0,
                    agencyId: senderId,
                    playerId: 0,
                    channelId: channelId,
                    messageText: messageText,
                    receiverSquadId: 0,
                    receiverAgencyId: 0,
                    receiverPlayerId: receiverId,
                });
            }
        };
    }

    public static sendPushNotificationToSquadWithoutFriendlySupervisors(
        receiverSquadId,
        messageText
    ) {
        return async (dispatch, getState: () => AppState) => {
            const { squadId, agencyId } = getState().auth;
            const stateMessages = getState().messages;
            const selectedSquad = stateMessages.selectedSquad;
            const receiverType = selectedSquad.type;
            if (squadId) {
                let channelId = getState().messages.channelId;

                switch (receiverType) {
                    case ReceiverType.Squad:
                        MessagingService.sendPushNotificationToSquadWithoutFriendlySupervisors({
                            channelId: channelId,
                            messageText: messageText,
                            receiverSquadId: receiverSquadId,
                            receiverAgencyId: 0,
                            receiverPlayerId: 0,
                            squadId: squadId,
                            agencyId: 0,
                            playerId: 0,
                        });
                        break;
                    case ReceiverType.Agency:
                        MessagingService.sendPushNotificationToAgency({
                            channelId: channelId,
                            messageText: messageText,
                            receiverSquadId: 0,
                            receiverAgencyId: receiverSquadId,
                            receiverPlayerId: 0,
                            squadId: squadId,
                            agencyId: 0,
                            playerId: 0,
                        });
                        break;
                    default:
                        break;
                }
            } else if (agencyId) {
                let channelId = getState().messages.channelId;
                MessagingService.sendPushNotificationToSquadWithoutFriendlySupervisorsFromAgency({
                    channelId: channelId,
                    messageText: messageText,
                    receiverSquadId: receiverSquadId,
                    receiverAgencyId: 0,
                    receiverPlayerId: 0,
                    agencyId: agencyId,
                    squadId: 0,
                    playerId: 0,
                });
            } else {
                return;
            }
        };
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    isSearch: false,
                    channelId: null,
                    channels: [],
                    messages: {
                        items: [],
                        isReadonly: false,
                    },
                    selectedSquad: null,
                }))
            );
        };
    }
    public static disposeActiveChannel() {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    channelId: null,
                }))
            );
        };
    }
    public static disposeSelectedSquad() {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    selectedSquad: null,
                }))
            );
        };
    }
    public static disposeMessages() {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    messages: {
                        isReadonly: false,
                        items: [],
                    },
                }))
            );
        };
    }
}

class Selectors {
    public static isChannelIsReadonly(state: AppState) {
        return state.messages.messages.isReadonly;
    }
    public static isUnregisteredReceiver(state: AppState) {
        return state.messages.messages.isUnregisteredReceiver;
    }

    public static isGetVirifiedToHomePage(state: AppState) {
        const activeChannelId = state.messages.channelId;
        const channel = state.messages.channels.find((item) => item.id === activeChannelId);
        if (!channel) return true;
        return !!channel.isPlayerWaitingForAcceptance;
    }

    public static lastMessage(state: AppState) {
        const lastMessageByDays = state.messages.messages.items?.at(-1);
        return lastMessageByDays && lastMessageByDays.messages?.at(-1);
    }

    public static selectActiveChannel(state: AppState) {
        const selectedChannel = state.messages.channelId;
        const channels = state.messages.channels;
        return (channels || []).find((channel) => channel.id === selectedChannel) || null;
    }

    public static selectCurrentClubName(state: AppState) {
        const selectedSquad = state.messages.selectedSquad;
        const isSearch = state.messages.isSearch;
        const messages = state.messages.messages.items;
        const activeChannel = Selectors.selectActiveChannel(state);

        let isCurrentClubIsVisible = !!selectedSquad || (!isSearch && !!messages);
        if (!isCurrentClubIsVisible) return null;
        let name;

        if (selectedSquad) {
            name = selectedSquad.name || '';
        } else if (activeChannel) {
            name = activeChannel.receiver.name;
        }
        return name;
    }

    public static selectIsAgentRecipient(state: AppState) {
        const channel = Selectors.selectActiveChannel(state);
        const myEndpointId = Selectors.currentUserEndpointId(state);

        const isUserSender = channel.sender.id === myEndpointId;
        const recipientType = isUserSender ? channel.receiver.type : channel.sender.type;
        return recipientType !== ReceiverType.Squad;
    }

    public static selectIsSquadRecipient(state: AppState) {
        const channel = Selectors.selectActiveChannel(state);
        const myEndpointId = Selectors.currentUserEndpointId(state);

        const isUserSender = channel.sender.id === myEndpointId;
        const recipientType = isUserSender ? channel.receiver.type : channel.sender.type;
        return recipientType === ReceiverType.Squad;
    }

    public static selectIsUserSender(state: AppState) {
        const channel = Selectors.selectActiveChannel(state);
        const myEndpointId = Selectors.currentUserEndpointId(state);
        return channel.sender.id === myEndpointId;
    }

    public static selectUserType(state: AppState): UserType {
        const { userTypeId } = getAuth(state);
        let userType: UserType;
        switch (userTypeId) {
            case UserTypeEnum.Agency:
                userType = UserType.Agency;
                break;
            case UserTypeEnum.Player:
                userType = UserType.Player;
                break;
            default:
                userType = UserType.Squad;
                break;
        }
        return userType;
    }

    public static getIsPlayerUser(state: AppState): boolean {
        const userType = Selectors.selectUserType(state);

        return userType === UserType.Player;
    }

    // Restricted when agency didn`t verified channel related player
    public static isSendMessageRestrictedForAgency(state: AppState) {
        const activeChannel = Selectors.selectActiveChannel(state);
        const userType = Selectors.selectUserType(state);
        const conversationType = activeChannel?.conversationType;
        if (!activeChannel || userType === UserType.Squad) return false;
        if (
            userType === UserType.Agency &&
            conversationType === ConversationTypeEnum.AgencyToPlayerRepresentation
        ) {
            return false;
        }

        return (
            userType === UserType.Agency &&
            (activeChannel.isPlayerVerified === false ||
                activeChannel.staffInfo?.isVerified === false)
        );
    }

    public static selectMessages(state: AppState) {
        const messages = state.messages.messages.items;
        if (!Selectors.isSendMessageRestrictedForAgency(state)) {
            return messages;
        } else {
            // If send message is restricted, we should hide chat companion Squad names and replace it with League placeholder
            const myEndpointId = Selectors.currentUserEndpointId(state);
            const relatedLeagueName = Selectors.selectRelativeLeague(state);
            return messages.map((item) => {
                return {
                    day: item.day,
                    messages: item.messages.map((item) => ({
                        ...item,
                        senderName:
                            item.messageType === MessageType.ClubAgencyPlayerInterest
                                ? ''
                                : item.senderId !== myEndpointId
                                  ? `${relatedLeagueName} Club`
                                  : item.senderName,
                    })),
                };
            });
        }
    }

    public static selectRelativeArea(state: AppState) {
        const channel = Selectors.selectActiveChannel(state);
        const isUserSender = Selectors.selectIsUserSender(state);
        const userType = Selectors.selectUserType(state);

        if (userType === UserType.Squad) {
            return isUserSender ? channel.sender.areaName : channel.receiver.areaName;
        }
        if (userType === UserType.Agency) {
            return isUserSender ? channel.receiver.areaName : channel.sender.areaName;
        }
    }

    public static selectRelativeLeague(state: AppState) {
        const channel = Selectors.selectActiveChannel(state);
        const isUserSender = Selectors.selectIsUserSender(state);
        const userType = Selectors.selectUserType(state);

        if (userType === UserType.Squad) {
            return isUserSender ? channel.sender.league : channel.receiver.league;
        }
        if (userType === UserType.Agency) {
            return isUserSender ? channel.receiver.league : channel.sender.league;
        }
    }

    public static selectRelativePlayerName(state: AppState) {
        const channel = Selectors.selectActiveChannel(state);
        return channel.playerName;
    }

    public static selectRelativeStaffName(state: AppState) {
        const channel = Selectors.selectActiveChannel(state);
        return channel.staffInfo?.staffName;
    }

    public static selectConversationType(state: AppState) {
        const channel = Selectors.selectActiveChannel(state);
        return channel.conversationType;
    }

    public static selectIsAgencysPlayerVerified(state: AppState) {
        return state.messages.channels.find((item) => item.id === state.messages.channelId)
            .isPlayerVerified;
    }

    public static selectIsAgencyStaffVerified(state: AppState) {
        return state.messages.channels.find((item) => item.id === state.messages.channelId)
            .staffInfo?.isVerified;
    }

    public static selectShouldShowVerificationWarning(state: AppState) {
        const objectiveChannel = state.messages.channels.find(
            (item) => item.id === state.messages.channelId
        );
        if (objectiveChannel.isPlayerVerified === null) return false; // null means that this channel could not be marked with worning
        if (objectiveChannel.isPlayerVerified === true) return false;
        if (objectiveChannel.isPlayerVerified === false) return true;
    }

    public static selectClubChannels(state: AppState) {
        const myEndpointId = Selectors.currentUserEndpointId(state);
        return state.messages.channels.filter((item) => {
            const isUserSender = item.sender.id === myEndpointId;
            const companionType = isUserSender ? item.receiver.type : item.sender.type;
            return companionType === ReceiverType.Squad;
        });
    }

    public static selectAgentsChannels(state: AppState) {
        const myEndpointId = Selectors.currentUserEndpointId(state);
        return state.messages.channels.filter((item) => {
            const isUserSender = item.sender.id === myEndpointId;
            const companionType = isUserSender ? item.receiver.type : item.sender.type;
            return companionType === ReceiverType.Agency;
        });
    }

    public static selectAllUnreadMessagesCount(state: AppState) {
        return state.messages.channels.reduce((acc, item) => {
            return item.unreadMessagesCount + acc;
        }, 0);
    }

    public static selectClubUnreadMessagesCount(state: AppState) {
        const channels = Selectors.selectClubChannels(state);
        return channels.reduce((acc, item) => {
            return item.unreadMessagesCount + acc;
        }, 0);
    }

    public static selectAgencyUnreadMessagesCount(state: AppState) {
        const channels = Selectors.selectAgentsChannels(state);
        return channels.reduce((acc, item) => {
            return item.unreadMessagesCount + acc;
        }, 0);
    }

    public static currentUserReceiverType(state: AppState) {
        const userType = Selectors.selectUserType(state);
        let senderType: ReceiverType;
        switch (userType) {
            case UserType.Squad:
                senderType = ReceiverType.Squad;
                break;

            case UserType.Agency:
                senderType = ReceiverType.Agency;
                break;

            case UserType.Player:
                senderType = ReceiverType.Player;
                break;
        }

        return senderType;
    }

    public static currentUserEndpointId(state: AppState) {
        const userType = Selectors.selectUserType(state);
        let endpointId: number;
        const auth = getAuth(state);
        switch (userType) {
            case UserType.Squad:
                endpointId = auth.squadId;
                break;

            case UserType.Agency:
                endpointId = auth.agencyId;
                break;

            case UserType.Player:
                endpointId = auth.playerId;
                break;
        }

        return endpointId;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    MessagesState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
};
