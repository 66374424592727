import axios from 'axios-config';
import { CancelToken } from 'axios';
import {
    PlayerPositionsResponse,
    PlayersDetailsResponse,
    SquadShortInfo,
    CreatePitchRequirementModel,
    PitchRequirementInfoModel,
} from './pitch-requirement.models';
import { IdName } from 'api/core/id-name';

export default class AgencyPitchRequirementService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };
    public static async getPlayerPositions(): Promise<PlayerPositionsResponse> {
        const { data } = await axios.get(`api/v3/Search/PlayerPositions`, this.axiosConfig);
        return data;
    }

    public static async getPlayersDetails(): Promise<PlayersDetailsResponse[]> {
        const { data } = await axios.get<PlayersDetailsResponse[]>(
            `api/v2/AgencyPlayer/Players/Details`,
            this.axiosConfig
        );
        return data;
    }

    public static async getSources(): Promise<IdName[]> {
        const { data } = await axios.get(`api/v2/AgentRequirement/GetSources`, this.axiosConfig);
        return data;
    }

    public static async getPlayerAdPitchedNoteStatuses(): Promise<IdName[]> {
        const { data } = await axios.get(
            `api/v2/AgentRequirement/GetPlayerAdPitchedNoteStatuses`,
            this.axiosConfig
        );
        return data;
    }

    public static async getTransferWindows(): Promise<IdName[]> {
        const { data } = await axios.get(
            `api/v2/AgentRequirement/GetTransferWindows`,
            this.axiosConfig
        );
        return data;
    }

    public static async getSquadsShortInfo(
        keyword: string,
        cancelToken: CancelToken
    ): Promise<SquadShortInfo[]> {
        const { data } = await axios.get(`api/Squad/SuggestSquads/0?keyword=${keyword}&take=200`, {
            ...this.axiosConfig,
            cancelToken: cancelToken,
        });
        return data.map((x) => ({
            id: x.id,
            name: x.englishOfficialName,
            shortName: x.shortName,
            league: x.league,
            areaName: x.areaName,
        }));
    }

    public static async createAgentRequirement(requirement: CreatePitchRequirementModel) {
        await axios.post(`api/v2/AgentRequirement/Create`, requirement, this.axiosConfig);
    }

    public static async getAgentRequirement(id: number): Promise<PitchRequirementInfoModel> {
        const { data } = await axios.get(`api/v2/AgentRequirement/${id}`, this.axiosConfig);
        return data;
    }
}
